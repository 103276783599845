/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import SideMenu from "../../layOuts/SideMenu";
import Header from "../../layOuts/Header";
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { Loader } from "../../layOuts/Loader";
import { ErrorPopup } from "../../layOuts/errorPopup";
import $ from "jquery";
import moment from "moment";
import * as Constant from "../../utils/constants";
import { Link } from "react-router-dom";
import { LinkPopup } from "../../layOuts/linkPopup";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import * as Common from "../../utils/common";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./DataTableDemo.css";
import ErrorHandlePopup from "../ErrorHandlePopup";
import Select from 'react-select-virtualized';
import { Dropdown } from "primereact/dropdown";
import { Calendar } from 'primereact/calendar';
import "react-datepicker/dist/react-datepicker.css";
class ConfiguredDeviceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRefreshModal: false,
      multipleSelect: "",
      toggledClearRows: false,
      showLoader: false,
      selectedTemplate: 1,
      selectedRows: null,
      selectedOption: [],
      title: "",
      message: "",
      show: false,
      showPopup: false,
      searchValue: "",
      showLink: false,
      showList: false,
      error: false,
      delete: false,
      factoryReset: false,
      reboot: false,
      keyRotation: false,
      pageNo: 1,
      pageSize: 10,
      first: 1,
      last: 10,
      next: true,
      prev: true,
      firstDefault: 0,
      modelList: [],
      deviceName: this.props?.history?.location?.state?.retain?.deviceName ? this.props?.history?.location?.state?.retain?.deviceName : [],
      deviceListWithId: [],
      deviceId: "",
      deviceModel: this.props?.location?.state?.deviceModel ? this.props?.location?.state?.deviceModel : "",
      showIcon: this.props?.location?.state?.deviceModel ? true : false,
      showFilter: false,
      totalCount: 0,
      deviceData: [],
      favDevices: false,
      favKey: false,
      order: '',
      sortOrder: 1,
      flag: 0,
      sortField: '',
      sortOn: false,
      searchClicked: false,
      status: this.props.location?.state?.deviceStatus || "",
      filterData: {},
      scheduleLater: false,
      startDate: null,
      allDevices: false,
      offlineDevice: false,
      pagenName: (this.props.history.location.state && this.props.history.location.state.pagenName) || "",
      searchRetain: (this.props.history.location.state && this.props.history.location.state.searchKey) || "",
      isAxiosError: false,
      columns: [
        {
          field: "device_name",
          header: "Device Name",
          value: "Device Name",
          body: this.deviceNameTemplate,
          frozen: false,
          alignFrozen: "left",
          sortable: true,
          style: { width: "170px", flexGrow: 1, minWidth: "130px" },
        },
        {
          field: "device_status",
          header: "Status",
          value: "Status",
          body: this.statusTemplate,
          frozen: false,
          sortable: true,
          style: { width: "150px", flexGrow: 1, minWidth: "110px" },
        },
        {
          field: "bootstrap_status",
          header: "Bootstrap Status",
          value: "Bootstrap Status",
          body: this.bootstrapStatusTemplate,
          frozen: false,
          sortable: true,
          style: { width: "150px", flexGrow: 1, minWidth: "110px" },
        },
        {
          field: "device_imei",
          header: "Device IMEI",
          frozen: false,
          value: "Device IMEI",
          sortable: false,
          body: this.deviceImeiTemplate,
          style: { width: "150px", flexGrow: 1, minWidth: "130px" },
        },
        {
          field: "device_mac",
          header: "MAC Address",
          frozen: false,
          value: "MAC Address",
          sortable: false,
          body: this.deviceMacTemplate,
          style: { width: "200px", flexGrow: 1, minWidth: "130px" },
        },
        {
          field: "device_model",
          header: "Model No.",
          frozen: false,
          value: "Model No.",
          sortable: false,
          body: this.deviceModelTemplate,
          style: { width: "150px", flexGrow: 1, minWidth: "130px" },
        },
        // added device group name column in table
        {
          field: "group_name",
          header: "Device Group",
          frozen: false,
          value: "Device Group",
          sortable: false,
          body: this.deviceGroupName,
          style: { width: "150px", flexGrow: 1, minWidth: "130px" },
        },
        {
          field: "",
          header: "Pending Ops",
          body: this.pendingOpsTemplate,
          frozen: false,
          value: "Pending Ops",
          sortable: false,
          style: { width: "150px", flexGrow: 1, minWidth: "130px" },
        },
        {
          field: "device_control_tunnel_ip",
          header: "Mgmt. Plane IP",
          frozen: false,
          value: "Mgmt. Plane IP",
          sortable: false,
          body: this.tunnelIpTemplate,
          style: { width: "150px", flexGrow: 1, minWidth: "130px" },
        },
        {
          field: "device_serial",
          header: "Serial No.",
          value: "Serial No.",
          frozen: false,
          sortable: true,
          body: this.deviceSerialTemplate,
          style: { width: "170px", flexGrow: 1, minWidth: "130px" },
        },
        {
          field: "device_configured_date",
          header: "Configured on",
          frozen: false,
          value: "Configured on",
          sortable: true,
          body: this.configuredDate,
          style: { width: "220px", flexGrow: 1, minWidth: "200px" },
        },
        {
          field: "Action",
          header: "",
          body: this.actionTemplate,
          value: "Action",
          style: { flexGrow: 1, flexBasis: "120px" },
          sortable: false,
          alignFrozen: "right",
          frozen: true,
        },
      ],
      showModel: false,
      is_bolt_reset: false,
    };
  }
  getDeviceListWithId = () => {
    let data = {};
    this.state.deviceData.map((item) => {
      data = { name: item.device_name, id: item.device_id };
      this.state.deviceListWithId.push(data);
    });
  };

  getFavDevices = () => {
    this.setState({
      favDevices: !this.state.favDevices,
      favKey: true,
      pageNo: 1
    }, () => this.getDeviceList())
  }

  returnClassStatus = (device_status) => {
    switch (device_status) {
      case 0:
        return "status offline";
      case 1:
        return "status online";
      case 2:
        return "status added";
      case 3:
        return "status available";
      case 4:
        return "status approve";
      default:
        return "status offline";
    }
  };

  returnClassBootstrapStatus = (bootstrap_status) => {
    switch (bootstrap_status) {
      case "Failure":
        return "status offline";
      case "Success":
        return "status online";
      case "In_Progress":
        return "status added";
      case "Not_Initiated":
        return "status not_initialized";
      case "In_Queue":
        return "status in_queue";
      default:
        return "";
    }
  };

  getTime = (time) => {
    let date = moment.utc(time);
    date = date.local();
    return date.format("DD MMMM YYYY hh:mm:ss A");
  };
  returnStatus = (device_status) => {
    switch (device_status) {
      case 0:
        return "Offline";
      case 1:
        return "Online";
      case 2:
        return "Added";
      case 3:
        return "Available";
      case 4:
        return "Auto Approved";
      default:
        return "Offline";
    }
  };

  returnBootstrapStatus = (bootstrap_status) => {
    switch (bootstrap_status) {
      case "Failure":
        return "Failure";
      case "Success":
        return "Success";
      case "In_Progress":
        return "In Progress";
      case "Not_Initiated":
        return "Not Initiated";
      case "In_Queue":
        return "In Queue";
      default:
        return "-";
    }
  };

  pendingOps = (data) => {
    let ops = [];
    let count = 0;
    if (data) {
      Object.values(data).forEach((item) => (count += item));
      ops.push(`${count}`);
    }
    return ops;
  };

  componentDidMount() {
    if (this.state.pagenName === "Clicked") {
      if (this.props.history.location.state && this.props.history.location.state.retain) {
        this.setState({
          deviceModel: this.props.history.location.state.retain.item1,
          deviceId: this.props.history.location.state.retain.item2,
          status: this.props.history.location.state.retain.item3,
          showIcon: true
        }, () => {
          this.listFromModel();
          this.getModelList();
        })
      }
    }
    else if (this.state.searchRetain === "SearchClicked") {
      this.setState({
        searchValue: this.props.history.location.state.searchValue
      }, () => {
        this.handleSearch()
      })
    }
    else if (this.props?.location?.state?.deviceModel) {
      this.listFromModel();
      this.getModelDeviceList(this.props?.location?.state?.deviceModel);
    }
    else {
      this.getDeviceList()
    }


    let newArray = this.state.columns;
    const localValue = JSON.parse(
      localStorage.getItem(Constant.CONFIG_DEVICE_LIST)
    );
    if (!!localValue) {
      newArray = [];
      localValue?.map((item) => {
        const column = this.valueReturn(item);
        if (!!column) newArray.push(column);
      });
    }
    this.setState({ selectedOption: newArray });
  }

  sortAlphaNum = (a, b) => {
    var reA = /[^a-zA-Z]/g;
    var reN = /[^0-9]/g;
    var aA = a.replace(reA, "");
    var bA = b.replace(reA, "");
    if (aA === bA) {
      var aN = parseInt(a.replace(reN, ""), 10);
      var bN = parseInt(b.replace(reN, ""), 10);
      return aN === bN ? 0 : aN > bN ? 1 : -1;
    } else {
      return aA > bA ? 1 : -1;
    }
  };
  deviceSort = (columnSort) => {
    if (this.state.flag < 2) {
      this.setState({
        sortField: columnSort.sortField,
        sortOrder: columnSort.sortOrder,
        order: columnSort.sortOrder === 1 ? 'ascending' : 'descending',
        sortOn: true,
        flag: this.state.flag + 1,
      }, this.state.searchClicked ? () => this.handleSearch('sort') : () => this.getDeviceList("sort")
      )
    } else {
      this.setState({
        sortField: null,
        order: 'descending',
        sortOn: true,
        flag: 0,
      }, this.state.searchClicked ? () => this.handleSearch('sort') : () => this.getDeviceList("sort")
      )
    }

  }
  //Get DeviceType List
  getDeviceList = async (sort) => {
    let sorting_parameter = sort === 'sort' && this.state.sortField !== null ? this.state.sortField : 'device_configured_date'
    let sorting_type = sort === 'sort' ? this.state.order : 'descending';
    let pinned = this.state.favDevices === true ? 1 : 0;
    let favKey = this.state.favKey === true ? 1 : 0;
    if (this.state.modelList.length > 0 || this.state.deviceModel) {
      this.listFromModel(sort);
    }
    //EMGT-36-EM - Filter should not get disturbed when doing refresh option
    else if (this.state.status) {
      this.listFromStatus(sort);
    } else {
      this.setState({
        // LONG-3171-EM - Contents entered in the search bar is not getting cleared after refresh
        searchValue: "",
        showLoader: true,
      });
      await ApiCall(
        urlMapping.getConfiguredDevicelist(
          sorting_parameter, sorting_type, pinned,
          this.state.pageNo,
          this.state.pageSize
        ),
        (response) => {
          this.setState({
            showLoader: false,
          });
          if (response?.results) {
            const data = response?.results;
            const totalCount = response?.count;
            const first = this.state.pageSize * (this.state.pageNo - 1) + 1;
            const last = response?.next
              ? this.state.pageSize * this.state.pageNo
              : response?.count;

            if (data) {
              if (data?.length === 0 && pinned === 1 && favKey === 1) {
                this.setState(
                  {
                    title: "Set as Favorite",
                    message: "No devices set as favorite to show. Select device and set as favorite from : option",
                    show: true,
                    error: false,
                    delete: false,
                    toggledClearRows: !this.state.toggledClearRows,
                    favDevices: !this.state.favDevices,
                    favKey: false,
                    showLoader: false
                  },
                  () => $("#errorMessage").modal("show")
                );
              }
              else {
                this.setState(
                  {
                    next: response?.next ? false : true,
                    prev: response?.previous ? false : true,
                    first: first,
                    last: last,
                    deviceData: data.filter((item) => {
                      return item.device_delete_status === 0;
                    }),
                    totalCount,
                    toggledClearRows: !this.state.toggledClearRows,
                    showLoader: false
                  },
                  () => this.getDeviceListWithId()
                );
              }
            }
          } else {
            if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
              this.setState({
                showRefreshModal: true,
                showLoader: false
              });
            } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
              this.setState({
                title: 'Tenant Error',
                message: response.response.data.message,
                showLoader: false,
                isAxiosError: true,
                delete: false,
                error: true
              }, () => $("#errorMessage").modal("show"))
            }
            else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined
              && response?.success === undefined
              && response?.response?.data?.success === undefined) {
              this.setState({
                title: 'Network Error',
                message: 'Something went wrong. Please try again later.',
                showLoader: false,
                isAxiosError: true,
                delete: false,
                error: true
              }, () => $("#errorMessage").modal("show"))
            }
          }
        }
      );
    }
  };
  checkDeviceTask = async () => {
    let deviceMac = [];

    this.state.multipleSelect.map((item) => deviceMac.push(item.device_mac));

    let data = { device_mac: deviceMac, operation_type: ["Firmware Upgrade"] };
    this.setState({
      showLoader: true,
    });

    const trueArr = [];
    await ApiCall(urlMapping.checkDeviceTask(data), (response) => {
      this.setState({
        showLoader: false,
        show: false,
      });
      $("#errorMessage").modal("hide");
      if (response?.success === true) {
        const data = response?.data;
        Object.keys(data)?.forEach((item) => {
          if (data?.[item]?.status === true) {
            trueArr?.push(data?.[item]);
          }
        });
        if (trueArr.length > 0) {
          this.setState(
            {
              title: "Device Task",
              message: trueArr?.[0]?.message,
              show: true,
              error: true,
              delete: false,
              toggledClearRows: !this.state.toggledClearRows,
            },
            () => $("#errorMessage").modal("show")
          );
        }
        this.setState({
          show: false,
          error: false,
          delete: false,
        });
      } else {
        $("#errorMessage").modal("hide");
        if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
          this.setState({
            showRefreshModal: true,
          });
        } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
          this.setState({
            title: 'Tenant Error',
            message: response.response.data.message,
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        } else {
          this.setState(
            {
              title: "Device Task",
              message: response.message,
              show: true,
              error: true,
              delete: false,
            },
            () => $("#errorMessage").modal("show")
          );
        }
      }
    });
    return trueArr;
  };
  saveConfiguration = () => {
    const data = [];
    this.state.multipleSelect.forEach((element) => {
      data.push({
        device_mac: element.device_mac,
        template_id: this.state.selectedTemplate,
      });
    });
    this.setState({
      showLoader: true,
    });
    ApiCall(urlMapping.saveConfig(data), (response) => {
      this.setState({
        showLoader: false,
      });
      $("#exampleModal").modal("hide");
      if (response?.data === "Success") {
        this.setState(
          {
            title: "Device Configuration",
            message: response?.message,
            show: true,
            error: false,
            delete: false,
            toggledClearRows: !this.state.toggledClearRows,
          },
          () => $("#errorMessage").modal("show")
        );
        this.getDeviceList();
        this.setState({
          show: false,
          error: false,
          delete: false,
        });
      } else {
        if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
          this.setState({
            showRefreshModal: true,
          });
        } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
          this.setState({
            title: 'Tenant Error',
            message: response.response.data.message,
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          this.setState(
            {
              title: "Device Configuration",
              message: response?.message,
              show: true,
              error: true,
              delete: false,
            },
            () => $("#errorMessage").modal("show")
          );
        }
      }
    });
  };

  confirmDelete = (row) => {
    //LONG-2530-EM - Pop-up should be included for delete device offline case
    let popupMessage;
    if (row) {
      if (row?.[0]?.group_name !== "") {
        popupMessage = "This device is present in the" + " \"" + `${row[0].group_name}` + "\" " + "device group. Please remove it from the device group before deleting.";
        this.setState(
          {
            title: "Factory Reset Device",
            message: popupMessage,
            show: true,
            error: true,
            delete: false,
          },
          () => {
            $("#errorMessage").modal("show");
          }
        );
      }
      else {
        if (row[0].device_status === 1) {
          popupMessage =
            "Factory Reset operation will remove all the applied configurations, delete all tunnels established, removes ZTP key files in the device, reset the BOLT and the device will go into factory state. ZTP process is needed to bring back device online to the EM. Click OK to proceed or Click Cancel to cancel the operation.";
          this.setState(
            {
              title:
                this.state.multipleSelect.length > 1
                  ? "Factory Reset Devices"
                  : "Factory Reset Device",
              message: popupMessage,
              show: true,
              error: true,
              delete: true,
            },
            () => {
              $("#errorMessage").modal("show");
            }
          );
        } else {
          popupMessage =
            "The device is offline.\nClick \"Yes\" to clear the device from this EM immediately. If the device comes online, it will show up on the onboard page. (Factory resets won't be performed on the device side.)\nor\nClick \"No\", The factory reset operation waits for the device to come online and performs a factory reset on the device. After 72 hours, EM will ignore this request."
          this.setState(
            {
              title:
                this.state.multipleSelect.length > 1
                  ? "Factory Reset Devices"
                  : "Factory Reset Device",
              message: popupMessage,
              show: true,
              error: true,
              info: true,
              delete: true,
              offlineDevice: true,
            },
            () => {
              $("#errorMessage").modal("show");
            }
          );
        }
      }
    } else {
      let groupDevice = this.state.multipleSelect?.filter(val => val?.group_name !== "");
      if (groupDevice?.length > 0) {
        popupMessage = groupDevice?.length === 1 ?
          "This device is present in the" + " \"" + `${groupDevice[0].group_name}` + "\" " + "device group. Please remove it from the device group before deleting." :
          "The selected devices are present in the device group. Please remove it from the device group before deleting.";
        this.setState(
          {
            title: groupDevice?.length === 1 ? "Factory Reset Device" : "Factory Reset Devices",
            message: popupMessage,
            show: true,
            error: true,
            delete: false,
          },
          () => {
            $("#errorMessage").modal("show");
          }
        )
      }
      else {
        if (this.state.multipleSelect.length > 0) {
          let multipleDataOnline = this.state.multipleSelect.filter(
            (item) => item.device_status === 1
          );
          let multipleDataOffLine = this.state.multipleSelect.filter(
            (item) => item.device_status === 0
          );
          if (multipleDataOnline.length > 0 && multipleDataOffLine.length > 0) {
            popupMessage =
              "The device is offline.\nClick \"Yes\" to clear the device from this EM immediately. If the device comes online, it will show up on the onboard page. (Factory resets won't be performed on the device side.)\nor\nClick \"No\", The factory reset operation waits for the device to come online and performs a factory reset on the device. After 72 hours, EM will ignore this request."
            this.setState(
              {
                title:
                  this.state.multipleSelect.length > 1
                    ? "Factory Reset Devices"
                    : "Factory Reset Device",
                message: popupMessage,
                show: true,
                error: true,
                info: true,
                delete: true,
                offlineDevice: true,
              },
              () => {
                $("#errorMessage").modal("show");
              }
            );
          } else if (multipleDataOnline.length > 0) {
            popupMessage =
              "Factory Reset operation will remove all the applied configurations, delete all tunnels established, removes ZTP key files in the device, reset the BOLT and the device will go into factory state. ZTP process is needed to bring back device online to the EM. Click OK to proceed or Click Cancel to cancel the operation.";
            this.setState(
              {
                title:
                  this.state.multipleSelect.length > 1
                    ? "Factory Reset Devices"
                    : "Factory Reset Device",
                message: popupMessage,
                show: true,
                error: true,
                delete: true,
              },
              () => {
                $("#errorMessage").modal("show");
              }
            );
          } else if (multipleDataOffLine.length > 0) {
            popupMessage =
              "The device is offline.\nClick \"Yes\" to clear the device from this EM immediately. If the device comes online, it will show up on the onboard page. (Factory resets won't be performed on the device side.)\nor\nClick \"No\", The factory reset operation waits for the device to come online and performs a factory reset on the device. After 72 hours, EM will ignore this request."
            this.setState(
              {
                title:
                  this.state.multipleSelect.length > 1
                    ? "Factory Reset Devices"
                    : "Factory Reset Device",
                message: popupMessage,
                show: true,
                error: true,
                info: true,
                delete: true,
                offlineDevice: true,
              },
              () => {
                $("#errorMessage").modal("show");
              }
            );
          }
        }
      }
    }
  };

  confirmFactoryReset = () => {
    this.setState(
      {
        title:
          this.state.multipleSelect.length > 1
            ? "Bootstrap Reset Devices"
            : "Bootstrap Reset Device",
        //LONG-3580
        message:
          "Bootstrap Reset operation will remove all the applied configurations, delete all tunnels established and the device will maintain the ZTP key files. ZTP process is not needed as device will come back online to EM automatically. Click OK to proceed or Click Cancel to cancel the operation.",
        show: true,
        error: true,
        delete: true,
        factoryReset: true,
      },
      () => {
        $("#errorMessage").modal("show");
      }
    );
  };
  confirmReboot = () => {
    this.setState(
      {
        title:
          this.state.multipleSelect.length > 1
            ? "Reboot Devices"
            : "Reboot Device",
        message:
          "Reboots the device and bring back device online to the EM automatically. Click OK to proceed or Click Cancel to cancel the operation.",
        show: true,
        error: true,
        delete: true,
        reboot: true,
      },
      () => {
        $("#errorMessage").modal("show");
      }
    );
  };

  deleteDevices = async () => {
    let data = { device_ids: [] };
    const id = [];
    this.state.multipleSelect.forEach((element) => {
      id.push(element.device_id);
    });
    data.device_ids = id;
    const haveTask = await this.checkDeviceTask();
    if (haveTask.length === 0) {
      this.setState({
        showLoader: true,
      });
      ApiCall(urlMapping.deleteDevice(data), (response) => {
        this.setState({
          showLoader: false,
        });
        if (response?.success === true) {
          let newPage = this.state.pageNo;
          if (this.state.pageNo > 1) {
            newPage =
              this.state.totalCount -
                this.state.pageSize * (this.state.pageNo - 1) -
                this.state.multipleSelect.length ===
                0
                ? this.state.pageNo - 1
                : this.state.pageNo;
          }
          $("#errorMessage").modal("hide");
          this.setState(
            {
              title: "Delete Devices",
              message:
                "Device Delete Operation Initiated Successfully.To see the progress ",
              showLink: true,
              id: response?.data,
              toggledClearRows: !this.state.toggledClearRows,
              pageNo: newPage,
              multipleSelect: "",
              showLoader: false
            },
            () => {
              $("#exampleModal").modal("show");
              this.getDeviceList();
            }
          );
        } else {
          $("#errorMessage").modal("hide");
          if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
            this.setState({
              showRefreshModal: true,
              showLoader: false
            });
          } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
            this.setState({
              title: 'Tenant Error',
              message: response.response.data.message,
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
          else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true,
              multipleSelect: ""
            }, () => {
              $("#errorMessage").modal("show");
              this.getDeviceList();
            })
          }
          else {
            this.setState(
              {
                title: "Delete Devices",
                message: response?.message,
                show: true,
                error: true,
                delete: false,
                showLoader: false,
                multipleSelect: ""
              },
              () => $("#errorMessage").modal("show")
            );
          }
        }
      });
    } else {
      this.setState({
        showLoader: false,
      });
    }
  };
  clearDevice = () => {
    this.setState({
      title: "",
      message: "",
      duplicate: false,
      showLoader: true,
      show: false,
      showPopup: false,
      error: false,
      delete: false,
      factoryReset: false,
      reboot: false,
      keyRotation: false,
      toggledClearRows: !this.state.toggledClearRows,
      scheduleLater: false,
      startDate: null,
      allDevices: false,
      offlineDevice: false
    })

    const id = [];
    this.state.multipleSelect.forEach((element) => {
      id.push(element.device_id);
    });
    let data = {
      device_ids: {
        devices_having: id,
        devices_to_approve: [],
      }
    };
    ApiCall(urlMapping.clearDevice(data), (response) => {
      if (response?.success) {
        let newPage = this.state.pageNo;
        if (this.state.pageNo > 1) {
          newPage =
            this.state.totalCount -
              this.state.pageSize * (this.state.pageNo - 1) -
              this.state.multipleSelect.length ===
              0
              ? this.state.pageNo - 1
              : this.state.pageNo;
        }
        this.setState({
          pageNo: newPage,
          title: this.state.multipleSelect.length > 1 ? "Clear Devices" : "Clear Device",
          showLoader: false,
          show: true,
          error: false,
          message: response?.message,
          multipleSelect: "",
        }, () => {
          $("#errorMessage").modal("show");
        })
      }
      else {
        if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
          this.setState({
            showLoader: false,
            showRefreshModal: true
          })
        } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
          this.setState({
            title: 'Tenant Error',
            message: response.response.data.message,
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          this.setState(
            {
              showLoader: false,
              multipleSelect: "",
            });
        }
      }
    })
  }
  confirmRotateKey = (row) => {
    if (row) {
      if (row[0].device_status === 1 && (row[0].PendingOperations === null)) {
        this.setState(
          {
            title: "Key Rotation",
            message: `Do you want to trigger Key rotation for: ${row[0].device_name}${"\n"}Note: There will be loss in Mgmt. Plane traffic during key rotation.`,
            showPopup: true,
            error: true,
            delete: true,
            keyRotation: true
          },
          () => {
            $("#keyRotationErrorMessage").modal("show");
          }
        );
      }
      else {
        this.setState(
          {
            title: "Key Rotation",
            message: `WARNING: The key rotation will not be triggered when the device is offline (or) when it has pending operation`,
            showPopup: true,
            error: true,
            keyRotation: false
          },
          () => {
            $("#keyRotationErrorMessage").modal("show");
          }
        );

      }
    }
    else {
      if (this.state.multipleSelect.length > 0) {
        this.setState(
          {
            title: "Key Rotation",
            message: `Do you want to trigger Key rotation? ${"\n"}Note: There will be loss in Mgmt. Plane traffic during key rotation.`,
            showPopup: true,
            error: true,
            delete: true,
            keyRotation: true
          },
          () => {
            $("#keyRotationErrorMessage").modal("show");
          }
        );
      }
    }
  }
  disableOption = (row) => {
    if (Common.getModels(row.device_model) === 'Packetfence' ||
      (this.props?.userRole === "Viewer_Group" || this.props?.userRole === "Operator_Group"))
      return "disabled"
    else if (process.env.REACT_APP_AD_USER === 'TRUE' &&
      (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
        localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true'))
      return "disabled"
    else
      return undefined
  }
  disableClick = (row) => {
    if (Common.getModels(row.device_model) === 'Packetfence' ||
      (this.props?.userRole === "Viewer_Group" || this.props?.userRole === "Operator_Group"))
      return ""
    else if (process.env.REACT_APP_AD_USER === 'TRUE' &&
      (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
        localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true'))
      return "disabled"
    else {
      this.setState({
        multipleSelect: [row]
      });
      this.confirmRotateKey([row]);
    }
  }
  setAsFavorite = (pinned) => {
    let data;
    this.setState({ showLoader: true });
    const device_ids = [];
    this.state.multipleSelect.forEach((element) => {
      device_ids.push(element.device_id);
    });
    data = {
      device_ids,
      pinning: pinned === true ? 1 : 0
    };
    ApiCall(
      urlMapping.setAsFavorite(
        data,
      ),
      (response) => {
        if (response?.success) {
          if (this.state.deviceData?.length === 1 || this.state.deviceData?.length === this.state.multipleSelect?.length) {
            this.setState({ favDevices: false })
          }
          this.setState(
            {
              title: pinned === true ? "Set as Favorite" : "Remove from Favorite",
              message: response?.message,
              show: true,
              error: false,
              showLoader: false
            },
            () => {
              $("#errorMessage").modal("show");
              this.getDeviceList();
            }
          );
        } else {
          if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
            this.setState({ showLoader: false, showRefreshModal: true });
          } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
            this.setState({
              title: 'Tenant Error',
              message: response.response.data.message,
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
          else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
          else {
            this.setState(
              {
                title: "Pin Device",
                message: response?.message,
                show: true,
                error: true,
                showLoader: false
              },
              () => {
                $("#errorMessage").modal("show");
              }
            );
          }
        }
      })
  }
  rotateKeyOperation = async () => {
    let data = {};
    if (this.state.multipleSelect.length > 0) {
      let deviceIds = this.state.multipleSelect.map(item => item.device_id)
      if (this.state.scheduleLater === true && this.state.startDate !== null) {
        data = {
          device_ids: deviceIds,
          scheduled_time: parseInt(new Date(this.state.startDate).getTime().toString().slice(0, 10)),
          on_all_device: this.state.allDevices
        }
      }
      else {
        data = {
          device_ids: deviceIds,
        }
      }
    }
    // data = {
    //   device_ids:[this.state.multipleSelect[0].device_id]
    // }
    this.setState({
      showLoader: true,
    });
    let msg = `Key Rotation has been scheduled for ${this.state.startDate}`
    ApiCall(urlMapping.rotateDeviceKey(data), (response) => {
      this.setState({
        showLoader: false,
      });

      if (response?.success === true) {
        $("#errorMessage").modal("hide");
        this.setState(
          {
            title: "Key Rotation",
            message: this.state.scheduleLater ? msg + " To view the Operation logs" : response?.message + " To view the Operation logs",
            showPopup: true,
            error: false,
            id: response?.data,
            showLink: true,
            toggledClearRows: !this.state.toggledClearRows,
            multipleSelect: "",
            delete: false,
          },
          () => $("#exampleModal").modal("show")
        );
        this.getDeviceList();
      } else {
        $("#errorMessage").modal("hide");
        if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
          this.setState({ showRefreshModal: true });
        } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
          this.setState({
            title: 'Tenant Error',
            message: response.response.data.message,
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        } else {
          this.setState(
            {
              title: "Key Rotation",
              message: response?.message,
              show: true,
              error: true,
              delete: false,
            },
            () => $("#errorMessage").modal("show")
          );
        }
      }
    });
  };
  multipleSelect(rows) {
    if (!rows.selectedRows.length) this.setState({ multipleSelect: "" });
    else {
      this.setState({ multipleSelect: rows.selectedRows });
    }
  }
  selectRows = (row) => {
    row.isSelected.selectableRowSelected = true;
    return row;
  };
  setTemplate = (e) => {
    this.setState({ selectedTemplate: e.target.value });
  };
  onCancel = () => {
    this.setState(
      {
        show: false,
        showPopup: false,
        error: false,
        delete: false,
        factoryReset: false,
        reboot: false,
        keyRotation: false,
        toggledClearRows: !this.state.toggledClearRows,
        scheduleLater: false,
        startDate: null,
        allDevices: false,
        offlineDevice: false
      },
      () => {
        $("#errorMessage").modal("hide");
        this.deleteDevices();
      }

    );
  }
  resetPopup = () => {
    this.setState(
      {
        show: false,
        showPopup: false,
        error: false,
        delete: false,
        factoryReset: false,
        reboot: false,
        keyRotation: false,
        // Long-1629 Reboot and Factory reset buttons become disable when clicked cancel in delete conformation
        multipleSelect: "",
        toggledClearRows: !this.state.toggledClearRows,
        scheduleLater: false,
        startDate: null,
        allDevices: false,
        offlineDevice: false
      },
      () => {
        $("#errorMessage").modal("hide");
        this.getDeviceList();
      }
    );
  };

  handleChange = (e) => {
    let array = [];
    this.state.selectedOption.map((option) => array.push(option.value));
    const result = e.target.value;
    if (result === "selectAll") {
      array = [];
      if (e.target.checked) {
        this.state.columns.map((column) => array.push(column.value));
      } else {
        array = ["Action"];
      }
    } else {
      if (e.target.checked) array.push(result);
      else array.splice(array.indexOf(result), 1);
    }
    if (array.includes("Action")) {
      array.splice(array.indexOf("Action"), 1);
      array.push("Action");
    }
    const selectedVlaues = [];
    array.map((item) => {
      const column = this.valueReturn(item);
      selectedVlaues.push(column);
    });
    localStorage.setItem(Constant.CONFIG_DEVICE_LIST, JSON.stringify(array));
    this.setState({ selectedOption: selectedVlaues });
  };
  handleSearch = (sort) => {
    this.setState({
      showLoader: true
    });
    let data = {
      device_data: this.state.searchValue,
      sorting_parameter: sort === 'sort' && this.state.sortField !== null ? this.state.sortField : 'created_date',
      sorting_type: sort === 'sort' ? this.state.order : 'descending',
      pinned: this.state.favDevices === true ? 1 : 0,
      approved: 1
    };
    ApiCall(
      urlMapping.searchDeviceList(
        this.state.pageNo,
        this.state.pageSize,
        data
      ),
      (response) => {
        if (response) {
          if (response?.results) {
            const first =
              this.state.pageSize * (this.state.pageNo - 1) + 1;
            const last = response.next
              ? this.state.pageSize * this.state.pageNo
              : response?.count;
            this.setState(
              {
                showLoader: false,
                next: response?.next ? false : true,
                prev: response?.previous ? false : true,
                first: first,
                last: last,
                deviceData: response?.results.filter(
                  (item) => item.device_delete_status === 0
                ),
                totalCount: response?.count,
              },
              () => this.getDeviceListWithId()
            );
          } else {
            if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
              this.setState({ showRefreshModal: true });
            } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
              this.setState({
                title: 'Tenant Error',
                message: response.response.data.message,
                showLoader: false,
                isAxiosError: true,
                delete: false,
                error: true
              }, () => $("#errorMessage").modal("show"))
            }
            else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
              this.setState({
                title: 'Network Error',
                message: 'Something went wrong. Please try again later.',
                showLoader: false,
                isAxiosError: true,
                delete: false,
                error: true
              }, () => $("#errorMessage").modal("show"))
            }
          }
        }
      }
    )
  };
  handleOnChange = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
    if (e.target.value === "") {
      this.getDeviceList();
    }
  };
  handleKeypress = (e) => {
    if (e.key === 'Enter') {
      this.setState(
        {
          pageNo: 1,
          pageSize: 10,
          searchClicked: true,
          deviceModel: "",
          deviceId: "",
          status: "",
          showFilter: false,
          deviceName: [],
          showIcon: false,
          modelList: [],
          pagenName: "",
          filterData: {}
        },
        () => { this.handleSearch() });
    }
  };
  clearSearch = () => {
    this.setState(
      {
        searchValue: "",
        pageNo: 1,
        searchClicked: false
      },
      () => this.getDeviceList()
    );
  };
  valueReturn = (item) => {
    return this.state.columns.filter((column) => column.value === item)[0];
  };
  getModelList = () => {
    this.setState((prevState) => ({ showFilter: !prevState.showFilter }));
    ApiCall(urlMapping.getModelList(), (response) => {
      if (response?.success) {
        this.setState({ modelList: response?.data });
      } else {
        if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
          this.setState({ showRefreshModal: true });
        } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
          this.setState({
            title: 'Tenant Error',
            message: response.response.data.message,
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
      }
    });
  };
  // On change select issue
  getDetailFromDevice = (e) => {
    if (e && e.label) {
      this.setState({
        deviceId: e.value
      })
    }
    else {
      this.setState({
        deviceId: '',
      })
    }
  };
  getOptions = () => {
    let options = [];
    options = this.state.deviceName.map((device, index) => {
      return { label: device.device_name, value: device.hostname }
    })
    return options;
  }
  filterOptions = (option, inputValue) => {
    const { label } = option;
    const otherKey = this.getOptions().filter(
      (opt) => opt.label.toLowerCase() === label.toLowerCase() && opt.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    return label.toLowerCase().includes(inputValue.toLowerCase()) || otherKey.length > 0;
  }
  getModelDeviceList = (value) => {
    if (value === "Select") {
      this.setState({ deviceModel: "", deviceName: [], deviceId: "" });
    } else {
      this.setState({
        deviceModel: value,
        deviceId: "",
        showLoader: true
      });
      let data = { device_model: value };
      ApiCall(urlMapping.getModelDeviceList(data), (response) => {
        if (response?.success) {
          this.setState({ deviceName: response?.data, showLoader: false });
        } else {
          if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
            this.setState({ showRefreshModal: true, showLoader: false });
          } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
            this.setState({
              title: 'Tenant Error',
              message: response.response.data.message,
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
          else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
        }
      });
    }
  };

  getStatus = (e) => {
    if (e.target.value === "select") {
      this.setState({ status: "" });
    } else {
      this.setState({ status: e.target.value });
    }
  }

  listFromStatus = (sort) => {
    let data;
    this.setState({ showLoader: true });
    data = {
      device_status:
        this.state.status === "offline" ? 0 : 1,
      sorting_parameter: sort === 'sort' && this.state.sortField !== null ? this.state.sortField : 'created_date',
      sorting_type: sort === 'sort' ? this.state.order : 'descending'
    };
    data.pinned = this.state.favDevices === true ? 1 : 0;
    ApiCall(
      urlMapping.getDeviceListByModel(
        data,
        this.state.pageNo,
        this.state.pageSize
      ),
      (response) => {
        if (response?.results) {
          const first = response?.previous
            ? this.state.pageSize * (this.state.pageNo - 1) + 1
            : 1;
          const last = response?.next
            ? this.state.pageSize * this.state.pageNo
            : response?.count;
          this.setState({
            deviceData: response?.results,
            showLoader: false,
            next: response?.next ? false : true,
            showIcon: true,
            prev: response?.previous ? false : true,
            first: first,
            last: last,
            totalCount: response?.count,
          });
        } else {
          if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
            this.setState({ showLoader: false, showRefreshModal: true });
          } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
            this.setState({
              title: 'Tenant Error',
              message: response.response.data.message,
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
          else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
        }
      }
    );
  };

  listFromModel = (sort) => {
    let data;
    this.setState({ showLoader: true });
    if (this.state.deviceModel) {
      data = {
        device_model: this.state.deviceModel,
        sorting_parameter: sort === 'sort' && this.state.sortField !== null ? this.state.sortField : 'created_date',
        sorting_type: sort === 'sort' ? this.state.order : 'descending'
      };
    } if (this.state.deviceId) {
      data = {
        ...data, hostname: this.state.deviceId,
        sorting_parameter: sort === 'sort' && this.state.sortField !== null ? this.state.sortField : 'created_date',
        sorting_type: sort === 'sort' ? this.state.order : 'descending'
      };
    }
    if (this.state.status) {
      data = {
        ...data,
        device_status: this.state.status === "offline" ? 0 : 1,
        sorting_parameter: sort === 'sort' && this.state.sortField !== null ? this.state.sortField : 'created_date',
        sorting_type: sort === 'sort' ? this.state.order : 'descending'
      };
    }
    data.pinned = this.state.favDevices === true ? 1 : 0;
    ApiCall(
      urlMapping.getDeviceListByModel(
        data,
        this.state.pageNo,
        this.state.pageSize
      ),
      (response) => {
        if (response?.results) {
          this.setState({
            filterData: {
              deviceModel: this.state.deviceModel,
              deviceId: this.state.deviceId,
              status: this.state.status
            }
          })
          const first = response.previous
            ? this.state.pageSize * (this.state.pageNo - 1) + 1
            : 1;
          const last = response?.next
            ? this.state.pageSize * this.state.pageNo
            : response?.count;
          this.setState({
            deviceData: response?.results,
            showLoader: false,
            showFilter: false,
            // showIcon: true,
            next: response?.next ? false : true,
            prev: response?.previous ? false : true,
            first: first,
            last: last,
            totalCount: response?.count,
          });
        } else {
          if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
            this.setState({ showLoader: false, showRefreshModal: true });
          } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
            this.setState({
              title: 'Tenant Error',
              message: response.response.data.message,
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
          else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
        }
      }
    );
  };
  clearDropdown = (type) => {
    if (type === "Status") this.setState({ status: "" });
    if (type === "Model") {
      this.setState({ deviceModel: "", deviceName: [], deviceId: "" });
    } else {
      this.setState({ deviceId: "" });
    }
  };
  resetValue = () => {
    this.setState(
      {
        deviceModel: "",
        deviceId: "",
        status: "",
        showFilter: false,
        deviceName: [],
        showIcon: false,
        modelList: [],
        pageNo: 1,
        pagenName: "",
        filterData: {}

      },
      () => this.getDeviceList()
    );
  };
  hideDropdown = (e) => {
    var popup = $("#parentDiv");
    if (
      !$("#open").is(e.target) &&
      !popup.is(e.target) &&
      popup.has(e.target).length === 0
    ) {
      this.setState({
        showFilter: false,
        deviceModel: this.state.showIcon ? this.state.deviceModel : '',
        modelList: this.state.showIcon ? this.state.modelList : []
      });
    }
  };

  factoryResetDevices = async () => {
    let deviceId = [];
    this.state.multipleSelect.map((element) =>
      deviceId.push(element.device_id)
    );
    let data = {
      device_ids: deviceId,
      is_bolt_reset: this.state.is_bolt_reset,
    };
    this.setState({
      showLoader: true,
    });

    const haveTask = await this.checkDeviceTask();

    if (haveTask.length === 0) {
      ApiCall(urlMapping.factoryResetDevices(data), (response) => {
        if (response?.success) {
          $("#errorMessage").modal("hide");
          this.setState({
            showLoader: false,
          });
          this.setState(
            {
              title: "Bootstrap Reset Devices",
              message: response?.message,
              show: true,
              error: false,
              id: deviceId,
              showList: true,
              toggledClearRows: !this.state.toggledClearRows,
              multipleSelect: "",
              delete: false,
              factoryReset: false,
              is_bolt_reset: false,
            },
            () => $("#exampleModal").modal("show")
          );
          this.getDeviceList();
        } else {
          $("#errorMessage").modal("hide");
          if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
            this.setState({
              showLoader: false,
              showRefreshModal: true,
            });
          } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
            this.setState({
              title: 'Tenant Error',
              message: response.response.data.message,
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          } else {
            this.setState(
              {
                title: "Bootstrap Reset Devices",
                message: response?.message,
                show: true,
                error: true,
                delete: false,
                factoryReset: false,
                is_bolt_reset: false,
              },
              () => $("#errorMessage").modal("show")
            );
          }
        }
      });
    } else {
      this.setState({
        showLoader: false,
      });
    }
  };

  rebootDevices = async () => {
    let deviceId = [];
    this.state.multipleSelect.map((element) =>
      deviceId.push(element.device_id)
    );
    let data = { device_ids: deviceId };
    this.setState({
      showLoader: true,
    });
    const haveTask = await this.checkDeviceTask();

    if (haveTask.length === 0) {
      ApiCall(urlMapping.rebootDevices(data), (response) => {
        this.setState({
          showLoader: false,
        });
        if (response?.success) {
          $("#errorMessage").modal("hide");
          this.setState(
            {
              title: "Reboot Devices",
              message:
                "Reboot Operation Initiated Successfully. To view the Operation logs ",
              show: true,
              error: false,
              id: deviceId,
              showList: true,
              toggledClearRows: !this.state.toggledClearRows,
              multipleSelect: "",
              delete: false,
            },
            () => $("#exampleModal").modal("show")
          );
          this.getDeviceList();
        } else {
          $("#errorMessage").modal("hide");
          if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
            this.setState({ showRefreshModal: true });
          } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
            this.setState({
              title: 'Tenant Error',
              message: response.response.data.message,
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
          else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
          else {
            this.setState(
              {
                title: "Reboot Devices",
                message: response?.message,
                show: true,
                error: true,
                delete: false,
              },
              () => $("#errorMessage").modal("show")
            );
          }
        }
      });
    } else {
      this.setState({
        showLoader: false,
      });
    }
  };
  checkBoltAction = () => {
    $("#errorMessage").modal("hide");
    //LONG-2242-EM - Bootstrap page - name for Bolt and cancel button in bolt popup
    this.setState(
      {
        title: "Factory Reset BOLT",
        message:
          "Do you want to Factory Reset the BOLT as well? (Factory Resetting Bolt will reset the APN Configurations)",
        show: false,
        error: true,
        delete: true,
        showModel: true,
        factoryReset: true,
      },
      () => {
        $("#errorMessage").modal("show");
      }
    );
  };
  chooseAction = (deleteAct, factoryResetAct, rebootAct) => {
    if (this.state.offlineDevice && deleteAct)
      this.clearDevice()
    else if (deleteAct && factoryResetAct && !this.state.offlineDevice) {
      this.factoryResetDevices();
    } else if (deleteAct && rebootAct) {
      this.rebootDevices();
      this.setState({ reboot: false });
    } else if (deleteAct) {
      this.deleteDevices();
    } else {
      return "undefined";
    }
  };

  checkOnlineDevices = () => {
    let result = false;
    if (
      this.state.multipleSelect.filter((item) => item.device_status === 0)
        .length > 0
    )
      result = true;
    return result;
  };
  checkPendingOps = () => {
    let result = false;
    if (this.state.multipleSelect.filter((ops) => ops.PendingOperations !== null).length > 0) {
      if (this.state.multipleSelect.filter((item) => (item.PendingOperations === 1).length > 0))
        result = true;
      return result;
    }
  };
  checkGroup = () => {
    let group = false;
    const result = this.state.multipleSelect.filter(
      (item) => item.group_name !== "" && item.group_name !== null
    );
    if (result.length > 0) {
      group = true;
    }
    return group;
  };

  openOperationList = (value) => {
    $("#exampleModal").modal("hide");
    this.props.history.push({
      pathname: "/operations",
      state: { data: value },
    });
  };
  closeLinkpopup = () => {
    $("#exampleModal").modal("hide");
    this.setState({ showLink: false, id: "", scheduleLater: false, startDate: null, allDevices: false });
  };

  onSelectRow = (e) => {
    if (e.value.length === 0) {
      this.setState({
        multipleSelect: "",
      });
    } else {
      this.setState({
        multipleSelect: e.value,
      });
    }
  };
  setOnAllDevices = () => {
    this.setState({
      allDevices: !this.state.allDevices
    })
  }
  scheduleLater = () => {
    this.setState({
      scheduleLater: !this.state.scheduleLater
    });
  }
  setStartDate = (e) => {
    this.setState({
      startDate: e.value
    })
  }
  monthNavigatorTemplate(e) {
    return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} style={{ lineHeight: 1 }} />;
  }

  yearNavigatorTemplate(e) {
    return <Dropdown value={e.value} options={e.options} onChange={(event) => e.onChange(event.originalEvent, event.value)} className="ml-2" style={{ lineHeight: 1 }} />;
  }
  deviceNameTemplate = (row) => {
    return (
      <span
        title={row.device_name}
        className="fav-icon"
      >
        {row.is_pinned &&
          <img src="/images/svg-icons/star-filled-yellow.svg" alt="" />}
        <Link
          to={{
            pathname: "/devicedetails",
            state: {
              data: row, DeviceList: this.state.deviceListWithId,
              filterData: this.state.filterData, searchValue: this.state.searchValue,
              deviceName: this.state.deviceName
            },
          }}
          className="txt-blue"
        >
          {` ${row.device_name} `}
        </Link>
        {row.is_operation_failed ? (
          <img src="/images/svg-icons/failed-temp-alert.svg" alt=""
            style={{ width: "13px" }}
            title={"Some of the templates are in failed/pending status and may affect the functionality of dependent features, please re-apply the template to have all features working as expected."}
            onClick={() => this.openTemplates(row)} />
        ) : (
          ""
        )}
      </span>
    );
  };
  openTemplates = (row) => {
    this.props.history.push({
      pathname: '/devicedetails',
      state: {
        selectedTab: "AppliedTemplate", data: row, DeviceList: this.state.deviceListWithId,
        filterData: this.state.filterData, searchValue: this.state.searchValue, tabChange: true
      },
    })
  }
  actionTemplate = (row) => {
    return (
      <React.Fragment>
        <div className="da-box dropleft">
          <img
            className="a-dots"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            src="/images/svg-icons/ellipsis.svg"
            alt=""
          />
          <div className="drop-box dropdown-menu">
            <ul>
              <li
                onClick={() =>
                  this.props.history.push({
                    pathname: "/devicedetails",
                    state: { data: row },
                  })
                }
              >
                <img src="/images/svg-icons/view.svg" alt="" /> View
              </li>
              {/* removed for multitenancy */}

              <li
                className={
                  (row?.device_model !== "V2000") ||
                    (this.props?.userRole === "Viewer_Group" ||
                      this.props?.userRole === "Operator_Group")
                    ? "disabled"
                    : ""}
                onClick={
                  (row?.device_model !== "V2000") ||
                    (this.props?.userRole === "Viewer_Group" ||
                      this.props?.userRole === "Operator_Group")
                    ? undefined
                    : () => {
                      this.setState({ multipleSelect: [row] });
                      this.confirmDelete([row]);
                    }
                }
              >
                <img
                  className="delete"
                  src="/images/svg-icons/delete.svg"
                  alt=""
                />{" "}
                Factory Reset
              </li>
              <li
                className={this.disableOption(row)}
                onClick={() => { this.disableClick(row) }}
              >
                <img
                  src="/images/svg-icons/rotate_key.svg"
                  alt=""
                />{" "}
                Rotate Key
              </li>
              <li
                onClick={() => this.setState({ multipleSelect: [row] }, () => this.setAsFavorite(row.is_pinned ? false : true))}>
                {row.is_pinned ? <img src="/images/svg-icons/star.svg" alt="" /> :
                  <img src="/images/svg-icons/star-filled.svg" alt="" />}
                {" "}
                {row.is_pinned ? "Remove from Favorite" : "Set as Favorite"}
              </li>
            </ul>
          </div>
        </div>
      </React.Fragment >
    );
  };

  pendingOpsTemplate = (row) => {
    return (
      <span title={row.PendingOperations ? JSON.stringify(row.PendingOperations) : 0}>
        {/* {this.pendingOps(row.PendingOperations)} */}
        <Link to={{
          pathname: '/operations',
          state: {
            pendingOperation: row,
          }
        }} className="txt-blue">{row.PendingOperations ? row.PendingOperations : 0}</Link>
      </span>
    );
  };

  statusTemplate = (row) => {
    return (
      <div id={`col${row.device_id}`}>
        <span id={`icon${row.device_id}`}>
          {" "}
          <i className={this.returnClassStatus(row.device_status)}></i>
          {this.returnStatus(row.device_status)}
        </span>
      </div>
    );
  };

  bootstrapStatusTemplate = (row) => {
    return (
      <div id={`col${row.device_id}`}>
        <span id={`icon${row.device_id}`}>
          {" "}
          <i className={this.returnClassBootstrapStatus(row.bootstrap_status)}></i>
          {this.returnBootstrapStatus(row.bootstrap_status)}
        </span>
      </div>
    );
  };

  deviceSerialTemplate = (row) => {
    return <span title={row.device_serial}>{row.device_serial}</span>;
  };

  deviceMacTemplate = (row) => {
    return (
      <span title={row.device_mac.toUpperCase()} style={{ width: "auto" }}>
        {row.device_mac.toUpperCase()}
      </span>
    );
  };
  deviceModelTemplate = (row) => {
    return <span title={row.device_model}>{row.device_model}</span>;
  };
  // added device group name column in table
  deviceGroupName = (row) => {
    return (
      <span title={row.group_name}>
        {row.group_name !== "" &&
          row.group_name !== "null" &&
          row.group_name !== null
          ? row.group_name
          : "-"}
      </span>
    );
  };
  tunnelIpTemplate = (row) => {
    return (
      <span title={row.device_control_tunnel_ip}>
        {row.device_control_tunnel_ip !== "" &&
          row.device_control_tunnel_ip !== "null" &&
          row.device_control_tunnel_ip !== null
          ? row.device_control_tunnel_ip
          : "-"}
      </span>
    );
  };
  deviceImeiTemplate = (row) => {
    return (
      <span title={row.device_imei}>
        {row.device_imei !== "" &&
          row.device_imei !== "null" &&
          row.device_imei !== null
          ? row.device_imei
          : "-"}
      </span>
    );
  };
  configuredDate = (row) => {
    return (
      <span
        className="date-time"
        title={this.getTime(row.device_configured_date)}
      >
        {this.getTime(row.device_configured_date)}
      </span>
    );
  };

  generateActivation = () => {
    this.setState({ showLoader: true });
    const device_ids = [];
    this.state.multipleSelect.forEach((element) => {
      device_ids.push(element.device_id);
    });
    const data = {
      device_id: device_ids
    }
    ApiCall(urlMapping.activationKey(data), (response) => {
      if (response) {
        this.setState({
          showLoader: false,
          multipleSelect: "",
        });
        const csvLines = Object.values(response).join('').split('\n');
        if (csvLines[csvLines.length - 1].includes('[object Object]')) {
          csvLines.pop();
        }
        const csvData = csvLines.join('\n');
        const blobData = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
        const url = window.URL.createObjectURL(blobData);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Activation_Code.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
          this.setState({ showRefreshModal: true, showLoader: false });
        } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
          this.setState({
            title: 'Tenant Error',
            message: response.response.data.message,
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => {
            $("#errorMessage").modal("show");
          });
        } else if (
          response?.isAxiosError &&
          response?.success === undefined &&
          response?.response?.data?.success === undefined
        ) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true,
            multipleSelect: "",
          }, () => {
            $("#errorMessage").modal("show");
          });
        } else {
          this.setState({
            showLoader: false,
            multipleSelect: "",
          });
        }
      }
    });
  };

  setPerPage = (e) => {
    this.setState(
      {
        pageNo: 1,
        pageSize: e.value,
      },
      () => {
        (this.state.searchValue !== "" && this.state.sortOn === true) ? this.handleSearch('sort') :
          (this.state.searchValue === "" && this.state.sortOn === true) ? this.getDeviceList('sort') :
            (this.state.searchValue !== "" && this.state.sortOn !== true) ? this.handleSearch() :
              this.getDeviceList()
      });

  };

  nextPageChangeHandle = () => {
    this.setState(
      {
        pageNo: this.state.pageNo + 1,
      },
      () => {
        (this.state.searchValue !== "" && this.state.sortOn === true) ? this.handleSearch('sort') :
          (this.state.searchValue === "" && this.state.sortOn === true) ? this.getDeviceList('sort') :
            (this.state.searchValue !== "" && this.state.sortOn !== true) ? this.handleSearch() :
              this.getDeviceList()
      });
  };

  prevPageChangeHandle = () => {
    this.setState(
      {
        pageNo: this.state.pageNo - 1,
      },
      () => {
        (this.state.searchValue !== "" && this.state.sortOn === true) ? this.handleSearch('sort') :
          (this.state.searchValue === "" && this.state.sortOn === true) ? this.getDeviceList('sort') :
            (this.state.searchValue !== "" && this.state.sortOn !== true) ? this.handleSearch() :
              this.getDeviceList()
      });
  };
  firstPageChangeHandle = () => {
    this.setState({
      pageNo: 1
    },
      () => {
        (this.state.searchValue !== "" && this.state.sortOn === true) ? this.handleSearch('sort') :
          (this.state.searchValue === "" && this.state.sortOn === true) ? this.getDeviceList('sort') :
            (this.state.searchValue !== "" && this.state.sortOn !== true) ? this.handleSearch() :
              this.getDeviceList()
      });
  }
  lastPageChangeHandle = () => {
    this.setState({
      pageNo: Math.ceil(this.state.totalCount / this.state.pageSize)
    },
      () => {
        (this.state.searchValue !== "" && this.state.sortOn === true) ? this.handleSearch('sort') :
          (this.state.searchValue === "" && this.state.sortOn === true) ? this.getDeviceList('sort') :
            (this.state.searchValue !== "" && this.state.sortOn !== true) ? this.handleSearch() :
              this.getDeviceList()
      });
  }
  render() {
    let colArray =
      this.state.selectedOption.length === 0
        ? this.state.columns
        : this.state.selectedOption;
    const dynamicColumns = colArray.map((col, i) => {
      return (
        <Column
          key={col.field}
          field={col.field}
          resizable={col.resizable}
          sortable={col.sortable}
          header={col.header}
          body={col.body}
          style={col.style}
          alignFrozen={col.alignFrozen}
          frozen={col.frozen}
        />
      );
    });

    const template2 = {
      layout: 'RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink',
      RowsPerPageDropdown: (options) => {
        const dropdownOptions = [
          { label: 10, value: 10 },
          { label: 20, value: 20 },
          { label: 30, value: 30 },
          { label: 40, value: 40 },
          { label: 50, value: 50 }
        ];

        return (
          <React.Fragment>
            <span
              className="p-mx-1"
              style={{ color: "var(--text-color)", userSelect: "none" }}
            >
              Rows per page:{" "}
            </span>
            <Dropdown
              value={this.state.pageSize}
              options={dropdownOptions}
              onChange={(e) => this.setPerPage(e)}
            />
          </React.Fragment>
        );
      },
      CurrentPageReport: (options) => {
        return (
          <span
            style={{
              color: "var(--text-color)",
              userSelect: "none",
              width: "120px",
              textAlign: "center",
            }}
          >
            {this.state.first} - {this.state.last} of {this.state.totalCount}
          </span>
        );
      },
      'FirstPageLink': () => {
        return (
          <button type="button"
            className=" p-link1"
            onClick={() => this.firstPageChangeHandle()}
            disabled={this.state.prev}
          >
            <img
              className={this.state.prev ? "p-link3" : "p-link2"}
              src={this.state.prev ? "/images/svg-icons/arrow-pre-disabled.svg" : "/images/svg-icons/arrow-pre.svg"}
              alt=""
            />
          </button>
        )
      },
      'PrevPageLink': () => {
        return (
          <button type="button"
            className=" p-link1"
            onClick={() => this.prevPageChangeHandle()}
            disabled={this.state.prev}
          >
            <i className="pi pi-chevron-left"></i>
          </button>
        )
      },
      'NextPageLink': () => {
        return (
          <button
            type="button"
            className="p-link1"
            onClick={() => this.nextPageChangeHandle()}
            disabled={this.state.next}
          >
            <i className="pi pi-chevron-right"></i>
          </button>
        )
      },
      'LastPageLink': () => {
        return (
          <button type="button"
            onClick={() => this.lastPageChangeHandle()}
            className=" p-link1"
            disabled={this.state.next}
          >
            <img
              className={this.state.next ? "p-link3" : "p-link2"}
              src={this.state.next ? "/images/svg-icons/arrow-next-disabled.svg" : "/images/svg-icons/arrow-next.svg"}
              alt=""
            />
          </button>
        )
      },
    };

    return (
      <div onMouseDown={(e) => this.hideDropdown(e)}>
        <SideMenu />
        {this.state.showLoader && <Loader />}
        <section
          id="rhsMain"
          className={this.props.open ? "rhs-main" : "rhs-main expand-w"}
        >
          <Header />
          <div className="white-bg  page-height">
            <div className="ta-box">
              <h1 className="page-title"> Devices
                <span className="fav-list" onClick={this.getFavDevices}>
                  {this.state.favDevices ? <img src="/images/svg-icons/star-filled-yellow.svg" alt="" /> :
                    <img src="/images/svg-icons/star.svg" alt="" />}
                  Favorite
                </span>
              </h1>
              <div className="action-btn d-flex align-items-center">
                <div className="search-box">
                  <input
                    type="text"
                    className="show"
                    placeholder="Please enter the device name, MAC address, or IMEI"
                    onChange={(e) => this.handleOnChange(e)}
                    value={this.state.searchValue}
                    onKeyPress={(e) => this.handleKeypress(e)} />
                  {this.state.searchValue && (
                    <>
                      <img
                        className="clear-icon show"
                        src="/images/svg-icons/cancel.svg"
                        onClick={() => this.clearSearch()}
                        alt=""
                      />
                    </>
                  )}
                  <button
                    className="search-btn"
                    disabled={this.state.searchValue === ""}
                    onClick={() => {
                      this.setState(
                        {
                          pageNo: 1,
                          pageSize: 10,
                          searchClicked: true,
                          deviceModel: "",
                          deviceId: "",
                          status: "",
                          showFilter: false,
                          deviceName: [],
                          showIcon: false,
                          modelList: [],
                          pagenName: "",
                          filterData: {}
                        },
                        () => { this.handleSearch() });
                    }
                    }
                  >
                    <img src="/images/svg-icons/search.svg" alt="" />
                  </button>
                </div>
                {/* Disable Bootstrap Reset & Reboot if device is added to device group */}
                <button onClick={() => this.getDeviceList()} title="Refresh">
                  <img src="/images/svg-icons/refreshing.svg" alt="" />
                  {/* Refresh */}
                </button>
                {
                  <button
                    className="button-lst"
                    onClick={() => this.confirmFactoryReset()}
                    disabled={
                      (process.env.REACT_APP_AD_USER === 'TRUE' &&
                        (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                          localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                      (this.props?.userRole === "Viewer_Group" || this.props?.userRole === "Operator_Group") ||
                      this.state.multipleSelect === "" ||
                      (this.state.multipleSelect.length > 0 &&
                        (this.checkOnlineDevices() || this.checkGroup()))
                    }
                    title="Bootstrap Reset"
                  >
                    <img src="/images/svg-icons/bootstrap_reset.svg" alt="" />{" "}
                    {/* Bootstrap Reset */}
                  </button>
                }
                {
                  <button
                    className="button-lst"
                    onClick={() => this.confirmReboot()}
                    disabled={
                      this.props?.userRole === "Viewer_Group" ||
                      this.state.multipleSelect === "" ||
                      (this.state.multipleSelect.length > 0 &&
                        (this.checkOnlineDevices() || this.checkGroup()) ||
                        (process.env.REACT_APP_AD_USER === 'TRUE' &&
                          (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager'
                            && localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')))
                    } title="Reboot"
                  >
                    <img src="/images/svg-icons/reboot.svg" alt="" />
                    {/* Reboot */}
                  </button>
                }
                <button
                  className="button-lst"
                  disabled={this.state.multipleSelect === "" || this.state.favDevices
                    || this.state.multipleSelect.filter(item => item.is_pinned === false).length === 0}
                  onClick={() => this.setAsFavorite(true)}
                  title="Set As Favorite"
                >
                  <img src="/images/svg-icons/star-filled.svg" alt="" />
                </button>
                <button
                  className="button-lst"
                  disabled={this.state.multipleSelect === "" || this.state.multipleSelect.filter(item => item.is_pinned === true).length === 0}
                  onClick={() => this.setAsFavorite(false)}
                  title="Remove from Favorite"
                >
                  <img src="/images/svg-icons/star.svg" alt="" />
                </button>
                {/* removed for multitenancy */}
                {/* {<button
                  className="button-lst"
                  disabled={
                    this.state.multipleSelect?.[0]?.device_model !== "V2000" ||
                    this.state.multipleSelect === "" ||
                    (this.props?.userRole === "Viewer_Group" || this.props?.userRole === "Operator_Group")
                  }
                  onClick={() => this.confirmDelete()}
                  title="Factory Reset"
                >
                  <img src="/images/svg-icons/delete.svg" alt="" />
                </button>} */}
                <button
                  className="button-lst"
                  disabled={
                    (process.env.REACT_APP_AD_USER === 'TRUE' && (this.props.userRole !== 'Admin' &&
                      this.props.userRole !== 'Manager' && localStorage.getItem('ssoUser') !== 'true' &&
                      sessionStorage.getItem('ssoUser') !== 'true')) ||
                    (this.props?.userRole === "Viewer_Group" || this.props?.userRole === "Operator_Group") ||
                    this.state.multipleSelect === "" ||
                    this.checkOnlineDevices() ||
                    this.checkPendingOps() ||
                    this.state.multipleSelect.filter(model => Common.getModels(model.device_model) === 'Packetfence').length > 0}
                  onClick={() => { this.confirmRotateKey() }}
                  title="Rotate Key"
                >
                  <img src="/images/svg-icons/rotate_key.svg" alt="" />
                  {/* Rotate Key */}
                </button>
                <button
                  className="button-lst"
                  disabled={String(this.state.multipleSelect.length) === "0"}
                  onClick={() => this.generateActivation()}
                  title="Download the activation key"
                >
                  <img src="/images/svg-icons/download-arrow.svg" />
                </button>
                <button
                  className="dropdown-toggle filter-btn"
                  data-toggle="dropdown" title="Columns"
                >
                  <img src="/images/svg-icons/columns.svg" alt="" />
                  {/* Columns */}
                </button>
                <div
                  className="filter-section dropdown-menu dropdown-menu-right"
                  onChange={(e) => this.handleChange(e)}
                >
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="selectAll"
                      checked={
                        this.state.selectedOption.length ===
                        this.state.columns.length
                      }
                      style={{ cursor: "pointer" }}
                    />
                    <label className="form-check-label">Select All</label>
                  </div>
                  {this.state.columns.map((item, index) => {
                    if (item.value !== "Action") {
                      return (
                        <div className="form-check" key={index}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={item.value}
                            checked={JSON.stringify(
                              this.state.selectedOption
                            ).includes(item.value)}
                            style={{ cursor: "pointer" }}
                          />
                          <label className="form-check-label">
                            {item.value}
                          </label>
                        </div>
                      );
                    }
                  })}
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Action"
                      checked={true}
                      disabled
                      style={{ cursor: "not-allowed" }}
                    />
                    <label className="form-check-label">Action</label>
                  </div>
                </div>
                <div className="btn-group">
                  {/* disabled filter button if no data */}
                  <button
                    type="button"
                    className="dropdown-toggle  filter-btn"
                    disabled={this.state.deviceData.length === 0 && !this.state.showIcon}
                    onClick={(e) => this.getModelList()} title="Filter"
                  >
                    {this.state.showIcon ? (
                      <img src="/images/svg-icons/info-icon.svg" alt="" />
                    ) : (
                      <img src="/images/svg-icons/filter.svg" alt="" />
                    )}
                    {/* Filter */}
                  </button>
                  {this.state.showFilter && (
                    <div
                      className={
                        this.state.showFilter
                          ? "dropdown-menu show  dropdown-menu-right filter-section width360 overflow-visible"
                          : "dropdown-menu dropdown-menu-right filter-section width360 overflow-visible"
                      }
                      id="parentDiv"
                    >
                      <div className="form-group">
                        <div className="d-flex align-items-center justify-content-between">
                          <label>Model</label>
                          <span onClick={() => this.clearDropdown("Model")}>
                            Clear
                          </span>
                        </div>
                        <select
                          className="form-control"
                          value={
                            this.state.deviceModel
                              ? this.state.deviceModel
                              : "Select"
                          }
                          onChange={(e) => this.getModelDeviceList(e.target.value)}
                        >
                          <option value="Select">Select Model</option>
                          {this.state.modelList.map((model, index) => {
                            if (model.device_model !== "")
                              return (
                                <option key={index} value={model.device_model}>
                                  {model.device_model}
                                </option>
                              );
                          })}
                        </select>
                      </div>
                      <div className="form-group">
                        <div className="d-flex align-items-center justify-content-between">
                          <label>Device Name</label>
                          <span onClick={() => this.clearDropdown()}>
                            Clear
                          </span>
                        </div>
                        <Select
                          options={this.getOptions()}
                          placeholder={"Select Device"}
                          className="filter-dropdown"
                          classNamePrefix="react-select-container"
                          value={
                            this.getOptions().filter(option =>
                              option.value === this.state.deviceId)
                          }
                          onChange={(e) => this.getDetailFromDevice(e)}
                        />
                      </div>
                      <div className="form-group">
                        <div className="d-flex align-items-center justify-content-between">
                          <label>Status</label>
                          <span onClick={() => this.clearDropdown("Status")}>
                            Clear
                          </span>
                        </div>
                        <select
                          className="form-control"
                          value={
                            this.state.status ? this.state.status : "Select"
                          }
                          onChange={(e) => this.getStatus(e)}
                        >
                          <option value="select">Select Status</option>
                          <option value="online">Online</option>
                          <option value="offline">Offline</option>
                        </select>
                      </div>

                      <div className="action-box">
                        <button
                          className="btn btn-light"
                          onClick={() => this.resetValue()}
                        >
                          Reset
                        </button>
                        {/* Device Filter Apply Button Disable problem is Fix */}
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            this.setState({
                              pageNo: 1,
                              pageSize: this.state.pageSize,
                              showIcon: true,
                              searchValue: "",
                              searchClicked: false,
                            }, () => this.listFromModel())
                          }}
                          disabled={this.state.deviceModel === "" && this.state.status === ""}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="card">
              <DataTable
                value={this.state.deviceData}
                onSort={(columnSort) => {
                  this.deviceSort(columnSort);
                }}
                sortField={this.state.sortField}
                sortOrder={this.state.sortOrder}
                scrollable
                scrollHeight="400px"
                loading={this.state.loading}
                removableSort
                resizableColumns
                columnResizeMode="fit"
                showGridlines
                paginator
                paginatorTemplate={template2}
                first={this.state.firstDefault}
                rows={this.state.pageSize}
                paginatorClassName="p-jc-end"
                selectionMode="checkbox"
                selection={this.state.multipleSelect}
                onSelectionChange={(e) => this.onSelectRow(e)}
                dataKey="device_id"
                scrollDirection="both"
                className="p-mt-3"
              >
                <Column
                  selectionMode="multiple"
                  style={{ flexGrow: 1, flexBasis: "80px" }}
                ></Column>
                {dynamicColumns}
              </DataTable>
            </div>
            {this.state.multipleSelect !== "" &&
              !this.state.showDelete &&
              !this.state.showSuccess &&
              !this.state.showError && (
                <div
                  className="modal fade exampleModal"
                  id="exampleModal1"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  data-backdrop="static"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Configure Device
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">
                            <img src="/images/svg-icons/cancel.svg" alt="" />
                          </span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="form-group">
                          <label>Select Template</label>
                          <select
                            className="form-control"
                            placeholder="Select Template"
                            onChange={(e) =>
                              this.setState({
                                selectedTemplate: e.target.value,
                              })
                            }
                          >
                            <option value="1" selected>
                              Branch VPN
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-light"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-target="#errorMessage"
                          onClick={() => this.saveConfiguration()}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </section >
        {
          this.state.show && (
            <ErrorPopup
              title={this.state.title}
              message={this.state.message}
              error={this.state.error}
              delete={this.state.delete}
              offlineDevice={this.state.offlineDevice}
              onCancel={() => this.onCancel()}
              reset={() => this.resetPopup()}
              info={this.state.info}
              onSubmit={() =>
                this.chooseAction(
                  this.state.delete,
                  this.state.factoryReset,
                  this.state.reboot
                )
              }
            />
          )
        }
        {
          this.state.showPopup && (
            <div className="modal fade errorMessage" id="keyRotationErrorMessage" tabIndex="-1" data-backdrop="static"
              role="dialog" aria-labelledby="errorMessageTitle" aria-hidden="true"
            >
              <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header bg-white border-0">
                    {this.state.keyRotation &&
                      <div className="pt-3">
                        <div className="col-md-8 col-sm-12">
                          <label>
                            <input type="checkbox" checked={this.state.scheduleLater} onChange={this.scheduleLater} />
                            <span className="ml-2">Schedule Later</span>
                          </label>
                          <label>
                            <input type="checkbox"
                              checked={this.state.scheduleLater ? this.state.allDevices : false}
                              disabled={this.state.scheduleLater === false || this.state.startDate === null}
                              onChange={() => this.setOnAllDevices()}
                            />
                            <span className="ml-2">On All Devices</span>
                          </label>
                        </div>
                      </div>
                    }
                    {this.state.scheduleLater &&
                      <div className="pt-2"><Calendar
                        id="navigatorstemplate"
                        value={this.state.startDate}
                        onChange={(e) => this.setStartDate(e)}
                        showTime
                        hourFormat="12"
                        monthNavigator={true}
                        yearNavigator={true}
                        hideOnDateTimeSelect={true}
                        yearRange={`${new Date().getFullYear() - 10}:${new Date().getFullYear() + 10}`}
                        minDate={new Date()}
                      // monthNavigatorTemplate={this.monthNavigatorTemplate}
                      // yearNavigatorTemplate={this.yearNavigatorTemplate}
                      /></div>
                    }
                  </div>
                  <div className="modal-body pt-5">
                    <div className="error-msg">
                      <span className={this.state.error ? "error-icon" : "success-icon"}>{this.state.error ? (this.state.info ? 'i' : '!') : ''}</span>
                      <div className="txt1">{this.state.title}</div>
                      <div className="txt2">{this.state.message}
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer border-top-0 justify-content-center pb-4">
                    {(this.state.delete || this.state.approve) && <button type="button" className="btn btn-light"
                      data-dismiss="modal"
                      onClick={() => this.resetPopup()}>
                      {'Cancel'}</button>}
                    {(this.state.delete || this.state.error) && <button type="button" className={this.state.error ? "btn btn-danger" : "btn btn-primary"}
                      data-dismiss="modal"
                      disabled={this.state.scheduleLater === true && this.state.startDate === null}
                      onClick={this.state.keyRotation ? () => this.rotateKeyOperation() : undefined}>
                      {'OK'}</button>}
                  </div>
                </div>
              </div>
            </div>
          )
        }
        {
          (this.state.showLink || this.state.showList) && (
            <LinkPopup
              title={this.state.title}
              message={this.state.message}
              open={() => this.openOperationList(this.state.id)}
              close={() => this.closeLinkpopup()}
            />
          )
        }
        {this.state.showRefreshModal && <ErrorHandlePopup />}
        {
          this.state.showModel && (
            <ErrorPopup
              title={this.state.title}
              message={this.state.message}
              error={this.state.error}
              delete={this.state.delete}
              factoryReset={this.state.factoryReset}
              dataReset={this.state.dataReset}
              onCancel={() => this.factoryResetDevices()}
              reset={() => this.resetPopup()}
              onSubmit={() => {
                this.setState({ is_bolt_reset: true }, () =>
                  this.factoryResetDevices()
                );
              }}
            />
          )
        }
        {
          (this.state.isAxiosError) &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            delete={this.state.delete}
            reset={() => this.setState({ isAxiosError: false })}
          />
        }
      </div >
    );
  }
}
const mapStateToProps = (state) => {
  return {
    authenticated: state.auth.authenticated,
    open: state.displaySidebarMenu.openSide,
    userRole: JSON.parse(localStorage.getItem('user'))?.userRole,
    SsoUser: state.auth.SsoUser
  };
};
export default withRouter(connect(mapStateToProps)(ConfiguredDeviceList));
