import axios from "axios";
import moment from 'moment';
import jwt from 'jwt-decode';
// import { config } from '../../config';

// const envURL = config.REACT_APP_BaseUrl + 'api-user/';

const callingApis = async (requestParams) => {
  if (!requestParams.url.includes('/login')) {
    requestParams['headers'] = {
      Authorization: `Bearer ${localStorage.getItem('ssoUser') === 'true' ?
        localStorage.getItem('ADtoken') : localStorage.getItem('ADtoken')}`,
      'x-application-id': process.env.REACT_APP_AAD_APP_ID,
      'x-application-name': "SD Edge",
      // 'x-tenant-id': localStorage.getItem('selectedTenantId'),
      'x-tenant-name': localStorage.getItem('ssoUser') === 'true' ?
        localStorage.getItem('selectedTenantName') : localStorage.getItem('selectedTenantName'),
      'x-user-id': `${localStorage.getItem('userId')}`,
      'Ocp-Apim-Subscription-Key': process.env.REACT_APP_AAD_SUBSCRIPTION_KEY
    }
  }
  const datax = await axios(requestParams);
  const { data } = datax;
  let res = {
    ...data,
    response: {
      status: datax.status
    }
  };
  if (
    requestParams.url.includes('downloadOperationLogfile') ||
    requestParams.url.includes('downloadDeviceLogfile') ||
    requestParams.url.includes('deviceLogDownload')
  ) {
    res = {
      ...datax,
      response: {
        status: datax.status
      }
    };
  };
  return res;
};

const apiCall = async (requestParams, cb) => {
  var token = localStorage.getItem('ssoUser') === 'true' ?
    localStorage.getItem('ADtoken') : localStorage.getItem('ADtoken');
  // var token = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IldvSVhXRE5WdldUMFp5OFBHVy1rSjZ6Qnc4SEJ2Y0h1YVY0ZUdwWDhBTzgiLCJ0eXAiOiJKV1QifQ.eyJ2ZXIiOiIxLjAiLCJpc3MiOiJodHRwczovL2RldnBlZ2FzdXN0b29sa2l0LmIyY2xvZ2luLmNvbS80MjQ1NmM2Ny0xZWVlLTRjNTQtOGNkMC1mNTc2NGI2M2IwYTcvdjIuMC8iLCJzdWIiOiI2ZWFiMzZmMS0wOTc0LTQ1YzItODdkYi0yNzgyNzljYmVjNGUiLCJhdWQiOiJjMzBiYzhiNS1mYjBkLTQ3N2ItYTM0My02MWNlNTU2ZTJiNjciLCJleHAiOjE2ODg1NDEwMTgsImFjciI6ImIyY18xYV9pbnNlZWdvX3B0a19zaWdudXBfc2lnbmluIiwibm9uY2UiOiI2YjRlNzljNS0wMWRmLTQwZTEtYWUyNi1jNjFiZjQzMTk3ZTIiLCJpYXQiOjE2ODg1Mzc0MTgsImF1dGhfdGltZSI6MTY4ODUzNzQxOCwiZW1haWwiOiJpc3BhY2UuYm90aEB5b3BtYWlsLmNvbSIsInRpZCI6IjQyNDU2YzY3LTFlZWUtNGM1NC04Y2QwLWY1NzY0YjYzYjBhNyIsIm5iZiI6MTY4ODUzNzQxOH0.rN-qL9DzQLQftW0E4p2-rBsFM3Y5lD3u9wzihMvxz0_P6QCUjrF8bgGNTeUttmdK53JrFew0-EIN-apujsPPPgX4cds8ZcyMcLGjL1sB6XO259JCYQt8bHJT6-eVoiznRuvByiXCjbZiMUbHu_PpcFoz30ic0Fh3jsanvAALQlwnlJE21SCZIcZzaXoBGNsXcPAyxRVHrUWp_rF_y-sDoiVasZiz6k6XRUWmNCMUuGPmIP2-bvcMeHDeocRbytxuTbOmbTObdHmS3XImBCQP5H9rXIxZ6Kjgf-A2vvSGLVpllSqIjD2e1V92uD-t9ilMGM7iPx9wbU1p5uXbXqxWfA';
  if (requestParams.url.includes('/login') || (token !== null && token !== '')) {
    var requestParamsCopy = requestParams;
    const now = moment().unix();
    let getMinutes = 0;
    const tokenValues = jwt(localStorage.getItem('ADtoken'));
    const duration = moment.duration(moment.unix(tokenValues?.exp).diff(moment.unix(now)));
    getMinutes = duration.asMinutes();
    if ((localStorage.getItem('ADtoken') !== null && localStorage.getItem('ADtoken') !== undefined) &&
      getMinutes < 1) {
      let response = {
        response: {
          status: 403
        }
      }
      cb(response);
    } else {
      await callingApis(requestParams).then(response => {
        // if (process.env.REACT_APP_AD_USER !== 'TRUE' && response.status === false && response.message === 'No tenant exists with this name') {
        //   alert(response.message);
        // } else
        cb(response);
      }).catch((error) => {
        if ((error.response && error.response.status && parseInt(error.response.status) !== 401) ||
          error.isAxiosError) {
          cb(error);
        }
      })
      // else {
      //   // if (!requestParams.url.includes('/login') && process.env.REACT_APP_AD_USER !== 'TRUE') {
      //   //   const user = jwt_decode(token);
      //   //   const refresh_token = localStorage.getItem('refresh');
      //   //   const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1
      //   //   var changed = false;
      //   //   if (isExpired) {
      //   //     await axios.post(`${process.env.REACT_APP_API_URL}token/refresh/`, {
      //   //       refresh: refresh_token
      //   //     }).then((response) => {
      //   //       localStorage.setItem('token', response.data.access);
      //   //       changed = true;
      //   //       if (changed) {
      //   //         callingApis(requestParamsCopy).then((res) => {
      //   //           cb(res);
      //   //         }, (err) => {
      //   //           cb(err);
      //   //         }
      //   //         );
      //   //       }
      //   //     },
      //   //       (error) => {
      //   //         cb(error);
      //   //       })
      //   //   }
      //   // }
      //   // else {
      //   cb(error);
      //   // }
      // }
    }
  }
};

export default apiCall;

