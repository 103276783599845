/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import SideMenu from '../../layOuts/SideMenu';
import Header from '../../layOuts/Header';
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { Loader } from '../../layOuts/Loader';
import moment from 'moment';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { Calendar } from 'primereact/calendar';
import { Link } from 'react-router-dom';
import * as Common from './../../utils/common';
import ErrorHandlePopup from "../ErrorHandlePopup";
import SelectTenant from "../SelectTenant";
import { ErrorPopup } from '../../layOuts/errorPopup';
import $ from "jquery";
import isEqual from 'lodash/isEqual';
import * as Action from "../../redux/actionTypes";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showErrorModal: false,
            multipleSelect: "",
            toggledClearRows: false,
            showLoader: false,
            selectedTemplate: 1,
            title: '',
            message: '',
            show: false,
            error: false,
            delete: false,
            configured: false,
            fullView: false,
            TemplateData: [],
            templateColumns: [],
            addTemplate: false,
            topologyOption: {},
            topologyData: {},
            totalDeviceData: {},
            modelData: [],
            graphData: [],
            pageNo: 1,
            pageSize: 10,
            selectedDuration: 1,
            topoGraphData: [],
            topoLinkData: [],
            dataUsageType: "daily",
            selectedUsageOption: "cellular_data_usage",
            custom: false,
            barGraphFullView: false,
            deviceList: [],
            custom: false,
            start_date: '',
            end_date: '',
            topConsDuration: "1",
            start_date_top: '',
            end_date_top: '',
            type_of_data_usage: "cellular_data_usage",
            customTop: false,
            lower_threshold: 0,
            upper_threshold: -1,
            isAxiosError: false,
            propsUser: localStorage.getItem('user'),
            option: {
                chart: {
                    height: 300,
                },
                title: {
                    text: ''
                },
                credits: {
                    enabled: false,
                },
                // for menu button enable & disabled
                exporting: {
                    enabled: false
                },
            },
            dailyOption: {
                chart: {
                    height: 300,
                },
                title: {
                    text: ''
                },
                credits: {
                    enabled: false,
                },
                // for menu button enable & disabled
                exporting: {
                    enabled: false
                },
            },
            deviceSeverityData: {},
            // deviceEventsData: [],
            totalCountWarningAndAlert: 0,
            tunnelCount: { "total_tunnel": 0, "online_tunnel": 0, "offline_tunnel": 0 },
            bandwidthFullView: false,
            tempDevice: {
                top_consumers: {
                    // 341: {
                    //     wan_data_usage: 10123991558,
                    //     device_name: "DND-CP-TEST"
                    // },
                    // 3: {
                    //     wan_data_usage: 8481647246,
                    //     device_name: "DND2"
                    // },
                    // 334: {
                    //     wan_data_usage: 8293776258,
                    //     device_name: "FG2000-3-F01A"
                    // },
                    // 4: {
                    //     wan_data_usage: 6895193833,
                    //     device_name: "DND1"
                    // },
                    // 339: {
                    //     wan_data_usage: 6053443636,
                    //     device_name: "DND-tunnel-issue"
                    // }
                },
                "success": true,
                "message": ""
            },
            // goToUser:false
        };

    }
    async componentDidMount() {
        if ((process.env.REACT_APP_AD_USER !== 'TRUE' &&
            localStorage.getItem('user') !== null && localStorage.getItem('user') !== undefined &&
            // this.props.user !== '' &&
            localStorage.getItem('activeUser') === 'true') ||
            ((sessionStorage.getItem('ssoUser') === 'true' || localStorage.getItem('ssoUser') === 'true')
                && localStorage.getItem('verifiedUser') === "true"
                && localStorage.getItem('ADtoken') !== undefined &&
                localStorage.getItem('ADtoken') !== '' &&
                localStorage.getItem('ADtoken') !== null)) {
            await this.getTotalModelDashboard();
            await this.getTunnelCountDashboard();
            await this.getTotalDeviceDashboard();
            await this.getDeviceUsage();
            await this.deviceSeverity();
            await this.deviceEvents();
            await this.getNetworkTopology();
            await this.getTopDeviceUsage();
            await this.getDeviceList();
        }
    }
    async UNSAFE_componentWillReceiveProps(nextProps) {
        if (localStorage.getItem('user') !== "" &&
            localStorage.getItem('user') !== undefined &&
            localStorage.getItem('user') !== null) {
            this.setState({
                propsUser: localStorage.getItem('user')
            })
            if ((process.env.REACT_APP_AD_USER === 'TRUE' &&
                localStorage.getItem('user') !== null && localStorage.getItem('user') !== undefined &&
                //  nextProps.user !== '' &&
                localStorage.getItem('activeUser') === 'true') ||
                ((sessionStorage.getItem('ssoUser') === 'true' || localStorage.getItem('ssoUser') === 'true') && localStorage.getItem('verifiedUser') === "true"
                    && localStorage.getItem('ADtoken') !== undefined && localStorage.getItem('ADtoken') !== '' &&
                    localStorage.getItem('ADtoken') !== null)) {
                await this.getTotalModelDashboard();
                await this.getTunnelCountDashboard();
                await this.getTotalDeviceDashboard();
                await this.getDeviceUsage();
                await this.deviceSeverity();
                await this.deviceEvents();
                await this.getNetworkTopology();
                await this.getTopDeviceUsage();
                await this.getDeviceList();
            }
        }
    }

    getTotalDeviceDashboard = async () => {
        await ApiCall(urlMapping.getDeviceDashboard(), (response) => {
            if (response?.success) {
                this.setState({ totalDeviceData: response?.data })
            } else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({ showErrorModal: true })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        }
        )
    }
    getTotalModelDashboard = async () => {
        await ApiCall(urlMapping.getDeviceModelDashboard(), (response) => {
            if (response?.success) {
                this.setState({ modelData: response?.data })
            } else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({ showErrorModal: true })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.props.dispatch({
                        type: Action.LOGOUT,
                    });
                    // this.setState({
                    //     title: 'Network Error',
                    //     message: 'Something went wrong. Please try again later.',
                    //     showLoader: false,
                    //     isAxiosError: true,
                    //     delete: false,
                    //     error: true
                    // }, () => $("#errorMessage").modal("show"))
                }
            }
        }
        )
    }
    getTunnelCountDashboard = async () => {
        await ApiCall(urlMapping.getTunnelDashboard(), (response) => {
            if (response?.success) {
                this.setState({ tunnelCount: response?.data })
            } else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({ showErrorModal: true })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        }
        )
    }
    multipleSelect(rows) {
        if (!rows.selectedRows.length)
            this.setState({ multipleSelect: "" });
        else {
            this.setState({ multipleSelect: rows.selectedRows }
            )
        }
    }

    handleAddTemplate = () => {
        this.setState(prevState => ({
            addTemplate: !prevState.addTemplate
        }));
    }
    getDeviceUsage = (e) => {
        this.setState({
            start_date: '',
            end_date: '',
        })
        let endDate = moment().format('YYYY-MM-DD HH:mm');
        let startDate = moment().format('YYYY-MM-DD HH:mm');
        let day = e ? parseInt(e.target.value) : 1;
        if (!isNaN(day)) {
            let edate;
            this.setState({ selectedDuration: day })
            if (e === 1) {
                // if (moment().format("HH:mm") > moment().format("HH")) {
                //     edate = moment.utc().add(1, "hours").format("YYYY-MM-DD HH:mm")
                // }
                // else {
                edate = moment().format("YYYY-MM-DD HH:mm")
                // }
            }
            endDate = e === 1 ? edate : moment().format('YYYY-MM-DD HH:mm')

            startDate = day === 1 ? moment(edate).subtract(24, 'hours').format("YYYY-MM-DD HH:mm") : day === 7 ?
                moment(edate).subtract(6, 'days').format('YYYY-MM-DD HH:mm') : moment(edate).subtract(29, 'days').format('YYYY-MM-DD HH:mm')
            // if (this.state.dataUsageType === 'daily' && day === 1) {
            //     startDate = moment(edate).subtract(24, 'hours').format("YYYY-MM-DD HH:mm");
            // }
            this.setState({
                start_date: startDate,
                end_date: endDate,
                custom: false,
                diffDays: 0
            }, () => this.getUsage())
        } else {
            this.setState({
                custom: true,
                start_date: '',
                selectedDuration: e.target.value
            })
        }
    }
    getUsage = async () => {
        let { start_date } = this.state;
        let { end_date } = this.state;
        if (this.state.dataUsageType === 'daily') {
            // if (this.state.selectedDuration === 1) {
            //     end_date = moment.utc(new Date()).format("YYYY-MM-DD HH:mm");
            // }
            // if (this.state.diffDays === 1) {
            //     start_date = moment.utc(start_date).format("YYYY-MM-DD HH:mm");
            // }           
            // start_date = moment.utc(start_date)
            start_date = this.state.selectedDuration === 1
                // || this.state.diffDays === 1 
                ? moment(start_date).set({ minute: 59, second: 59, millisecond: 0 }).format("YYYY-MM-DD HH:mm") :
                moment(start_date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format("YYYY-MM-DD HH:mm");
            start_date = moment.utc(new Date(start_date));
            start_date = start_date.format('YYYY-MM-DD HH:mm');
            end_date = moment.utc(new Date(end_date));
            end_date = this.state.custom ? end_date.add(1, 'days').format('YYYY-MM-DD HH:mm') : end_date.format('YYYY-MM-DD HH:mm');
            // end_date = end_date.format('YYYY-MM-DD HH:mm');
            let data = {
                day_no: this.state.custom ? this.state.diffDays + 1 : this.state.selectedDuration,
                start_date,
                end_date
            }
            this.setState({ showLoader: true })
            await ApiCall(urlMapping.getUsage(data), (response) => {
                this.setState({ showLoader: false })
                if (response?.success) {
                    this.setState({ graphData: response?.data }, () => this.graphData())
                } else {
                    if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                        this.setState({ showErrorModal: true })
                    } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                        this.setState({
                            title: 'Tenant Error',
                            message: response.response.data.message,
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                }
            })
        } else {
            // start_date = moment.utc(new Date(start_date))
            // start_date = start_date.set({ minute: 0, second: 0, millisecond: 0 }).format("YYYY-MM-DD HH:mm");
            // start_date = moment.utc(start_date).format('YYYY-MM-DD HH');

            start_date = this.state.selectedDuration === 1 || this.state.diffDays === 1 ?
                moment(start_date).set({ minute: 59, second: 59, millisecond: 0 }).format("YYYY-MM-DD HH:mm") :
                moment(start_date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format("YYYY-MM-DD HH:mm");
            start_date = moment.utc(new Date(start_date));
            start_date = start_date.format('YYYY-MM-DD HH:mm');
            end_date = moment.utc(new Date(end_date));
            end_date = this.state.custom ? end_date.add(1, 'days').format('YYYY-MM-DD HH:mm') : end_date.format('YYYY-MM-DD HH:mm');
            let data = {
                day_no: this.state.custom ? this.state.diffDays + 1 : this.state.selectedDuration,
                start_date,
                end_date
            }
            this.setState({ showLoader: true })
            await ApiCall(urlMapping.dashboardDataUsage(data), (response) => {
                this.setState({ showLoader: false })
                if (response?.success) {
                    this.setState({ graphData: response?.data }, () => this.graphData())
                } else {
                    if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                        this.setState({ showErrorModal: true })
                    } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                        this.setState({
                            title: 'Tenant Error',
                            message: response.response.data.message,
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                }
            })
        }
    }
    getTopDeviceUsage = () => {
        this.setState({ showLoader: true })
        let edate;
        if (this.state.topConsDuration === "1") {
            edate = moment().format("YYYY-MM-DD HH:mm")
        }
        let endDate = this.state.topConsDuration === "1" ? edate : this.state.topConsDuration === "custom" ? moment(this.state.end_date_top).add(1, 'days').format("YYYY-MM-DD HH:mm") : moment().format('YYYY-MM-DD HH:mm')

        let startDate = this.state.topConsDuration === "1" ? moment(edate).subtract(24, 'hours').format("YYYY-MM-DD HH:mm") : this.state.topConsDuration === "7" ?
            moment().subtract(6, 'days').format('YYYY-MM-DD HH:mm') : this.state.topConsDuration === "30" ? moment().subtract(29, 'days').format('YYYY-MM-DD HH:mm') : moment(this.state.start_date_top).format("YYYY-MM-DD HH:mm")
        if (this.state.topConsDuration !== "1")
            startDate = moment(startDate).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format('YYYY-MM-DD HH:mm');
        let data = {
            type_of_data_usage: this.state.type_of_data_usage,
            start_date: moment.utc(new Date(startDate)).format('YYYY-MM-DD HH:mm'),
            end_date: moment.utc(new Date(endDate)).format('YYYY-MM-DD HH:mm'),
            count: parseInt(process.env.REACT_APP_TOP_COUNT),
            upper_threshold: this.state.upper_threshold ? this.state.upper_threshold : -1,
            lower_threshold: this.state.lower_threshold ? this.state.lower_threshold : 0
        }
        ApiCall(urlMapping.get_topConsumer(data), (response) => {
            if (response?.success) {
                this.setState({
                    tempDevice: response?.data,
                    showLoader: false
                }, () => this.BarGraph())
            } else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({
                        showErrorModal: true,
                        showLoader: false
                    })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        showLoader: false
                    });
                }
            }
        })
    }
    resetPopup = () => {
        this.setState({
            isAxiosError: false,
            error: false,
        }, () => {
            if (this.state.title === 'Tenant Error') {
                this.props.dispatch({
                    type: Action.LOGOUT,
                });
            }
            $("#errorMessage").modal("hide");
        })
    }
    getNetworkTopology = async () => {
        await ApiCall(urlMapping.networkTopology(), (response) => {
            if (response) {
                this.setState({ topologyData: response }, () => this.getTopologyData1())
            } else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({ showErrorModal: true })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })
    }
    getTopologyData = () => {
        let array = []
        Object.keys(this.state.topologyData).map((item) => {
            if (this.state.topologyData[item].length > 0) {
                for (let i = 0; i < this.state.topologyData[item].length; i++) {

                    Object.keys(this.state.topologyData[item][i]).map((p) => {
                        array.push([item, p])
                    })
                }
            }
        })
        this.setState({ topoGraphData: array })
    }

    myCustomLabelBuilder = (p, mac) => {
        // do stuff to get the final result...
        return `${p} ${mac}`;
    }
    returnImage = (device_model) => {
        var models = Common.getModels(device_model)
        switch (models) {
            case "MIFI":
                models = Common.getMiFiModels(device_model)
                if (models === "Moretti_Model")
                    return "/images/Product_Images/Moretti_M2100_M2000_A_B_C/Moretti generic packaging image_glamour.png";
                else
                    return "/images/Product_Images/FX2000/Duvel_back angle.png";
            case "CHIMAY":
                const str = device_model.split("-")[0]
                if (str === "FX2000")
                    return "/images/Product_Images/FX2000/Duvel_back angle.png";
                else if (str === "FG2000e")
                    return "/images/Product_Images/FG2000e/Chimay ioT_back angle.png";
                else
                    return "/images/Product_Images/FG2000/Chimay_ortho_back.png";
            case "vCPE":
                return "/images/vCPE_Image100.png"
            default:
                return "/images/dd-img.png";
        }
    }
    getTopologyData1 = () => {
        let array = []
        let linkArray = []

        Object.keys(this.state.topologyData).map((item, index) => {
            if (this.state.topologyData[item]?.length > 0) {
                for (let i = 0; i < this.state.topologyData[item].length; i++) {
                    Object.keys(this.state.topologyData[item][i]).map((p) => {
                        const imageUrl = this.returnImage(this.state.topologyData[item][i][p].device_model)

                        array.push({
                            id: p,
                            svg: imageUrl, size: 500, fontSize: 10,
                            labelPosition: "bottom",
                            renderLabel: true,
                            labelProperty: this.myCustomLabelBuilder(p, this.state.topologyData[item][i][p].Device_Mac),
                            x: Math.floor(Math.random() * 500), y: Math.floor(Math.random() * 500)
                        })

                        const found = linkArray.findIndex(item1 => item1.source === p && item1.target === item);
                        if (found === -1) {
                            linkArray.push({
                                source: item,
                                target: p,
                                labelPosition: "center",
                                color: this.state.topologyData[item][i][p].Status === "Online" ? "green" : "red"
                            })
                        }
                    })

                }
            }


        })
        this.setState({ topoGraphData: array, topoLinkData: linkArray })

    }
    // Add date also if its changed for 24 hour data
    getExactTime = (array) => {
        let x = moment(array[0], "DD MMMM HH:mm").format("DD");
        for (let i = 0; i < array.length; i++) {
            if (moment(array[i], "DD MMMM HH:mm").format("DD") !== x) {
                array[i] = moment(array[i], "DD MMMM HH:mm").format("DD MMMM HH:mm");
                x = moment(array[i], "DD MMMM HH:mm").format("DD");
            } else {
                array[i] = moment(array[i], "DD MMMM HH:mm").format("HH:mm");
            }
        }
        return array

    }
    getTime = (time) => {
        // get local time
        let date = moment.utc(time);
        date = date.local();
        // if its daily.day data ignore minutes in time 
        if (this.state.selectedDuration === 1
            // || this.state.diffDays === 1
        ) {
            if (this.state.dataUsageType === 'daily')
                date.set({ minute: 0, second: 0, millisecond: 0 }).format('DD MMMM HH:mm')
            else
                date = date.format('DD MMMM HH:mm')
        } else
            date = date.format('DD MMMM');
        return date;
    }
    setDataUsageType = (e) => {
        this.setState({
            selectedUsageOption: e.target.value
        }, () => this.getUsage())
    }
    calculateValue = (val, fix) => {
        let unit = '';
        let iteration = 1;
        val = Math.abs(val);
        if (val > 0) {
            for (let i = 1; i <= 4; i++) {
                if (val >= 1000) {
                    val = val / 1000;
                    iteration = i;
                }
            }
            switch (iteration) {
                case 1:
                    unit = val.toFixed(fix) + ' KB';
                    break;
                case 2:
                    unit = val.toFixed(fix) + ' MB';
                    break;
                case 3:
                    unit = val.toFixed(fix) + ' GB';
                    break;
                case 4:
                    unit = val.toFixed(fix) + ' TB';
                    break;
                default:
                    unit = val.toFixed(fix) + ' KB';
                    break;
            }
            return unit;
        } else
            return val;
    }
    getTooltip = (val) => {
        if (val.split(':')[0].split(' ').length > 2) {
            let parts = val.split(':')[0].split(' ');
            return (parseInt(parts[2]) + 1).toString() + ':00';
        } else
            return (parseInt(val.split(':')[0]) + 1).toString() + ':00'
    }
    graphData = () => {
        // let dataTunnel = []
        // let wifiUsage = []
        let ControlTunnel = []
        let wanUsage = []
        let cellularUsage = []
        let time = []
        if (this.state.dataUsageType === 'cumulative') {
            this.state.graphData.map((item) => {
                if (moment(this.getTime(item.bucket)).format('mm') === '00') {
                    // dataTunnel.push(item.data_tunnel_usage / (1000 * 1000))
                    // wifiUsage.push(item.wifi_data_usage)
                    ControlTunnel.push(item.control_tunnel_usage)
                    wanUsage.push(item.wan_data_usage)
                    cellularUsage.push(item.cellular_data_usage)
                    time.push(this.getTime(item.bucket))
                }
            })
            if (this.state.graphData.length > 1 && moment(this.getTime(this.state.graphData[this.state.graphData.length - 1].bucket)).minutes() !== 0) {
                // wifiUsage.push(this.state.graphData[this.state.graphData.length - 1].wifi_data_usage)
                ControlTunnel.push(this.state.graphData[this.state.graphData.length - 1].control_tunnel_usage)
                wanUsage.push(this.state.graphData[this.state.graphData.length - 1].wan_data_usage)
                cellularUsage.push(this.state.graphData[this.state.graphData.length - 1].cellular_data_usage)
                time.push(this.getTime(this.state.graphData[this.state.graphData.length - 1].bucket));
            }
        } else {
            for (let index = 0; index < this.state.graphData.length; index++) {
                // wifiUsage.push(this.state.graphData[index].wifi_data_usage)
                ControlTunnel.push(this.state.graphData[index].control_tunnel_usage)
                wanUsage.push(this.state.graphData[index].wan_data_usage)
                cellularUsage.push(this.state.graphData[index].cellular_data_usage)
                time.push(this.getTime(this.state.graphData[index].bucket));
                // if (this.state.selectedDuration === 1 || this.state.diffDays === 1)
                // index = index + 5
            }
            // if (this.state.graphData.length > 1 && moment(this.getTime(this.state.graphData[this.state.graphData.length - 1].bucket)).minutes() !== 0) {
            //     wifiUsage.push(
            //         (this.state.graphData[this.state.graphData.length - 1].wifi_data_usage
            //         )
            //     );
            //     ControlTunnel.push(
            //         (this.state.graphData[this.state.graphData.length - 1].control_tunnel_usage
            //         )
            //     );
            //     wanUsage.push(
            //         (this.state.graphData[this.state.graphData.length - 1].wan_data_usage
            //         )
            //     );
            //     cellularUsage.push(
            //         (this.state.graphData[this.state.graphData.length - 1].cellular_data_usage
            //         )
            //     );
            //     time.push(this.getTime(this.state.graphData[this.state.graphData.length - 1].bucket));
        }
        if (this.state.selectedDuration === 1
            // || this.state.diffDays === 1
        ) {
            time = this.getExactTime(time)

        }
        let series = [
            {
                name: 'wan_data_usage',
                data: wanUsage
            },
            {
                name: 'cellular_data_usage',
                data: cellularUsage
            },
            {
                name: 'control_tunnel_usage',
                data: ControlTunnel
            },
            // {
            //     name: 'Data Tunnel',
            //     data: dataTunnel
            // },
            // {
            //     name: 'Wifi Usage',
            //     data: wifiUsage
            // }

        ]
        // let unit = this.state.dataUsageType === "daily" ? 'KB' : 'MB';
        const obj = this;
        let { selectedDuration } = this.state;
        let data1 = {
            chart: {
                height: 300,
                type:
                    this.state.dataUsageType === "daily" ? "column" :
                        "area"
            },
            title: {
                text: ''
            },
            credits: {
                enabled: false,
            },
            // for menu button enable & disabled
            exporting: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: 'Data usage'
                },
                type: 'logarithmic',
                labels: {
                    formatter: function () {
                        // if (dataUsageType === "cumulative") {
                        return '<a href="' + obj.calculateValue(this.value, 0) + '">' +
                            obj.calculateValue(this.value, 0) + '</a>';
                        // }
                    }
                }
            },
            tooltip: {
                enabled: true,
                crosshairs: true,
                formatter: function () {
                    if (obj.state.selectedDuration === 1
                        //  || obj.state.diffDays === 1 
                        && obj.state.dataUsageType === 'daily') {
                        return this.x + '-' + obj.getTooltip(this.x) + '<br>  <div className="dot-circle">&#9679;</div>' + this.series.name + ' ' + obj.calculateValue(this.y.toFixed(4), 4);
                    } else
                        return this.x + '<br>  <div className="dot-circle">&#9679;</div>' + this.series.name + ' ' + obj.calculateValue(this.y.toFixed(4), 4)
                    //  this.y.toFixed(4) + unit;
                }
            },
            xAxis: {
                categories: time,
                title: {
                    text: "Time Interval"
                },
                labels: {
                    formatter: function () {
                        let parts = this.value.split(' ');
                        if (selectedDuration === 1
                            // || obj.state.diffDays === 1
                        ) {
                            if (parts.length > 1) {
                                return parts[0] + " " + parts[1].substr(0, 3) + " " + parts[2]
                            } else {
                                return '<a href="' + time[this.value] + '">' +
                                    this.value + '</a>';
                            }
                        } else {
                            return '<a href="' + time[this.value] + '">' +
                                parts[0] + " " + parts[1].substr(0, 3) + '</a>';
                        }
                    }
                }
            },
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false
                    },
                    connectNulls: true,
                    pointStart: time[this.value],
                    marker: {
                        enabled: false,
                        symbol: 'circle',
                        radius: 2,
                        states: {
                            hover: {
                                enabled: true
                            }
                        }
                    }
                }
            },
            legend: { enabled: false },
            series: series.filter(item => item.name === this.state.selectedUsageOption),
        }
        this.setState({ option: data1, dailyOption: data1 })
    }

    setStartTime = (e) => {
        this.setState({
            start_date: e.value ? e.value : '',
            end_date: '',
            keyUpdate: Math.random()
        })
    }
    setEndTime = (e) => {
        let start_date = moment(this.state.start_date).format("YYYY-MM-DD HH:mm");
        let end_date = moment(e.value).format("YYYY-MM-DD HH:mm");
        const date1 = new Date(start_date);
        const date2 = new Date(end_date);
        const diffTime = Math.abs(date2 - date1);
        var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        this.setState({
            end_date: e.value ? e.value : '',
            diffDays
        }, () => {
            if (this.state.end_date !== '')
                this.getUsage()
        })
    }

    setTopStartTime = (e) => {
        this.setState({
            start_date_top: e.value ? e.value : '',
            end_date_top: '',
            keyUpdate: Math.random()
        })
    }
    setTopEndTime = (e) => {
        this.setState({
            end_date_top: e.value ? e.value : '',
        }, () => {
            if (this.state.end_date_top !== '')
                this.getTopDeviceUsage()
        }
        )
    }
    BarGraph = () => {
        const obj = this;
        let devices = []
        let usage = []
        let totalDevice = this.state.tempDevice.top_consumers.length > 4 ? 4 : this.state.tempDevice.top_consumers.length - 1
        let totalUsage = this.state.tempDevice.total_data_usage
        this.state.tempDevice.top_consumers.map((item) => {
            devices.push(item.device_name)
            if (item.wan_data_usage) {
                usage.push((item.wan_data_usage))
            }
            else if (item.cellular_data_usage) {
                usage.push((item.cellular_data_usage))
            }
            else if (item.control_tunnel_usage) {
                usage.push((item.control_tunnel_usage))
            }
        })
        let data = {
            chart: {
                type: 'bar'
            },
            title: {
                text: ''
            },
            exporting: {
                enabled: false
            },
            xAxis: {
                categories: devices,
                title: {
                    text: 'Devices'
                },
                scrollbar: {
                    enabled: this.state.tempDevice?.top_consumers?.length > 5 ? true : false
                },
                min: 0,
                max: totalDevice,
            },
            yAxis: {
                title: {
                    text: 'Data Usage'
                },
                type: 'logarithmic',
                tickInterval: 0.1,
                labels: {
                    rotation: -50,
                    formatter: function () {
                        return '<a href="' + obj.calculateValue(this.value, 0) + '">' +
                            obj.calculateValue(this.value, 0) + '</a>';
                    }
                }
            },
            tooltip: {
                valueSuffix: ' millions',
                formatter: function () {
                    return this.x + '<br>  <div className="dot-circle">&#9679;</div>' + "Data usage" + ' ' + obj.calculateValue(this.y.toFixed(4), 4)
                }
            },
            plotOptions: {
                series: {
                    cursor: "pointer"
                },
                bar: {
                    zones: [{
                        value: totalUsage / 2,
                    }, {
                        value: (totalUsage / 4) * 3,
                        color: '#839cb8'
                        // color: '#063970'
                    }, {
                        color: '#063970'
                    }
                    ],
                    dataLabels: {
                        enabled: true,
                        // x: -80,
                        // color: '#FFFFFF',
                        formatter: function () {
                            return ((this.y / totalUsage) * 100).toFixed(1) + '%';
                        }
                    }
                }
            },
            credits: {
                enabled: false
            },
            legend: { enabled: false },
            series: [{
                showInLegend: false,
                data: usage,
            },
            ]
        }
        this.setState({ option3: data, keyUpdateBar: Math.random() })
    }
    getDeviceList = () => {
        ApiCall(
            urlMapping.getConfiguredDevicelist('created_date', 'descending', 0, 1, 10),
            (response) => {
                this.setState({
                    showLoader: false,
                });
                if (response?.results) {
                    this.setState({
                        deviceList: response?.results
                    })
                }
                else {
                    if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                        this.setState({
                            showLoader: false,
                            showErrorModal: true
                        });
                    } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                        this.setState({
                            title: 'Tenant Error',
                            message: response.response.data.message,
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                }
            })
    }
    deviceSeverity = async () => {
        await ApiCall(urlMapping.homeDashboardDeviceSeverity(), (response) => {
            if (response?.success) {
                this.setState({ deviceSeverityData: response?.data }, () => this.getTotalCount());
            } else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({ showErrorModal: true })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })
    }

    deviceEvents = async () => {
        await ApiCall(urlMapping.homeDashboardDeviceEvents(this.state.pageNo, this.state.pageSize), (response) => {
            if (response?.results) {
                this.setState({ deviceEventsData: response?.results })
            } else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({ showErrorModal: true })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })
    }

    getTimeExact = (time) => {
        let date = moment.utc(time);
        date = date.local();
        return date.format('DD MMMM YYYY hh:mm:ss A');
    }

    returnSeverity = (severity) => {
        switch (severity) {
            case "0":
            case "1":
                return "Information";
            case "2":
            case "3":
                return "Warning";
            case "4":
            case "5":
                return "Critical";
            default:
                return "Information";
        }
    }
    returnSeverityStyle = (severity) => {
        switch (severity) {
            case "0":
            case "1":
                return "gray-bg wa-card information-card";
            case "2":
            case "3":
                return "gray-bg wa-card warning-card";
            case "4":
            case "5":
                return "gray-bg wa-card critical-card";
            default:
                return "gray-bg wa-card information-card";
        }
    }

    getTotalCount = () => {
        let sum = 0
        Object.keys(this.state.deviceSeverityData).map((item) => {
            sum = sum + this.state.deviceSeverityData[item]
        })

        this.setState({ totalCountWarningAndAlert: sum })

    }
    setUsageType = (e) => {
        this.setState({
            dataUsageType: e.target.value
        }, () => {
            this.getDeviceUsage()
        })
    }
    reDirect = (e) => {
        if (e.target.point !== undefined) {
            let deviceList = this.state.tempDevice.top_consumers.filter((item) => item.device_name === e.target.point.category)
            this.props.history.push({
                pathname: '/devicedetails',
                state: { data: { device_id: deviceList[0].device_id } }
            })
        }
    }
    selectedTopDuration = (e) => {
        if (e.target.value === "custom") {
            this.setState({
                start_date_top: '',
                end_date_top: '',
                topConsDuration: e.target.value,
                customTop: true
            })
        }
        else {
            this.setState({
                topConsDuration: e.target.value,
                customTop: false
            }, () => this.getTopDeviceUsage())
        }
    }
    setUpperThreshold = (e) => {
        if (Common.validateNumeric(e)) {
            this.setState({ upper_threshold: e.target.value })
        }
    }
    setLowerThreshold = (e) => {
        if (Common.validateNumeric(e)) {
            this.setState({
                lower_threshold: e.target.value,
                upper_threshold: ""
            })
        }
    }
    checkThresholds = () => {
        if (this.state.lower_threshold !== 0)
            if (parseInt(this.state.lower_threshold) > parseInt(this.state.upper_threshold)) {
                const element = document.getElementById('upper_threshold');
                element.style.display = 'block';
                this.setState({
                    showError: true
                })
            } else {
                const element = document.getElementById('upper_threshold');
                element.style.display = 'none';
                this.setState({
                    showError: false
                }, () => this.getTopDeviceUsage())
            }
    }
    downloadGraph = (value, key) => {
        this.setState({ showLoader: true })
        let data = key === "TopConsumer" ?
            {
                data: value,
                key: key,
                dataType: this.state.type_of_data_usage,
                user_time_zone: moment.tz.guess()
            }
            :
            {
                data: value,
                key: key,
                dataType: this.state.selectedUsageOption,
                usageType: this.state.dataUsageType,
                user_time_zone: moment.tz.guess()
            }
        ApiCall(urlMapping.downloadDataUsageGraphs(data), (response) => {
            this.setState({ showLoader: false })
            if (parseInt(response?.response?.status) === 200) {
                const url = new Blob([response?.data])
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(url);
                link.setAttribute('download', key === "Bandwidth" ? ("Dashboard_Bandwidthusage" + "_" + moment().format('YYYY-MM-DD HH:mm') + ".xlsx") : ("Dashboard_Topconsumer" + "_" + moment().format('YYYY-MM-DD HH:mm') + ".xlsx"));
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.setState({
                    showLoader: false
                })
            } else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({ showErrorModal: true })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })
    }
    render() {
        const ADUser = process.env.REACT_APP_AD_USER;
        return (
            <div>
                {ADUser === 'TRUE' &&
                    (this.state.propsUser === null || this.state.propsUser === undefined)
                    && (sessionStorage.getItem('ssoUser') !== 'true' && localStorage.getItem('ssoUser') !== 'true')

                    ? <>
                        <SelectTenant history={this.props.history} />
                    </>
                    :
                    <> <SideMenu />
                        {this.state.showLoader && <Loader />}
                        <section id="rhsMain" className={this.props.open ? "rhs-main" : "rhs-main expand-w"}>
                            <Header />
                            {/* {this.state.fullView ?
                        <div className="bg-white rounded full-view">
                            <h1 className="page-title">Network Map</h1>
                            <img src="./images/svg-icons/resize.svg" alt="" title="Minimize" className="cancel-btn" onClick={() => this.setState({ fullView: false })} />
                            {(this.state.topoGraphData.length > 0 && this.state.fullView) &&
                                <D3TopoLogy
                                    data={this.state.topoGraphData}
                                    link={this.state.topoLinkData}
                                    fullView={this.state.fullView}
                                />}
                        </div> : */}
                            {this.state.bandwidthFullView ?
                                (
                                    <div className="bg-white radius-spacing tb-usages">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h1 className="page-title">Total Bandwidth Usages</h1>
                                            <div className="form-pos">
                                                <select className="form-control"
                                                    onChange={this.setUsageType}
                                                    value={this.state.dataUsageType}
                                                    disabled={this.state.selectedDuration === "custom" && this.state.end_date === ''}
                                                    style={{ cursor: this.state.selectedDuration === "custom" && this.state.end_date === '' ? "not-allowed" : "" }}
                                                >
                                                    <option value='daily'>Daily</option>
                                                    <option value='cumulative'>Cumulative</option>
                                                </select>
                                                <select
                                                    className="form-control"
                                                    onChange={this.getDeviceUsage}
                                                    value={this.state.selectedDuration.toString()}
                                                >
                                                    <option value='1'>Last 24 hours</option>
                                                    <option value='7'>Week</option>
                                                    <option value='30'>Month</option>
                                                    <option value='custom'>Custom</option>
                                                </select>
                                                {this.state.custom && <div className="tbu-date tbu-date1">
                                                    <div className="form-group" id="schedulerDiv">
                                                        <label>Start Date</label>
                                                        <Calendar
                                                            id="navigatorstemplate"
                                                            value={this.state.start_date}
                                                            onChange={(e) => this.setStartTime(e)}
                                                            readonlyInput="true"
                                                            placeholder="MM/DD/YYYY"
                                                            monthNavigator={true}
                                                            yearNavigator={true}
                                                            hideOnDateTimeSelect={true}
                                                            yearRange={`${new Date().getFullYear() - 10}: ${new Date().getFullYear() + 10}`}
                                                            maxDate={new Date()}
                                                            minDate={new Date(moment().subtract(90, 'days'))}
                                                        />
                                                    </div>

                                                    <div className="form-group" id="schedulerDiv">
                                                        <div className={this.state.start_date ? "" : "disabled"} key={this.state.keyUpdate}>
                                                            <label>End Date</label>
                                                            <Calendar
                                                                id="navigatorstemplate"
                                                                value={this.state.end_date}
                                                                onChange={this.state.start_date ? (e) => this.setEndTime(e) : "disabled"}
                                                                disabled={this.state.start_date === '' ? true : false}
                                                                readonlyInput="true"
                                                                placeholder="MM/DD/YYYY"
                                                                monthNavigator={true}
                                                                yearNavigator={true}
                                                                showOtherMonths={false}
                                                                hideOnDateTimeSelect={true}
                                                                yearRange={`${new Date().getFullYear() - 10}: ${new Date().getFullYear() + 10}`}
                                                                minDate={new Date(moment(this.state.start_date).add(1, 'days'))}
                                                                maxDate={new Date(moment(this.state.start_date).add(90, 'days')) > new Date() ? new Date() :
                                                                    new Date(moment(this.state.start_date).add(90, 'days'))}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                                <select
                                                    className="form-control"
                                                    onChange={this.setDataUsageType}
                                                    value={this.state.selectedUsageOption}
                                                    disabled={this.state.selectedDuration === "custom" && this.state.end_date === ''}
                                                    style={{ cursor: this.state.selectedDuration === "custom" && this.state.end_date === '' ? "not-allowed" : "" }}
                                                >
                                                    <option value='wan_data_usage'>ETH-WAN</option>
                                                    <option value='cellular_data_usage'>Cellular</option>
                                                    <option value='control_tunnel_usage'>Mgmt. Plane</option>
                                                </select>
                                                <button
                                                    className="form-control border-0"
                                                    style={{ width: "85px" }}
                                                    title={"Download Graph Data"}
                                                    onClick={() => { this.downloadGraph(this.state.graphData, "Bandwidth") }}
                                                >
                                                    <img src="/images/svg-icons/download-arrow.svg" />
                                                </button>
                                                <button
                                                    className="form-control border-0"
                                                    key="fullscreen"
                                                    style={{ width: "100px" }}
                                                    onClick={() => this.setState({ bandwidthFullView: false })}>
                                                    <img src="./images/svg-icons/resize.svg" alt="" />
                                                </button>
                                            </div>
                                        </div>
                                        {this.state.graphData?.length !== 0 ?
                                            (this.state.dataUsageType === 'cumulative' ?
                                                <div className={this.state.custom ? "graph-box graph-scrol mt-7 mb-2 tbu-chart" : "graph-box graph-scrol mt-3 mb-2 tbu-chart"}>
                                                    {/* <img src="../images/tbu.jpg" className="w-100" alt="" /> */}
                                                    <HighchartsReact
                                                        highcharts={Highcharts}
                                                        options={this.state.option}
                                                    />
                                                </div> : <div className={this.state.custom ? "graph-box graph-scrol mt-7 mb-2 tbu-chart" : "graph-box graph-scrol mt-3 mb-2 tbu-chart"}>
                                                    {/* <img src="../images/tbu.jpg" className="w-100" alt="" /> */}
                                                    <HighchartsReact
                                                        highcharts={Highcharts}
                                                        options={this.state.dailyOption}
                                                    />
                                                </div>
                                            ) : <div className="no-data-key">There are no records to display</div>
                                        }
                                    </div>
                                ) : this.state.barGraphFullView ? (
                                    <div className="bg-white radius-spacing tb-usages">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h1 className="page-title mr-7">Top Consumers</h1>
                                            {this.state.customTop && <div className="tbu-date tbu-date1">
                                                <div className="form-group" id="schedulerDiv">
                                                    <label>Start Date</label>
                                                    <Calendar
                                                        id="navigatorstemplate"
                                                        value={this.state.start_date_top}
                                                        onChange={(e) => this.setTopStartTime(e)}
                                                        readonlyInput="true"
                                                        placeholder="MM/DD/YYYY"
                                                        monthNavigator={true}
                                                        yearNavigator={true}
                                                        hideOnDateTimeSelect={true}
                                                        yearRange={`${new Date().getFullYear() - 10}: ${new Date().getFullYear() + 10}`}
                                                        maxDate={new Date()}
                                                        minDate={new Date(moment().subtract(90, 'days'))}
                                                    />
                                                </div>

                                                <div className="form-group" id="schedulerDiv">
                                                    <div className={this.state.start_date_top ? "" : "disabled"} key={this.state.keyUpdate}>
                                                        <label>End Date</label>
                                                        <Calendar
                                                            id="navigatorstemplate"
                                                            value={this.state.end_date_top}
                                                            onChange={this.state.start_date_top ? (e) => this.setTopEndTime(e) : "disabled"}
                                                            disabled={this.state.start_date_top === '' ? true : false}
                                                            readonlyInput="true"
                                                            placeholder="MM/DD/YYYY"
                                                            monthNavigator={true}
                                                            yearNavigator={true}
                                                            showOtherMonths={false}
                                                            hideOnDateTimeSelect={true}
                                                            yearRange={`${new Date().getFullYear() - 10}: ${new Date().getFullYear() + 10}`}
                                                            minDate={new Date(moment(this.state.start_date_top).add(1, 'days'))}
                                                            maxDate={new Date(moment(this.state.start_date_top).add(90, 'days')) > new Date() ? new Date() :
                                                                new Date(moment(this.state.start_date_top).add(90, 'days'))}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                            <div>
                                                <label className="mr-1 mt-1">{"Lower Threshold :"} </label>
                                                <input
                                                    className="upper_threshold"
                                                    type="text"
                                                    disabled={this.state.topConsDuration === 'custom' && this.state.end_date_top === ''}
                                                    value={this.state.lower_threshold === 0 ? '' : this.state.lower_threshold}
                                                    onChange={this.setLowerThreshold}
                                                    onBlur={this.checkThresholds}
                                                    style={{ cursor: this.state.topConsDuration === 'custom' && this.state.end_date_top === '' ? "not-allowed" : "" }}
                                                />
                                                <small className="ml-1">bytes</small>
                                            </div>
                                            <div className={this.state.lower_threshold ? "" : "disabled"}>
                                                <label className="mr-1 mt-1">{"Upper Threshold :"}</label>
                                                <input
                                                    type="text"
                                                    style={{ cursor: this.state.lower_threshold ? "" : "not-allowed" }}
                                                    className="upper_threshold"
                                                    value={this.state.upper_threshold === -1 ? "" : this.state.upper_threshold}
                                                    disabled={this.state.lower_threshold ? "" : "disabled"}
                                                    onChange={this.state.lower_threshold ? this.setUpperThreshold : ""}
                                                    onBlur={this.checkThresholds} />
                                                <small className="ml-1">bytes</small>
                                            </div>
                                            <div className="form-pos">
                                                <select
                                                    className="form-control"
                                                    onChange={this.selectedTopDuration}
                                                    value={this.state.topConsDuration}
                                                >
                                                    <option value='1'>Last 24 hours</option>
                                                    <option value='7'>Week</option>
                                                    <option value='30'>Month</option>
                                                    <option value='custom'>Custom</option>
                                                </select>
                                                <select
                                                    className="form-control"
                                                    onChange={(e) => { this.setState({ type_of_data_usage: e.target.value }, () => this.getTopDeviceUsage()) }}
                                                    value={this.state.type_of_data_usage}
                                                    disabled={this.state.topConsDuration === 'custom' && this.state.end_date_top === ''}
                                                    style={{ cursor: this.state.topConsDuration === 'custom' && this.state.end_date_top === '' ? "not-allowed" : "" }}
                                                >
                                                    <option value={"wan_data_usage"}>ETH-WAN</option>
                                                    <option value={"control_tunnel_usage"}>Mgmt. Plane</option>
                                                    <option value={"cellular_data_usage"}>Cellular</option>
                                                </select>
                                                <button
                                                    className="form-control border-0"
                                                    style={{ width: "65px" }}
                                                    title={"Download Graph Data"}
                                                    onClick={() => { this.downloadGraph(this.state.tempDevice.top_consumers, "TopConsumer") }}
                                                >
                                                    <img src="/images/svg-icons/download-arrow.svg" />
                                                </button>
                                                <button
                                                    className="form-control border-0"
                                                    style={{ width: "80px" }}
                                                    key="fullscreen"
                                                    onClick={() => this.setState({ barGraphFullView: false })}>
                                                    <img src="./images/svg-icons/resize.svg"
                                                        alt="" />
                                                </button>
                                            </div>
                                        </div>
                                        <div
                                            className={this.state.topConsDuration === 'custom' ? "error-txt text-center threshold_error" : "error-txt text-center"}
                                            id="upper_threshold"
                                        >
                                            Upper threshold must be greater than lower threshold
                                        </div>
                                        {this.state.tempDevice?.top_consumers?.length !== 0 ?
                                            <div
                                                className={this.state.customTop ? "graph-box graph-scrol mt-7 mb-2 tbu-chart" : "graph-box graph-scrol mt-3 mb-2 tbu-chart"}
                                                onClick={(e) => this.reDirect(e)}
                                                key={this.state.keyUpdateBar}
                                            >
                                                <HighchartsReact
                                                    highcharts={Highcharts}
                                                    options={this.state.option3}
                                                />
                                            </div>
                                            : <div className="no-data-key">There are no records to display</div>}
                                    </div>
                                )
                                    :
                                    (<>
                                        <div className="no-bg page-height">
                                            <div className="page-header bg d-flex align-items-center">
                                                <h1 className="page-title flex-grow-1">Dashboard</h1>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="d-flex align-items-center">
                                                        <h1 className="page-title txt-danger">Alerts</h1>
                                                        <ul className="alarms-list ml-3">
                                                            <li>
                                                                <Link to={{
                                                                    pathname: '/troubleshoot',
                                                                    state: { selectedTab: 'Warning&Alerts', deviceSeverity: 'Disaster', tabChange: true }
                                                                }}
                                                                    title="Critical"
                                                                >
                                                                    <div className="emergency">{this.state.deviceSeverityData.Disaster}</div>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to={{
                                                                    pathname: '/troubleshoot',
                                                                    state: { selectedTab: 'Warning&Alerts', deviceSeverity: 'High', tabChange: true }
                                                                }}
                                                                    title="High"
                                                                >
                                                                    <div className="critical">{this.state.deviceSeverityData.High}</div>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to={{
                                                                    pathname: '/troubleshoot',
                                                                    state: { selectedTab: 'Warning&Alerts', deviceSeverity: 'Average', tabChange: true }
                                                                }}
                                                                    title="Medium"
                                                                >
                                                                    <div className="average" title="Medium">{this.state.deviceSeverityData.Average}</div>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to={{
                                                                    pathname: '/troubleshoot',
                                                                    state: { selectedTab: 'Warning&Alerts', deviceSeverity: 'Warning', tabChange: true }
                                                                }}
                                                                    title="Low"
                                                                >
                                                                    <div className="warning">{this.state.deviceSeverityData.Warning}</div>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to={{
                                                                    pathname: '/troubleshoot',
                                                                    state: { selectedTab: 'Warning&Alerts', deviceSeverity: 'Information', tabChange: true }
                                                                }}
                                                                    title="Information"
                                                                >
                                                                    <div className="information">
                                                                        {this.state.deviceSeverityData.Information}
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                        {/* <select className="form-control" onChange={this.setDataUsageType}>
                                                    <option value='ETH-WAN'>Eth WAN</option>
                                                    <option value='Cellular-WAN'>Cellular</option>
                                                    <option value='Control Tunnel'>Control Tunnel</option>
                                                </select> */}
                                                    </div>
                                                    {/* <ul className="nav nav-pills" key="full"> <li className="nav-item fwidth pt-1 pl-2" key="fullscreen"
                                                onClick={() => this.setState({ bandwidthFullView: true })}>
                                                <img src="./images/svg-icons/fullscreen.svg" className="view-btn" alt="" />
                                            </li></ul> */}
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <Link to={{
                                                        pathname: '/troubleshoot',
                                                        state: { selectedTab: 'Warning&Alerts', tabChange: true }
                                                    }} className="txt-blue mr-3 ml-3">
                                                        View More</Link>
                                                    <div className="total">Total <span></span>{this.state.totalCountWarningAndAlert}</div>
                                                </div>
                                            </div>
                                            <div className="row no-gutters mb-10">
                                                <div className="col-md-7">
                                                    <div className="row no-gutters h-100">
                                                        <div className="col mr-1">
                                                            <div className="bg-white dash-card td-group">
                                                                <div className="icon">
                                                                    <img src="../images/svg-icons/wifi-router-white.svg" alt="" />
                                                                </div>
                                                                <div className="total-no">
                                                                    <a href="/devices/configured"><span className="txt-blue">{this.state.totalDeviceData.total_devices}</span></a>
                                                                    <small>DEVICES</small>
                                                                </div>
                                                                <div className="up-no">
                                                                    <img src="../images/svg-icons/up-arrow.svg" alt="" />
                                                                    <Link to={{
                                                                        pathname: "/devices/configured",
                                                                        state: {
                                                                            deviceStatus: 'online',
                                                                        }
                                                                    }}><span>{this.state.totalDeviceData.online_devices}</span></Link>
                                                                    <div className="sub-title">ONLINE</div>
                                                                </div>
                                                                <div className="down-no">
                                                                    <img src="../images/svg-icons/downs-arrow.svg" alt="" />
                                                                    <Link to={{
                                                                        pathname: "/devices/configured",
                                                                        state: {
                                                                            deviceStatus: 'offline',
                                                                        }
                                                                    }}><span>{this.state.totalDeviceData.offline_devices}</span></Link>
                                                                    <div className="sub-title">OFFLINE</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col mr-1">
                                                            <div className="bg-white dash-card tunnel">
                                                                <div className="icon">
                                                                    <img src="../images/svg-icons/tunnels-white.svg" alt="" />
                                                                </div>
                                                                <div className="total-no">
                                                                    <a href="/tunnels"><span className="txt-blue">{this.state.tunnelCount.total_tunnel}</span></a>
                                                                    <small>TUNNELS</small>
                                                                </div>
                                                                <div className="up-no">
                                                                    <img src="../images/svg-icons/up-arrow.svg" alt="" />
                                                                    <Link to={{
                                                                        pathname: "/tunnels",
                                                                        state: {
                                                                            tunnelStatus: 'Online',
                                                                        }
                                                                    }}><span>{this.state.tunnelCount.online_tunnel}</span></Link>
                                                                    <div className="sub-title">ONLINE</div>
                                                                </div>
                                                                <div className="down-no">
                                                                    <img src="../images/svg-icons/downs-arrow.svg" alt="" />
                                                                    <Link to={{
                                                                        pathname: "/tunnels",
                                                                        state: {
                                                                            tunnelStatus: 'Offline',
                                                                        }
                                                                    }}><span>{this.state.tunnelCount.offline_tunnel}</span></Link>
                                                                    <div className="sub-title">OFFLINE</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-5">
                                                    <div className="bg-white device-modal radius-spacing ml-1">
                                                        <h1 className="page-title txt-blue">Device Models</h1>
                                                        <div className="row no-gutters">
                                                            {this.state.modelData.map((item, index) => {
                                                                return (<div className="col-md-3 col-sm-6" key={index}>
                                                                    <div className="dm mr-2">
                                                                        <Link to={{
                                                                            pathname: '/devices/configured',
                                                                            state: { deviceModel: item.model_name }
                                                                        }}>
                                                                            <span >{item.model_name}</span> <span className="no" title="No. of devices">{item.count}</span>
                                                                        </Link>
                                                                    </div>

                                                                </div>)
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div >
                                            <div className="row no-gutters mb-10 ">
                                                <div className="col-md-12">
                                                    <div className="bg-white radius-spacing tb-usages">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <h1 className="page-title">Total Bandwidth Usages</h1>
                                                            <div className="form-pos">
                                                                <select
                                                                    className="form-control"
                                                                    onChange={this.setUsageType}
                                                                    value={this.state.dataUsageType}
                                                                    disabled={this.state.selectedDuration === "custom" && this.state.end_date === ''}
                                                                    style={{ cursor: this.state.selectedDuration === "custom" && this.state.end_date === '' ? "not-allowed" : "" }}
                                                                >
                                                                    <option value='daily'>Daily</option>
                                                                    <option value='cumulative'>Cumulative</option>
                                                                </select>
                                                                <select
                                                                    className="form-control"
                                                                    onChange={this.getDeviceUsage}
                                                                    value={this.state.selectedDuration.toString()}
                                                                >
                                                                    <option value='1'>Last 24 hours</option>
                                                                    <option value='7'>Week</option>
                                                                    <option value='30'>Month</option>
                                                                    <option value='custom'>Custom</option>
                                                                </select>
                                                                {this.state.custom && <div className="tbu-date">
                                                                    <div className="form-group" id="schedulerDiv">
                                                                        <label>Start Date</label>
                                                                        <Calendar
                                                                            id="navigatorstemplate"
                                                                            value={this.state.start_date}
                                                                            onChange={(e) => this.setStartTime(e)}
                                                                            readonlyInput="true"
                                                                            placeholder="MM/DD/YYYY"
                                                                            monthNavigator={true}
                                                                            yearNavigator={true}
                                                                            hideOnDateTimeSelect={true}
                                                                            yearRange={`${new Date().getFullYear() - 10}: ${new Date().getFullYear() + 10}`}
                                                                            maxDate={new Date()}
                                                                            minDate={new Date(moment().subtract(90, 'days'))}
                                                                        />
                                                                    </div>
                                                                    <div className="form-group" id="schedulerDiv">
                                                                        <div className={this.state.start_date ? "" : "disabled"} key={this.state.keyUpdate}>
                                                                            <label>End Date</label>
                                                                            <Calendar
                                                                                id="navigatorstemplate"
                                                                                value={this.state.end_date}
                                                                                onChange={this.state.start_date ? (e) => this.setEndTime(e) : "disabled"}
                                                                                disabled={this.state.start_date === '' ? true : false}
                                                                                readonlyInpushowButtonBar="true"
                                                                                placeholder="MM/DD/YYYY"
                                                                                monthNavigator={true}
                                                                                yearNavigator={true}
                                                                                showOtherMonths={false}
                                                                                hideOnDateTimeSelect={true}
                                                                                yearRange={`${new Date().getFullYear() - 10}: ${new Date().getFullYear() + 10}`}
                                                                                minDate={new Date(moment(this.state.start_date).add(1, 'days'))}
                                                                                maxDate={new Date(moment(this.state.start_date).add(90, 'days')) > new Date() ? new Date() :
                                                                                    new Date(moment(this.state.start_date).add(90, 'days'))}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                }
                                                                <select
                                                                    className="form-control"
                                                                    onChange={this.setDataUsageType}
                                                                    value={this.state.selectedUsageOption}
                                                                    disabled={this.state.selectedDuration === "custom" && this.state.end_date === ''}
                                                                    style={{ cursor: this.state.selectedDuration === "custom" && this.state.end_date === '' ? "not-allowed" : "" }}
                                                                >
                                                                    <option value='wan_data_usage'>ETH-WAN</option>
                                                                    <option value='cellular_data_usage'>Cellular</option>
                                                                    <option value='control_tunnel_usage'>Mgmt. Plane</option>
                                                                </select>
                                                                <button
                                                                    className="form-control border-0"
                                                                    style={{ width: "85px" }}
                                                                    title={"Download Graph Data"}
                                                                    onClick={() => { this.downloadGraph(this.state.graphData, "Bandwidth") }}
                                                                >
                                                                    <img src="/images/svg-icons/download-arrow.svg" />
                                                                </button>
                                                                <button
                                                                    className="form-control border-0"
                                                                    key="fullscreen"
                                                                    style={{ width: "85px" }}
                                                                    onClick={() => this.setState({ bandwidthFullView: true })}
                                                                >
                                                                    <img src="./images/svg-icons/fullscreen.svg" alt="" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                        {this.state.graphData?.length !== 0 ? (this.state.dataUsageType === 'cumulative' ?
                                                            <div className={this.state.custom ? "graph-box graph-scrol mt-7 mb-2 tbu-chart" : "graph-box graph-scrol mt-3 mb-2 tbu-chart"}>
                                                                {/* <img src="../images/tbu.jpg" className="w-100" alt="" /> */}
                                                                <HighchartsReact
                                                                    highcharts={Highcharts}
                                                                    options={this.state.option}
                                                                />
                                                            </div> : <div className={this.state.custom ? "graph-box graph-scrol mt-7 mb-2 tbu-chart" : "graph-box graph-scrol mt-3 mb-2 tbu-chart"}>
                                                                {/* <img src="../images/tbu.jpg" className="w-100" alt="" /> */}
                                                                <HighchartsReact
                                                                    highcharts={Highcharts}
                                                                    options={this.state.dailyOption}
                                                                />
                                                            </div>) : <div className="no-data-key">There are no records to display</div>}
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-5">
                                            <div className={this.state.fullView ? "bg-white radius-spacing map h-100 ml-1" : "bg-white radius-spacing map h-100 ml-1"}>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <h1 className="page-title">Network Map</h1>
                                                    <div className="fwidth" onClick={() => this.setState({ fullView: true })} >
                                                        <img src="./images/svg-icons/fullscreen.svg" className="view-btn" alt="" />
                                                        <img src="./images/svg-icons/cancel.svg" className="cancel-btn" /> 
                                                    </div>
                                                </div>
                                                <div className="graph-box mt-3">
                                                    {
                                                        this.state.topoGraphData.length > 0 && !this.state.fullView && <D3TopoLogy
                                                            data={this.state.topoGraphData}
                                                            link={this.state.topoLinkData}
                                                            fullView={this.state.fullView}
                                                        />
                                                    }
                                                    {this.state.topoGraphData.length === 0 && <div className="text-center p-5">No Data Available</div>}
                                                </div >
                                            </div >
                                        </div> */}
                                            </div>
                                            <div className="row no-gutters">
                                                <div className="col-md-12">
                                                    <div className="bg-white radius-spacing tb-usages">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <h1 className="page-title mr-7">Top Consumers</h1>
                                                            {this.state.customTop && <div className="tbu-date">
                                                                <div className="form-group" id="schedulerDiv">
                                                                    <label>Start Date</label>
                                                                    <Calendar
                                                                        id="navigatorstemplate"
                                                                        value={this.state.start_date_top}
                                                                        onChange={(e) => this.setTopStartTime(e)}
                                                                        readonlyInput="true"
                                                                        placeholder="MM/DD/YYYY"
                                                                        monthNavigator={true}
                                                                        yearNavigator={true}
                                                                        hideOnDateTimeSelect={true}
                                                                        yearRange={`${new Date().getFullYear() - 10}: ${new Date().getFullYear() + 10}`}
                                                                        maxDate={new Date()}
                                                                        minDate={new Date(moment().subtract(90, 'days'))}
                                                                    />
                                                                </div>

                                                                <div className="form-group" id="schedulerDiv">
                                                                    <div className={this.state.start_date_top ? "" : "disabled"} key={this.state.keyUpdate}>
                                                                        <label>End Date</label>
                                                                        <Calendar
                                                                            id="navigatorstemplate"
                                                                            value={this.state.end_date_top}
                                                                            onChange={this.state.start_date_top ? (e) => this.setTopEndTime(e) : "disabled"}
                                                                            disabled={this.state.start_date_top === '' ? true : false}
                                                                            readonlyInput="true"
                                                                            placeholder="MM/DD/YYYY"
                                                                            monthNavigator={true}
                                                                            yearNavigator={true}
                                                                            showOtherMonths={false}
                                                                            hideOnDateTimeSelect={true}
                                                                            yearRange={`${new Date().getFullYear() - 10}: ${new Date().getFullYear() + 10}`}
                                                                            minDate={new Date(moment(this.state.start_date_top).add(1, 'days'))}
                                                                            maxDate={new Date(moment(this.state.start_date_top).add(90, 'days')) > new Date() ? new Date() :
                                                                                new Date(moment(this.state.start_date_top).add(90, 'days'))}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            }
                                                            <div>
                                                                <label className="mr-1 mt-1">{"Lower Threshold :"} </label>
                                                                <input
                                                                    className="upper_threshold"
                                                                    type="text"
                                                                    disabled={this.state.topConsDuration === 'custom' && this.state.end_date_top === ''}
                                                                    value={this.state.lower_threshold === 0 ? '' : this.state.lower_threshold}
                                                                    onChange={this.setLowerThreshold}
                                                                    onBlur={this.checkThresholds}
                                                                    style={{ cursor: this.state.topConsDuration === 'custom' && this.state.end_date_top === '' ? "not-allowed" : "" }}
                                                                />
                                                                <small className="ml-1">bytes</small>
                                                            </div>
                                                            <div className={this.state.lower_threshold ? "" : "disabled"}>
                                                                <label className="mr-1 mt-1">{"Upper Threshold :"}</label>
                                                                <input
                                                                    type="text"
                                                                    style={{ cursor: this.state.lower_threshold ? "" : "not-allowed" }}
                                                                    className="upper_threshold"
                                                                    value={this.state.upper_threshold === -1 ? "" : this.state.upper_threshold}
                                                                    disabled={this.state.lower_threshold ? "" : "disabled"}
                                                                    onChange={this.state.lower_threshold ? this.setUpperThreshold : ""}
                                                                    onBlur={this.checkThresholds} />
                                                                <small className="ml-1">bytes</small>
                                                            </div>
                                                            <div className="form-pos">
                                                                <select
                                                                    className="form-control"
                                                                    onChange={this.selectedTopDuration}
                                                                    value={this.state.topConsDuration}
                                                                >
                                                                    <option value='1'>Last 24 hours</option>
                                                                    <option value='7'>Week</option>
                                                                    <option value='30'>Month</option>
                                                                    <option value='custom'>Custom</option>
                                                                </select>
                                                                <select
                                                                    className="form-control"
                                                                    onChange={(e) => { this.setState({ type_of_data_usage: e.target.value }, () => this.getTopDeviceUsage()) }}
                                                                    value={this.state.type_of_data_usage}
                                                                    disabled={this.state.topConsDuration === 'custom' && this.state.end_date_top === ''}
                                                                    style={{ cursor: this.state.topConsDuration === 'custom' && this.state.end_date_top === '' ? "not-allowed" : "" }}
                                                                >
                                                                    <option value={"wan_data_usage"}>ETH-WAN</option>
                                                                    <option value={"control_tunnel_usage"}>Mgmt. Plane</option>
                                                                    <option value={"cellular_data_usage"}>Cellular</option>
                                                                </select>
                                                                <button
                                                                    className="form-control border-0"
                                                                    style={{ width: "67px" }}
                                                                    title={"Download Graph Data"}
                                                                    onClick={() => { this.downloadGraph(this.state.tempDevice.top_consumers, "TopConsumer") }}
                                                                >
                                                                    <img src="/images/svg-icons/download-arrow.svg" />
                                                                </button>
                                                                <button
                                                                    className="form-control border-0"
                                                                    style={{ width: "67px" }}
                                                                    key="fullscreen"
                                                                    onClick={() => this.setState({ barGraphFullView: true })}>
                                                                    <img src="./images/svg-icons/fullscreen.svg"
                                                                        // className="view-btn" 
                                                                        alt="" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={this.state.topConsDuration === 'custom' ? "error-txt text-center threshold_error" : "error-txt text-center"}
                                                            id="upper_threshold"
                                                        >
                                                            Upper threshold must be greater than lower threshold
                                                        </div>
                                                        {this.state.tempDevice?.top_consumers?.length !== 0 ? <div className={this.state.customTop ? "graph-box graph-scrol mt-7 mb-2 tbu-chart" :
                                                            "graph-box graph-scrol mt-3 mb-2 tbu-chart"} onClick={(e) => this.reDirect(e)}
                                                            key={this.state.keyUpdateBar}>
                                                            <HighchartsReact
                                                                highcharts={Highcharts}
                                                                options={this.state.option3}
                                                            />
                                                        </div>
                                                            : <div className="no-data-key">There are no records to display</div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    )
                            }
                        </section> </>
                }
                {this.state.showErrorModal && <ErrorHandlePopup />}
                {(this.state.isAxiosError) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.resetPopup()}
                    />
                }
            </div >
        );
    }
}
const mapStateToProps = (state) => {
    return ({
        authenticated: state.auth.authenticated,
        open: state.displaySidebarMenu.openSide,
        user: state.auth.user,
        // SsoUser: state.auth.SsoUser
    });
}
export default withRouter(connect(mapStateToProps)(Dashboard));
