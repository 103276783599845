/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import SideMenu from "../../../layOuts/SideMenu";
import Header from "../../../layOuts/Header";
import urlMapping from "../../../redux/API/api";
import { Loader } from "../../../layOuts/Loader";
import moment from "moment";
import apiCall from "../../../redux/API/apiCall";
import Highcharts from 'highcharts/highstock';
import HighchartsReact from "highcharts-react-official";
import DeviceGroupTemplateList from "./DeviceGroupTemplateList";
import DeviceGroupNetworkView from "./DeviceGroupNetworkView";
import DataTable from "react-data-table-component";
import * as Common from "../../../utils/common";
import ErrorHandlePopup from "../../ErrorHandlePopup";
import { Link } from "react-router-dom";
import DeviceGroupTunnelView from "./DeviceGroupTunnelView.js";
import * as Action from "../../../redux/actionTypes"
import Select from "react-select";
import { Calendar } from 'primereact/calendar';
import AddDeviceGroup from '../../device/addDeviceGroup';
import $ from 'jquery';
import { ErrorPopup } from "../../../layOuts/errorPopup";
class DeviceGroupDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      selectedTab: "Dashboard",
      group_id: this.props.location?.state?.data?.group_id ? this.props.location?.state?.data?.group_id : "",
      group_name: "",
      group_uuid: "",
      groupDeviceData: [],
      DeviceGroupLookUp: [],
      getDeviceId: "",
      option: {},
      option2: {},
      graphData: [],
      graphData2: [],
      alertWarningCount: [],
      selectedDuration: 1,
      selectedPerDuration: 1,
      //long-1762-MA - [Device group] - Data usage should be displayed based on device model
      deviceModel: "",
      startDate: "",
      endDate: "",
      bandwidthFullView: false,
      deviceData: this.props.location?.state?.data,
      deviceGroupList: [],
      deviceGroupOptions: [],
      perDeviceFullView: false,
      topConsDuration: "1",
      start_date_top: '',
      end_date_top: '',
      type_of_data_usage: "cellular_data_usage",
      customTop: false,
      dataUsageType_band: "daily",
      selectedUsageOption_band: "cellular_data_usage",
      selectedDuration_band: 1,
      custom_band: false,
      diffDays_band: 0,
      lower_threshold: 0,
      upper_threshold: -1,
      deviceGroupDetails: [],
      isAxiosError: false,
      columns: [
        {
          name: 'Name',
          // selector: row => row.device_name,
          cell: (row) =>
          (<span title={row.device_name} style={{ width: 'auto' }} className="cursor-pointer"
          >
            <Link to={{
              pathname: '/devicedetails',
              state: {
                data: row,
                backTo: true
              }
            }} className="txt-blue">{row.device_name}</Link>
          </span>
          ),
        },
        {
          name: 'Status',
          selector: row => row.device_status === 1 ? 'Online' : 'Offline',
          cell: (row) => (
            <div id={`col${row.device_id}`}>
              <span id={`icon${row.device_id}`}>
                <i className={row.device_status === 1 ? "status online" : "status offline"}></i>
                {row.device_status === 1 ? 'Online' : 'Offline'}
              </span>
            </div>
          ),
        },
        {
          name: 'Model',
          selector: row => row.device_model,
        },
        {
          name: 'MAC Address',
          selector: row => row.device_mac,
        },
        {
          name: 'Failed Operations',
          cell: (row) =>
          (<span title={row.Failed_operations} style={{ width: 'auto' }} className={row.Failed_operations !== 0 ? "cursor-pointer" : ""}
          >
            {row.Failed_operations !== 0 ? <Link to={{
              pathname: '/operations',
              state: {
                failedOperation: row,
                group_uuid: this.state.group_uuid
              }
            }} className="txt-blue">{row.Failed_operations}</Link> : row.Failed_operations}
          </span>
          ),
        }
      ],
      data: []
    }
  }
  //long-1762-MA - [Device group] - Data usage should be displayed based on device model
  async componentDidMount() {
    await this.getDeviceGroupLookUp();
    await this.getDeviceGroupDetails();
    await this.getDeviceUsageData();
    await this.getPerDeviceUsage(1);
    if (this.props.location.state) {
      this.setState({
        group_id: this.props.location.state.data.group_id,
        group_name: this.props.location.state.data.group_name,
      }, () => {
      });
    } else {
      this.props.history.push("/deviceGroup");
    }
  }
  getOptions = () => {
    let device = [];
    {
      this.state.DeviceGroupLookUp &&
        this.state.DeviceGroupLookUp.length > 0 &&
        this.state.DeviceGroupLookUp.sort((a, b) =>
          a.group_name.localeCompare(b.group_name)
        ).map((item) => {
          device.push({
            value: item.group_id,
            label: item.group_name,
            icon: (
              <i
                className={
                  item.is_all_device_online === 1 ? "status online" : "status offline"
                }
              ></i>
            ),
          });
        });
    }
    return device
  }
  filterOptions = (option, inputValue) => {
    const label = option.data.label;
    const otherKey = this.getOptions().filter(
      (opt) => opt.label.toLowerCase() === label.toLowerCase() && opt.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    return label.toLowerCase().includes(inputValue.toLowerCase()) || otherKey.length > 0;
  }
  getDeviceList = () => {
    let device = [];
    {
      this.state.DeviceGroupLookUp &&
        this.state.DeviceGroupLookUp.length > 0 &&
        this.state.DeviceGroupLookUp.sort((a, b) =>
          a.group_name.localeCompare(b.group_name)
        ).map((item) => {
          device.push({
            value: item.group_id,
            label: item.group_name,
            icon: (
              <i
                className={
                  item.is_all_device_online === 1 ? "status online" : "status offline"
                }
              ></i>
            ),
          });
        });
    }
    let defaultValue = [];
    defaultValue.push({
      value: this.state.group_id,
      label: this.state.groupDeviceData.group_name,
      icon: (
        <i
          className={
            this.state.groupDeviceData.is_all_device_online === 1
              ? "status online"
              : "status offline"
          }
        ></i>
      ),
    });
    this.setState({
      deviceGroupOptions: device,
      deviceGroupList: defaultValue,
    });
  };
  getDeviceGroupLookUp = async () => {
    this.setState({ showLoader: true });
    // Page break issue was there due to same name used for calling API 
    await apiCall(urlMapping.deviceGroupLookUpDrop(), (response) => {
      this.setState({
        showLoader: false,
      });
      if (response?.data) {
        const data = response?.data;
        this.setState({
          DeviceGroupLookUp: data,
          count: response?.count,
        });
      } else {
        if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
          this.setState({
            showRefreshModal: true,
          });
        } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
          this.setState({
              title: 'Tenant Error',
              message: response.response.data.message,
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
          }, () => $("#errorMessage").modal("show"))
      }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
      }
    });
  };

  // Get DeviceGroup Info
  getDeviceGroupDetails = async () => {
    if (this.props.location.state !== undefined) {
      this.setState({
        showLoader: true
      });
      const data = {
        group_id: this.state.group_id
      }
      await apiCall(urlMapping.deviceGroupInfo(data), (response) => {
        this.setState({
          showLoader: false,
        });
        if (response?.data) {
          let model = Common.getModels(response?.data?.supported_models);
          if (model === "MIFI")
            model = Common.getMiFiModels(response?.data?.supported_models);
          this.setState({
            groupDeviceData: response?.data,
            deviceData: response?.data,
            deviceModel: model,
            group_uuid: response?.data?.group_uuid,
            selectedUsageOption_band: model === 'vCPE' ? 'wan_data_usage' : 'cellular_data_usage',
            type_of_data_usage: model === 'vCPE' ? "wan_data_usage" : "cellular_data_usage"
          });
        } else {
          if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
            this.setState({ showRefreshModal: true })
          } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
            this.setState({
                title: 'Tenant Error',
                message: response.response.data.message,
                showLoader: false,
                isAxiosError: true,
                delete: false,
                error: true
            }, () => $("#errorMessage").modal("show"))
        }
          else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
        }
      });
    }
    this.getDeviceList();
  }
  reDirect = (e) => {
    if (e.target.point !== undefined) {
      let deviceList = this.state.graphData2.top_consumers.filter((item) => item.device_name === e.target.point.category)
      this.props.history.push({
        pathname: '/devicedetails',
        state: { data: { device_id: deviceList[0].device_id } }
      })
    }
  }
  setTopStartTime = (e) => {
    this.setState({
      start_date_top: e.value ? e.value : '',
      end_date_top: '',
      keyUpdate: Math.random()
    })
  }
  setTopEndTime = (e) => {
    this.setState({
      end_date_top: e.value ? e.value : '',
    }, () => {
      if (this.state.end_date_top !== '')
        this.getPerDeviceUsage()
    })
  }
  selectedTopDuration = (e) => {
    if (e.target.value === "custom") {
      this.setState({
        start_date_top: '',
        end_date_top: '',
        topConsDuration: e.target.value,
        customTop: true
      })
    }
    else {
      this.setState({
        topConsDuration: e.target.value,
        customTop: false
      }, () => this.getPerDeviceUsage())
    }
  }
  getPerDeviceUsage = () => {
    this.setState({ showLoader: true })
    let edate;
    if (this.state.topConsDuration === "1") {
      edate = moment().format("YYYY-MM-DD HH:mm")
    }
    let endDate = this.state.topConsDuration === "1" ? edate : this.state.topConsDuration === "custom" ?
      moment(this.state.end_date_top).add(1, 'days').format("YYYY-MM-DD HH:mm") : moment().format('YYYY-MM-DD HH:mm')

    let startDate = this.state.topConsDuration === "1" ? moment(edate).subtract(24, 'hours').format("YYYY-MM-DD HH:mm") : this.state.topConsDuration === "7" ?
      moment().subtract(6, 'days').format('YYYY-MM-DD HH:mm') : this.state.topConsDuration === "30" ?
        moment().subtract(29, 'days').format('YYYY-MM-DD HH:mm') : moment(this.state.start_date_top).format("YYYY-MM-DD HH:mm")

    if (this.state.topConsDuration !== "1")
      startDate = moment(startDate).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format('YYYY-MM-DD HH:mm');
    let data = {
      type_of_data_usage: this.state.type_of_data_usage,
      start_date: moment.utc(new Date(startDate)).format('YYYY-MM-DD HH:mm'),
      end_date: moment.utc(new Date(endDate)).format('YYYY-MM-DD HH:mm'),
      group_id: this.state.group_id,
      count: parseInt(process.env.REACT_APP_TOP_COUNT),
      upper_threshold: this.state.upper_threshold ? this.state.upper_threshold : -1,
      lower_threshold: this.state.lower_threshold ? this.state.lower_threshold : 0
    }


    // let endDate = this.state.topConsDuration === "1" ? edate :
    //   this.state.topConsDuration === "custom" ? moment.utc(this.state.end_date_top).format("YYYY-MM-DD HH:mm") : moment.utc().format('YYYY-MM-DD HH:mm')

    // let startDate = this.state.topConsDuration === "1" ?
    //   moment.utc(edate).subtract(24, 'hours').format("YYYY-MM-DD HH:mm") : this.state.topConsDuration === "7" ?
    //     moment.utc().subtract(7, 'days').format('YYYY-MM-DD HH:mm') : this.state.topConsDuration === "30" ? moment.utc().subtract(30, 'days').format('YYYY-MM-DD HH:mm') : moment.utc(this.state.start_date_top).format("YYYY-MM-DD HH:mm")
    // if (this.state.type_of_data_usage === 'daily' && this.state.topConsDuration === "1") {
    //   startDate = moment.utc(edate).subtract(24, 'hours').format("YYYY-MM-DD HH:mm");
    // }
    // let data = {
    //   type_of_data_usage: this.state.type_of_data_usage,
    //   count: parseInt(process.env.REACT_APP_TOP_COUNT),
    //   group_id: this.state.group_id,
    //   start_date: startDate,
    //   end_date: endDate,
    //   upper_threshold: this.state.upper_threshold ? this.state.upper_threshold : -1,
    //   lower_threshold: this.state.lower_threshold ? this.state.lower_threshold : 0
    // }
    apiCall(urlMapping.getTopConsumerDeviceGroup(data), (response) => {
      if (response?.success) {
        this.setState({
          graphData2: response?.data,
          showLoader: false
        }, () => this.graphDataPer())
      } else {
        if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
          this.setState({
            showRefreshModal: true,
            showLoader: false
          })
        } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
          this.setState({
              title: 'Tenant Error',
              message: response.response.data.message,
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
          }, () => $("#errorMessage").modal("show"))
      }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          this.setState({
            showLoader: false
          })
        }
      }
    })
  }
  getExactTime = (array) => {
    let x = moment(array[0], "DD MMMM HH:mm").format("DD");
    for (let i = 0; i < array.length; i++) {
      if (moment(array[i], "DD MMMM HH:mm").format("DD") !== x) {
        array[i] = moment(array[i], "DD MMMM HH:mm").format("DD MMMM HH:mm");
        x = moment(array[i], "DD MMMM HH:mm").format("DD");
      } else {
        array[i] = moment(array[i], "DD MMMM HH:mm").format("HH:mm");
      }
    }
    return array

  }
  getTimeExact = (time) => {
    let date = moment.utc(time);
    date = date.local();
    return date.format("DD MMMM YYYY hh:mm:ss A");
  };

  getTime = (time) => {
    let date = moment.utc(time);
    date = date.local();
    if (this.state.selectedDuration_band === 1
      // || this.state.diffDays_band === 1
    ) {
      if (this.state.dataUsageType_band === 'daily')
        date.set({ minute: 0, second: 0, millisecond: 0 }).format('DD MMMM HH:mm')
      else
        date = date.format('DD MMMM HH:mm')
    } else
      date = date.format('DD MMMM');
    return date;
  }
  //long-1762-MA - [Device group] - Data usage should be displayed based on device model
  formatDate(date) {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) { dd = '0' + dd }
    if (mm < 10) { mm = '0' + mm }
    date = mm + '/' + dd + '/' + yyyy;
    return moment(date).format('DD MMMM');
  }
  //long-1762-MA - [Device group] - Data usage should be displayed based on device model
  Last7Days = (count) => {
    var result = [];
    for (var i = 0; i < count; i++) {
      var d = new Date();
      d.setDate(d.getDate() - i);
      result.push(this.formatDate(d))
      // result.push(this.formatDate(d))
    }
    return result;     //device group minor fix for 7 and 30 days - device group fix
  }

  //long-1762-MA - [Device group] - Data usage should be displayed based on device model
  timeOneDay = () => {
    var formattedTime;
    let time = [];
    const hoursPerDay = 24;
    for (let i = 0; i < hoursPerDay + 1; i++) { //fill in all of the hours
      formattedTime = (moment().subtract(i, "hours")).format("YYYY-MM-DDTHH:mm:ss");
      time.unshift(formattedTime);  //add to beginning of array
    }
    return time;                            //do this for all 24 hours
  }


  onDropChange = (e) => {
    this.props.dispatch({
      type: Action.DROPDOWN_CHANGE,
      payload: e.value,
    });
    let device = [];
    device.push(e);
    this.setState(
      {
        group_id: e.value,
        deviceGroupList: device,

      },
      () => this.refresh()
    );
  };
  calculateValue = (val, fix) => {
    let unit = '';
    let iteration = 1;
    val = Math.abs(val);
    if (val > 0) {
      for (let i = 1; i <= 4; i++) {
        if (val >= 1000) {
          val = val / 1000;
          iteration = i;
        }
      }
      switch (iteration) {
        case 1:
          unit = val.toFixed(fix) + ' KB';
          break;
        case 2:
          unit = val.toFixed(fix) + ' MB';
          break;
        case 3:
          unit = val.toFixed(fix) + ' GB';
          break;
        case 4:
          unit = val.toFixed(fix) + ' TB';
          break;
        default:
          unit = val.toFixed(fix) + ' KB';
          break;
      }
      return unit;
    } else
      return val;
  }
  graphDataPer = () => {
    const obj = this;
    let totalDevice = this.state.graphData2.top_consumers.length > 4 ? 4 : this.state.graphData2.top_consumers.length - 1
    let devices = []
    let usage = []
    let totalUsage = this.state.graphData2.total_data_usage
    this.state.graphData2.top_consumers.map((item) => {
      devices.push(item.device_name)
      if (item.wan_data_usage) {
        usage.push((item.wan_data_usage))
      }
      else if (item.cellular_data_usage) {
        usage.push((item.cellular_data_usage))
      }
      else if (item.control_tunnel_usage) {
        usage.push((item.control_tunnel_usage))
      }
    })
    let data2 = {
      chart: {
        type: 'bar'
      },
      title: {
        text: ''
      },
      exporting: {
        enabled: false
      },
      xAxis: {
        categories: devices,
        title: {
          text: 'Devices'
        },
        scrollbar: {
          enabled: this.state.graphData2.top_consumers.length > 4 ? true : false
        },
        min: 0,
        max: totalDevice,
      },
      yAxis: {
        title: {
          text: 'Data Usage'
        },
        type: 'logarithmic',
        tickInterval: 0.1,
        labels: {
          rotation: -50,
          formatter: function () {
            return '<a href="' + obj.calculateValue(this.value, 0) + '">' +
              obj.calculateValue(this.value, 0) + '</a>';
          }
        }
      },
      tooltip: {
        valueSuffix: ' millions',
        formatter: function () {
          return this.x + '<br>  <div className="dot-circle">&#9679;</div>' + "Data usage" + ' ' + obj.calculateValue(this.y.toFixed(4), 4)
        }
      },
      plotOptions: {
        series: {
          cursor: "pointer"
        },
        bar: {
          zones: [{
            value: totalUsage / 2,
          }, {
            value: (totalUsage / 4) * 3,
            color: '#839cb8'
          }, {
            color: '#063970'
          }
          ],
          dataLabels: {
            enabled: true,
            formatter: function () {
              return ((this.y / totalUsage) * 100).toFixed(2) + '%';
            }
          }
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        showInLegend: false,
        data: usage,
      },
      ]
    }
    this.setState({ option2: data2, keyUpdateBar: Math.random() })

  }
  setUpperThreshold = (e) => {
    if (Common.validateNumeric(e)) {
      this.setState({ upper_threshold: e.target.value })
    }
  }
  setLowerThreshold = (e) => {
    if (Common.validateNumeric(e)) {
      this.setState({
        lower_threshold: e.target.value,
        upper_threshold: ""
      })
    }
  }
  checkThresholds = () => {
    if (this.state.lower_threshold !== 0)
      if (parseInt(this.state.lower_threshold) > parseInt(this.state.upper_threshold)) {
        // const element = document.getElementById('threshold'); 
        // if(element)
        // element.style.display = 'block';
        this.setState({
          showThresholdError: true
        })
      } else {
        //   if(this.state.upper_threshold){
        //   const element = document.getElementById('threshold');
        //   if(element)
        //   element.style.display = 'none';
        // }
        this.setState({
          showThresholdError: false
        }, () => this.getPerDeviceUsage())
      }
  }
  // disableOption = () => {
  //   let result = false;
  //   if (process.env.REACT_APP_AD_USER === 'TRUE') {
  //     if (this.props.userRole !== 'Admin' || this.props.userRole !== 'Manager' ||
  //       sessionStorage.getItem('ssoUser') !== 'true') {
  //       result = true;
  //     }
  //   } else {
  //     if (this.props?.userRole === "Viewer_Group" ||
  //       this.props?.userRole === "Operator_Group") {
  //       result = true;
  //     }
  //   }
  //   return result;
  // }
  refresh = async () => {
    const selected = this.state.selectedTab
    await this.getDeviceGroupDetails();
    if (selected === 'Dashboard') {
      await this.getDeviceUsageData();
      await this.getPerDeviceUsage(1);
    }
  }
  getDeviceUsageData = (e) => {
    this.setState({
      startDate_band: '',
      endDate_band: '',
    })
    let endDate = moment().format('YYYY-MM-DD HH:mm');
    let startDate = moment().format('YYYY-MM-DD HH:mm');
    let day = e ? parseInt(e.target.value) : 1;
    if (!isNaN(day)) {
      let edate;
      this.setState({ selectedDuration_band: day })
      if (e === 1) {
        // if (moment().format("HH:mm") > moment().format("HH")) {
        //     edate = moment.utc().add(1, "hours").format("YYYY-MM-DD HH:mm")
        // }
        // else {
        edate = moment().format("YYYY-MM-DD HH:mm")
        // }
      }
      endDate = e === 1 ? edate : moment().format('YYYY-MM-DD HH:mm')

      startDate = day === 1 ? moment(edate).subtract(24, 'hours').format("YYYY-MM-DD HH:mm") : day === 7 ?
        moment().subtract(6, 'days').format('YYYY-MM-DD HH:mm') : moment().subtract(29, 'days').format('YYYY-MM-DD HH:mm')
      // if (this.state.dataUsageType_band === 'daily' && e === 1) {
      //   startDate = moment(edate).subtract(24, 'hours').format("YYYY-MM-DD HH:mm");
      // }
      this.setState({
        startDate_band: startDate,
        endDate_band: endDate,
        custom_band: false,
        diffDays_band: 0
      }, () => this.getDeviceUsage())
    } else {
      this.setState({
        custom_band: true,
        startDate_band: '',
        selectedDuration_band: e.target.value
      })
    }
  }
  getDeviceUsage = async (e) => {
    let { startDate_band } = this.state;
    let { endDate_band } = this.state;
    this.setState({ showLoader: true })
    if (this.state.dataUsageType_band === 'daily') {
      startDate_band = this.state.selectedDuration_band === 1
        //  || this.state.diffDays_band === 1
        ? moment(startDate_band).set({ minute: 59, second: 59, millisecond: 0 }).format("YYYY-MM-DD HH:mm") :
        moment(startDate_band).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format("YYYY-MM-DD HH:mm");
      startDate_band = moment.utc(new Date(startDate_band));
      startDate_band = startDate_band.format('YYYY-MM-DD HH:mm');
      endDate_band = moment.utc(new Date(endDate_band));
      endDate_band = this.state.custom_band ? endDate_band.add(1, 'days').format('YYYY-MM-DD HH:mm') : endDate_band.format('YYYY-MM-DD HH:mm');


      // startDate_band = moment.utc(startDate_band)
      // startDate_band = startDate_band.set({ minute: 0, second: 0, millisecond: 0 }).format("YYYY-MM-DD HH:mm");
      // endDate_band = moment.utc(endDate_band);
      // endDate_band = endDate_band.set({ minute: 0, second: 0, millisecond: 0 }).format('YYYY-MM-DD HH:mm');
      let data = {
        day_no: this.state.custom_band ? this.state.diffDays_band + 1 : this.state.selectedDuration_band,
        start_date: startDate_band,
        end_date: endDate_band,
        group_id: this.state.group_id,
      }
      apiCall(urlMapping.getDeviceGroupDelta(data), (response) => {
        if (response?.success) {
          this.setState({
            graphData: response?.data,
            showLoader: false
          }, () => this.graphData());
        } else {
          if (parseInt(response?.response?.results) === 401) {
            this.setState({
              showRefreshModal: true,
              showLoader: false
            });
          } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
            this.setState({
                title: 'Tenant Error',
                message: response.response.data.message,
                showLoader: false,
                isAxiosError: true,
                delete: false,
                error: true
            }, () => $("#errorMessage").modal("show"))
        }
          else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
          else {
            this.setState({
              showLoader: false
            });
          }
        }
      });
    } else {
      startDate_band = this.state.selectedDuration_band === 1
        // || this.state.diffDays_band === 1 
        ? moment(startDate_band).set({ minute: 59, second: 59, millisecond: 0 }).format("YYYY-MM-DD HH:mm") :
        moment(startDate_band).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format("YYYY-MM-DD HH:mm");
      startDate_band = moment.utc(new Date(startDate_band));
      startDate_band = startDate_band.format('YYYY-MM-DD HH:mm');
      // startDate_band = moment.utc(new Date(startDate_band))
      // startDate_band = startDate_band.set({ minute: 0, second: 0, millisecond: 0 }).format("YYYY-MM-DD HH:mm");
      // start_date = moment.utc(start_date).format('YYYY-MM-DD HH');
      endDate_band = moment.utc(new Date(endDate_band));
      endDate_band = this.state.custom_band ? endDate_band.add(1, 'days').format('YYYY-MM-DD HH:mm') : endDate_band.format('YYYY-MM-DD HH:mm');
      // startDate_band = moment.utc(startDate_band)
      // startDate_band = startDate_band.set({ minute: 0, second: 0, millisecond: 0 }).format("YYYY-MM-DD HH:mm");
      // endDate_band = moment.utc(endDate_band);
      // endDate_band = endDate_band.set({ minute: 0, second: 0, millisecond: 0 }).format('YYYY-MM-DD HH:mm');
      let data = {
        day_no: this.state.custom_band ? this.state.diffDays_band + 1 : this.state.selectedDuration_band,
        start_date: startDate_band,
        end_date: endDate_band,
        group_id: this.state.group_id,
      }
      apiCall(urlMapping.dashboardDeviceGroupUsage(data), (response) => {
        if (response?.Success) {
          this.setState({
            graphData: response?.data,
            showLoader: false
          }, () => this.graphData());
        } else {
          if (parseInt(response?.response?.results) === 401) {
            this.setState({
              showRefreshModal: true,
              showLoader: false
            });
          } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
            this.setState({
                title: 'Tenant Error',
                message: response.response.data.message,
                showLoader: false,
                isAxiosError: true,
                delete: false,
                error: true
            }, () => $("#errorMessage").modal("show"))
        }
          else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
            this.setState({
              title: 'Network Error',
              message: 'Something went wrong. Please try again later.',
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
            }, () => $("#errorMessage").modal("show"))
          }
          else {
            this.setState({
              showLoader: false
            });
          }
        }
      })
    }
  };
  getTooltip = (val) => {
    if (val.split(':')[0].split(' ').length > 2) {
      let parts = val.split(':')[0].split(' ');
      return (parseInt(parts[2]) + 1).toString() + ':00';
    } else
      return (parseInt(val.split(':')[0]) + 1).toString() + ':00'
  }
  graphData = () => {
    let wifiUsage = []
    let ControlTunnel = []
    let wanUsage = []
    let cellularUsage = []
    let time = []
    if (this.state.dataUsageType_band === 'cumulative') {
      this.state.graphData.map((item) => {
        if (moment(this.getTime(item.bucket)).format('mm') === '00') {
          // dataTunnel.push(item.data_tunnel_usage / (1000 * 1000))
          // wifiUsage.push(item.wifi_data_usage)
          ControlTunnel.push(item.control_tunnel_usage)
          wanUsage.push(item.wan_data_usage)
          cellularUsage.push(item.cellular_data_usage)
          time.push(this.getTime(item.bucket))
        }
      })
      if (this.state.graphData.length > 1 && moment(this.getTime(this.state.graphData[this.state.graphData.length - 1].bucket)).minutes() !== 0) {
        // wifiUsage.push(this.state.graphData[this.state.graphData.length-1].wifi_data_usage)
        ControlTunnel.push(this.state.graphData[this.state.graphData.length - 1].control_tunnel_usage)
        wanUsage.push(this.state.graphData[this.state.graphData.length - 1].wan_data_usage)
        cellularUsage.push(this.state.graphData[this.state.graphData.length - 1].cellular_data_usage)
        time.push(this.getTime(this.state.graphData[this.state.graphData.length - 1].bucket));
      }
    } else {
      for (let index = 0; index < this.state.graphData.length; index++) {
        // wifiUsage.push(this.state.graphData[index].wifi_data_usage)
        ControlTunnel.push(this.state.graphData[index].control_tunnel_usage)
        wanUsage.push(this.state.graphData[index].wan_data_usage)
        cellularUsage.push(this.state.graphData[index].cellular_data_usage)
        time.push(this.getTime(this.state.graphData[index].bucket));
      }
    }
    if (this.state.selectedDuration_band === 1
      //  || this.state.diffDays_band === 1
    ) {
      time = this.getExactTime(time)

    }
    let series = [
      {
        name: "wan_data_usage",
        data: wanUsage,
      },
      {
        name: "cellular_data_usage",
        data: cellularUsage,
      },
      {
        name: "control_tunnel_usage",
        data: ControlTunnel,
      },
      // {
      //   name: "Data-Tunnel",
      //   data: dataTunnel,
      // },
      // {
      //   name: "Wifi-Usage",
      //   data: wifiUsage,
      // },
    ]
    const obj = this;
    let { selectedDuration_band } = this.state;
    let data1 = {
      chart: {
        height: 300,
        type:
          this.state.dataUsageType_band === "daily" ? "column" :
            "area"
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false
      },
      yAxis: {
        title: {
          text: 'Data usage'
        },
        type: 'logarithmic',
        tickInterval: 0.5,
        labels: {
          formatter: function () {
            return '<a href="' + obj.calculateValue(this.value, 0) + '">' +
              obj.calculateValue(this.value, 0) + '</a>';
          }
        }
      },
      tooltip: {
        enabled: true,
        crosshairs: true,
        formatter: function () {
          if ((obj.state.selectedDuration_band === 1
            // || obj.state.diffDays_band === 1
          ) && obj.state.dataUsageType_band === 'daily') {
            return this.x + '-' + obj.getTooltip(this.x) + '<br>  <div className="dot-circle">&#9679;</div>' + this.series.name + ' ' + obj.calculateValue(this.y.toFixed(4), 4);
          } else
            return this.x + '<br>  <div className="dot-circle">&#9679;</div>' + this.series.name + ' ' + obj.calculateValue(this.y.toFixed(4), 4)
          //  this.y.toFixed(4) + unit;
        }
      },
      xAxis: {
        categories: time,
        title: {
          text: "Time Interval"
        },
        labels: {
          formatter: function () {
            let parts = this.value.split(' ');
            if (selectedDuration_band === 1
              // || obj.state.diffDays_band === 1
            ) {
              if (parts.length > 1) {
                return parts[0] + " " + parts[1].substr(0, 3) + " " + parts[2]
              } else {
                return '<a href="' + time[this.value] + '">' +
                  this.value + '</a>';
              }
            } else {
              return '<a href="' + time[this.value] + '">' +
                parts[0] + " " + parts[1].substr(0, 3) + '</a>';
            }
          }
        }
      },
      plotOptions: {
        series: {
          label: {
            connectorAllowed: false
          },
          connectNulls: true,
          pointStart: time[this.value],
          marker: {
            enabled: false,
            symbol: 'circle',
            radius: 2,
            states: {
              hover: {
                enabled: true
              }
            }
          }
        }
      },
      legend: { enabled: false },
      series: series.filter(item => item.name === this.state.selectedUsageOption_band),
    }
    this.setState({ option: data1 })
  }
  setDataUsageType = (e) => {
    this.setState({
      selectedUsageOption_band: e.target.value
    }, () => this.graphData())
  }
  calculateValue = (val, fix) => {
    let unit = '';
    let iteration = 1;
    val = Math.abs(val);
    if (val > 0) {
      for (let i = 1; i <= 4; i++) {
        if (val >= 1000) {
          val = val / 1000;
          iteration = i;
        }
      }
      switch (iteration) {
        case 1:
          unit = val.toFixed(fix) + ' KB';
          break;
        case 2:
          unit = val.toFixed(fix) + ' MB';
          break;
        case 3:
          unit = val.toFixed(fix) + ' GB';
          break;
        case 4:
          unit = val.toFixed(fix) + ' TB';
          break;
        default:
          unit = val.toFixed(fix) + ' KB';
          break;
      }
      return unit;
    } else
      return val;
  }
  setBandStartTime = (e) => {
    this.setState({
      startDate_band: e.value ? moment(e.value).format('YYYY-MM-DD HH:mm') : '',
      //  ? moment.utc(e.value).format('YYYY-MM-DD HH:mm') : '',
      endDate_band: '',
      keyUpdate: Math.random()
    })
  }
  setBandEndTime = (e) => {
    let startDate = moment(this.state.startDate_band).format("YYYY-MM-DD HH:mm");
    // moment.utc(this.state.startDate_band).format("YYYY-MM-DD HH:mm");
    let endDate = moment(e.value).format("YYYY-MM-DD HH:mm")
    //  moment.utc(e.value).format("YYYY-MM-DD HH:mm");
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    const diffTime = Math.abs(date2 - date1);
    var diffDays_band = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    this.setState({
      endDate_band: e.value ? moment(e.value).format("YYYY-MM-DD HH:mm") : '',
      //  moment.utc(e.value).format('YYYY-MM-DD HH:mm') : '',
      diffDays_band
    }, () => {
      if (this.state.endDate_band !== '')
        this.getDeviceUsage()
    })
  }

  setUsageType = (e) => {
    this.setState({
      dataUsageType_band: e.target.value
    }, () => this.getDeviceUsage())
  }

  setTopFullview = () => {
    this.setState({ perDeviceFullView: !this.state.perDeviceFullView })
  }
  downloadGraph = (value, key) => {
    this.setState({ showLoader: true })
    let data = key === "TopConsumer" ?
      {
        data: value,
        key: key,
        dataType: this.state.type_of_data_usage,
        user_time_zone: moment.tz.guess()
      }
      :
      {
        data: value,
        key: key,
        dataType: this.state.selectedUsageOption_band,
        usageType: this.state.dataUsageType,
        user_time_zone: moment.tz.guess()
      }
    apiCall(urlMapping.downloadDataUsageGraphs(data), (response) => {
      this.setState({ showLoader: false })
      if (parseInt(response?.response?.status) === 200) {
        const url = new Blob([response?.data])
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(url);
        link.setAttribute('download', key === "Bandwidth" ? ("DeviceGroup_Bandwidthusage" + "_" + moment().format('YYYY-MM-DD HH:mm') + ".xlsx") : ("DeviceGroup_Topconsumer" + "_" + moment().format('YYYY-MM-DD HH:mm') + ".xlsx"));
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.setState({
          showLoader: false
        })
      } else {
        if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
          this.setState({ showErrorModal: true })
        } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
          this.setState({
              title: 'Tenant Error',
              message: response.response.data.message,
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
          }, () => $("#errorMessage").modal("show"))
      }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
      }
    })
  }
  getGroupDetails = (id) => {
    let data = { group_id: id }
    this.setState({ showLoader: true })
    apiCall(urlMapping.deviceGroupList(data), (response) => {
      this.setState({ showLoader: false })
      if (response?.Success) {
        this.setState({
          deviceGroupDetails: response?.data
        }, () => this.openAddDeviceGroup())
      } else {
        if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
          this.setState({
            showRefreshModal: true
          });
        } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
          this.setState({
              title: 'Tenant Error',
              message: response.response.data.message,
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
          }, () => $("#errorMessage").modal("show"))
      }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
      }
    })
  }
  openAddDeviceGroup = () => {
    this.props.dispatch({
      type: Action.OPEN_UPDATE_FIRMWARE,
    });
  }
  closeAddDeviceGroup = () => {
    this.props.dispatch({
      type: Action.CLOSE_UPDATE_FIRMWARE,
    });
    this.setState({ deviceGroupDetails: [] })
  }
  render() {
    return (
      <div>
        <SideMenu />
        {this.state.showLoader && <Loader />}
        <section
          id="rhsMain"
          className={this.props.open ? "rhs-main" : "rhs-main expand-w"}
        >
          <Header />
          {/* Added fullscreen button  */}
          {this.state.bandwidthFullView ?
            <div className="bg-white h-100 radius-spacing du-box">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="page-title txt-blue flex-grow-1">
                  Total Bandwidth Usages
                </h1>
                <div className="form-pos">
                  <select
                    className="form-control"
                    onChange={this.setUsageType}
                    value={this.state.dataUsageType_band}
                    disabled={this.state.selectedDuration_band === "custom" && this.state.endDate_band === ''}
                    style={{ cursor: this.state.selectedDuration_band === "custom" && this.state.endDate_band === '' ? "not-allowed" : "" }}
                  >
                    <option value='daily'>Daily</option>
                    <option value='cumulative'>Cumulative</option>
                  </select>
                  <select
                    className="form-control"
                    onChange={(e) => this.getDeviceUsageData(e)}
                    value={this.state.selectedDuration_band.toString()}
                  >
                    <option value='1'>Last 24 hours</option>
                    <option value='7'>Week</option>
                    <option value='30'>Month</option>
                    <option value='custom'>Custom</option>
                  </select>
                  {this.state.custom_band && <div className="tbu-date tbu-date1">
                    <div className="form-group" id="schedulerDiv">
                      <label>Start Date</label>
                      <Calendar
                        id="navigatorstemplate"
                        value={this.state.startDate_band}
                        onChange={(e) => this.setBandStartTime(e)}
                        readonlyInput="true"
                        placeholder="MM/DD/YYYY"
                        monthNavigator={true}
                        yearNavigator={true}
                        hideOnDateTimeSelect={true}
                        yearRange={`${new Date().getFullYear() - 10}: ${new Date().getFullYear() + 10}`}
                        maxDate={new Date()}
                        minDate={new Date(moment().subtract(90, 'days'))}
                      />
                    </div>

                    <div className="form-group" id="schedulerDiv">
                      <div className={this.state.startDate_band ? "" : "disabled"} key={this.state.keyUpdate}>
                        <label>End Date</label>
                        <Calendar
                          id="navigatorstemplate"
                          value={this.state.endDate_band}
                          onChange={this.state.startDate_band ? (e) => this.setBandEndTime(e) : "disabled"}
                          disabled={this.state.startDate_band === '' ? true : false}
                          readonlyInput="true"
                          placeholder="MM/DD/YYYY"
                          monthNavigator={true}
                          yearNavigator={true}
                          showOtherMonths={false}
                          hideOnDateTimeSelect={true}
                          yearRange={`${new Date().getFullYear() - 10}: ${new Date().getFullYear() + 10}`}
                          minDate={new Date(moment(this.state.startDate_band).add(1, 'days'))}
                          maxDate={new Date(moment(this.state.startDate_band).add(90, 'days')) > new Date() ? new Date() :
                            new Date(moment(this.state.startDate_band).add(90, 'days'))}
                        />
                      </div>
                    </div>
                  </div>
                  }
                  <select
                    className="form-control"
                    onChange={this.setDataUsageType}
                    value={this.state.selectedUsageOption_band}
                    disabled={this.state.selectedDuration_band === "custom" && this.state.endDate_band === ''}
                    style={{ cursor: this.state.selectedDuration_band === "custom" && this.state.endDate_band === '' ? "not-allowed" : "" }}
                  >
                    {this.state.deviceModel !== 'Moretti_Model' && this.state.deviceModel !== 'Duvel_Model' && this.state.deviceModel !== 'Stormbreaker' && this.state.deviceModel !== 'Firestorm' &&
                      <option value='wan_data_usage'>ETH-WAN</option>}
                    {this.state.deviceModel !== 'vCPE' && <option value='cellular_data_usage'>Cellular-WAN</option>}
                    <option value='control_tunnel_usage'>Mgmt. Plane</option>
                  </select>
                  <button
                    className="form-control border-0"
                    style={{ width: "100px" }}
                    title={"Download Graph Data"}
                    onClick={() => { this.downloadGraph(this.state.graphData, "Bandwidth") }}
                  >
                    <img src="/images/svg-icons/download-arrow.svg" />
                  </button>
                  <li
                    className="nav-item fwidth"
                    key="fullscreen"
                    onClick={() => this.setState({ bandwidthFullView: false })}>

                    <img
                      src="./images/svg-icons/resize.svg"
                      style={{ width: "30px" }}
                      className="view-btn"
                      alt=""
                    />
                  </li>
                </div>
              </div>
              {this.state.graphData?.length !== 0 ?
                <div className={this.state.custom ? "graph-box pt-7 mt-8" : "graph-box pt-7 mt-2"}
                // key={this.state.keyUpdate}
                >
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={this.state.option}
                  />
                </div> : <div className="no-data-key">There are no records to display</div>}
            </div>
            : this.state.perDeviceFullView ?
              <div className="bg-white h-100 radius-spacing du-box">
                <div className="d-flex justify-content-between align-items-center">
                  <h1 className="page-title txt-blue">Top Consumers</h1>
                  <ul className="nav nav-pills topNav">
                    <li className="nav-item topNav-item">
                      <div className="thresholdInput">
                        <input
                          title="Lower Threshold"
                          type="text"
                          disabled={this.state.topConsDuration === 'custom' && this.state.end_date_top === ''}
                          placeholder="Lower Threshold"
                          className="lower_threshold"
                          value={this.state.lower_threshold === 0 ? '' : this.state.lower_threshold}
                          onChange={this.setLowerThreshold}
                          style={{ cursor: this.state.topConsDuration === 'custom' && this.state.end_date_top === '' ? "not-allowed" : "" }}
                          onBlur={() => this.checkThresholds()}
                        />
                        <small className="ml-1">bytes</small>
                      </div>
                    </li>

                    <li className="nav-item topNav-item">
                      <div className={this.state.lower_threshold ? "" : "disabled"}>
                        <input
                          title="Upper Threshold"
                          type="text"
                          placeholder="Upper Threshold"
                          style={{ cursor: this.state.lower_threshold ? "" : "not-allowed" }}
                          className="lower_threshold"
                          value={this.state.upper_threshold === -1 ? "" : this.state.upper_threshold}
                          disabled={this.state.lower_threshold ? "" : "disabled"}
                          onChange={this.state.lower_threshold ? this.setUpperThreshold : ""}
                          onBlur={() => this.checkThresholds()} />
                        <small className="ml-1">bytes</small>
                      </div>
                    </li>

                    <li className="nav-item topNav-item">
                      <select className="form-control" onChange={this.selectedTopDuration} value={this.state.topConsDuration}>
                        <option value='1'>Last 24 hours</option>
                        <option value='7'>Week</option>
                        <option value='30'>Month</option>
                        <option value='custom'>Custom</option>
                      </select>
                    </li>
                    <li className="nav-item topNav-item">
                      <select
                        className="form-control"
                        onChange={(e) => { this.setState({ type_of_data_usage: e.target.value }, () => this.getPerDeviceUsage()) }}
                        value={this.state.type_of_data_usage}
                        disabled={this.state.topConsDuration === 'custom' && this.state.end_date_top === ''}
                        style={{ cursor: this.state.topConsDuration === 'custom' && this.state.end_date_top === '' ? "not-allowed" : "" }}
                      >

                        {this.state.deviceModel !== 'Moretti_Model' && this.state.deviceModel !== 'Duvel_Model' && this.state.deviceModel !== 'Stormbreaker' && this.state.deviceModel !== 'Firestorm' &&
                          <option value="wan_data_usage">ETH-WAN</option>}
                        {this.state.deviceModel !== 'vCPE' && <option value={"cellular_data_usage"}>
                          Cellular Usage
                        </option>}
                        <option value={"control_tunnel_usage"}>
                          Mgmt. Plane
                        </option>

                      </select>
                    </li>
                    <li className="nav-item topNav-item">
                      <button
                        className="form-control border-0"
                        style={{ width: "43px" }}
                        title={"Download Graph Data"}
                        onClick={() => { this.downloadGraph(this.state.graphData2.top_consumers, "TopConsumer") }}
                      >
                        <img src="/images/svg-icons/download-arrow.svg" />
                      </button>
                    </li>
                    <li className="nav-item topNav-item fwidth pt-1 pl-2"
                      key="fullscreen"
                      onClick={this.setTopFullview}>
                      <img src="./images/svg-icons/resize.svg"
                        style={{ width: "28px" }}
                        className="view-btn" alt="" />
                    </li>
                    {this.state.customTop && <div className="tbu-date tbu-date1">
                      <div className="form-group" id="schedulerDiv">
                        <label>Start Date</label>
                        <Calendar
                          id="navigatorstemplate"
                          value={this.state.start_date_top}
                          onChange={(e) => this.setTopStartTime(e)}
                          readonlyInput="true"
                          placeholder="MM/DD/YYYY"
                          monthNavigator={true}
                          yearNavigator={true}
                          hideOnDateTimeSelect={true}
                          yearRange={`${new Date().getFullYear() - 10}: ${new Date().getFullYear() + 10}`}
                          maxDate={new Date()}
                          minDate={new Date(moment().subtract(90, 'days'))}
                        />
                      </div>

                      <div className="form-group" id="schedulerDiv">
                        <div className={this.state.start_date_top ? "" : "disabled"} key={this.state.keyUpdate}>
                          <label>End Date</label>
                          <Calendar
                            id="navigatorstemplate"
                            value={this.state.end_date_top}
                            onChange={this.state.start_date_top ? (e) => this.setTopEndTime(e) : "disabled"}
                            disabled={this.state.start_date_top === '' ? true : false}
                            readonlyInput="true"
                            placeholder="MM/DD/YYYY"
                            monthNavigator={true}
                            yearNavigator={true}
                            showOtherMonths={false}
                            hideOnDateTimeSelect={true}
                            yearRange={`${new Date().getFullYear() - 10}: ${new Date().getFullYear() + 10}`}
                            minDate={new Date(moment(this.state.start_date_top).add(1, 'days'))}
                            maxDate={new Date(moment(this.state.start_date_top).add(90, 'days')) > new Date() ? new Date() :
                              new Date(moment(this.state.start_date_top).add(90, 'days'))}
                          />
                        </div>
                      </div>
                    </div>
                    }
                  </ul>
                </div>
                {this.state.showThresholdError && <div className={this.state.topConsDuration === 'custom' ? "error-txt text-center threshold_error" : "error-txt text-center"}
                  style={{ display: 'block' }}
                  id="threshold">Upper threshold must be greater than lower threshold</div>}
                {this.state.graphData2?.top_consumers?.length !== 0 ?
                  <div className={this.state.customTop ? "graph mt-5 pt-3 text-center" : "graph pt-3 text-center"}
                    onClick={(e) => this.reDirect(e)}
                    key={this.state.keyUpdateBar}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={this.state.option2}
                    />
                  </div> : <div className="no-data-key">There are no records to display</div>}
              </div>
              :
              <div className="no-bg page-height">
                <div className="page-header bg d-flex align-items-center device-head">
                  <div className="back-page-icon"
                    onClick={(this.props.location.state && this.props.location.state.back) ?
                      () => this.props.history.push("/templates") :
                      () => this.props.history.push(
                        {
                          pathname: "/deviceGroup",
                          state: {
                            showIcon: this.props?.location?.state?.showIcon, deviceData: this.props?.location?.state?.deviceData,
                            totalCount: this.props?.location?.state?.totalCount, showFilter: this.props?.location?.state?.showFilter,
                            deviceModel: this.props?.location?.state?.deviceModel,
                            last: this.props?.location?.state?.last,
                            filterList: this.props?.location?.state?.filterList
                          }
                        })}>

                    <img src="/images/svg-icons/back-page.svg" alt="" title="Back" />
                  </div>
                  <Select
                    placeholder={false}
                    filterOption={(o, i) => this.filterOptions(o, i)}
                    value={
                      this.state.deviceGroupList.filter(option =>
                        option.value === this.state.group_id)
                    }
                    isSearchable
                    className='select-device'
                    options={this.getOptions()}
                    onChange={this.onDropChange}
                    getOptionLabel={(e) => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {e.icon}
                        <span style={{ marginLeft: 5 }}>{e.label}</span>
                      </div>
                    )}
                  />

                  <ul className="tab-nav nav nav-pills flex-grow-1" id="pills-tab" role="tablist">
                    <li className="nav-item" key='a'>
                      <a

                        className="nav-link active"
                        id="pills-dashboard-tab"
                        data-toggle="pill"
                        href="#pills-dashboard"
                        role="tab"
                        aria-controls="pills-dashboard"
                        aria-selected="true"
                        onClick={() => { this.setState({ selectedTab: "Dashboard" }, () => this.refresh()) }}
                      >
                        {" "}
                        Dashboard
                      </a>
                    </li>
                    <li className="nav-item" key='b'>
                      <a

                        className="nav-link"
                        id="pills-templates-tab"
                        data-toggle="pill"
                        href="#pills-templates"
                        role="tab"
                        aria-controls="pills-templates"
                        aria-selected="false"
                        onClick={() =>
                          this.setState({ selectedTab: "AppliedTemplate" })
                        }
                      >
                        {" "}
                        Templates
                      </a>
                    </li>
                    <li className="nav-item" key='c'>
                      <a
                        className="nav-link"
                        id="pills-Networks-tab"
                        data-toggle="pill"
                        href="#pills-Networks"
                        role="tab"
                        aria-controls="pills-Networks"
                        aria-selected="false"
                        onClick={() => this.setState({ selectedTab: "Networks" })}
                      >
                        {" "}
                        Networks
                      </a>
                    </li>
                    <li className="nav-item" key='d'>
                      <a
                        className="nav-link"
                        id="pills-Tunnels-tab"
                        data-toggle="pill"
                        href="#pills-Tunnels"
                        role="tab"
                        aria-controls="pills-Tunnels"
                        aria-selected="false"
                        onClick={() => this.setState({ selectedTab: "Tunnels" })}
                      >
                        {" "}
                        Tunnels
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  className="tab-content tab-pane fade active show"
                  id="pills-dashboard"
                  role="tabpanel"
                  aria-labelledby="pills-dashboard-tab"
                >
                  {this.state.selectedTab === "Dashboard" && (
                    <div>
                      <div className="device-status bg-white d-flex align-items-top">
                        <div className="device-on-off">
                          <h1 className="page-title txt-blue">Devices
                            &nbsp;
                            <span>{Object.keys(this.state.groupDeviceData).length ? this.state.groupDeviceData.attached_devices.length : 0}</span>
                            <button
                              className="btn btn-primary float-right"
                              style={{ padding: "2px", minWidth: "50px" }}
                              onClick={() => this.getGroupDetails(this.state.group_id)}
                              disabled={(process.env.REACT_APP_AD_USER === 'TRUE' &&
                                    (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                                      sessionStorage.getItem('ssoUser') !== 'true')) ||
                                    (this.props?.userRole === "Viewer_Group" ||
                                      this.props?.userRole === "Operator_Group")}
                            >
                              Edit
                            </button>
                          </h1>
                          <div className="d-flex align-items-center justify-content-between ml-3 mr-4 mt-3">
                            <div className="up-no"><img src="/images/svg-icons/up-arrow.svg" alt="" /><span>{Object.keys(this.state.groupDeviceData).length ? this.state.groupDeviceData.attached_devices.filter((deviceStatus) => deviceStatus.device_status === 1).length : 0}</span><div className="sub-title">ONLINE</div></div>
                            <div className="down-no"><img src="/images/svg-icons/downs-arrow.svg" alt="" /><span>{Object.keys(this.state.groupDeviceData).length ? this.state.groupDeviceData.attached_devices.filter((deviceStatus) => deviceStatus.device_status === 0).length : 0}</span><div className="sub-title">OFFLINE</div></div>
                          </div>
                        </div>
                        <div className="device-models device-modal">
                          <h1 className="page-title txt-blue">Device Model
                          </h1>
                          <div className="row no-gutters">
                            <div className="col-md-3 col-sm-6">
                              <div className="dm mr-2">
                                <span>{Object.keys(this.state.groupDeviceData).length ? this.state.groupDeviceData.supported_models : ''}</span>

                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="warning-alerts ml-auto w-33">
                          <h1 className="page-title txt-danger">Alerts <span>{Object.keys(this.state.groupDeviceData).length ? this.state.groupDeviceData.alerts.disaster + this.state.groupDeviceData.alerts.high + this.state.groupDeviceData.alerts.warning + this.state.groupDeviceData.alerts.information + this.state.groupDeviceData.alerts.average : ""}</span></h1>
                          <div className="row no-gutters">
                            <div className="col-md-4 col-sm-12">
                              <Link to={{
                                pathname: '/troubleshoot',
                                state: {
                                  selectedTab: 'Warning&Alerts',
                                  deviceSeverity: 'Disaster',
                                  group_id: this.state.group_id,
                                  tabChange: true
                                }
                              }}
                              >
                                <div className="emergency mr-2">
                                  <span>Critical</span>
                                  <span className="no">{Object.keys(this.state.groupDeviceData).length ? this.state.groupDeviceData.alerts.disaster : ""}</span>
                                </div>
                              </Link>
                            </div>
                            <div className="col-md-4 col-sm-12">
                              <Link to={{
                                pathname: '/troubleshoot',
                                state: {
                                  selectedTab: 'Warning&Alerts',
                                  deviceSeverity: 'High',
                                  group_id: this.state.group_id,
                                  tabChange: true
                                }
                              }}
                              >
                                <div className="critical mr-2">
                                  <span>High</span>
                                  <span className="no">{Object.keys(this.state.groupDeviceData).length ? this.state.groupDeviceData.alerts.high : ""}</span>
                                </div>
                              </Link>
                            </div>
                            <div className="col-md-4 col-sm-12">
                              <Link to={{
                                pathname: '/troubleshoot',
                                state: {
                                  selectedTab: 'Warning&Alerts',
                                  deviceSeverity: 'Average',
                                  group_id: this.state.group_id,
                                  tabChange: true
                                }
                              }}
                              >
                                <div className="average mr-2">
                                  <span>Medium</span>
                                  <span className="no">{Object.keys(this.state.groupDeviceData).length ? this.state.groupDeviceData.alerts.average : ""}</span>
                                </div>
                              </Link>
                            </div>
                            <div className="col-md-4 col-sm-12">
                              <Link to={{
                                pathname: '/troubleshoot',
                                state: {
                                  selectedTab: 'Warning&Alerts',
                                  deviceSeverity: 'Warning',
                                  group_id: this.state.group_id,
                                  tabChange: true
                                }
                              }}
                              >
                                <div className="warning mr-2">
                                  <span>Low</span>
                                  <span className="no">{Object.keys(this.state.groupDeviceData).length ? this.state.groupDeviceData.alerts.warning : ""}</span>
                                </div>
                              </Link>
                            </div>
                            <div className="col-md-4 col-sm-12">
                              <Link to={{
                                pathname: '/troubleshoot',
                                state: {
                                  selectedTab: 'Warning&Alerts',
                                  deviceSeverity: 'Information',
                                  group_id: this.state.group_id,
                                  tabChange: true
                                }
                              }}
                              >
                                <div className="information">
                                  <span>Information</span>
                                  <span className="no">{Object.keys(this.state.groupDeviceData).length ? this.state.groupDeviceData.alerts.information : ""}</span>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row no-gutters">
                        <div className="col-md-5 col-sm-12">
                          <div className="bg-white h-100 radius-spacing gps-box">
                            <h1 className="page-title txt-blue mb-2">Device Group Info</h1>
                            <div className="row no-gutters">
                              <div className="col-md-6 col-sm-12">
                                <div className="di-list"><small>Group Name</small>
                                  <span className="text-truncate">{this.state.groupDeviceData.group_name}</span>
                                </div>
                              </div>
                              <div className="col-md-6 col-sm-12">
                                <div className="di-list">
                                  <small>Created On</small>
                                  <span className="text-truncate">
                                    {this.getTimeExact(
                                      this.state.groupDeviceData.created_date
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="row no-gutters">
                              <div className="col-md-6 col-sm-12">
                                <div className="di-list"><small>Template Name</small>
                                  {this.state.groupDeviceData?.attached_template_count?.map(templateName => {
                                    return <span className="text-truncate">{templateName || "-"}</span>
                                  })}
                                </div>
                              </div>
                              <div className="col-md-6 col-sm-12">
                                <div className="di-list">
                                  <small>Template Version</small>
                                  {this.state.groupDeviceData?.attached_template_count?.map(item => {
                                    return <span className="text-truncate">{this.state.groupDeviceData?.templates_with_version?.[item] || "-"}</span>
                                  })}
                                </div>
                              </div>
                              {/* <div className="col-md-6 col-sm-12">
                                <div className="di-list">
                                  <small>Firmware</small>
                                  <span className="text-truncate">{this.state.groupDeviceData.min_supported_fw_version}</span>
                                </div>
                              </div> */}
                            </div>
                            {/* Devices Details */}
                            <h1 className="page-title txt-blue mt-3">Devices</h1>
                            <div className="devicegroup-table-height dg-space">
                              <DataTable columns={this.state.columns}
                                data={Object.keys(this.state.groupDeviceData).length ? this.state.groupDeviceData.attached_devices : []} pagination
                                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7 col-sm-12">
                          <div className="bg-white h-100 radius-spacing du-box">

                            <div className="d-flex justify-content-between align-items-center">
                              <h1 className="page-title txt-blue flex-grow-1">
                                Total Bandwidth Usages
                              </h1>
                              <div className="form-pos">
                                <select
                                  className="form-control"
                                  onChange={this.setUsageType}
                                  value={this.state.dataUsageType_band}
                                  disabled={this.state.selectedDuration_band === "custom" && this.state.endDate_band === ''}
                                  style={{ cursor: this.state.selectedDuration_band === "custom" && this.state.endDate_band === '' ? "not-allowed" : "" }}
                                >
                                  <option value='daily'>Daily</option>
                                  <option value='cumulative'>Cumulative</option>
                                </select>
                                <select
                                  className="form-control"
                                  onChange={(e) => this.getDeviceUsageData(e)}
                                  value={this.state.selectedDuration_band.toString()}
                                >
                                  <option value='1'>Last 24 hours</option>
                                  <option value='7'>Week</option>
                                  <option value='30'>Month</option>
                                  <option value='custom'>Custom</option>
                                </select>
                                {this.state.custom_band && <div className="tbu-date">
                                  <div className="form-group" id="schedulerDiv">
                                    <label>Start Date</label>
                                    <Calendar
                                      id="navigatorstemplate"
                                      value={this.state.startDate_band}
                                      onChange={(e) => this.setBandStartTime(e)}
                                      readonlyInput="true"
                                      placeholder="MM/DD/YYYY"
                                      monthNavigator={true}
                                      yearNavigator={true}
                                      hideOnDateTimeSelect={true}
                                      yearRange={`${new Date().getFullYear() - 10}: ${new Date().getFullYear() + 10}`}
                                      maxDate={new Date()}
                                      minDate={new Date(moment().subtract(90, 'days'))}
                                    />
                                  </div>

                                  <div className="form-group" id="schedulerDiv">
                                    <div className={this.state.startDate_band ? "" : "disabled"} key={this.state.keyUpdate}>
                                      <label>End Date</label>
                                      <Calendar
                                        id="navigatorstemplate"
                                        value={this.state.endDate_band}
                                        onChange={this.state.startDate_band ? (e) => this.setBandEndTime(e) : "disabled"}
                                        disabled={this.state.startDate_band === '' ? true : false}
                                        readonlyInput="true"
                                        placeholder="MM/DD/YYYY"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        hideOnDateTimeSelect={true}
                                        showOtherMonths={false}
                                        yearRange={`${new Date().getFullYear() - 10}: ${new Date().getFullYear() + 10}`}
                                        minDate={new Date(moment(this.state.startDate_band).add(1, 'days'))}
                                        maxDate={new Date(moment(this.state.startDate_band).add(90, 'days')) > new Date() ? new Date() :
                                          new Date(moment(this.state.startDate_band).add(90, 'days'))}
                                      />
                                    </div>
                                  </div>
                                </div>
                                }
                                <select
                                  className="form-control"
                                  onChange={this.setDataUsageType}
                                  value={this.state.selectedUsageOption_band}
                                  disabled={this.state.selectedDuration_band === "custom" && this.state.endDate_band === ''}
                                  style={{ cursor: this.state.selectedDuration_band === "custom" && this.state.endDate_band === '' ? "not-allowed" : "" }}
                                >
                                  {this.state.deviceModel !== 'Moretti_Model' && this.state.deviceModel !== 'Duvel_Model' && this.state.deviceModel !== 'Stormbreaker' && this.state.deviceModel !== 'Firestorm' &&
                                    <option value='wan_data_usage'>ETH-WAN</option>}
                                  {this.state.deviceModel !== 'vCPE' && <option value='cellular_data_usage'>Cellular-WAN</option>}
                                  <option value='control_tunnel_usage'>Mgmt. Plane</option>
                                </select>
                                <button
                                  className="form-control border-0"
                                  style={{ width: "100px" }}
                                  title={"Download Graph Data"}
                                  onClick={() => { this.downloadGraph(this.state.graphData, "Bandwidth") }}
                                >
                                  <img src="/images/svg-icons/download-arrow.svg" />
                                </button>
                                <li
                                  className="nav-item fwidth"
                                  key="fullscreen"
                                  onClick={() => this.setState({ bandwidthFullView: true })}>

                                  <img
                                    src="./images/svg-icons/fullscreen.svg"
                                    className="view-btn"
                                    alt=""
                                  />
                                </li>
                              </div>
                            </div>
                            {this.state.graphData?.length !== 0 ?
                              <div className={this.state.custom ? "graph-box pt-7 mt-8" : "graph-box pt-7 mt-2"}
                              >
                                <HighchartsReact
                                  highcharts={Highcharts}
                                  options={this.state.option}
                                />
                              </div> : <div className="no-data-key">There are no records to display</div>}

                            <div className="d-flex justify-content-between align-items-center">
                              <h1 className="page-title txt-blue">Top Consumers</h1>
                              <ul className="nav nav-pills topNav">
                                <li className="nav-item topNav-item">
                                  <div className="thresholdInput">
                                    <input
                                      title="Lower Threshold"
                                      type="text"
                                      disabled={this.state.topConsDuration === 'custom' && this.state.end_date_top === ''}
                                      placeholder="Lower Threshold"
                                      className="lower_threshold"
                                      value={this.state.lower_threshold === 0 ? '' : this.state.lower_threshold}
                                      onChange={this.setLowerThreshold}
                                      style={{ cursor: this.state.topConsDuration === 'custom' && this.state.end_date_top === '' ? "not-allowed" : "" }}
                                      onBlur={() => this.checkThresholds()}
                                    />
                                    <small className="ml-1">bytes</small>
                                  </div>
                                </li>

                                <li className="nav-item topNav-item">
                                  <div className={this.state.lower_threshold ? "" : "disabled"}>
                                    <input
                                      title="Upper Threshold"
                                      type="text"
                                      placeholder="Upper Threshold"
                                      style={{ cursor: this.state.lower_threshold ? "" : "not-allowed" }}
                                      className="lower_threshold"
                                      value={this.state.upper_threshold === -1 ? "" : this.state.upper_threshold}
                                      disabled={this.state.lower_threshold ? "" : "disabled"}
                                      onChange={this.state.lower_threshold ? this.setUpperThreshold : ""}
                                      onBlur={() => this.checkThresholds()} />
                                    <small className="ml-1">bytes</small>
                                  </div>
                                </li>

                                <li className="nav-item topNav-item">
                                  <select className="form-control" onChange={this.selectedTopDuration} value={this.state.topConsDuration}>
                                    <option value='1'>Last 24 hours</option>
                                    <option value='7'>Week</option>
                                    <option value='30'>Month</option>
                                    <option value='custom'>Custom</option>
                                  </select>
                                </li>
                                <li className="nav-item topNav-item">
                                  <select
                                    className="form-control"
                                    onChange={(e) => { this.setState({ type_of_data_usage: e.target.value }, () => this.getPerDeviceUsage()) }}
                                    value={this.state.type_of_data_usage}
                                    disabled={this.state.topConsDuration === 'custom' && this.state.end_date_top === ''}
                                    style={{ cursor: this.state.topConsDuration === 'custom' && this.state.end_date_top === '' ? "not-allowed" : "" }}
                                  >
                                    {this.state.deviceModel !== 'Moretti_Model' && this.state.deviceModel !== 'Duvel_Model' && this.state.deviceModel !== 'Stormbreaker' && this.state.deviceModel !== 'Firestorm' &&
                                      <option value="wan_data_usage">ETH-WAN</option>}
                                    {this.state.deviceModel !== 'vCPE' && <option value={"cellular_data_usage"}>
                                      Cellular Usage
                                    </option>}
                                    <option value={"control_tunnel_usage"}>
                                      Mgmt. Plane
                                    </option>
                                  </select>
                                </li>
                                <li className="nav-item topNav-item">
                                  <button
                                    className="form-control border-0"
                                    style={{ width: "43px" }}
                                    title={"Download Graph Data"}
                                    onClick={() => { this.downloadGraph(this.state.graphData2.top_consumers, "TopConsumer") }}
                                  >
                                    <img src="/images/svg-icons/download-arrow.svg" />
                                  </button>
                                </li>
                                <li className="nav-item topNav-item fwidth pt-1 pl-2 mt-1" key="fullscreen"
                                  onClick={this.setTopFullview}>
                                  <img src="./images/svg-icons/fullscreen.svg" className="view-btn" alt="" />
                                </li>
                                {this.state.customTop && <div className="tbu-date tbu-date2">
                                  <div className="form-group" id="schedulerDiv">
                                    <label>Start Date</label>
                                    <Calendar
                                      id="navigatorstemplate"
                                      value={this.state.start_date_top}
                                      onChange={(e) => this.setTopStartTime(e)}
                                      readonlyInput="true"
                                      placeholder="MM/DD/YYYY"
                                      monthNavigator={true}
                                      yearNavigator={true}
                                      hideOnDateTimeSelect={true}
                                      yearRange={`${new Date().getFullYear() - 10}: ${new Date().getFullYear() + 10}`}
                                      maxDate={new Date()}
                                      minDate={new Date(moment().subtract(90, 'days'))}
                                    />
                                  </div>

                                  <div className="form-group" id="schedulerDiv">
                                    <div className={this.state.start_date_top ? "" : "disabled"} key={this.state.keyUpdate}>
                                      <label>End Date</label>
                                      <Calendar
                                        id="navigatorstemplate"
                                        value={this.state.end_date_top}
                                        onChange={this.state.start_date_top ? (e) => this.setTopEndTime(e) : "disabled"}
                                        disabled={this.state.start_date_top === '' ? true : false}
                                        readonlyInput="true"
                                        placeholder="MM/DD/YYYY"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        showOtherMonths={false}
                                        hideOnDateTimeSelect={true}
                                        yearRange={`${new Date().getFullYear() - 10}: ${new Date().getFullYear() + 10}`}
                                        minDate={new Date(moment(this.state.start_date_top).add(1, 'days'))}
                                        maxDate={new Date(moment(this.state.start_date_top).add(90, 'days')) > new Date() ? new Date() :
                                          new Date(moment(this.state.start_date_top).add(90, 'days'))}
                                      />
                                    </div>
                                  </div>
                                </div>
                                }
                              </ul>
                            </div>
                            {this.state.showThresholdError && <div className={this.state.topConsDuration === 'custom' ?
                              "error-txt text-center threshold_error" : "error-txt text-center"} id="threshold" style={{ display: 'block' }}>
                              Upper threshold must be greater than lower threshold</div>}
                            {this.state?.graphData2?.top_consumers?.length !== 0 ?
                              <div
                                className={this.state.customTop ? "graph mt-5 pt-3 text-center" : "graph pt-3 text-center"}
                                onClick={(e) => this.reDirect(e)}
                                key={this.state.keyUpdateBar}>
                                <HighchartsReact
                                  highcharts={Highcharts}
                                  options={this.state.option2}
                                />
                              </div> : <div className="no-data-key">There are no records to display</div>}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.selectedTab === "AppliedTemplate" && (
                    <DeviceGroupTemplateList
                      id={this.state.group_id}
                      group_name={this.state.group_name}
                      deviceData={this.state.deviceData}
                    />
                  )}
                  {this.state.selectedTab === "Networks" && (
                    <DeviceGroupNetworkView
                      id={this.state.group_id}
                      data={this.state.deviceData} />
                  )}
                  {this.state.selectedTab === "Tunnels" && (
                    <DeviceGroupTunnelView
                      id={this.state.group_id}
                      data={this.state.deviceData}
                    />
                  )}
                </div>
              </div>
          }
        </section>
        {this.props.openUpdateFirmware &&
          <AddDeviceGroup
            id={this.state.group_id}
            props={this.props}
            deviceGroupDetails={this.state.deviceGroupDetails}
            closeAddDeviceGroup={() => this.closeAddDeviceGroup()}
            getDeviceGroupList={() => this.refresh()}
          />}
        {this.state.showRefreshModal && <ErrorHandlePopup />}
        {(this.state.isAxiosError) &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            delete={this.state.delete}
            reset={() => this.setState({ isAxiosError: false })}
          />
        }
      </div >
    )
  }
};
const mapStateToProps = (state) => {
  return {
    authenticated: state.auth.authenticated,
    open: state.displaySidebarMenu.openSide,
    openUpdateFirmware: state.displayUpdateFirmware.openUpdateFirmware,
    userRole: JSON.parse(localStorage.getItem('user'))?.userRole,
    SsoUser: state.auth.SsoUser
  };
};

export default withRouter(connect(mapStateToProps)(DeviceGroupDetails));