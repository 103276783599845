/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import SideMenu from '../../layOuts/SideMenu';
import Header from '../../layOuts/Header';
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { Loader } from '../../layOuts/Loader';
import Topology from './Topology';
import moment from 'moment';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import ErrorHandlePopup from "../ErrorHandlePopup";
import EditTunnel from "../tunnels/EditTunnel";
import * as Action from "../../redux/actionTypes";
import $ from "jquery";
import { ErrorPopup } from "../../layOuts/errorPopup";

class Tunnelview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRefreshModal: false,
            showLoader: false,
            selectedTemplate: 1,
            title: '',
            message: '',
            show: false,
            error: false,
            delete: false,
            configured: false,
            device_mac: [],
            tunnel_id: "",
            selectedDuration: 1,
            tunnelGraphData: [],
            keyUpdate: "",
            option: {},
            tunnelData: undefined,
            TemplateData: [],
            templateColumns: [],
            addTemplate: false,
            tunnel_status: this.props?.location?.state?.data?.tunnel_status,
            // For updating tunnel info and status properly
            addTunnel: false,
            disableUpdate: false,
            deviceA_networks: [],
            deviceB_networks: [],
            tunnel_type: "",
            data: [],
            isAxiosError: false
        };
    }
    componentDidMount() {
        if (this.props.location.state) {
            this.setState({
                tunnel_id: this.props.location.state.data.tunnel_id,
                tunnel_type: this.props.location.state.data.tunnel_type
            }, () => { this.getTunnelDataUsage(1); this.getTunnelDetails(); this.getPollingData(); })
        }
    }
    getPollingData = () => {
        this.setState({
            showLoader: true,
        });
        let data = {
            device_id: this.state.tunnel_id,
        }
        ApiCall(urlMapping.getPollingData(data), (response) => {
            this.setState({
                showLoader: false,
            });
            if (response?.success) {
                this.setState({ data: response?.data?.getTunnelStatistics?.keys });
            } else {
                this.setState({
                    showLoader: false
                });
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({
                        showRefreshModal: true,
                    });
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else {
                    this.setState({
                        title: 'Telemetry',
                        message: "Unable to get data!",
                        show: true,
                        error: true,
                    }, () => $("#errorMessage").modal("show"));
                }
            }
        });
    }
    getTunnelDataUsage = (e) => {
        let day = e
        let edate;
        this.setState({ selectedDuration: day })
        if (e === 1) {
            if (moment.utc().format("HH:mm") > moment.utc().format("HH")) {
                edate = moment.utc().add(1, "hours").format("YYYY-MM-DD HH:mm")
            }
            else {
                edate = moment.utc().format("YYYY-MM-DD HH:mm")
            }
        }
        let endDate = e === 1 ? edate : moment.utc().format('YYYY-MM-DD HH:mm')

        let startDate = day === 1 ? moment.utc(edate).subtract(25, 'hours').format("YYYY-MM-DD HH:mm") : day === 7 ?
            moment.utc().subtract(7, 'days').format('YYYY-MM-DD HH:mm') : moment.utc().subtract(30, 'days').format('YYYY-MM-DD HH:mm')
        let data = {
            tunnel_id: this.state.tunnel_id,
            day_no: day,
            start_date: startDate,
            end_date: endDate
        }
        ApiCall(urlMapping.tunnelDataUsage(data), (response) => {
            if (response?.success) {
                this.setState({ tunnelGraphData: response?.data }, () => this.tunnelData())
            } else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({ showRefreshModal: true })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })
    }
    getTimeExact = (time, format) => {
        let date = moment.utc(time);
        date = date.local();
        return date.format(format);
    }

    tunnelData = () => {
        let data_usage = [];
        let time = [];
        this.state.tunnelGraphData.map((item) => {
            data_usage.push(item.data_usage / (1024 * 1024))
            if (this.state.selectedDuration === 1)
                time.push(this.getTimeExact(item.bucket, 'hh:mm A'));
            else
                time.push(this.getTimeExact(item.bucket, 'DD MMMM YYYY'));
        })



        let series = [{
            name: 'Data Usage',
            data: data_usage
        }]
        let data = {
            chart: {
                type: 'area',
                height: 290,
            },
            title: {
                text: ''
            },
            credits: {
                enabled: false,

            },
            // for menu button enable & disabled
            exporting: {
                enabled: false
            },
            xAxis: {
                categories: time,
                labels: {
                    formatter: function () {
                        return '<a href="' + time[this.value] + '">' +
                            this.value + '</a>';
                    }
                }
            },
            yAxis: {
                title: {
                    text: 'values in MB'
                },
            },
            tooltip: {
                pointFormat: '{series.name} <b>{point.y} MB</b>'
            },
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false
                    },
                }
            },
            series: series

        }

        this.setState({ option: data });

    }

    handleAddTemplate = () => {
        this.setState(prevState => ({
            addTemplate: !prevState.addTemplate
        }));
    }
    getTunnelDetails = () => {
        const device_mac = [];
        device_mac.push(this.props.location.state.data.device_A_mac);
        device_mac.push(this.props.location.state.data.device_B_mac);
        let tunnel_id = this.state.tunnel_id ? this.state.tunnel_id : this.props.location.state.data.tunnel_id;
        let data = {
            device_mac: this.state.device_mac.length > 0 ? this.state.device_mac : device_mac,
            tunnel_id
        };
        this.setState({
            showLoader: true,
        });
        ApiCall(urlMapping.tunnelInfo(data), (response) => {
            this.setState({
                showLoader: false,
            });
            if (response?.success && response?.data?.length > 0) {
                this.setState({ tunnelData: response?.data[0], keyUpdate: Math.random() })
            } else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({ showRefreshModal: true })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })
        setTimeout(() => {
        }, 1000);
    }
    onDropChange = (e) => {
        const selectedData = this.props.location.state.tunnelListWithId.filter(item => item.id === parseInt(e.target.value))[0]
        this.setState({
            device_mac: selectedData.mac,
            tunnel_id: selectedData.id,
            // For updating tunnel info and status properly
            tunnel_status: selectedData.status,
            tunnel_latency: selectedData.tunnel_latency,
            drop_rate_in: selectedData.drop_rate_in,
            drop_rate_out: selectedData.drop_rate_out,
            tunnel_data_usage: selectedData.tunnel_data_usage,
            created_date: selectedData.created_date,
            tunnel_type: selectedData.tunnel_type
        }, () => {
            this.getTunnelDetails("onDropChange");
            this.getTunnelDataUsage(1);
            this.getPollingData();
        })

    }
    openEdit = () => {
        const device_mac = [];
        device_mac.push(this.props.location.state.data.device_A_mac);
        device_mac.push(this.props.location.state.data.device_B_mac);
        let tunnel_id = this.state.tunnel_id ? this.state.tunnel_id : this.props.location.state.data.tunnel_id;
        let data = {
            device_mac: this.state.device_mac.length > 0 ? this.state.device_mac : device_mac,
            tunnel_id
        };
        this.setState({
            showLoader: true,
        });
        ApiCall(urlMapping.tunnelInfo(data), (response) => {
            this.setState({
                showLoader: false,
            });
            if (response?.success) {
                this.getNetworksDeviceA(response?.data?.[0]?.device_A?.device_id);
                this.getNetworksDeviceB(response?.data?.[0]?.device_B?.device_id);
                this.setState(
                    {
                        tunnelDevicesData: response?.data[0],
                        pass: true,
                    },
                    () => {
                        this.props.dispatch({
                            type: Action.OPEN_EDIT_TUNNEL,
                            payload: tunnel_id
                        })
                    }
                );
            }
            else if (response?.data) {
                if (response?.data?.length < 1) {
                    this.setState(
                        {
                            tunnelDevicesData: undefined,
                            title: "Edit Tunnel",
                            message: response?.message,
                            show: true,
                            error: true,
                            pass: false,
                        },
                        () => {
                            $("#errorMessage").modal("show");
                        }
                    );
                } else {
                    this.setState(
                        {
                            tunnelDevicesData: undefined,
                            title: "Edit Tunnel",
                            message: response?.data?.message,
                            show: true,
                            error: true,
                            pass: false,
                        },
                        () => {
                            $("#errorMessage").modal("show");
                        }
                    );
                }
            } else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({ showRefreshModal: true })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else {
                    this.setState(
                        {
                            tunnelDevicesData: undefined,
                            title: "Edit Tunnel",
                            message: response?.message,
                            show: true,
                            error: true,
                            pass: false,
                        },
                        () => {
                            $("#errorMessage").modal("show");
                        }
                    );
                }
            }
        });
    };
    getNetworksDeviceA = (id) => {
        let data = {
            device_id: id
        }
        if (String(data.device_id) !== "") {
            this.setState({ showLoader: true })
            ApiCall(urlMapping.getNetworkView(data), (response) => {
                if (response?.Success) {
                    this.setState({
                        showLoader: false,
                        deviceA_networks: response?.data,
                    });
                } else {
                    this.setState({
                        showLoader: false
                    });
                    if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                        this.setState({ showRefreshModal: true })
                    } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                        this.setState({
                            title: 'Tenant Error',
                            message: response.response.data.message,
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else {
                        this.setState({
                            title: 'Update Tunnel',
                            message: 'No Networks found on device. Network template must be applied to update tunnel',
                            show: true,
                            error: true,
                            showLoader: false,
                            disableUpdate: true
                        }, () => {
                            $("#errorMessage").modal("show")
                        });
                    }
                }
            });
        }
    }
    getNetworksDeviceB = (id) => {
        let data = {
            device_id: id
        }
        if (String(data.device_id) !== "") {
            this.setState({ showLoader: true })
            ApiCall(urlMapping.getNetworkView(data), (response) => {
                if (response?.Success) {
                    this.setState({
                        showLoader: false,
                        deviceB_networks: response?.data,
                    });
                } else {
                    if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                        this.setState({ showRefreshModal: true })
                    } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                        this.setState({
                            title: 'Tenant Error',
                            message: response.response.data.message,
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else {
                        this.setState({
                            title: 'Update Tunnel',
                            message: 'No Networks found on device. Network template must be applied to update tunnel',
                            show: true,
                            error: true,
                            showLoader: false,
                            disableUpdate: true
                        }, () => {
                            $("#errorMessage").modal("show")
                        });
                    }
                }
            });
        }
    }
    resetPopup = () => {
        this.setState({
            show: false,
            error: false,
        }, () => {
            $("#errorMessage").modal("hide");
        })
    }

    refreshTelemetry = () => {
        this.setState({ showLoader: true });
        let temp = {
            device_A: {
                name: this.state.tunnelData.device_A.device_name,
                control_ip: this.state.tunnelData.device_A.device_a_c_ip
            },
            device_B: {
                name: this.state.tunnelData.device_B.device_name,
                control_ip: this.state.tunnelData.device_B.device_b_c_ip
            },
            interface_name: this.state.tunnelData.settings.tunnel_interface_name
        }
        let data = {
            tunnel: temp,
            device_id: ""
        }
        ApiCall(urlMapping.get_Global_refresh(data), (response) => {
            this.setState({ showLoader: false });
            if (response?.success) {
                this.getTunnelDetails()
            }
            else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({
                        showRefreshModal: true
                    });
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        showLoader: false
                    });
                }
            }
        })
    }


    render() {
        return (

            <div>
                <SideMenu />
                {this.state.showLoader && <Loader />}
                <section id="rhsMain" className={this.props.open ? "rhs-main" : "rhs-main expand-w"}>
                    <Header />
                    {(this.state.tunnelData !== undefined) ?
                        <div className="no-bg page-height tunnel-view">
                            <div className="page-header bg d-flex align-items-center">
                                <div className="back-page-icon"
                                    onClick={() => this.props.history.push(
                                        {
                                            pathname: "/tunnels",
                                            state: {
                                                filterData: this.props.location.state.filterData || "",
                                                filterKey: (this.props.location.state.filterData?.selectedDevice || this.props.location.state.filterData?.status) ? "FilterClicked" : "",
                                                searchValue: this.props.location.state.searchValue,
                                                searchKey: this.props.location.state.searchValue ? "SearchClicked" : ""
                                            }
                                        })}>
                                    <img src="/images/svg-icons/back-page.svg" alt="" title="Back" />
                                </div>
                                <select className="form-control dd-drop" value={this.state.tunnel_id} onChange={(e) => this.onDropChange(e)}>
                                    {this.props.location.state.tunnelListWithId.sort((a, b) => a.name.localeCompare(b.name)).map((item) => {
                                        return (<option value={item.id}>{item.name}</option>)
                                    })}

                                </select>

                            </div>

                            <div className="row no-gutters mb-10">
                                <div className="col-md-6">
                                    <div className="bg-white tunnel-info radius-spacing h-100">
                                        <div class="row">
                                            <div class="col">
                                                <h1 className="page-title text-blue mb-2">Tunnel Info</h1>
                                            </div>
                                            <div class="col-12 col-sm-auto">
                                                <button
                                                    className= {(this.state?.tunnel_type === "Hub & Spoke" &&
                                                    (this.state.tunnelData.settings?.group_id !== 'null' &&
                                                        this.state.tunnelData.settings?.group_id !== null &&
                                                        this.state.tunnelData.settings?.group_id !== '')
                                                ) ||
                                                (process.env.REACT_APP_AD_USER === 'TRUE' &&
                                                (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                                                localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                                                (this.props?.userRole === "Viewer_Group" ||
                                                  this.props?.userRole === "Operator_Group") ? "btn btn-primary btn-sm disabled" : "btn btn-primary btn-sm"}
                                                    onClick={() =>
                                                        (process.env.REACT_APP_AD_USER === 'TRUE' &&
                                                        (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                                                        localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                                                        (this.props?.userRole === "Viewer_Group" ||
                                                          this.props?.userRole === "Operator_Group") 
                                                            ? undefined
                                                            : this.openEdit()}
                                                    disabled={
                                                        (this.state?.tunnel_type === "Hub & Spoke" &&
                                                            (this.state.tunnelData.settings?.group_id !== 'null' &&
                                                                this.state.tunnelData.settings?.group_id !== null &&
                                                                this.state.tunnelData.settings?.group_id !== '')
                                                        ) ||
                                                        (process.env.REACT_APP_AD_USER === 'TRUE' &&
                                                        (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                                                        localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                                                        (this.props?.userRole === "Viewer_Group" ||
                                                          this.props?.userRole === "Operator_Group")
                                                    }
                                                >
                                                    Edit Tunnel
                                                </button>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <label>Tunnel Name</label>
                                                    <strong> {this.state.tunnelData.settings.tunnel_name}</strong>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <label>Tunnel Status</label>
                                                    <strong> <i className={this.state.tunnel_status === "Online" ? "status online" : "status offline"}></i>{this.state.tunnel_status} </strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="graybg-title">Device A</div>
                                        <div className="row">
                                            <div className="col-md-5 col-sm-12">
                                                <div className="form-group">
                                                    <label>Device Name</label>
                                                    <strong> {this.state.tunnelData.device_A.device_name}</strong>
                                                </div>
                                            </div>
                                            {/* show bgp & zone interface status */}
                                            <div className="col-md-4">
                                                <div className="form-group d-flex align-items-center">
                                                    <input type="checkbox" checked={this.state.tunnelData.device_A.add_zone_in_firewall} style={{ cursor: "not-allowed", accentColor: "grey" }} />

                                                    <label className="mb-0 mt-1 ml-2">Add Interface to Zone
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group d-flex align-items-center">

                                                    <input type="checkbox" checked={this.state.tunnelData.device_A.add_bgp_in_tunnel} style={{ cursor: "not-allowed", accentColor: "grey" }} />

                                                    <label className="mb-0 mt-1 ml-2">Enable BGP
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4 col-sm-12">
                                                <div className="form-group">
                                                    <label>Endpoint Details</label>
                                                    <strong> {this.state.tunnelData.device_A.endpoint_details}</strong>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <div className="form-group fixedIp">
                                                    <label>Allowed IP</label>
                                                    <strong> {this.state.tunnelData.device_A.allowed_ips.map((item) => {
                                                        return item + " "
                                                    })}</strong>
                                                </div>
                                            </div>
                                            {/* LONG-2371 : Fixed IP Icon show in Data Tunnel Page */}
                                            <div className="col-md-4 col-sm-12">
                                                <div className="form-group fixedIp d-flex align-items-center">
                                                    <input type="checkbox" checked={this.state.tunnelData.device_A.is_fixed_ip} style={{ cursor: "not-allowed", accentColor: "grey" }} />

                                                    <label className="mb-0 mt-1 ml-2">Is Fixed IP
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-5 col-sm-12">
                                                <div className="form-group">
                                                    <label>Mgmt. Plane IP</label>
                                                    <strong> {this.state.tunnelData.device_A.private_ip}</strong>
                                                </div>

                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <div className="form-group">
                                                    <label>Listen Port</label>
                                                    <strong> {this.state.tunnelData.device_A.own_port}</strong>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="graybg-title mt-3">Device B</div>
                                        <div className="row">
                                            <div className="col-md-5 col-sm-12">
                                                <div className="form-group">
                                                    <label>Device Name</label>
                                                    <strong> {this.state.tunnelData.device_B.device_name}</strong>
                                                </div>
                                            </div>
                                            {/* show bgp & zone interface status  */}
                                            <div className="col-md-4">
                                                <div className="form-group d-flex align-items-center">
                                                    <input type="checkbox" checked={this.state.tunnelData.device_B.add_zone_in_firewall} style={{ cursor: "not-allowed", accentColor: "grey" }} />

                                                    <label className="mb-0 mt-1 ml-2">Add Interface to Zone
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group d-flex align-items-center">
                                                    <input type="checkbox" checked={this.state.tunnelData.device_B.add_bgp_in_tunnel} style={{ cursor: "not-allowed", accentColor: "grey" }} />

                                                    <label className="mb-0 mt-1 ml-2">Enable BGP
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4 col-sm-12">
                                                <div className="form-group">
                                                    <label>Endpoint Details</label>
                                                    <strong> {this.state.tunnelData.device_B.endpoint_details}</strong>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <div className="form-group fixedIp">
                                                    <label>Allowed IP</label>
                                                    <strong> {this.state.tunnelData.device_B.allowed_ips.map((item) => {
                                                        return item + "  "
                                                    })}</strong>
                                                </div>
                                            </div>
                                            {/* LONG-2371 : Fixed IP Icon show in Data Tunnel Page */}
                                            <div className="col-md-4 col-sm-12">
                                                <div className="form-group fixedIp d-flex align-items-center">
                                                    <input type="checkbox" checked={this.state.tunnelData.device_B.is_fixed_ip} style={{ cursor: "not-allowed", accentColor: "grey" }} />

                                                    <label className="mb-0 mt-1 ml-2">Is Fixed IP
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-5 col-sm-12">
                                                <div className="form-group">
                                                    <label>Mgmt. Plane IP</label>
                                                    <strong> {this.state.tunnelData.device_B.private_ip}</strong>
                                                </div>

                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <div className="form-group">
                                                    <label>Listen Port</label>
                                                    <strong> {this.state.tunnelData.device_B.own_port}</strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="page-title border-bottom">Settings</div>
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="d-flex align-items-center justify-content-between mb-1">
                                                    <label className="mb-0 mr-3">Keep Alive

                                                    </label>
                                                    <label className="switch gray">
                                                        <input type="checkbox" name="tunnel_keep_alive_status" checked={this.state.tunnelData.settings.tunnel_keep_alive_status} />
                                                        <span className="slider round" style={{ cursor: "not-allowed" }}></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="form-group">
                                                    <label>Keep Alive Interval

                                                    </label>
                                                    <strong> {this.state.tunnelData.settings.tunnel_keep_alive_interval} Seconds</strong>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="page-title border-bottom">Tunnel Info</div>
                                        <img
                                            className="float-right refreshTunnelInfo"
                                            style={{ width: "40px", cursor: "pointer" }}
                                            src="/images/refreshT.png"
                                            title="Refresh Telemetry data"
                                            alt=""
                                            onClick={() => this.refreshTelemetry()}
                                        />
                                        <div className="gps-box p-0 mr-0">
                                            <div className="row no-gutters">
                                                <div className="col-md-4 col-sm-12">
                                                    <div className="di-list">
                                                        <small>Average Latency</small>
                                                        <span className="text-truncate">
                                                            {/* For updating tunnel info and status properly */}
                                                            {this.state.tunnelData?.settings?.tunnel_latency || '-'}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-sm-12">
                                                    <div className="di-list">
                                                        <small>Drop Rate TX</small>
                                                        <span className="text-truncate">
                                                            {/* For updating tunnel info and status properly */}
                                                            {this.state.tunnelData?.settings?.drop_rate_out || '-'}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-sm-12">
                                                    <div className="di-list">
                                                        <small>Drop Rate RX</small>
                                                        {/* For updating tunnel info and status properly */}
                                                        <span className="text-truncate">
                                                            {this.state.tunnelData?.settings?.drop_rate_in || '-'}
                                                        </span>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row no-gutters">

                                                <div className="col-md-4 col-sm-12">
                                                    <div className="di-list">
                                                        <small>Data Usage(bytes)</small>
                                                        {/* For updating tunnel info and status properly */}
                                                        <span className="text-truncate">
                                                            {this.state.tunnelData?.settings?.tunnel_data_usage || '-'}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-8 col-sm-12">
                                                    <div className="di-list mb-0">
                                                        <small>Created On</small>
                                                        <span className="text-truncate" title={this.getTimeExact(this.state.created_date, 'DD MMMM YYYY hh:mm:ss A')}>
                                                            {/* For updating tunnel info and status properly */}
                                                            {this.getTimeExact(this.state?.tunnelData?.settings?.created_date, 'DD MMMM YYYY hh:mm:ss A')}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div className="col-md-6">
                                    <div key={this.state.keyUpdate} className="bg-white t-topology radius-spacing mb-10">
                                        <h1 className="page-title text-blue">Tunnel Topology</h1>
                                        <Topology data={this.state.tunnelData} status={this.props.location.state.data.tunnel_status} />
                                    </div>
                                    <div className="bg-white tb-usages radius-spacing">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h1 className="page-title text-blue">Total Bandwidth Usages</h1>
                                            <ul className="nav nav-pills">
                                                <li className="nav-item"><button className={this.state.selectedDuration === 1 ? "nav-link active" : "nav-link"}
                                                    onClick={() => this.getTunnelDataUsage(1)}>24 hrs</button></li>
                                                <li className="nav-item"><button className={this.state.selectedDuration === 7 ? "nav-link active" : "nav-link"}
                                                    onClick={() => this.getTunnelDataUsage(7)}>7 Days</button></li>
                                                <li className="nav-item"><button className={this.state.selectedDuration === 30 ? "nav-link active" : "nav-link"}
                                                    onClick={() => this.getTunnelDataUsage(30)}>30 Days</button></li>
                                            </ul>
                                        </div>
                                        <div className="graph-box mt-3 mb-2">
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={this.state.option}
                                            />


                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div> : <div className="white-bg no-data">NO DATA</div>}
                    {this.props.openEditTunnel && (
                        <EditTunnel
                            data={this.state.tunnelDevicesData}
                            deviceA_networks={this.state.deviceA_networks}
                            deviceB_networks={this.state.deviceB_networks}
                            disableUpdate={this.state.disableUpdate}
                            getTunnelInfo={() => this.getTunnelDetails()}
                        />
                    )}
                </section>
                {this.state.show && (
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.resetPopup()}
                    />
                )}
                {this.state.showRefreshModal && <ErrorHandlePopup />}
                {(this.state.isAxiosError) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.setState({ isAxiosError: false })}
                    />
                }
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return ({
        authenticated: state.auth.authenticated,
        open: state.displaySidebarMenu.openSide,
        openEditTunnel: state.displayAddTunnel.openEditTunnel,
        userRole: JSON.parse(localStorage.getItem('user'))?.userRole
    });
}
export default withRouter(connect(mapStateToProps)(Tunnelview));