import React, { Component } from "react";
import urlMapping from "../../redux/API/api";
import apiCall from "../../redux/API/apiCall";
import { Loader } from "../../layOuts/Loader";
import $ from 'jquery';
import { ErrorPopup } from "../../layOuts/errorPopup";
export default class TunnelsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mac: this.props.mac,
      tunnelsData: [],
      showLoader: false,
      devicedata: this.props?.data,
      isAxiosError: false,
    };
  }
  componentDidMount() {
    if (this.props.data.device_id) {
      this.setState({
        device_id: this.props.data.device_id
      })
    }
    this.getTunnelsView();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.data).length !== 0) {
      this.setState({
        devicedata: nextProps.data,
      });
    }
    if (nextProps.mac !== this.state.mac) {
      this.setState(
        {
          mac: nextProps.mac,
          device_id: nextProps.data.device_id,
        },
        () => this.getTunnelsView()
      );
    }
  }
  getTunnelsView = () => {
    let data = {
      device_mac: this.state.mac,
    };
    this.setState({ showLoader: true });
    apiCall(urlMapping.getTunnelsView(data), (response) => {
      this.setState({ showLoader: false });
      if (response?.success) {
        let tunnelsData = response?.data;
        this.setState({
          tunnelsData
        });
      } else {
        if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
          this.setState({ showRefreshModal: true });
        } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
          this.setState({
              title: 'Tenant Error',
              message: response.response.data.message,
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
          }, () => $("#errorMessage").modal("show"))
      } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        } else {
          let tunnelsData = [];
          this.setState({ tunnelsData });
        }
      }
    });
  };
  refreshTelemetry = (val) => {
    this.setState({ showLoader: true });
    let temp = {
      device_A: {
        name: (val.device_A.device_A_name),
        control_ip: val.device_A.device_c_tunnel_ip
      },
      device_B: {
        name: val.device_B.device_B_name,
        control_ip: val.device_B.device_c_tunnel_ip
      },
      interface_name: val.tunnel_interface_name
    }
    let data = {
      device_ip: this.props.data.device_control_tunnel_ip,
      tunnel: temp,
      device_id: this.state.device_id
    }
    apiCall(urlMapping.get_Global_refresh(data), (response) => {
      this.setState({ showLoader: false });
      if (response?.success) {
        this.getTunnelsView();
      }
      else {
        if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
          this.setState({
            showRefreshModal: true
          });
        } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
          this.setState({
              title: 'Tenant Error',
              message: response.response.data.message,
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
          }, () => $("#errorMessage").modal("show"))
      }
        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        }
        else {
          this.setState({
            showLoader: false
          });
        }
      }
    })
  }

  render() {
    return (
      <>
        {this.state.showLoader && <Loader />}
        <div className="ta-box set-pos mb-0">
          <div className="action-btn d-flex align-items-center">
            {/* <img
              className="mr-2"
              style={{ width: "25px", cursor: "pointer" }}
              src="/images/refreshT.png"
              title="Refresh Telemetry data"
              onClick={() => this.refreshTelemetry()}
            /> */}
            <button className="short-btn" onClick={() => this.getTunnelsView()} title="Refresh">
              <img src="/images/svg-icons/refreshing.svg" alt="" /> Refresh
            </button>
          </div>
        </div>
        <div className="white-bg pl-2 pr-2">
          {this.state.tunnelsData.length > 0 ? (
            <>
              <div className="networks-view">
                <div className="row no-gutters">
                  {this.state.tunnelsData.map((item, index) => {
                    return (
                      <div key={index} className="col-md-4 mb-3">
                        <div className="network-card rounded">
                          <div className="network-header tunnel-head rounded-top d-flex justify-content-between p-2">
                            <div className="">
                              Tunnel Name <strong>{item.tunnel_name}</strong>
                            </div>
                            <img
                              className="tunnelRefresh"
                              style={{ width: "40px", cursor: "pointer" }}
                              src="/images/refreshT.png"
                              title="Refresh Telemetry data"
                              onClick={() => this.refreshTelemetry(item)}
                            />
                            <div className="text-center">
                              Status{" "}
                              <strong>
                                <i
                                  className={
                                    item.tunnel_status === "Online"
                                      ? "status online"
                                      : "status offline"
                                  }
                                ></i>
                              </strong>
                            </div>
                          </div>
                          <div className="p-2 tunnel-body">
                            <ul>
                              <li className="system-connection">
                                <span>&nbsp;</span>
                                <span className="dotted-line">
                                  <img
                                    src={
                                      item.tunnel_type === "Hub and spoke" &&
                                        item.device_A.device_A_name ===
                                        this.state.devicedata.device_name
                                        ? "/images/svg-icons/hub.svg"
                                        : "/images/svg-icons/computer.svg"
                                    }
                                    alt="hub/computer"
                                  />
                                  <div>{item.device_A.device_A_name}</div>
                                </span>
                                <span>
                                  <img
                                    src={
                                      item.tunnel_type === "Hub and spoke" &&
                                        item.device_B.device_B_name ===
                                        this.state.devicedata.device_name
                                        ? "/images/svg-icons/hub.svg"
                                        : "/images/svg-icons/computer.svg"
                                    }
                                    alt="hub/computer"
                                  />
                                  <div>{item.device_B.device_B_name}</div>
                                </span>
                              </li>
                              <li>
                                <span>IP Address:</span>
                                <strong>{item.device_A.ip_address ? item.device_A.ip_address : "-"}</strong>
                                <strong>{item.device_B.ip_address ? item.device_B.ip_address : "-"}</strong>
                              </li>
                              <li>
                                <span>Interface Name:</span>
                                <strong>{item.device_A.interfaceName ? item.device_A.interfaceName : "-"}</strong>
                                <strong>{item.device_B.interfaceName ? item.device_B.interfaceName : "-"}</strong>
                              </li>
                              <li>
                                <span>Drop Rate TX:</span>
                                <strong>
                                  {item.txBytes ? item.txBytes : "-"}
                                </strong>
                                <strong>
                                  {item.txBytes ? item.txBytes : "-"}
                                </strong>
                              </li>
                              <li>
                                <span>Drop Rate RX:</span>
                                <strong>
                                  {item.rxBytes ? item.rxBytes : "-"}
                                </strong>
                                <strong>
                                  {item.rxBytes ? item.rxBytes : "-"}
                                </strong>
                              </li>
                              <li>
                                <span>Public Key:</span>
                                <strong>{item.device_A.public_key ? item.device_A.public_key : "-"}</strong>
                                <strong>{item.device_B.public_key ? item.device_B.public_key : "-"}</strong>
                              </li>
                              <li>
                                <span>End Point IP:</span>
                                <strong>{item.device_A.endpoint_ip ? item.device_A.endpoint_ip : "-"}</strong>
                                <strong>{item.device_B.endpoint_ip ? item.device_B.endpoint_ip : "-"}</strong>
                              </li>
                              <li>
                                <span>Listen Port:</span>
                                <strong>{item.device_A.listen_port ? item.device_A.listen_port : "-"}</strong>
                                <strong>{item.device_B.listen_port ? item.device_B.listen_port : "-"}</strong>
                              </li>
                              <li>
                                <span>BGP Enable:</span>
                                <strong>
                                  {item.device_A.bgp === true ? "Yes" : "No"}
                                </strong>
                                <strong>
                                  {item.device_B.bgp === true ? "Yes" : "No"}
                                </strong>
                              </li>
                              <li>
                                <span className="braek-word">
                                  Add interface to Zone:
                                </span>
                                <strong>
                                  {item.device_A.interface_to_zone === true
                                    ? "Enabled"
                                    : "-"}
                                </strong>
                                <strong>
                                  {item.device_B.interface_to_zone === true
                                    ? "Enabled"
                                    : "-"}
                                </strong>
                              </li>
                              <li>
                                <span className="braek-word">
                                  Device Group Name:
                                </span>
                                <strong>
                                  {item.device_A.device_group_name
                                    ? item.device_A.device_group_name
                                    : "-"}
                                </strong>
                                <strong>
                                  {item.device_B.device_group_name
                                    ? item.device_B.device_group_name
                                    : "-"}
                                </strong>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          ) : (
            <div className="no-tunnels">
              No tunnels found for this device
            </div>
          )}
        </div>
        {(this.state.isAxiosError) &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            delete={this.state.delete}
            reset={() => this.setState({ isAxiosError: false })}
          />
        }
      </>
    );
  }
}
