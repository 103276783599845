import $ from 'jquery';
import React, { useEffect } from 'react';
import * as Action from '../redux/actionTypes';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import apiCall from '../redux/API/apiCall';
import urlMapping from "../redux/API/api";

const ErrorHandlePopup = (props) => {
    useEffect(async () => {
        await localStorage.removeItem("token");
        await $('.modal').modal('hide');
        await $("#refreshFail").modal("show")
    }, [])

    const logoutNow = () => {
        if(process.env.REACT_APP_AD_USER !== 'TRUE') {
        const refresh_token = localStorage.getItem('refresh');

        const data = {
            refresh: refresh_token
        }
        apiCall(urlMapping.logout(data), (response) => {
            if (response) {
                props.dispatch({
                    type: Action.LOGOUT,
                });

                localStorage.removeItem("token");
                localStorage.removeItem("refresh");
                props.history.push("/");
                $("#refreshFail").modal("hide");
                $('.modal-backdrop').remove();
            }
        }
        );
    }else {
        props.dispatch({
            type: Action.LOGOUT,
        });
        localStorage.removeItem("token");
        props.history.push("/");
        $("#refreshFail").modal("hide");
        $('.modal-backdrop').remove();
    }
    }

    return (
        <div className="modal fade refreshFail" id="refreshFail" tabIndex="-1" data-backdrop="static"
            role="dialog" aria-labelledby="refreshFailTitle" aria-hidden="true"
        >
            <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-body pt-5">
                        <div className="error-msg">
                            <span className="error-icon">!</span>
                            <div className="txt1">Session Expired</div>
                            <div className="txt2">Your login session has been expired! Please log in again!
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer border-top-0 justify-content-center pb-4">
                        <button type="button" className="btn btn-danger"
                            data-dismiss="modal" onClick={() => logoutNow()}>OK</button>

                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return ({
        authenticated: state.auth.authenticated
    });
}
export default withRouter(connect(mapStateToProps)(ErrorHandlePopup));
