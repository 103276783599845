import React, { Component } from 'react';
import { connect } from "react-redux";
import { matchPath, withRouter } from "react-router";
import * as Action from '../redux/actionTypes';

class SideMenu extends Component {
    closeSidebarMenu = () => {
        this.props.dispatch({
            type: this.props.open ? Action.CLOSE_SIDEBAR_MENU : Action.OPEN_SIDEBAR_MENU,
        });
    }
    render() {
        return (
            <div>
                <aside className={this.props.open ? "lhs-nav" : "lhs-nav collaps-nav"} id="lhsNav">
                    <div className="ec-menu" onClick={() => this.closeSidebarMenu()}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <ul className="lhs-nav-list">
                        <li className={matchPath(this.props.location.pathname, { path: "/dashboard" }) ? "active" : ""}>
                            <a href="/dashboard">
                                <i className="icon dashboard"></i>
                                <span>Dashboard</span>
                            </a>
                        </li>
                        <li className={matchPath(this.props.location.pathname, { path: "/tunnels" }) || matchPath(this.props.location.pathname, { path: "/tunnelsView" }) ? "active" : ""}>
                            <a href="/tunnels">
                                <i className="icon tunnels"></i>
                                <span>Tunnels</span>
                            </a>
                        </li>
                        <li className={matchPath(this.props.location.pathname, { path: "/devices" }) ||
                            matchPath(this.props.location.pathname, { path: "/devices/configured" }) ? "active" : ""}>
                            <a href="/devices/configured">
                                <i className="icon device"></i>
                                <span>Devices</span>
                            </a>
                            <ul className="lhs-sub-nav">
                                <li><a href="/devices/configured">Devices</a></li>
                                <li><a href="/devices">Onboarding Devices</a></li>
                                <li><a href="/deviceGroup">Device Group</a></li>
                            </ul>
                        </li>

                        <li className={matchPath(this.props.location.pathname, { path: "/templates" }) ? "active" : ""}>
                            <a
                                href="/templates"
                            >
                                <i className="icon templates"></i>
                                <span>Templates</span>
                            </a>
                            <ul className="lhs-sub-nav">
                                <li><a href="/templates">Templates</a></li>
                                <li><a href="/bootstrapTemplates">Bootstrap Templates</a></li>
                            </ul>
                        </li>
                        <li className={matchPath(this.props.location.pathname, { path: "/operations" }) ? "active" : ""}>
                            <a href="/operations">
                                <i className="icon operations"></i>
                                <span>Operations</span>
                            </a>
                        </li>
                        {process.env.REACT_APP_AD_USER !== 'TRUE' && <li className={matchPath(this.props.location.pathname, { path: "/configuration" }) ? "active" : ""}>
                            <a href="/configuration">
                                <i className="icon configuration"></i>
                                <span>Configuration</span>
                            </a>
                        </li>}
                        <li className={matchPath(this.props.location.pathname, { path: "/deviceFirmware" }) ? "active" : ""}>
                            <a href="/deviceFirmware">
                                <i className="icon administration"  ></i>
                                <span>Administration</span>
                            </a>
                            <ul className="lhs-sub-nav">
                                <li><a href="/deviceFirmware">Device Firmware</a></li>
                                {(process.env.REACT_APP_AD_USER === 'TRUE' &&
                                    (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                                        localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                                    (this.props?.userRole !== "Viewer_Group" &&
                                        this.props?.userRole !== "Operator_Group")
                                    && <li>
                                        <a href="/userList">User</a>
                                    </li>
                                }
                            </ul>
                        </li>
                        <li className={matchPath(this.props.location.pathname, { path: "/troubleshoot" }) ? "active" : ""}>
                            <a href="/troubleshoot">
                                <i className="icon troubleshoot"></i>
                                <span>Troubleshooting</span>
                            </a>
                        </li>
                    </ul>
                    <div className="version">Version: 2024.7.1.0</div>
                </aside>

            </div>
        )
    }
}
const mapStateToProps = state => {
    return ({
        open: state.displaySidebarMenu.openSide,
        SsoUser: state.auth.SsoUser,
        userRole: JSON.parse(localStorage.getItem('user'))?.userRole
    });
};
export default withRouter(connect(mapStateToProps)(SideMenu));
