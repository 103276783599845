/* eslint-disable array-callback-return */
import React, { Component, Fragment } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ApiCall from "../../../../redux/API/apiCall";
import urlMapping from "../../../../redux/API/api";
import { ErrorPopup } from "../../../../layOuts/errorPopup";
import * as Action from "../../../../redux/actionTypes";
import * as Common from "../../../../utils/common";
import ErrorHandlePopup from "../../../ErrorHandlePopup";
import Select, { components } from 'react-select';
import debounce from 'lodash/debounce';
import { Netmask } from 'netmask';
import isEqual from 'lodash/isEqual';
const ButtonComponent = (props, onClickHandler, id, name) => {
    return (
        <Fragment>
            <components.MenuList {...props}>
                <div>{props.children}</div>
                <button data-toggle="modal" data-target={id} onClick={() => onClickHandler(name)} name="addIP"
                    className="btn btn-primary btn-block mt-3">{name === 'customSubnet' ? '+ Add Subnet' :
                        '+ Add IP'}</button>
            </components.MenuList>
        </Fragment>
    );
};
class IPSecTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRefreshModal: false,
            showError: false,
            show: false,
            selectedTemplateId: this.props?.id ? this.props?.id : "",
            template_type: this.props?.templateData?.template_type || "",
            template_name: this.props?.templateData?.template_name || "",
            template_desc: this.props?.templateData?.template_desc || "",
            device_model: this.props?.templateData?.device_model || "",
            collection_version: this.props?.templateData?.collection_version || "",
            fw_version: this.props?.templateData?.fw_version || "",
            chimay: this.props.chimay,
            cbr: this.props.cbr,
            mifi: this.props.mifi,
            update: false,
            openAddVPN: false,
            selectedVPN: [],
            showPassword: false,
            customIP: false,
            customIPAddress: '',
            customSubnet: '',
            subnet: false,
            validSubnet: false,
            validIP: false,
            remoteIP: false,
            selectedUpload: undefined,
            isAxiosError: false,
            template_schema_json: {
                "vpn_enable": "1",
                "vpnConfig": []
            },
            VPNData: {
                "tunnel_id": Math.random().toString(36).substring(2, 11).toUpperCase(),
                "start_tunnel": "1",
                "enable_tunnel": "1",
                "tunnel_name": "",
                "authentication": "psk",
                "pre_shared_key": "",
                "cert_id": "",
                "cert_file": "",
                "local_id": "",
                "remote_id": "",
                "local_ip": "",
                "local_network": [],
                "remote_network": [],
                "remote_ip": "",
                "keylife1": "28800",
                "hash_algo1": 'md5',
                "enc_algo1": 'aes256',
                "dh_group1": 'modp1024',
                "keylife2": "3600",
                "hash_algo2": 'md5',
                "enc_algo2": 'aes256',
                "dh_group2": 'modp1024',
                "dpd_enable": "1",
                "dpd_delay": "15",
                "dpd_action": "clear",
                "dpd_timeout": "20",
                "isdeleted": "0",
                "isedit": "0"
            },
            availableEncryptions: [
                'aes256',
                'aes128',
                '3des',
                'blowfish'
            ],
            availableHash: [
                'md5',
                'sha1'
            ],
            availableDHGroups: [
                'modp1024',
                'modp1536',
                'modp2048',
                'modp3072',
                'modp4096',
                'modp6144',
                'modp8192'
            ],
            availableEncryptions2: [
                'aes256',
                'aes128',
                '3des',
                'blowfish',
                'cast'
            ],
            availableHash2: [
                'md5',
                'sha1'
            ],
            availableDHGroups2: [
                'modp1024',
                'modp1536',
                'modp2048',
                'modp3072',
                'modp4096',
                'modp6144',
                'modp8192'
            ],
            availableNetworks: ['auto'],
            availableSubnets: [],
            availableRemoteNetworks: ['auto'],
            remoteOptions: []
        };
    }
    componentDidMount() {
        if (this.props.template_schema_json !== undefined) {
            let template_schema_json = this.undefinedCheck(JSON.parse(
                JSON.stringify(this.props.template_schema_json)));
            if (this.props.edit) {
                template_schema_json.vpnConfig.map((vpn) => {
                    vpn.isedit = '0';
                    return vpn;
                })
            }
            this.setState({
                template_schema_json
            }, () => {
                if (this.props.edit) {
                    template_schema_json.vpnConfig.map((vpn) => {
                        this.validateRemoteSubnet(vpn);
                    })
                }
            });
            template_schema_json.vpnConfig.map((item) => {
                item.local_network = Array.isArray(item.local_network) ? item.local_network : item.local_network.split(",");
                item.remote_network = Array.isArray(item.remote_network) ? item.remote_network : item.remote_network.split(",");
            })
            this.setState({ template_schema_json })
            if (this.props.apply && this.props.networkViewData) {
                let { availableNetworks } = this.state;
                let options = [];
                let subnetOptions = [];
                let remoteOptions = [];
                let { availableSubnets } = this.state;
                let { availableRemoteNetworks } = this.state;
                this.props.networkViewData.filter(element => (element.network_zone === 'wan' &&
                    element.ip_address !== '')).map((network) => {
                        availableNetworks.push(network.ip_address)
                    })
                this.props.networkViewData.filter(element => (element.network_zone === 'lan' &&
                    element.ip_address !== ''))?.map((network) => {
                        availableSubnets?.push(`${network.ip_address}/${network.subnet}`)
                    })
                template_schema_json.vpnConfig?.map((vpn) => {
                    if (vpn.local_ip !== '' && vpn.local_ip !== 'auto') {
                        availableNetworks.push(vpn.local_ip);
                    }
                    if (vpn.remote_ip !== '' && vpn.remote_ip !== 'auto') {
                        availableRemoteNetworks.push(vpn.remote_ip);
                    }
                    if (vpn.local_network.length !== 0) {
                        vpn.local_network.map((item) => {
                            if (!availableSubnets.includes(item)) {
                                availableSubnets.push(item);
                            }
                        })
                    }
                })
                availableNetworks.length > 0 && availableNetworks.map((item) => {
                    if (!options?.find(val => val.value === item)) {
                        if (item === 'auto') {
                            options.push({ label: 'Auto', value: item });
                        } else
                            options.push({ label: item, value: item });
                    }
                })
                availableSubnets.length > 0 && availableSubnets.map((item) => {
                    if (!subnetOptions?.find(val => val.value === item)) {
                        subnetOptions.push({ label: item, value: item });
                    }
                })
                availableRemoteNetworks.length > 0 && availableRemoteNetworks.map((item) => {
                    if (!remoteOptions.find(val => val.value === item)) {
                        if (item === 'auto') {
                            remoteOptions.push({ label: 'Auto', value: item });
                        } else
                            remoteOptions.push({ label: item, value: item });
                    }
                })
                this.setState({
                    availableNetworks,
                    availableSubnets,
                    options,
                    subnetOptions,
                    remoteOptions
                })
            }
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (Object.keys(nextProps.templateData).length !== 0) {
            this.setState({
                selectedTemplateId: nextProps.id ? nextProps.id : '',
                template_type: nextProps?.templateData?.template_type || "",
                template_name: nextProps?.templateData?.template_name || "",
                template_desc: nextProps?.templateData?.template_desc || "",
                device_model: nextProps?.templateData?.device_model || "",
                collection_version: nextProps?.templateData?.collection_version || "",
                fw_version: nextProps?.templateData?.fw_version || ""
            });
        }
        if (nextProps.edit) {
            if (!isEqual(this.props.template_schema_json, nextProps.template_schema_json)) {
                let template_schema_json = this.undefinedCheck(JSON.parse(
                    JSON.stringify(nextProps.template_schema_json)));
                template_schema_json.vpnConfig.map((vpn) => {
                    vpn.isedit = '0';
                    this.validateRemoteSubnet(vpn);
                    return vpn;
                })
                this.setState({ template_schema_json });
            }
            if (nextProps.networkViewData) {
                if (nextProps.edit && nextProps.networkViewData) {
                    let { availableNetworks } = this.state;
                    let { availableSubnets } = this.state;
                    availableNetworks = nextProps.networkViewData.filter(element => (element.network_zone === 'wan' &&
                        element.ip_address !== ''));
                    availableSubnets = nextProps.networkViewData.filter(element => (element.network_zone === 'lan' &&
                        element.ip_address !== ''));
                    this.setState({ availableNetworks, availableSubnets })
                }
            }
        }
        if (nextProps.apply && nextProps.networkViewData) {
            let { availableNetworks } = this.state;
            let options = [];
            let subnetOptions = [];
            let { availableSubnets } = this.state;
            nextProps.networkViewData.filter(element => (element.network_zone === 'wan' &&
                element.ip_address !== '')).map((network) => {
                    availableNetworks.push(network.ip_address)
                })
            nextProps.networkViewData.filter(element => (element.network_zone === 'lan' &&
                element.ip_address !== ''))?.map((network) => {
                    availableSubnets.push(`${network.ip_address}/${network.subnet}`)
                })
            availableNetworks.length > 0 && availableNetworks.map((item) => {
                if (!options?.find(val => val.value === item)) {
                    if (item === 'auto') {
                        options.push({ label: 'Auto', value: item });
                    } else
                        options.push({ label: item, value: item });
                }
            })
            availableSubnets.length > 0 && availableSubnets.map((item) => {
                if (!subnetOptions.find(val => val.value === item)) {
                    subnetOptions.push({ label: item, value: item });
                }
            })
            this.setState({
                availableNetworks,
                availableSubnets,
                options,
                subnetOptions
            })
        }
        if (!isEqual(this.props?.chimay, nextProps?.chimay) ||
            !isEqual(this.props?.cbr, nextProps?.cbr) ||
            !isEqual(this.props?.mifi, nextProps?.mifi)) {
            this.setState({
                chimay: nextProps.chimay,
                cbr: nextProps.cbr,
                mifi: nextProps.mifi
            })
        }
    }
    undefinedCheck = (value) => {
        if (value) {
            let { template_schema_json } = this.state;
            template_schema_json.vpn_enable = value?.vpn_enable || "1";
            template_schema_json.vpnConfig = [];
            Array.isArray(value?.vpnConfig) && value?.vpnConfig?.map((item) => {
                if (item) {
                    let vpnConfig = {
                        "tunnel_id": item?.tunnel_id || Math.random().toString(36).substring(2, 11).toUpperCase(),
                        "start_tunnel": item?.start_tunnel || "1",
                        "enable_tunnel": item?.enable_tunnel || "1",
                        "tunnel_name": item?.tunnel_name || "",
                        "authentication": item?.authentication || "psk",
                        "pre_shared_key": item?.pre_shared_key || "",
                        "cert_id": item?.cert_id || "",
                        "cert_file": item?.cert_file || "",
                        "local_id": item?.local_id || "",
                        "remote_id": item?.remote_id || "",
                        "local_ip": item?.local_ip || "",
                        "local_network": item?.local_network || [],
                        "remote_network": item?.remote_network || [],
                        "remote_ip": item?.remote_ip || "",
                        "keylife1": item?.keylife1 || "28800",
                        "hash_algo1": item?.hash_algo1 || 'md5',
                        "enc_algo1": item?.enc_algo1 || 'aes256',
                        "dh_group1": item?.dh_group1 || 'modp1024',
                        "keylife2": item?.keylife2 || "3600",
                        "hash_algo2": item?.hash_algo2 || 'md5',
                        "enc_algo2": item?.enc_algo2 || 'aes256',
                        "dh_group2": item?.dh_group2 || 'modp1024',
                        "dpd_enable": item?.dpd_enable || "1",
                        "dpd_delay": item?.dpd_delay || "15",
                        "dpd_action": item?.dpd_action || "clear",
                        "dpd_timeout": item?.dpd_timeout || "20",
                        "isdeleted": item?.isdeleted || "0",
                        "isedit": item?.isedit || "0"
                    }
                    template_schema_json.vpnConfig.push(vpnConfig);
                }
            })
            return template_schema_json;
        }
    }
    getGroupValue = (group) => {
        switch (group) {
            case 'modp1024':
                return 'Group-2';
            case 'modp1536':
                return 'Group-5';
            case 'modp2048':
                return 'Group-14';
            case 'modp3072':
                return 'Group-15';
            case 'modp4096':
                return 'Group-16';
            case 'modp6144':
                return 'Group-17';
            case 'modp8192':
                return 'Group-18';
        }
    }
    getEncrytion = (value) => {
        switch (value) {
            case 'aes256':
                return 'AES-256';
            case 'aes128':
                return 'AES-128';
            case '3des':
                return '3DES';
            case 'blowfish':
                return 'Blowfish';
            case 'cast':
                return 'CAST';
        }
    }
    getHash = (value) => {
        switch (value) {
            case 'md5':
                return 'MD5';
            case 'sha1':
                return 'SHA 1';
        }
    }
    handleFileUpload = (e) => {
        let { VPNData } = this.state;
        if (e) {
            const file = e.target.files[0] || '';
            VPNData.cert_file = e.target.files[0].name
            this.setState({
                VPNData,
                selectedUpload: file
            });
        }
        else {
            VPNData.cert_file = ""
            this.setState({
                VPNData,
                selectedUpload: undefined
            });
        }
    }
    setTunnelValues = (e) => {
        if (Common.removeError(e)) {
            this.setState({
                showError: true
            })
        }
        const name = e.target.name;
        let value;
        value = e.target.type === "checkbox" ? e.target.checked === true ? "1" : "0" : e.target.value;
        const data = this.state.VPNData;
        if (name === "dpd_delay" || name === "dpd_timeout"
            || name === "keylife1" || name === "keylife2") {
            if (Common.validateNumeric(e))
                data[name] = value;
        }
        else
            data[name] = value;
        if (name === "cert_id") {
            const elements = document.getElementsByClassName("error-txt tunnel id")
            for (let i = 0; i < elements.length; i++) {
                elements[i].style.cssText = "display: none;"
            }
            data.local_id = '';
            data.remote_id = '';
        }
        if (name === "authentication") {
            data.pre_shared_key = '';
            data.cert_file = "";
            data.local_id = '';
            data.remote_id = '';
            if (value === "pubkey-cert")
                data.cert_id = "fqdn";
        }
        if (name === "dpd_enable") {
            const elements = document.getElementsByClassName("error-txt tunnel enable")
            for (let i = 0; i < elements.length; i++) {
                elements[i].style.display = 'none';
            }
            data.dpd_action = "clear";
            data.dpd_delay = "15";
            data.dpd_timeout = "20"
        }
        if (this.props.edit && this.state.update) {
            data.isedit = "1";
        }
        this.setState({
            VPNData: data
        })
    }
    checkTunnelName = (e) => {
        const element = document.getElementById(e.target.name);
        if (e.target.value === "") {
            element.style.display = "block";
            element.innerHTML = '3-20 Alphanumeric charaters allowed without space';
            this.setState({
                showError: true
            })
        }
        const regex = /^[a-zA-Z0-9]*$/;
        const result = regex.test(e.target.value);
        if (!result || e.target.value.length < 3 || e.target.value.length > 20) {
            const element = document.getElementById(e.target.name);
            if (element) {
                element.style.display = 'block';
                element.innerHTML = '3-20 Alphanumeric charaters allowed without space';
                this.setState({
                    showError: true
                })
            }
        }
        if (e.target.name === 'tunnel_name') {
            const tunnels = this.props.edit ?
                this.state.template_schema_json.vpnConfig.filter(item => item.isdeleted !== "1") :
                this.state.template_schema_json.vpnConfig;
            let found = false;
            tunnels.map((item) => {
                if (item.tunnel_name === e.target.value && item.tunnel_id !== this.state.VPNData.tunnel_id)
                    found = true
            })
            if (found) {
                const element = document.getElementById(e.target.name);
                if (element) {
                    element.innerHTML = 'Name already exists';
                    element.style.display = 'block';
                    this.setState({
                        showError: true
                    })
                }
            }
        }
    }
    checkPreSharedKey = (e) => {
        if (e.target.name === 'pre_shared_key') {
            if (e.target.value.length < 8 || e.target.value.length > 64) {
                const element = document.getElementById(e.target.name);
                if (element) {
                    element.style.display = 'block';
                    this.setState({
                        showError: true
                    })
                }
            }
        }
    }
    checkCertificate = (e) => {
        if (this.state.VPNData.cert_id === "fqdn") {
            const regex = /^\@[a-zA-Z0-9]+\.[a-zA-Z0-9]+\.([a-zA-Z0-9]{2,5})+$/;
            const result = regex.test(e.target.value);
            if (!result) {
                const element = document.getElementById(e.target.name);
                if (element) {
                    element.style.display = "block";
                    this.setState({
                        showError: true
                    })
                }
            }
        }
        if (this.state.VPNData.cert_id === "ufqdn") {
            const regex = /^[a-zA-Z0-9]+\@[a-zA-Z0-9]+\.([a-zA-Z0-9]{2,5})+$/;
            const result = regex.test(e.target.value);
            if (!result) {
                const element = document.getElementById(e.target.name);
                if (element) {
                    element.style.display = "block";
                    this.setState({
                        showError: true
                    })
                }
            }
        }
        if (this.state.VPNData.cert_id === "dn") {
            const regex = /^(([A-Z]{1,})\=([a-zA-Z]{1,})\,?)+$/;
            const result = regex.test(e.target.value);
            if (!result) {
                const element = document.getElementById(e.target.name);
                if (element) {
                    element.style.display = "block";
                    this.setState({
                        showError: true
                    })
                }
            }
        }
    }
    checkTunnelError = () => {
        let result = false;
        const elements = document.getElementsByClassName('error-txt tunnel');
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        if (this.state.VPNData.authentication === 'psk' && this.state.VPNData.pre_shared_key === '')
            result = true;
        if (this.state.VPNData.authentication === 'pubkey-cert')
            if (this.state.VPNData.cert_file === '' || this.state.VPNData.cert_id === '' || this.state.VPNData.local_id === '' || this.state.VPNData.remote_id === '')
                result = true;
        if (this.state.VPNData.tunnel_name === '' || this.state.VPNData.keylife1 === '' ||
            this.state.VPNData.keylife2 === '')
            result = true;
        if (this.state.VPNData.dpd_enable === '1' && (this.state.VPNData.dpd_delay === '' ||
            this.state.VPNData.dpd_timeout === '' || this.state.VPNData.dpd_action == '')) {
            result = true;
        }
        return result;
    }
    saveData = () => {
        let data = {};
        data.template_name = this.state.template_name;
        data.template_desc = this.state.template_desc;
        data.device_model = this.props?.selectedModelList ? this.props?.selectedModelList?.toString() : this.state.device_model.toString();
        data.template_type = this.state.template_type;
        data.collection_version = this.state.collection_version;
        data.fw_version = this.state.fw_version;
        data.template_order = 16;
        if (this.state.selectedTemplateId === "") {
            data.template_version = {
                chimay: this.state.chimay,
                cbr: this.state.cbr,
                mifi: this.state.mifi
            }
        }
        data.template_schema_json = JSON.parse(JSON.stringify(this.state.template_schema_json));
        this.setState({
            showLoader: true,
        });
        if (this.state.selectedTemplateId !== "") {
            ApiCall(
                urlMapping.updateTemplate(this.state.selectedTemplateId, data),
                (response) => {
                    this.setState({
                        showLoader: false,
                    });
                    if (response?.Success) {
                        this.props.closeAddTemplate();
                        this.props.refreshList();
                        this.props.openApplyTemplate(this.state.selectedTemplateId);
                    } else {
                        if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                            this.setState({ showRefreshModal: true });
                        } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                            this.setState({
                                title: 'Tenant Error',
                                message: response.response.data.message,
                                showLoader: false,
                                isAxiosError: true,
                                delete: false,
                                error: true
                            }, () => $("#errorMessage").modal("show"))
                        }
                        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                            this.setState({
                                title: 'Network Error',
                                message: 'Something went wrong. Please try again later.',
                                showLoader: false,
                                isAxiosError: true,
                                delete: false,
                                error: true
                            }, () => $("#errorMessage").modal("show"))
                        }
                        else {
                            this.setState(
                                {
                                    title: "Update Template",
                                    message: response?.message,
                                    show: true,
                                    error: true,
                                },
                                () => $("#errorMessage").modal("show")
                            );
                        }
                    }
                }
            );
        } else {
            ApiCall(urlMapping.createTemplate(data), (response) => {
                this.setState({
                    showLoader: false,
                });
                if (response?.success) {
                    this.props.refreshList();
                    this.props.openApplyTemplate(response?.data?.id);
                    this.props.closeAddTemplate();
                } else {
                    if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                        this.setState({ showRefreshModal: true })
                    } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                        this.setState({
                            title: 'Tenant Error',
                            message: response.response.data.message,
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else {
                        this.setState(
                            {
                                title: "Create Template",
                                message: response?.message,
                                show: true,
                                error: true,
                            },
                            () => $("#errorMessage").modal("show")
                        );
                    }
                }
            });
        }
    };
    resetPopup = () => {
        this.setState(
            {
                show: false,
                error: false,
            },
            () => {
                $("#errorMessage").modal("hide");
                this.props.refreshList();
                this.props.closeAddTemplate();
            }
        );
    };
    closeAll = () => {
        this.props.dispatch({
            type: Action.CLOSE_APPLY_TEMPLATE,
        });
        this.props.closeAddTemplate()
    }
    validateIP = (e) => {
        const element = document.getElementById(e.target.name);
        if (element) {
            element.style.display = 'none';
            element.innerHTML = "";
            this.setState({
                showError: false
            })
        }
        if (!Common.validateIPaddress(e)) {
            this.setState({
                showError: true
            })
        }
    }
    validate = (e) => {
        if (!Common.validateNumeric(e)) {
            const element = document.getElementById(e.target.name);
            if (element) {
                element.style.display = 'block';
                this.setState({
                    showError: true
                })
            }
        }
        if ((e.target.name === 'keylife1' || e.target.name === 'keylife2') && (
            parseInt(e.target.value) < 3600 || parseInt(e.target.value) > 28800
        )) {
            const element = document.getElementById(e.target.name);
            if (element) {
                element.style.display = 'block';
                this.setState({
                    showError: true
                })
            }
        }
        if (e.target.name === 'dpd_delay' && (
            parseInt(e.target.value) < 2 || parseInt(e.target.value) > 30
        )) {
            const element = document.getElementById(e.target.name);
            if (element) {
                element.style.display = 'block';
                this.setState({
                    showError: true
                })
            }
        }
        if (e.target.name === 'dpd_timeout' && (
            parseInt(e.target.value) < 10 || parseInt(e.target.value) > 3600
        )) {
            const element = document.getElementById(e.target.name);
            if (element) {
                element.style.display = 'block';
                this.setState({
                    showError: true
                })
            }
        }
    }
    removeErrorIP = (e) => {
        if (Common.removeError(e)) {
            this.setState({
                showError: true
            })
        }
    }
    checkError = () => {
        let result = false;
        const elements = document.getElementsByClassName("error-txt");
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        if (
            this.state.template_name === "" ||
            this.state.template_type === ""
        )
            result = true;
        if (this.props.apply) {
            if (this.props.applying) {
                result = true;
            }
            this.state.template_schema_json.vpnConfig.map((vpn) => {
                if (vpn.local_ip === '' || vpn.local_network.length === 0 || vpn.remote_ip === '' || vpn.remote_network.length === 0)
                    result = true;
            })
        }
        return result;
    };
    applyTemplate = () => {
        let { template_schema_json } = this.state;
        template_schema_json = JSON.parse(JSON.stringify(this.state.template_schema_json));
        this.props.dispatch({
            type: Action.SET_APPLY_TEMPLATE,
        });
        this.props.applyTemplate(template_schema_json);
    }
    debounceApply = debounce((fn, event) => {
        fn(event)
    }, 500);
    openAddVPN = (data) => {
        if (data) {
            let rowData = JSON.parse(JSON.stringify(data));
            let { availableEncryptions } = this.state;
            let { availableHash } = this.state;
            let { availableDHGroups } = this.state;
            let { availableEncryptions2 } = this.state;
            let { availableHash2 } = this.state;
            let { availableDHGroups2 } = this.state;
            rowData.enc_algo1?.split('-')?.map((item) => {
                availableEncryptions = availableEncryptions?.filter(val => val !== item);
            })
            rowData.hash_algo1?.split('-')?.map((item) => {
                availableHash = availableHash?.filter(val => val !== item);
            })
            rowData.dh_group1?.split('-')?.map((item) => {
                availableDHGroups = availableDHGroups?.filter(val => val !== item);
            })
            rowData.enc_algo2?.split('-')?.map((item) => {
                availableEncryptions2 = availableEncryptions2?.filter(val => val !== item);
            })
            rowData.hash_algo2?.split('-')?.map((item) => {
                availableHash2 = availableHash2.filter(val => val !== item);
            })
            rowData.dh_group2?.split('-')?.map((item) => {
                availableDHGroups2 = availableDHGroups2?.filter(val => val !== item);
            })
            this.setState({
                VPNData: rowData,
                openAddVPN: true,
                update: true,
                availableEncryptions,
                availableDHGroups,
                availableHash,
                availableEncryptions2,
                availableHash2,
                availableDHGroups2
            })
        } else {
            const elements = document.getElementsByClassName('error-txt tunnel');
            for (let i = 0; i < elements.length; i++) {
                elements[i].style.cssText = "display: none;"
            }
            this.setState({
                selectedVPN: [],
                VPNData: {
                    "tunnel_id": Math.random().toString(36).substring(2, 11).toUpperCase(),
                    "start_tunnel": "1",
                    "enable_tunnel": "1",
                    "tunnel_name": "",
                    "authentication": "psk",
                    "pre_shared_key": "",
                    "cert_id": "",
                    "cert_file": "",
                    "local_id": "",
                    "remote_id": "",
                    "local_ip": "",
                    "local_network": [],
                    "remote_network": [],
                    "remote_ip": "",
                    "keylife1": "28800",
                    "hash_algo1": 'md5',
                    "enc_algo1": 'aes256',
                    "dh_group1": 'modp1024',
                    "keylife2": "3600",
                    "hash_algo2": 'md5',
                    "enc_algo2": 'aes256',
                    "dh_group2": 'modp1024',
                    "dpd_enable": "1",
                    "dpd_delay": "15",
                    "dpd_action": "clear",
                    "dpd_timeout": "20",
                    "isdeleted": "0",
                    "isedit": "0"
                },
                openAddVPN: true,
                availableEncryptions: ['aes128',
                    '3des',
                    'blowfish'],
                availableHash: [
                    'sha1'
                ],
                availableDHGroups: [
                    'modp1536',
                    'modp2048',
                    'modp3072',
                    'modp4096',
                    'modp6144',
                    'modp8192'
                ],
                availableEncryptions2: ['aes128',
                    '3des',
                    'blowfish',
                    'cast'],
                availableHash2: [
                    'sha1'
                ],
                availableDHGroups2: [
                    'modp1536',
                    'modp2048',
                    'modp3072',
                    'modp4096',
                    'modp6144',
                    'modp8192'
                ]
            })
        }
    }
    closeAddVPN = () => {
        const elements = document.getElementsByClassName('error-txt tunnel');
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.cssText = "display: none;"
        }
        this.setState({
            selectedVPN: [],
            openAddVPN: false,
            update: false,
            showError: false
        })
    }
    selectVPN = (e, vpn) => {
        let { selectedVPN } = this.state;
        if (e.target.checked === true) {
            selectedVPN.push(vpn);
        } else {
            selectedVPN = selectedVPN.filter(item => item.tunnel_id !== vpn.tunnel_id);
        }
        this.setState({
            selectedVPN
        })
    }
    deleteVPN = () => {
        let template_schema_json = JSON.parse(JSON.stringify(this.state.template_schema_json))
        let data = this.state.template_schema_json.vpnConfig;
        if (this.props.edit) {
            this.state.selectedVPN.map((item) => {
                let found = false;
                this.props.template_schema_json.vpnConfig.map((vpn) => {
                    if (item.tunnel_id === vpn.tunnel_id) {
                        data.filter(value => value.tunnel_id === item.tunnel_id)[0].isdeleted = "1";
                        found = true;
                    }
                    return vpn;
                })
                if (found === false) {
                    data = data.filter(finalValue => finalValue.tunnel_id !== item.tunnel_id);
                }
            })
        } else {
            this.state.selectedVPN.map((item) => {
                data = this.state.template_schema_json.vpnConfig.filter(finalValue => finalValue.tunnel_id !== item.tunnel_id);
            })
        }
        template_schema_json.vpnConfig = data;
        this.setState({
            template_schema_json,
            selectedVPN: [],
        })
    }
    setTunnelEnable = (e) => {
        const status = e.target.checked === true ? "1" : "0";
        const { template_schema_json } = this.state;
        template_schema_json.vpn_enable = status;
        this.setState({ template_schema_json })
    }
    addVPNTunnel = async () => {
        let { VPNData } = this.state;
        let result = false;
        const elements = document.getElementsByClassName("error-txt tunnel");
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;") result = true;
        }
        if (result === false) {
            let cert_upload = true;
            if (this.state.selectedUpload !== undefined &&
                VPNData.authentication === 'pubkey-cert') {
                const formData = new FormData();
                formData.append("file", this.state.selectedUpload);
                this.setState({ showLoader: true })
                await ApiCall(urlMapping.deviceListUpload(formData), (response) => {
                    if (response?.success) {
                        cert_upload = true;
                        VPNData.cert_file = response.file_name;
                        this.setState({
                            selectedUpload: undefined,
                            showLoader: false
                        });
                    } else {
                        if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                            cert_upload = false
                            this.setState({
                                showRefreshModal: true,
                                showLoader: false,
                            });
                        } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                            this.setState({
                                title: 'Tenant Error',
                                message: response.response.data.message,
                                showLoader: false,
                                isAxiosError: true,
                                delete: false,
                                error: true
                            }, () => $("#errorMessage").modal("show"))
                        }
                        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                            cert_upload = false
                            this.setState({
                                title: 'Network Error',
                                message: 'Something went wrong. Please try again later.',
                                showLoader: false,
                                isAxiosError: true,
                                delete: false,
                                error: true
                            }, () => $("#errorMessage").modal("show"))
                        }
                        else {
                            cert_upload = false
                            this.setState(
                                {
                                    title: "Upload Files",
                                    message: response?.message,
                                    show: true,
                                    error: true,
                                    showLoader: false,
                                },
                                () => $("#errorMessage").modal("show")
                            );
                        }
                    }
                })
            }
            if (cert_upload) {
                const template_schema_json = this.state.template_schema_json;
                let found = false;
                template_schema_json.vpnConfig.map((item, index) => {
                    if (item.tunnel_id === VPNData.tunnel_id) {
                        found = true;
                        template_schema_json.vpnConfig[index] = VPNData;
                    }
                });
                if (!found)
                    template_schema_json.vpnConfig.push(VPNData);
                this.setState(
                    {
                        template_schema_json,
                        showLoader: false,
                        VPNData: {
                            "tunnel_id": Math.random().toString(36).substring(2, 11).toUpperCase(),
                            "start_tunnel": "1",
                            "enable_tunnel": "1",
                            "tunnel_name": "",
                            "authentication": "psk",
                            "pre_shared_key": "",
                            "cert_id": "",
                            "cert_file": "",
                            "local_id": "",
                            "remote_id": "",
                            "local_ip": "",
                            "local_network": "",
                            "remote_network": "",
                            "remote_ip": "",
                            "keylife1": "28800",
                            "hash_algo1": 'md5',
                            "enc_algo1": 'aes256',
                            "dh_group1": 'modp1024',
                            "keylife2": "3600",
                            "hash_algo2": 'md5',
                            "enc_algo2": 'aes256',
                            "dh_group2": 'modp1024',
                            "dpd_enable": "1",
                            "dpd_delay": "15",
                            "dpd_action": "clear",
                            "dpd_timeout": "20",
                            "isdeleted": "0",
                            "isedit": "0"
                        }
                    },
                    () => this.closeAddVPN()
                )
            }
        }
    }
    handleChange = (e, name) => {
        let { VPNData } = this.state;
        let { availableEncryptions } = this.state;
        let { availableHash } = this.state;
        let { availableDHGroups } = this.state;
        let { availableEncryptions2 } = this.state;
        let { availableHash2 } = this.state;
        let { availableDHGroups2 } = this.state;
        let values = '';
        // pick selected values of key
        switch (name) {
            case 'enc_algo1':
                values = VPNData.enc_algo1;
                break;
            case 'hash_algo1':
                values = VPNData.hash_algo1;
                break;
            case 'dh_group1':
                values = VPNData.dh_group1;
                break;
            case 'enc_algo2':
                values = VPNData.enc_algo2;
                break;
            case 'hash_algo2':
                values = VPNData.hash_algo2;
                break;
            case 'dh_group2':
                values = VPNData.dh_group2;
                break;
        }
        // convert to array
        let enc_values = [];
        if (values !== '') {
            if (values.includes('-')) {
                enc_values = values?.split('-');
            } else {
                enc_values.push(values);
            }
        }
        const req_length = name?.includes('hash_algo') ? 2 : 3;
        //if adding value
        if (e.target.checked) {
            if (enc_values.filter(item => item === e.target.id).length === 0) {
                enc_values.push(e.target.id);
                if (enc_values.length <= req_length) {
                    const element = document.getElementById(name);
                    if (element) {
                        element.style.display = "none"
                        this.setState({
                            showError: false
                        })
                    }
                } else {
                    const element = document.getElementById(name);
                    if (element) {
                        element.style.display = "block"
                        this.setState({
                            showError: true
                        })
                    }
                }
                switch (name) {
                    case 'enc_algo1':
                        availableEncryptions = availableEncryptions.filter(item => item !== e.target.id);
                        break;
                    case 'hash_algo1':
                        availableHash = availableHash.filter(item => item !== e.target.id);
                        break;
                    case 'dh_group1':
                        availableDHGroups = availableDHGroups.filter(item => item !== e.target.id);
                        break;
                    case 'enc_algo2':
                        availableEncryptions2 = availableEncryptions2.filter(item => item !== e.target.id);
                        break;
                    case 'hash_algo2':
                        availableHash2 = availableHash2.filter(item => item !== e.target.id);
                        break;
                    case 'dh_group2':
                        availableDHGroups2 = availableDHGroups2.filter(item => item !== e.target.id);
                        break;
                }
            } else {
                enc_values = enc_values.filter(item => item !== e.target.id);
                switch (name) {
                    case 'enc_algo1':
                        availableEncryptions.push(e.target.id);
                        break;
                    case 'hash_algo1':
                        availableHash.push(e.target.id);
                        break;
                    case 'dh_group1':
                        availableDHGroups.push(e.target.id);
                        break;
                    case 'enc_algo2':
                        availableEncryptions2.push(e.target.id);
                        break;
                    case 'hash_algo2':
                        availableHash2.push(e.target.id);
                        break;
                    case 'dh_group2':
                        availableDHGroups2.push(e.target.id);
                        break;
                }
            }
        } else {
            // removing value
            enc_values = enc_values.filter(item => item !== e.target.id);
            // remove error if req length is there
            if (enc_values.length <= req_length) {
                const element = document.getElementById(name);
                if (element) {
                    element.style.display = "none"
                    this.setState({
                        showError: false
                    })
                }
            }
            switch (name) {
                case 'enc_algo1':
                    availableEncryptions.push(e.target.id);
                    break;
                case 'hash_algo1':
                    availableHash.push(e.target.id);
                    break;
                case 'dh_group1':
                    availableDHGroups.push(e.target.id);
                    break;
                case 'enc_algo2':
                    availableEncryptions2.push(e.target.id);
                    break;
                case 'hash_algo2':
                    availableHash2.push(e.target.id);
                    break;
                case 'dh_group2':
                    availableDHGroups2.push(e.target.id);
                    break;
            }
        }
        // let sec_name = name.replace('1', '2');
        VPNData[name] = enc_values.join('-');
        // VPNData[sec_name] = enc_values.join('-');
        if (this.props.edit && this.state.update)
            VPNData.isedit = '1';
        this.setState({
            VPNData,
            availableEncryptions,
            availableDHGroups,
            availableHash,
            availableEncryptions2,
            availableHash2,
            availableDHGroups2
        })
    }
    showPassword = () => {
        const x = document.getElementById('password');
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
        this.setState(prevState => ({
            showPassword: !prevState.showPassword
        }));
    }
    setIP = (e, key, vpn) => {
        let template_schema_json = JSON.parse(JSON.stringify(this.state.template_schema_json));
        template_schema_json.vpnConfig.map((tunnel) => {
            if (tunnel.tunnel_id === vpn.tunnel_id) {
                if (key === 'remote_ip' && e.value !== 'auto') {
                    if (this.state.template_schema_json.vpnConfig.filter(
                        (item) => (item.remote_ip === e.value && item.tunnel_id !== vpn.tunnel_id &&
                            item.isdeleted !== "1")).length > 0) {
                        const element = document.getElementById(`${key}_${vpn.tunnel_id}`);
                        if (element) {
                            element.style.display = 'block';
                            element.innerHTML = 'Duplicate remote IP'
                            this.setState({
                                showError: true,
                            })
                        }
                    }
                } else {
                    let element = document.getElementById(`remote_ip_${vpn.tunnel_id}`);
                    if (element) {
                        element.style.display = 'none';
                        element.innerHTML = "";
                    }
                    element = document.getElementById(`remote_network_${vpn.tunnel_id}`);
                    if (element) {
                        element.style.display = 'none';
                        element.innerHTML = "";
                    }
                    this.setState({
                        showError: true
                    })
                }
                tunnel[key] = e.value;
                if (this.props.edit && this.props.template_schema_json.vpnConfig.filter(item => item.tunnel_id === vpn.tunnel_id).length > 0)
                    tunnel.isedit = '1';
            }
            return tunnel;
        })
        this.setState({ template_schema_json }, () => {
            if (key === 'remote_ip')
                this.validateRemoteSubnet(template_schema_json.vpnConfig.filter(tunnel =>
                    tunnel.tunnel_id === vpn.tunnel_id)[0])
        })
    }
    validateCustomIP = (e) => {
        if (this.state.subnet) {
            if (!Common.validateIPaddressWithSubnetMask(e) || !e.target.value.includes('/') ||
                e.target.value.split('/')[1] < 8 || e.target.value.split('/')[1] > 32) {
                const element = document.getElementById(`${e.target.name}`);
                if (element) {
                    element.style.display = 'block';
                    element.innerHTML = 'Invalid IP address/subnet';
                    this.setState({
                        showError: true,
                        validSubnet: false
                    })
                }
            } else {
                this.setState({
                    validSubnet: true,
                    customSubnet: e.target.value
                })
            }
        } else {
            if (!Common.validateIPaddress(e)) {
                const element = document.getElementById(`${e.target.name}`);
                if (element) {
                    element.style.display = 'block';
                    element.innerHTML = 'Invalid IP address';
                    this.setState({
                        showError: true,
                        validIP: false,
                    })
                }
            }
            else if (e.target.value === '0.0.0.0' ||
                e.target.value === '255.255.255.255') {
                const element = document.getElementById(`${e.target.name}`);
                if (element) {
                    element.style.display = 'block';
                    element.innerHTML = 'This is restricted IP address';
                    this.setState({
                        showError: true,
                        validIP: false,
                    })
                }
            }
            else {
                this.setState({
                    validIP: true,
                    customIPAddress: e.target.value
                })
            }
        }
    }
    addIP = () => {
        let { availableNetworks, options, availableSubnets, subnetOptions, availableRemoteNetworks, remoteOptions } = this.state;
        if (this.state.subnet) {
            if (this.state.validSubnet) {
                if (!availableSubnets.includes(this.state.customSubnet)) {
                    availableSubnets.push(this.state.customSubnet);
                    subnetOptions.push({ label: this.state.customSubnet, value: this.state.customSubnet });
                }
                this.setState({
                    customIP: false,
                    customSubnet: '',
                    subnetOptions,
                    validSubnet: false
                }, () => {
                    $("#errorMessage").modal("hide")
                })
            }
        } else {
            if (this.state.validIP) {
                if (this.state.remoteIP) {
                    if (!availableRemoteNetworks.includes(this.state.customIPAddress)) {
                        availableRemoteNetworks.push(this.state.customIPAddress);
                        remoteOptions.push({ label: this.state.customIPAddress, value: this.state.customIPAddress });
                    }
                } else {
                    if (!availableNetworks.includes(this.state.customIPAddress)) {
                        availableNetworks.push(this.state.customIPAddress);
                        options.push({ label: this.state.customIPAddress, value: this.state.customIPAddress });
                    }
                }
                this.setState({
                    customIP: false,
                    customIPAddress: '',
                    options,
                    remoteOptions,
                    remoteIP: false,
                    validIP: false
                }, () => {
                    $("#errorMessage").modal("hide")
                })
            }
        }
    }
    changeIp = (name) => {
        if (name === 'customSubnet') {
            this.setState({ subnet: true })
        }
        // if adding rempote IP 
        if (name === 'remote_ip') {
            this.setState({ remoteIP: true })
        }
        this.setState({
            customIP: !this.state.customIP,
        })
    }
    cancelIP = () => {
        let element = document.getElementById('ipAddress');
        if (element) {
            element.style.display = 'none';
            element.innerHTML = "";
            this.setState({
                showError: false
            })
        }
        this.setState({
            customIP: false,
            subnet: false
        })

    }
    setValues = (e, vpn) => {
        const element = document.getElementById(`${e.target.name}_${vpn.tunnel_id}`);
        if (element) {
            element.style.display = 'none';
            element.innerHTML = '';
            this.setState({
                showError: true,
            })
        }
        let { template_schema_json } = this.state;
        template_schema_json.vpnConfig.map((tunnel) => {
            if (tunnel.tunnel_id === vpn.tunnel_id) {
                tunnel[e.target.name] = e.target.value;
                if (this.props.edit && this.props.template_schema_json.vpnConfig.filter(item => item.tunnel_id === vpn.tunnel_id).length > 0)
                    tunnel.isedit = '1';
            }
        })
        this.setState({ template_schema_json })
    }
    validateRemoteSubnet = (vpn) => {
        let error = false;
        try {
            if (vpn.remote_ip !== 'auto' && vpn.remote_ip !== '') {
                if (vpn.remote_network.length !== 0) {
                    vpn.remote_network.map((item) => {
                        const block = new Netmask(item);
                        if (block.contains(vpn.remote_ip) === true) {
                            const element = document.getElementById(`remote_network_${vpn.tunnel_id}`);
                            error = true;
                            if (element) {
                                element.style.display = 'block';
                                element.innerHTML = "Remote IP address should not be in the same range as remote subnets!";
                                this.setState({
                                    showError: true
                                })
                            }
                        }
                    })
                }
            }
            if (vpn.local_network.length !== 0 && vpn.remote_network.length !== 0) {
                vpn.local_network.map((item) => {
                    vpn.remote_network.map((val) => {
                        const block = new Netmask(val);
                        if (block.contains(item.split('/')[0]) === true) {
                            const element = document.getElementById(`remote_network_${vpn.tunnel_id}`);
                            error = true;
                            if (element) {
                                element.style.display = 'block';
                                element.innerHTML = "Remote subnets should not be in the same range as local subnets!";
                                this.setState({
                                    showError: true
                                })
                            }
                        }
                    })
                })
            }
            if (error === false) {
                let element = document.getElementById(`remote_ip_${vpn.tunnel_id}`);
                if (element) {
                    element.style.display = 'none';
                    element.innerHTML = "";
                }
                element = document.getElementById(`remote_network_${vpn.tunnel_id}`);
                if (element) {
                    element.style.display = 'none';
                    element.innerHTML = "";
                }
                this.setState({
                    showError: true
                })
            }

        } catch {
            const element = document.getElementById(`remote_network_${vpn.tunnel_id}`);
            if (element) {
                element.style.display = 'block';
                element.innerHTML = "Invalid value";
                this.setState({
                    showError: true
                })
            }
        }
    }
    setLocalSubnet = (e, vpn) => {
        let { template_schema_json } = this.state;
        template_schema_json.vpnConfig.map((tunnel) => {
            if (tunnel.tunnel_id === vpn.tunnel_id) {
                tunnel.local_network = []
                e?.map(val => {
                    tunnel.local_network.push(val.value)
                })
                if (this.props.edit && this.props.template_schema_json.vpnConfig.filter(item => item.tunnel_id === vpn.tunnel_id).length > 0)
                    tunnel.isedit = '1';
            }
            return tunnel
        })
        this.setState({
            template_schema_json
        },
            () => {
                this.validateRemoteSubnet(template_schema_json.vpnConfig.filter(tunnel =>
                    tunnel.tunnel_id === vpn.tunnel_id)[0])
            }
        )
    }
    handleKeyDown(e, vpn) {
        if (e.key === 'Enter') {
            this.remoteSubnetOptions(e, vpn);
        }
        else {
            this.removeRemoteSubnetError(e, vpn);
        }
    }
    validateIPaddressWithSubnetMask = (e) => {
        if (/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/([1-9]|0[0-9]|1[0-9]|2[0-9]|3[0-2])$/.test(e))
            return false;
        return true;
    }
    remoteSubnetOptions = (e, vpn) => {
        let { template_schema_json } = this.state;
        let value = e.target.value.trim();
        if (value.trim() !== "") {
            if (this.validateIPaddressWithSubnetMask(value) || !value.includes('/')
                || value.split('/')[1] < 8 || value.split('/')[1] > 32) {
                const element = document.getElementById(`${e.target.name}_${vpn.tunnel_id}`);
                if (element) {
                    element.style.display = 'block';
                    element.innerHTML = 'Invalid subnet';
                    this.setState({
                        showError: true,
                    })
                }
            }
            else if (vpn.remote_network.filter(val => val === value).length !== 0) {
                const element = document.getElementById(`${e.target.name}_${vpn.tunnel_id}`);
                if (element) {
                    element.style.display = 'block';
                    element.innerHTML = 'Entered subnet already added!';
                    this.setState({
                        showError: true,
                    })
                }
            }
            else {
                template_schema_json.vpnConfig.map((tunnel) => {
                    if (tunnel.tunnel_id === vpn.tunnel_id) {
                        tunnel.remote_network.push(value);
                        if (this.props.edit && this.props.template_schema_json.vpnConfig.filter(item => item.tunnel_id === vpn.tunnel_id).length > 0)
                            tunnel.isedit = '1';
                    }
                    return tunnel
                })
                this.setState({
                    template_schema_json
                },
                    () => {
                        this.validateRemoteSubnet(template_schema_json.vpnConfig.filter(tunnel =>
                            tunnel.tunnel_id === vpn.tunnel_id)[0])
                    }
                )
                $("textarea[name=remote_network]").val("");
            }
        }
    }
    removeRemoteSubnet = (i, vpn) => {
        if (i && vpn.tunnel_id) {
            let { template_schema_json } = this.state;
            template_schema_json.vpnConfig.map((tunnel) => {
                if (tunnel.tunnel_id === vpn.tunnel_id) {
                    tunnel.remote_network = tunnel.remote_network.filter(val => val !== String(i));
                }
                return tunnel
            })
            this.setState({ template_schema_json })
        }
    }
    removeRemoteSubnetError = (e, vpn) => {
        const element = document.getElementById(`${e.target.name}_${vpn.tunnel_id}`);
        if (element) {
            element.style.display = 'none';
            this.setState({
                showError: false,
            })
        }
    }
    render() {
        return (
            <div className="tab-box firewall">
                <div className={this.state.openAddVPN ?
                    "disabled-slide" : ""}></div>
                <div className="gray-bg rounded p-2">
                    <div className="form-group mb-2">
                        <label>
                            <strong>VPN Tunnel Configuration</strong>
                            <div
                                className="tooltip-c"
                                data-toggle="tooltip"
                                data-placement="right"
                                title="To enable the IPSec VPN"
                            >
                                <img src="/images/svg-icons/info.svg" alt="" />
                            </div>
                        </label>
                    </div>
                    <div className="mb-2">
                        <div className="d-inline-flex align-items-center">
                            <span className='mr-3 text-bold'>Enable</span>
                            <label className={!this.props.info && !this.props.view && !this.props.reapply ? 'switch' : "switch gray"}>
                                <input
                                    type="checkbox"
                                    name="vpn_enable"
                                    checked={this.state.template_schema_json.vpn_enable === '1'}
                                    onChange={!this.props.info && !this.props.view && !this.props.reapply ? (e) => this.setTunnelEnable(e) : undefined}
                                />
                                <span
                                    className="slider round"
                                    style={{ cursor: (this.props.info || this.props.view || this.props.reapply) ? "not-allowed" : "" }}>
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="page-sub-title border-0 pb-0">
                        <div><strong>VPN</strong>
                            {!this.props.info && !this.props.view && !this.props.reapply &&
                                <img src="/images/svg-icons/add.svg"
                                    className={
                                        this.state.template_schema_json.vpnConfig.filter(item => item.isdeleted !== '1').length > 49 ||
                                            this.state.template_schema_json.vpn_enable !== '1' ||
                                            (this.props.templateData.default_type !== ''
                                                && this.props.templateData.default_type !== null) ?
                                            "add-btn ml-2 disabled" : "add-btn ml-2"} alt=""
                                    onClick={this.state.template_schema_json.vpnConfig.filter(item => item.isdeleted !== '1').length > 49 ||
                                        this.state.template_schema_json.vpn_enable !== '1' ||
                                        (this.props.templateData.default_type !== ''
                                            && this.props.templateData.default_type !== null) ?
                                        undefined : () => this.openAddVPN()} />}
                        </div>
                        {!this.props.info && !this.props.view && !this.props.reapply && <div className="device-action">
                            <span className={this.state.template_schema_json.vpn_enable === '1' &&
                                (this.state.selectedVPN.length === 1) ?
                                "icon-box" : "icon-box disabled"}><img src="/images/svg-icons/edit.svg" alt=""
                                    onClick={this.state.template_schema_json.vpn_enable === '1' &&
                                        (this.state.selectedVPN.length === 1) ? () =>
                                        this.openAddVPN(this.state.selectedVPN[0])
                                        : undefined} /></span>
                            <span className={this.state.template_schema_json.vpn_enable === '1' &&
                                (this.state.selectedVPN.length > 0) ?
                                "icon-box" : "icon-box disabled"}><img src="/images/svg-icons/delete.svg" alt=""
                                    onClick={this.state.template_schema_json.vpn_enable === '1' &&
                                        (this.state.selectedVPN.length > 0) ? () => this.deleteVPN() : undefined} /></span>
                        </div>}
                    </div>
                </div>
                <table className={this.state.template_schema_json.vpn_enable === '1' ? "table" : "table disabled"}>
                    <thead>
                        <tr>
                            <th>
                            </th>
                            <th>
                                <div className="zone-forwarding">
                                    <span>Tunnel Name</span>
                                </div>
                            </th>
                            {this.props.apply && <>
                                <th>Local Network</th>
                                <th>Remote network</th>
                            </>}
                            <th>IKE Phase 1</th>
                            <th>IKE Phase2</th>
                            <th>Dead Peer Detection</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.template_schema_json.vpnConfig.filter(item => item.isdeleted !== '1').map((vpn) => {
                            return <>
                                <tr>
                                    {(!this.props.view) ? <td>
                                        <input
                                            className={this.state.template_schema_json.vpn_enable !== '1' ? 'disabled' : ''}
                                            type="checkbox"
                                            readOnly={this.state.template_schema_json.vpn_enable !== '1'}
                                            checked={this.state.selectedVPN.filter(item => (item.tunnel_id === vpn.tunnel_id)).length > 0}
                                            onClick={this.state.template_schema_json.vpn_enable === '1' ?
                                                (e) => this.selectVPN(e, vpn) : undefined}
                                        />
                                    </td> : <td></td>}
                                    <td>
                                        <div className="zone-forwarding">
                                            {!this.props.info && !this.props.view && !this.props.reapply ?
                                                <Link
                                                    to={{}}
                                                    className={this.state.template_schema_json.vpn_enable === '1' ? "txt-blue" : "disabled"}
                                                    onClick={this.state.template_schema_json.vpn_enable === '1' ? () => this.openAddVPN(vpn) : undefined}
                                                >
                                                    {" "}
                                                    {vpn.tunnel_name}
                                                </Link> :
                                                <span>{vpn.tunnel_name}</span>}
                                        </div>
                                    </td>
                                    {
                                        this.props.apply &&
                                        <>
                                            <td>
                                                <span><strong>Local IP:</strong></span>
                                                <span className="red-txt">*</span>
                                                <Select
                                                    isClearable
                                                    // components={{ MenuList: (props) => ButtonComponent(props, this.changeIp, '#exampleModalIP', 'customip') }}
                                                    isSearchable
                                                    className="custom-dropdown w-115 h-27"
                                                    classNamePrefix="react-select-container"
                                                    name="customip"
                                                    isDisabled={this.state.template_schema_json.vpn_enable !== '1' || this.props.view}
                                                    options={this.state.options}
                                                    value={{ label: vpn.local_ip === 'auto' ? 'Auto' : vpn.local_ip, value: vpn.local_ip }}
                                                    onChange={(e) => this.setIP(e, 'local_ip', vpn)}
                                                />
                                                <span><strong>Local Subnet:</strong></span>
                                                <span className="red-txt">*</span>
                                                <Select
                                                    components={{ MenuList: (props) => ButtonComponent(props, this.changeIp, '#exampleModalIP', 'customSubnet') }}
                                                    isSearchable
                                                    className={(this.state.template_schema_json.vpn_enable !== '1' || this.props.view)
                                                        ? "localSubnet custom-dropdown w-115 h-27 disabled"
                                                        : "localSubnet custom-dropdown w-115 h-27"}
                                                    classNamePrefix="react-select-container"
                                                    name="customSubnet"
                                                    isMulti
                                                    isDisabled={this.state.template_schema_json.vpn_enable !== '1' || this.props.view}
                                                    options={this.state.subnetOptions}
                                                    value={(vpn.local_network).map((val) => {
                                                        return { label: val, value: val }
                                                    })}
                                                    onChange={(e) => this.setLocalSubnet(e, vpn)}
                                                />
                                            </td>
                                            <td>
                                                <span><strong>Remote IP:</strong></span>
                                                <span className="red-txt">*</span>
                                                <Select
                                                    isClearable
                                                    components={{ MenuList: (props) => ButtonComponent(props, this.changeIp, '#exampleModalIP', 'remote_ip') }}
                                                    isSearchable
                                                    className="custom-dropdown w-115 h-27"
                                                    classNamePrefix="react-select-container"
                                                    name="remote_ip"
                                                    isDisabled={this.state.template_schema_json.vpn_enable !== '1' || this.props.view}
                                                    options={this.state.remoteOptions}
                                                    value={{ label: vpn.remote_ip === 'auto' ? 'Auto' : vpn.remote_ip, value: vpn.remote_ip }}
                                                    onChange={(e) => this.setIP(e, 'remote_ip', vpn)}
                                                />
                                                <div className="error-txt tunnel" id={`remote_ip_${vpn.tunnel_id}`} >Invalid IP address</div>
                                                <span><strong>Remote Subnet:</strong></span>
                                                <span className="red-txt">*</span>
                                                <div className="w-115">
                                                    <div className={(this.state.template_schema_json.vpn_enable !== '1' || this.props.view)
                                                        ? "chip-list-border cp disabled"
                                                        : "chip-list-border cp"}>
                                                        {vpn.remote_network?.map(i => <div className="chip-set remoteNetwork "><div
                                                            className="remoteNetworklist"
                                                            id={i}
                                                            title={i}>
                                                            {i}

                                                        </div>
                                                            <div className="remoteNetworkImage">
                                                                <img
                                                                    src="/images/svg-icons/cancel.svg"
                                                                    id={i}
                                                                    alt=""
                                                                    onClick={() => (this.state.template_schema_json.vpn_enable !== '1' || this.props.view)
                                                                        ? undefined
                                                                        : this.removeRemoteSubnet(i, vpn)}
                                                                />
                                                            </div>
                                                        </div>)}
                                                        <textarea
                                                            name="remote_network"
                                                            type="text"
                                                            disabled={this.state.template_schema_json.vpn_enable !== '1' || this.props.view}
                                                            onKeyDown={(e) => this.handleKeyDown(e, vpn)}
                                                            onBlur={(e) => this.remoteSubnetOptions(e, vpn)}
                                                            className="w-115"
                                                        />
                                                    </div>
                                                    <div className="error-txt tunnel" id={`remote_network_${vpn.tunnel_id}`} >Invalid subnet</div>
                                                </div>
                                            </td>
                                        </>}
                                    <td>
                                        <ul className="ip-sec-list">
                                            <li>
                                                <span>Key Lifetime:</span>
                                                <span>{vpn.keylife1 !== '' ? `${vpn.keylife1} seconds` : ''}</span>
                                            </li>
                                            <li>
                                                <span>Encryption:</span>
                                                <span>{vpn.enc_algo1?.split('-')?.map((item) => {
                                                    return `${this.getEncrytion(item)} `
                                                })}</span>
                                            </li>
                                            <li>
                                                <span>Hash:</span>
                                                <span>{vpn.hash_algo1?.split('-')?.map((item) => {
                                                    return item && this.getHash(item)
                                                })}</span>
                                            </li>
                                            <li>
                                                <span>Diffie-Hellman group:</span>
                                                <span>{vpn.dh_group1?.split('-')?.map((item) => {
                                                    return item && this.getGroupValue(item)
                                                })}</span>
                                            </li>
                                        </ul>
                                    </td>
                                    <td>
                                        <ul className="ip-sec-list">
                                            <li>
                                                <span>Key Lifetime:</span>
                                                <span>{vpn.keylife2 !== '' ? `${vpn.keylife2} seconds` : ''}</span>
                                            </li>
                                            <li>
                                                <span>Encryption:</span>
                                                <span>{vpn.enc_algo2?.split('-')?.map((item) => {
                                                    return `${this.getEncrytion(item)} `
                                                })}</span>
                                            </li>
                                            <li>
                                                <span>Hash:</span>
                                                <span>{vpn.hash_algo2?.split('-')?.map((item) => {
                                                    return item && this.getHash(item)
                                                })}</span>
                                            </li>
                                            <li>
                                                <span>Diffie-Hellman group:</span>
                                                <span>{vpn.dh_group2?.split('-')?.map((item) => {
                                                    return item && this.getGroupValue(item)
                                                })}</span>
                                            </li>
                                        </ul>
                                    </td>
                                    <td>
                                        <ul className="ip-sec-list">
                                            <li>
                                                <span>Enable:</span>
                                                <label className="switch gray">
                                                    <input
                                                        type="checkbox"
                                                        checked={vpn?.dpd_enable === '1'} />
                                                    <span className="slider round" style={{ cursor: "not-allowed" }}>
                                                    </span>
                                                </label>
                                            </li>
                                            <li>
                                                <span>DPD Action:</span>
                                                <span>{vpn?.dpd_action?.charAt(0).toUpperCase() + vpn?.dpd_action?.slice(1)}</span>
                                            </li>
                                            <li>
                                                <span>DPD delay:</span>
                                                <span>{vpn.dpd_delay !== '' ? `${vpn.dpd_delay} seconds` : ''}</span>
                                            </li>
                                            <li>
                                                <span>DPD timeout:</span>
                                                <span>{vpn.dpd_timeout !== '' ? `${vpn.dpd_timeout} seconds` : ''}</span>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </>
                        })}
                    </tbody>
                </table>
                {!this.props.info && !this.props.view && <div className="s-footer">
                    <button className="btn btn-light"
                        onClick={(this.props.apply && !this.props.edit && !this.props.reapply) ? () => this.props.closeAddTemplate() :
                            () => this.closeAll()}>{this.props.apply && !this.props.edit && !this.props.reapply ? 'Back' : 'Cancel'}</button>
                    <button className="btn btn-primary"
                        disabled={this.checkError()}
                        onClick={this.props.apply ? (event) => this.debounceApply(this.applyTemplate, event) : () => this.saveData()}
                    >{this.props.apply ? 'Apply' : (this.state.selectedTemplateId !== '' ? 'Update' : 'Save')}</button>
                </div>}
                {/* Add new VPN tunnel */}
                <div
                    className={this.state.openAddVPN
                        ? "sidebar-slide  zindex10 w-400 second-open r-757"
                        : "sidebar-slide  zindex10 w-400"
                    }
                >
                    <div className="s-header">
                        <h1 className="ss-title">
                            {this.state.update ? "Edit VPN Tunnel" : "Add New VPN Tunnel"}
                        </h1>
                        <button>
                            <img
                                src="/images/svg-icons/s-cancel.svg"
                                alt=""
                                onClick={() => this.closeAddVPN()}
                            />
                        </button>
                    </div>
                    <div className="s-body">
                        <div className="form-group">
                            <strong>
                                General Settings
                            </strong>
                        </div>
                        <div class="row form-group">
                            <div className='mt-1 col-md-6'>
                                <label>Start Tunnel
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="Mode of tunnel establishment">
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </label>
                            </div>
                            <div className='col-md-6'>
                                <select
                                    className="form-control"
                                    name=""
                                    disabled
                                    value={this.state.VPNData.start_tunnel}
                                >
                                    <option value="">Select</option>
                                    <option value="1">Automatically</option>
                                    <option value="0">Manually</option>
                                </select>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div className='mt-1 col-md-6'>
                                <label>Enable Tunnel
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="Start the IPSec tunnel by default">
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </label>
                            </div>
                            <div className='col-md-6' style={{ "padding-top": "8px" }}>
                                <input
                                    type="checkbox"
                                    checked={this.state.VPNData.enable_tunnel === "1"}
                                    disabled />
                            </div>
                        </div>
                        <div class="row form-group">
                            <div className='mt-1 col-md-6'>
                                <label>Tunnel Name</label>
                                <span className="red-txt">*</span>
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="Name of the Tunnel">
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <input
                                    type="name"
                                    class="form-control"
                                    name="tunnel_name"
                                    value={this.state.VPNData.tunnel_name}
                                    onChange={this.setTunnelValues}
                                    onBlur={(e) => this.checkTunnelName(e)}
                                    title={this.state.VPNData.tunnel_name}
                                />
                            </div>
                            <div className="col-md-12 error-txt tunnel" id="tunnel_name">3-20 Alphanumeric charaters allowed without space</div>
                        </div>
                        <div class="row form-group">
                            <div className='mt-1 col-md-6'>
                                <label>Authentication
                                    <span className="red-txt">*</span>
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="Local authentication method for creating a tunnel">
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </label>
                            </div>
                            <div className='col-md-6'>
                                <select
                                    className="form-control"
                                    name="authentication"
                                    disabled={this.props.view || this.props.reapply}
                                    onChange={this.setTunnelValues}
                                    value={this.state.VPNData.authentication}
                                >
                                    <option value="psk">Preshared key</option>
                                    <option value="pubkey-cert">Certificate</option>
                                </select>
                            </div>
                        </div>
                        {this.state.VPNData.authentication === "psk" && <div class="row form-group">
                            <div className='mt-1 col-md-6'>
                                <label>Pre shared key</label>
                                <span className="red-txt">*</span>
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="Password for the preshared key authentication">
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <input
                                    type="password"
                                    class="form-control showIconSpacing"
                                    name="pre_shared_key"
                                    id="password"
                                    value={this.state.VPNData.pre_shared_key}
                                    onChange={this.setTunnelValues}
                                    onBlur={(e) => this.checkPreSharedKey(e)}
                                />
                                <div className='sh-icon-smtp'>
                                    <img src={this.state.showPassword ? "/images/show.png" : "/images/hide.png"} alt=""
                                        onClick={() => this.showPassword()} />
                                </div>
                            </div>
                            <div className="col-md-12 error-txt tunnel" id="pre_shared_key">Value must be in range between 8 and 64</div>
                        </div>}
                        {this.state.VPNData.authentication === "pubkey-cert" &&
                            <div>
                                <div class="row form-group">
                                    <div className='mt-1 col-md-6'>
                                        <label>Certificate File</label>
                                        <span className="red-txt">*</span>
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                            title="Upload the openSSL Certificate for authentication">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <input
                                            ref="fileInput"
                                            accept={".tar"}
                                            onChange={(e) => this.handleFileUpload(e)}
                                            type="file"
                                            style={{ display: "none" }}
                                        />
                                        <button className="btn btn-primary" onClick={() => this.refs.fileInput.click()}>Browse</button>
                                        {this.state.VPNData.cert_file !== "" &&
                                            <div className="droped-files">
                                                <img className="csv" src="/images/svg-icons/tar-file.png" alt="" />
                                                <span className="ipSecFile">{this.state.VPNData?.cert_file}</span>
                                                <img className="delete-icon" src="/images/svg-icons/delete-red.svg" alt=''
                                                    onClick={() => this.handleFileUpload()} />
                                            </div>}
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div className='mt-1 col-md-6'>
                                        <label>Certificate ID Type</label>
                                        <span className="red-txt">*</span>
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                            title="Identity to use for the certificate authentication of the local device">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <select
                                            className="form-control"
                                            name="cert_id"
                                            onChange={this.setTunnelValues}
                                            value={this.state.VPNData.cert_id}
                                        >
                                            <option value="fqdn">{"Fully Qualified Domain Name(FQDN)"}</option>
                                            <option value="ufqdn">{"User FQDN"}</option>
                                            <option value="dn">{"Distinguished Name (DN)"}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div className='mt-1 col-md-6'>
                                        <label>Local ID</label>
                                        <span className="red-txt">*</span>
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                            title="Identity to use for the certificate authentication of the local device">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <input
                                            type="name"
                                            class="form-control"
                                            name="local_id"
                                            value={this.state.VPNData.local_id}
                                            onChange={this.setTunnelValues}
                                            onBlur={(e) => this.checkCertificate(e)}
                                            title={this.state.VPNData.local_id}
                                        />
                                        <div className="error-txt tunnel id" id="local_id">Invalid value</div>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div className='mt-1 col-md-6'>
                                        <label>Remote ID</label>
                                        <span className="red-txt">*</span>
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                            title="Identity to use for the certificate authentication of the Remote device">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <input
                                            type="name"
                                            class="form-control"
                                            name="remote_id"
                                            value={this.state.VPNData.remote_id}
                                            onChange={this.setTunnelValues}
                                            onBlur={(e) => this.checkCertificate(e)}
                                            title={this.state.VPNData.remote_id}
                                        />
                                        <div className="error-txt tunnel id" id="remote_id">Invalid value</div>
                                    </div>
                                </div>
                            </div>}
                        <div className="form-group">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <span className="mr-5"><strong>IKE Phase 1</strong></span>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div className='mt-1 col-md-6'>
                                <label>Key Lifetime</label>
                                <span className="red-txt">*</span>
                                <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                    title="Duration for cryptographic key exchange in IKE phase 1">
                                    <img src="/images/svg-icons/info.svg" alt="" />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <input
                                    type="text"
                                    class="form-control pr-7"
                                    name="keylife1"
                                    value={this.state.VPNData.keylife1}
                                    onChange={this.setTunnelValues}
                                    onBlur={(e) => this.validate(e)}
                                />
                                <span className="time">Seconds</span>
                            </div>
                            <div className="col-md-12 error-txt tunnel" align='end' id="keylife1">Value from 3600 to 28800 allowed</div>
                        </div>
                        <div class="row form-group">
                            <div className='mt-1 col-md-6'>
                                <label>Encryption
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="To provide security during data transfer">
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </label>
                            </div>
                            <div className='col-md-6'>
                                <div className="dropdown">
                                    <ul className="chip-list z-index-9 bg-white radius">
                                        {
                                            this.state.VPNData.enc_algo1.split('-').map((item) => {
                                                return (item !== '' && <li>{this.getEncrytion(item)}
                                                    {<img src="/images/svg-icons/cancel.svg" alt="" id={item}
                                                        onClick={(e) => this.handleChange(e, 'enc_algo1')}
                                                    />}</li>)
                                            })
                                        }
                                    </ul>
                                    <div className="chip-box add-arrow"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    </div>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <ul>
                                            {this.state.availableEncryptions.map((item) => {
                                                return <li >
                                                    <input type="checkbox" id={item}
                                                        checked={this.state.VPNData.enc_algo1.split('-').filter(val => val === item).length > 0}
                                                        onClick={(e) => this.handleChange(e, 'enc_algo1')}
                                                    />
                                                    {this.getEncrytion(item)}</li>
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 error-txt tunnel" align="end" id="enc_algo1">Maximum 3 values allowed</div>
                        </div>
                        <div class="row form-group">
                            <div className='mt-1 col-md-6'>
                                <label>Hash
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="To encrypt the data with additional keys">
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </label>
                            </div>
                            <div className='col-md-6'>
                                <div className="dropdown">
                                    <ul className="chip-list z-index-9 bg-white radius">
                                        {
                                            this.state.VPNData.hash_algo1.split('-').map((item) => {
                                                return (item !== '' && <li>{this.getHash(item)}
                                                    {<img src="/images/svg-icons/cancel.svg" alt="" id={item}
                                                        onClick={(e) => this.handleChange(e, 'hash_algo1')}
                                                    />}</li>)
                                            })
                                        }
                                    </ul>
                                    <div className="chip-box add-arrow"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    </div>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <ul>
                                            {this.state.availableHash.map((item) => {
                                                return <li >
                                                    <input type="checkbox" id={item}
                                                        checked={this.state.VPNData.hash_algo1.split('-').filter(val => val === item).length > 0}
                                                        onClick={(e) => this.handleChange(e, 'hash_algo1')}
                                                    />
                                                    {this.getHash(item)}</li>
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 error-txt tunnel" align="end" id="hash_algo1">Maximum 2 values allowed</div>
                        </div>
                        <div class="row form-group">
                            <div className='mt-1 col-md-6'>
                                <label>Diffie-Hellman group
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="To establish session keys">
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </label>
                            </div>
                            <div className='col-md-6'>
                                <div className="dropdown">
                                    <ul className="chip-list z-index-9 bg-white radius">
                                        {
                                            this.state.VPNData.dh_group1.split('-').map((item) => {
                                                return (item !== '' && <li>{this.getGroupValue(item)}
                                                    {<img src="/images/svg-icons/cancel.svg" alt="" id={item.toLowerCase().replace(' ', '')}
                                                        onClick={(e) => this.handleChange(e, 'dh_group1')}
                                                    />}</li>)
                                            })
                                        }
                                    </ul>
                                    <div className="chip-box add-arrow"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    </div>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <ul>
                                            {this.state.availableDHGroups.map((item) => {
                                                return <li >
                                                    <input type="checkbox" id={(item).replace(' ', '').toLowerCase()}
                                                        checked={this.state.VPNData.dh_group1.split('-').filter(val => val === item).length > 0}
                                                        onClick={(e) => this.handleChange(e, 'dh_group1')}
                                                    />
                                                    {this.getGroupValue(item)}</li>
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 error-txt tunnel" align="end" id="dh_group1">Maximum 3 values allowed</div>
                        </div>
                        <div>
                            <div className="form-group">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <span className="mr-5"><strong>IKE Phase 2</strong></span>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div className='mt-1 col-md-6'>
                                    <label>Key Lifetime</label>
                                    <span className="red-txt">*</span>
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="Duration for cryptographic key exchange in IKE phase 2">
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <input
                                        type="text"
                                        class="form-control pr-7"
                                        name="keylife2"
                                        onChange={this.setTunnelValues}
                                        value={this.state.VPNData.keylife2}
                                        onBlur={this.validate}
                                    />
                                    <span className="time">Seconds</span>
                                </div>
                                <div className="col-md-12 error-txt tunnel" align='end' id="keylife2">Value from 3600 to 28800 allowed</div>
                            </div>
                            <div class="row form-group">
                                <div className='mt-1 col-md-6'>
                                    <label>Encryption
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                            title="To provide security during data transfer">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </label>
                                </div>
                                <div className='col-md-6'>
                                    <div className="dropdown">
                                        <ul className="chip-list z-index-9 bg-white radius">
                                            {
                                                this.state.VPNData.enc_algo2.split('-').map((item) => {
                                                    return (item !== '' && <li>{this.getEncrytion(item)}
                                                        {<img src="/images/svg-icons/cancel.svg" alt="" id={item}
                                                            onClick={(e) => this.handleChange(e, 'enc_algo2')}
                                                        />}</li>)
                                                })
                                            }
                                        </ul>
                                        <div className="chip-box add-arrow"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        </div>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <ul>
                                                {this.state.availableEncryptions2.map((item) => {
                                                    return <li >
                                                        <input type="checkbox" id={item}
                                                            checked={this.state.VPNData.enc_algo2.split('-').filter(val => val === item).length > 0}
                                                            onClick={(e) => this.handleChange(e, 'enc_algo2')}
                                                        />
                                                        {this.getEncrytion(item)}</li>
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 error-txt tunnel" align="end" id="enc_algo2">Maximum 3 values allowed</div>
                            </div>
                            <div class="row form-group">
                                <div className='mt-1 col-md-6'>
                                    <label>Hash
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                            title="To encrypt the data with additional keys">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </label>
                                </div>
                                <div className='col-md-6'>
                                    <div className="dropdown">
                                        <ul className="chip-list z-index-9 bg-white radius">
                                            {
                                                this.state.VPNData.hash_algo2.split('-').map((item) => {
                                                    return (item !== '' && <li>{this.getHash(item)}
                                                        {<img src="/images/svg-icons/cancel.svg" alt="" id={item}
                                                            onClick={(e) => this.handleChange(e, 'hash_algo2')}
                                                        />}</li>)
                                                })
                                            }
                                        </ul>
                                        <div className="chip-box add-arrow"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        </div>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <ul>
                                                {this.state.availableHash2.map((item) => {
                                                    return <li >
                                                        <input type="checkbox" id={item}
                                                            checked={this.state.VPNData.hash_algo2.split('-').filter(val => val === item).length > 0}
                                                            onClick={(e) => this.handleChange(e, 'hash_algo2')}
                                                        />
                                                        {this.getHash(item)}</li>
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 error-txt tunnel" align="end" id="hash_algo2">Maximum 2 values allowed</div>
                            </div>
                            <div class="row form-group">
                                <div className='mt-1 col-md-6'>
                                    <label>Diffie-Hellman group
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                            title="To establish shared secret keys">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </label>
                                </div>
                                <div className='col-md-6'>
                                    <div className="dropdown">
                                        <ul className="chip-list z-index-9 bg-white radius">
                                            {
                                                this.state.VPNData.dh_group2.split('-').map((item) => {
                                                    return (item !== '' && <li>{this.getGroupValue(item)}
                                                        {<img src="/images/svg-icons/cancel.svg" alt="" id={item.toLowerCase().replace(' ', '')}
                                                            onClick={(e) => this.handleChange(e, 'dh_group2')}
                                                        />}</li>)
                                                })
                                            }
                                        </ul>
                                        <div className="chip-box add-arrow"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        </div>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <ul>
                                                {this.state.availableDHGroups2.map((item) => {
                                                    return <li >
                                                        <input type="checkbox" id={(item).replace(' ', '').toLowerCase()}
                                                            checked={this.state.VPNData.dh_group2.split('-').filter(val => val === item).length > 0}
                                                            onClick={(e) => this.handleChange(e, 'dh_group2')}
                                                        />
                                                        {this.getGroupValue(item)}</li>
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div><div className="col-md-12 error-txt tunnel" align="end" id="dh_group2">Maximum 3 values allowed</div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <span className="mr-5"><strong>Dead Peer Detection</strong>
                                </span>
                            </div>
                        </div>
                        <div class="form-group">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <span className="mr-5">Enable
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="Enable to detect the aliveness of the peer">
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </span>
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        name="dpd_enable"
                                        onChange={this.setTunnelValues}
                                        checked={this.state.VPNData.dpd_enable === '1'} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                        {this.state.VPNData.dpd_enable === '1' && <div className="gray-bg">
                            <div class="row form-group">
                                <div className='mt-1 col-md-6'>
                                    <label>DPD Action
                                        <span className="red-txt">*</span>
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                            title="Method of a dead pear detection">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </label>
                                </div>
                                <div className='col-md-6'>
                                    <select
                                        className="form-control"
                                        name="dpd_action"
                                        disabled={this.props.view || this.props.reapply}
                                        onChange={this.setTunnelValues}
                                        value={this.state.VPNData.dpd_action}
                                    >
                                        <option value="">Select</option>
                                        <option value="clear">Clear</option>
                                        <option value="hold">Hold</option>
                                        <option value="restart">Restart</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div className='mt-1 col-md-6'>
                                    <label>DPD delay</label>
                                    <span className="red-txt">*</span>
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="Time interval to send the messages when the IPSec traffic is idle.">
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <input
                                        type="text"
                                        class="form-control pr-7"
                                        name="dpd_delay"
                                        value={this.state.VPNData.dpd_delay}
                                        onChange={this.setTunnelValues}
                                        onBlur={(e) => this.validate(e)}
                                    />
                                    <span className="time">Seconds</span>
                                </div>
                                <div className="col-md-12 error-txt tunnel enable" align='end' id="dpd_delay">Value from 2 to 30 allowed</div>
                            </div>
                            <div class="row form-group">
                                <div className='mt-1 col-md-6'>
                                    <label>DPD timeout</label>
                                    <span className="red-txt">*</span>
                                    <div className="tooltip-c" data-toggle="tooltip" data-placement="right"
                                        title="Waiting time to consider the peer to be dead if no response is received">
                                        <img src="/images/svg-icons/info.svg" alt="" />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <input
                                        type="text"
                                        class="form-control pr-7"
                                        name="dpd_timeout"
                                        value={this.state.VPNData.dpd_timeout}
                                        onChange={this.setTunnelValues}
                                        onBlur={(e) => this.validate(e)}
                                    />
                                    <span className="time">Seconds</span>
                                </div>
                                <div className="col-md-12 error-txt tunnel enable" align='end' id="dpd_timeout">Value from 10 to 3600 allowed</div>
                            </div>
                        </div>}
                    </div>
                    <div className="s-footer">
                        <button
                            className="btn btn-light"
                            onClick={() => this.closeAddVPN()}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-primary"
                            disabled={this.checkTunnelError()}
                            onClick={() => this.addVPNTunnel()}
                        >
                            {this.state.update ? "Update" : "Add"}
                        </button>
                    </div>
                </div>
                {this.state.customIP &&
                    <div className="modal fade" id="exampleModalIP" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">{this.state.subnet ? 'Add Subnet' : 'Add IP'}</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                        onClick={() => this.cancelIP()}>
                                        <span aria-hidden="true"><img src="/images/svg-icons/cancel.svg" alt="" /></span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group mb-1">
                                        <label>{this.state.subnet ? 'IP address/Subnet' : 'IP address'}</label>
                                        <div className="d-flex align-items-center">
                                            <input
                                                type="text"
                                                className="form-control w-100"
                                                name="ipAddress"
                                                onBlur={(e) => this.validateCustomIP(e)}
                                                onChange={(e) => this.removeErrorIP(e)}
                                            />
                                        </div>
                                        <div className="error-txt ip" id="ipAddress">{this.state.customSubnet ? 'Invalid IP address/Subnet' :
                                            'Invalid IP address'}</div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-light" data-dismiss="modal"
                                        onClick={() => this.cancelIP()}
                                    >Cancel</button>
                                    <button type="button" className="btn btn-primary" data-dismiss="modal"
                                        disabled={!this.state.validIP && !this.state.validSubnet}
                                        onClick={this.addIP}
                                    >Add</button>
                                </div>
                            </div>
                        </div>
                    </div>}
                {/* error popup */}
                {(this.state.show) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        reset={() => this.resetPopup()}
                    />}
                {(this.state.isAxiosError) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.setState({ isAxiosError: false })}
                    />
                }
                {this.state.showRefreshModal && <ErrorHandlePopup />}
            </div >);
    }
}
const mapStateToProps = (state) => {
    return {
        applying: state.displayAddTemplate.applying,
    };
};
export default connect(mapStateToProps)(IPSecTemplate);
