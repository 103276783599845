/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ApiCall from "../../redux/API/apiCall";
import urlMapping from "../../redux/API/api";
import { Loader } from '../../layOuts/Loader';
import moment from 'moment';
import $ from 'jquery';
import DataTable from "react-data-table-component";
import * as Constant from "../../utils/constants";
import * as Action from "../../redux/actionTypes";
import ApplyTemplate from "../templates/AddTemplates/ApplyTemplate";
import { ErrorPopup } from "../../layOuts/errorPopup";
import * as Common from "../../utils/common";
import { LinkPopup } from "../../layOuts/linkPopup";
import ErrorHandlePopup from "../ErrorHandlePopup";
class AppliedTemplateList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRefreshModal: false,
            showLoader: false,
            selectedTab: "Dashboard",
            multipleSelect: "",
            title: "",
            message: "",
            edit: false,
            show: false,
            error: false,
            delete: false,
            copyShow: false,
            showCopy: false,
            reapply: false,
            openLog: false,
            option: {},
            operation_id: "",
            id: this.props.id,
            appliedTemplateList: [],
            applyTemplateData: {},
            OperationLog: [],
            openApplyTemplate: true,
            deviceData: this.props?.deviceData || "",
            selectedOption: [],
            view: false,
            selectedModel: "",
            showLink: false,
            interfaces: [],
            uploadTemp: false,
            downloadTemp: false,
            showOptional: false,
            showPopup: false,
            nonSync: false,
            nonSyncCount: 0,
            nonSyncTemplates: [],
            templateVersions: {},
            optionalTemplates: ['DNSFiltering', 'ModemConfiguration'],
            supported_templates: this.props?.supported_templates ? this.props?.supported_templates : null,
            isAxiosError: false,
            columns: [
                {
                    name: "Template Name",
                    selector: "template_name",
                    value: "Template Name",
                    cell: (row) => (<span title={row?.template_name} style={{ width: 'auto' }}>
                        {row.template_name}</span>
                    ),
                },
                {
                    name: "Template Type",
                    selector: "template_type",
                    value: "Template Type",
                    center: true,
                    cell: (row) => (
                        <span
                            title={Common.getTemplateType(row?.template_type)}
                            style={{ width: "auto" }}
                        >
                            {Common.getTemplateType(row?.template_type)}
                        </span>
                    ),
                },
                {
                    name: "Template Operation Status",
                    selector: "template_operation_status",
                    value: "Template Operation Status",
                    sortable: true,
                    center: true,
                    cell: (row) => (
                        <div id={`col${row?.template_operation_status}`}>
                            <span id={`icon${row?.template_status}`}>
                                <i
                                    className={this.returnClassStatus(
                                        row?.template_operation_status
                                    )}
                                ></i>
                                {this.returnStatus(row?.template_operation_status)}
                            </span>
                        </div>
                    ),
                },
                {
                    name: "Created By",
                    selector: "created_by",
                    value: "Created By",
                    center: true,
                    cell: (row) => (
                        <span title={row?.created_by || "-"} style={{ width: "auto" }}>
                            {row?.created_by || "-"}
                        </span>
                    ),
                }, {
                    name: "Updated By",
                    selector: "updated_by",
                    value: "Updated By",
                    center: true,
                    cell: (row) => (
                        <span title={row?.updated_by || "-"} style={{ width: "auto" }}>
                            {row?.updated_by || "-"}
                        </span>
                    ),
                },
                {
                    name: "Supported Version",
                    selector: "supported_verion",
                    value: "Supported Version",
                    center: true,
                    cell: (row) => (
                        <span title={"Version of templates supported by the device"} style={{ width: "auto" }}>
                            {row?.versions?.supported_version}
                        </span>
                    ),
                }, {
                    name: "Applied Version",
                    selector: "applied_verion",
                    value: "Applied Version",
                    center: true,
                    cell: (row) => (
                        <span title={"Version of the applied templates for the device"} style={{ width: "auto" }}>
                            {row?.versions?.applied_version}
                        </span>
                    ),
                }, {
                    name: "Latest Version",
                    selector: "latest_verion",
                    value: "Latest Version",
                    center: true,
                    cell: (row) => (
                        <span title={"Version of the latest templates available in the EM"} style={{ width: "auto" }}>
                            {row?.versions?.latest_version}
                        </span>
                    ),
                },
                {
                    name: "Last Updated On",
                    selector: "created_date",
                    value: "Last Updated On",
                    center: true,
                    cell: (row) => (
                        <span title={row?.created_date} style={{ width: "auto" }}>
                            {this.getTimeExact(row?.created_date)}
                        </span>
                    ),
                },
                {
                    value: "Action",
                    center: true,
                    cell: (row) => (
                        <div className="da-box">
                            <img
                                className="a-dots"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                src="/images/svg-icons/ellipsis.svg"
                                alt=""
                            />
                            <div className="drop-box dropdown-menu">
                                <ul>
                                    {/* Disable view for Device Provision templates */}
                                    <li
                                        className={this.disableOption(row, 'View')}
                                        onClick={this.disableClick(row, 'View')}
                                    >
                                        <img src="/images/svg-icons/view.svg" alt="" /> View
                                    </li>
                                    {row.template_operation_status !== "Pending" && (
                                        <li
                                            // LONG-3916 : Disable Edit for Device Provision templates
                                            // Disable view for Device Provision templates
                                            className={
                                                (this.props?.userRole === "Viewer_Group" ||
                                                    this.props?.userRole === "Operator_Group")
                                                    ? "disabled"
                                                    : this.disableOption(row, 'Edit & ReApply')
                                            }
                                            onClick={
                                                (this.props?.userRole === "Viewer_Group" ||
                                                    this.props?.userRole === "Operator_Group")
                                                    ? undefined
                                                    : this.disableClick(row, 'Edit & ReApply')
                                            }>
                                            <img className="edit" src="/images/svg-icons/reply.svg" alt="" />Edit & ReApply Template</li>
                                    )}
                                    {row.template_operation_status === "Failed" && (
                                        <li
                                            className={
                                                (process.env.REACT_APP_AD_USER === 'TRUE' &&
                                                    (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                                                        localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                                                    (this.props?.userRole === "Viewer_Group" ||
                                                        this.props?.userRole === "Operator_Group")
                                                    ? "disabled"
                                                    : ""
                                            }
                                            onClick={() =>
                                                (process.env.REACT_APP_AD_USER === 'TRUE' &&
                                                    (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                                                        localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                                                    (this.props?.userRole === "Viewer_Group" ||
                                                        this.props?.userRole === "Operator_Group")
                                                    ? undefined
                                                    : this.handleApplyTemplate(row, false, false, true)
                                            }
                                        >
                                            <img
                                                className="edit"
                                                src="/images/svg-icons/reply.svg"
                                                alt=""
                                            />{" "}
                                            ReApply Template
                                        </li>
                                    )}
                                    <li onClick={() => this.getOperationLog(row.operation_id)}>
                                        <img src="/images/svg-icons/view.svg" alt="" /> View
                                        Operation Logs
                                    </li>
                                    <li
                                        className={row.template_operation_status === "Pending" ? "" : "disabled"}
                                        onClick={row.template_operation_status === "Pending" ? () => this.redirectToOperations(row) : ""}>
                                        <img src="/images/svg-icons/view.svg" alt="" /> View
                                        Operation
                                    </li>
                                    <li
                                        className={row.template_type === 'DeviceProvision' || (process.env.REACT_APP_AD_USER === 'TRUE' &&
                                            (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                                                localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                                            (this.props?.userRole === "Viewer_Group" ||
                                                this.props?.userRole === "Operator_Group") ? "disabled" : ""}
                                        onClick={(process.env.REACT_APP_AD_USER === 'TRUE' &&
                                            (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                                                localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                                            (this.props?.userRole === "Viewer_Group" ||
                                                this.props?.userRole === "Operator_Group") ||
                                            row.template_type === 'DeviceProvision'
                                            ? undefined
                                            : () =>
                                                this.setState({
                                                    multipleSelect: [row]
                                                }, () => this.getCopyTemplateDetails(row)
                                                )
                                        }
                                    >
                                        <img
                                            className="edit"
                                            src="/images/svg-icons/copy.svg"
                                            alt=""
                                        />
                                        Copy Template
                                    </li>
                                </ul>
                            </div>
                        </div>
                    ),
                    allowOverflow: true,
                    button: true,
                    width: "56px",
                },
            ],
        };
    }
    componentDidMount() {
        this.getAppliedTemplateList();
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (Object.keys(nextProps.deviceData).length !== 0) {
            this.setState({
                deviceData: nextProps.deviceData,
            });
        }
        if (nextProps.id !== this.props.id) {
            this.setState(
                {
                    id: nextProps.id,
                    nonSync: false
                },
                () => this.getAppliedTemplateList()
            );
        }
    }
    componentDidUpdate() {
        let newArray = this.state.columns;
        let localValue = [];
        localValue = JSON.parse(
            localStorage.getItem(Constant.APPLIED_TEMPLATE_LIST)
        );
        if (localValue !== null && localValue.length !== 0) {
            newArray = [];
            localValue.map((item) => {
                const column = this.valueReturn(item);
                if (column !== undefined)
                    newArray.push(column);
            })
        }
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.selectedOption = newArray;
    }
    // disableListOption = () => {
    //     let result = false;
    //     if (process.env.REACT_APP_AD_USER === 'TRUE') {
    //         if (this.props.userRole !== 'Admin' || this.props.userRole !== 'Manager' ||
    //             sessionStorage.getItem('ssoUser') !== 'true') {
    //             result = true;
    //         }
    //     } else {
    //         if (this.props?.userRole === "Viewer_Group" ||
    //             this.props?.userRole === "Operator_Group") {
    //             result = true;
    //         }
    //     }
    //     return result;
    // }
    returnClassStatus = (template_status) => {
        switch (template_status) {
            case "Successful":
                return "status completed";
            case "Deleted":
                return "status offline";
            case "Pending":
                return "status pending";
            case "Failed":
                return "status offline";
            default:
                return "";
        }
    };
    returnStatus = (template_status) => {
        switch (template_status) {
            case "Successful":
                return "Successful";
            case "Deleted":
                return "Deleted";
            case "Pending":
                return "Pending";
            case "Failed":
                return "Failed";
            default:
                return template_status;
        }
    };
    // LONG-1505 : Added copy template functionality on device template page
    getCopyTemplateDetails = (row) => {
        let selectedTemplate = JSON.parse(JSON.stringify(row));
        selectedTemplate.template_id = "";
        selectedTemplate.template_desc = "";
        selectedTemplate.id = "";
        selectedTemplate.template_name = row.template_name + "_copy";
        this.setState(
            {
                copyTemplate: selectedTemplate,
                showCopy: true,
            },
            () => {
                $("#exampleModalCopy").modal("show");
            }
        );
    };
    getModulesData = async () => {
        this.setState({
            showLoader: true,
        });
        await ApiCall(urlMapping.getModuleList(), (response) => {
            this.setState({
                showLoader: false,
            });
            if (response?.success) {
                Object.keys(response?.data?.models_interface)?.map(item => {
                    if (item === this.state.deviceData.device_model) {
                        this.setState({
                            templateVersions: response?.data,
                            interfaces: response?.data?.models_interface?.[item],
                        });
                    }
                })
            } else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({ showRefreshModal: true });
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        });
    };
    // LONG-1505 : Added copy template functionality on device template page
    setCopyTemplateValues = (e) => {
        let data = this.state.copyTemplate;
        let name = e.target.name;
        let value = e.target.value;
        if (name === "template_name") {
            data.template_name = value;
        }
        if (name === "template_desc") {
            data.template_desc = value;
        }
        this.setState({
            copyTemplate: data,
        });
    };
    // LONG-1505 : Added copy template functionality on device template page
    saveData = () => {
        let data = this.state.copyTemplate;
        this.setState({
            showLoader: true,
        });
        ApiCall(urlMapping.createTemplate(data), (response) => {
            this.setState({
                showLoader: false,
            });
            if (response?.success) {
                $("#exampleModalCopy").modal("hide")
                this.setState({
                    title: 'Copy Template',
                    message: response?.message + ' To edit ',
                    copyShow: true,
                    error: false,
                    delete: false,
                    showCopy: false,
                    copyId: response?.data?.id,
                    copyTemplate: {},
                }, () => {
                    $("#exampleModal").modal("show");
                    this.getAppliedTemplateList();
                }
                );
            } else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({
                        showRefreshModal: true,
                    });
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else {
                    $("#exampleModalCopy").modal("hide");
                    this.setState(
                        {
                            title: "Copy Template",
                            message: response.message,
                            copyShow: true,
                            error: true,
                            delete: false,
                            showCopy: false,
                        },
                        () => {
                            $("#errorMessage").modal("show");
                        }
                    );
                }
            }
        });
    };
    // LONG-1505 : Added copy template functionality on device template page
    resetPopupCopy = () => {
        this.setState({
            error: false,
            copyShow: false,
        }, () => $("#errorMessage").modal("hide"))
    }
    getTimeExact = (time) => {
        let date = moment.utc(time);
        date = date.local();
        return date.format("DD MMMM YYYY hh:mm:ss A");
    };
    getTime = (time) => {
        let date = moment.utc(time);
        date = date.local();
        return date.format("DD");
    };
    getAppliedTemplateList = async () => {
        await this.props.refreshDropDown()
        this.setState({ showLoader: true });
        let data = { device_id: this.state.id };
        ApiCall(
            urlMapping.deviceAppliedTemplateList(
                data
            ),
            async (response) => {
                if (response?.data) {
                    await this.getModulesData();
                    let model = Common.getModels(this.state?.deviceData?.device_model);
                    if (model === 'MIFI') {
                        model = Common.getMiFiModels(this.state?.deviceData?.device_model);
                    }
                    response?.data?.map((item) => {
                        let templateType = item?.template_operation_type;
                        if (templateType === 'TunnelConfiguration') {
                            templateType = 'DataTunnel'
                        } else if (templateType === 'DNSFiltering') {
                            templateType = 'DNSFilteringConfiguration'
                        }
                        let supported_version = this.state.deviceData?.supported_templates?.[String(templateType)];
                        let applied_version = model === "vCPE"
                            ? item.template_version?.cbr
                            : model === "CHIMAY"
                                ? item.template_version?.chimay
                                : item.template_version?.mifi;
                        let latest_version = model === "vCPE"
                            ? this.state.templateVersions?.cbr_templates_version?.[String(templateType)]
                            : model === "CHIMAY"
                                ? this.state.templateVersions?.chimay_templates_version?.[String(templateType)]
                                : this.state.templateVersions?.mifi_templates_version?.[String(templateType)];
                        item.versions = {
                            supported_version: supported_version || "-",
                            applied_version: applied_version || "-",
                            latest_version: latest_version || "-"
                        }
                        return item
                    })
                    this.setState({
                        appliedTemplateList: response?.data,
                        showLoader: false
                    }, () => {
                        if (this.state.nonSync)
                            this.setState({
                                nonSyncCount: this.state.nonSyncCount + 1,
                                showLoader: true
                            }, () => this.nonSync()
                            )
                    });
                } else {
                    if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                        this.setState({
                            showLoader: false,
                            showRefreshModal: true,
                        });
                    } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                        this.setState({
                            title: 'Tenant Error',
                            message: response.response.data.message,
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    } else {
                        this.setState({
                            showLoader: false,
                        });
                    }
                }
            }
        );
    };
    setOptionalTemplates = (e, template) => {
        let { optionalTemplates } = this.state;
        if (e.target.checked === false) {
            if (optionalTemplates.filter(item => item === template).length === 0) {
                optionalTemplates.push(template)
            }
        } else {
            if (optionalTemplates.filter(item => item === template).length > 0) {
                optionalTemplates = optionalTemplates.filter(item => item !== template);
            }
        }
        this.setState({ optionalTemplates })
    }
    confirmToSet = () => {
        let result = true;
        let createdTime = "";
        let diff = "";
        let templates = [];
        let model = Common.getModels(this.state.deviceData.device_model);
        if (model === "MIFI") {
            model = Common.getMiFiModels(this.state.deviceData.device_model);
        }
        this.setState({
            showOptional: false
        });
        $("#optionalModal").modal("hide");
        switch (model) {
            case "CHIMAY":
                templates = this.state.appliedTemplateList.filter(
                    (item) =>
                        item.template_operation_type === "NetworkConfiguration" ||
                        item.template_operation_type === "WiFiConfiguration" ||
                        item.template_operation_type === "WANConfiguration" ||
                        item.template_operation_type === "FirewallConfiguration" ||
                        item.template_operation_type === "TriggerConfiguration" ||
                        item.template_operation_type === "QoSConfiguration" ||
                        item.template_operation_type === "BGPConfiguration"
                );
                if (templates.length < 7) result = false;
                break;
            case "vCPE":
                templates = this.state.appliedTemplateList.filter(
                    (item) =>
                        item.template_operation_type === "NetworkConfiguration" ||
                        item.template_operation_type === "BGPConfiguration" ||
                        item.template_operation_type === "WANConfiguration" ||
                        item.template_operation_type === "FirewallConfiguration" ||
                        item.template_operation_type === "TriggerConfiguration" ||
                        item.template_operation_type === "QoSConfiguration"
                );
                if (templates.length < 6) result = false;
                break;
            case "Stormbreaker":
                templates = this.state.appliedTemplateList.filter(
                    (item) =>
                        item.template_operation_type === "NetworkConfiguration" ||
                        item.template_operation_type === "TriggerConfiguration" ||
                        item.template_operation_type === "FirewallConfiguration"
                        || item.template_operation_type === 'ModemConfiguration'
                );
                if (templates.length < 4) result = false;
                break;
            case "Firestorm":
                templates = this.state.appliedTemplateList.filter(
                    (item) =>
                        item.template_operation_type === "NetworkConfiguration" ||
                        item.template_operation_type === "TriggerConfiguration"
                );
                if (templates.length < 2) result = false;
                break;
            case "Moretti_Model":
            case "Duvel_Model":
                templates = this.state.appliedTemplateList.filter(
                    (item) =>
                        item.template_operation_type === "NetworkConfiguration" ||
                        item.template_operation_type === "TriggerConfiguration" ||
                        item.template_operation_type === "WiFiConfiguration" ||
                        item.template_operation_type === "FirewallConfiguration"
                );
                if (templates.length < 4) result = false;
                break;
            case "Orval":
                templates = this.state.appliedTemplateList.filter(
                    (item) =>
                        item.template_operation_type === "NetworkConfiguration" ||
                        item.template_operation_type === "WANConfiguration" ||
                        item.template_operation_type === "TriggerConfiguration"
                );
                if (templates.length < 3) result = false;
                break;
            default:
                templates = this.state.appliedTemplateList.filter(
                    (item) =>
                        item.template_operation_type === "NetworkConfiguration" ||
                        item.template_operation_type === "WiFiConfiguration" ||
                        item.template_operation_type === "WANConfiguration" ||
                        item.template_operation_type === "FirewallConfiguration" ||
                        item.template_operation_type === "TriggerConfiguration" ||
                        item.template_operation_type === "QoSConfiguration" ||
                        item.template_operation_type === "BGPConfiguration"
                );
                if (templates.length < 7) result = false;
                break;
        }
        if (!result) {
            this.setState(
                {
                    title: this.state.downloadTemp ? "Download Templates" : "Set As Bootstrap Templates",
                    message: "Device doesn't have all the required templates",
                    show: true,
                    error: true,
                    delete: false,
                },
                () => {
                    $("#errorMessage").modal("show");
                }
            );
        } else if (templates.filter((item) => item.template_operation_status !== "Successful").length > 0) {
            this.setState(
                {
                    title: this.state.downloadTemp ? "Download Templates" : "Set As Bootstrap Templates",
                    message: "All templates must be in successful state",
                    show: true,
                    error: true,
                    delete: false,
                },
                () => {
                    $("#errorMessage").modal("show");
                }
            );
        } else if (templates.filter((item) => item.template_operation_status !== "Successful").length === 0) {
            let optionalTemplates
            this.state.optionalTemplates.map((item) => {
                optionalTemplates = templates.filter(val => val.template_type === item &&
                    item.template_operation_status !== "Successful")
            });
            if (optionalTemplates.length > 0) {
                this.setState(
                    {
                        title: this.state.downloadTemp ? "Download Templates" : "Set As Bootstrap Templates",
                        message: "All templates must be in successful state",
                        show: true,
                        error: true,
                        delete: false,
                    },
                    () => {
                        $("#errorMessage").modal("show");
                    }
                );
            }
            else {
                templates.map((item) => {
                    if (item.template_operation_type === "NetworkConfiguration") {
                        createdTime = moment.utc(item.created_date).local();
                    } else {
                        if (createdTime !== "" && (item.template_operation_type !== "TriggerConfiguration" ||
                            item.template_operation_type === 'DNSFiltering')) {
                            diff = moment
                                .utc(
                                    moment(
                                        moment.utc(item.created_date).local(),
                                        "DD MMMM YYYY hh:mm:ss:SSS A"
                                    ).diff(moment(createdTime, "DD MMMM YYYY hh:mm:ss:SSS A"))
                                )
                                .format("HH:mm:ss:SSS");
                        }
                    }
                });
                if (diff === "") {
                    this.setState(
                        {
                            title: this.state.downloadTemp ? "Download Templates" : "Set As Bootstrap Templates",
                            message:
                                this.state.downloadTemp ? "All applied templates will be downloaded" :
                                    "Newly on-boarded device will be applied with these templates after ZTP process once you set this as Bootstrap Templates. Click OK to proceed or Click Cancel to cancel the operation",
                            show: true,
                            error: true,
                            delete: true,
                        },
                        () => {
                            $("#errorMessage").modal("show");
                        }
                    );
                } else {
                    this.setState(
                        {
                            title: this.state.downloadTemp ? "Download Templates" : "Set As Bootstrap Templates",
                            message:
                                "The Network template and other templates are not in sync with each other. Kindly, check the dependent templates and update them to be in sync with the Network template.",
                            show: true,
                            error: true,
                            delete: false
                        },
                        () => {
                            $("#errorMessage").modal("show");
                        }
                    );
                }
            }
        }
    };
    setAsDefaultTemplates = () => {
        this.setState({ showLoader: true });
        let template_operation_ids = [];
        this.state.appliedTemplateList.map((item) => {
            if (this.state.optionalTemplates.filter(val => val === item.template_type).length === 0)
                template_operation_ids.push(item.template_operation_id);
        });
        let data = { device_id: this.state.id, template_operation_ids };
        ApiCall(urlMapping.setAsDefaultTemplates(data), (response) => {
            if (response?.success) {
                this.setState(
                    {
                        showLoader: false,
                        title: "Set As Bootstrap Template",
                        message:
                            "Template set as Bootstrap Successfully.To View Template to the device(s) ", //Long_1692:Instead of To Apply Change to View
                        showLink: true,
                    },
                    () => {
                        $("#exampleModal").modal("show");
                    }
                );
            } else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({
                        showLoader: false,
                        showRefreshModal: true,
                    });
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else {
                    this.setState(
                        {
                            showLoader: false,
                            title: "Set As Bootstrap Template",
                            message: response?.message,
                            error: true,
                            show: true,
                        },
                        () => {
                            $("#exampleModal").modal("show");
                        }
                    );
                }
            }
        });
    };
    getOperationLog = (e) => {
        let data = { operation_id: e };
        this.setState({ showLoader: true, operation_id: e });
        ApiCall(urlMapping.operationLog(data), (response) => {
            if (response?.success) {
                this.setState({
                    OperationLog: response?.data,
                    showLoader: false,
                    openLog: true,
                });
            } else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({
                        showLoader: false,
                        showRefreshModal: true,
                    });
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else {
                    this.setState({ showLoader: false, openLog: true });
                }
            }
        });
    };
    // Go to operations page on click of View Operations
    redirectToOperations = (row) => {
        this.props.history.push({
            pathname: '/operations',
            state: { deviceMac: this.state.deviceData.device_mac, },
        })
    }
    handleChange = (e) => {
        let array = [];
        this.state.selectedOption.map((option) => {
            array.push(option.value);
        });
        const result = e.target.value;
        if (result === "selectAll") {
            array = [];
            if (e.target.checked) {
                this.state.columns.map((column) => {
                    array.push(column.value);
                });
            } else {
                array = ["Action"];
            }
        } else {
            if (e.target.checked) array.push(result);
            else array.splice(array.indexOf(result), 1);
        }
        if (array.includes("Action")) {
            array.splice(array.indexOf("Action"), 1);
            array.push("Action");
        }
        const selectedVlaues = [];
        array.map((item) => {
            const column = this.valueReturn(item);
            selectedVlaues.push(column);
        });
        const listName = Constant.APPLIED_TEMPLATE_LIST;
        localStorage.setItem(listName, JSON.stringify(array));
        this.setState({ selectedOption: selectedVlaues });
    };
    valueReturn = (item) => {
        if (this.state.selectedTab === "ConnectedClient")
            return this.state.clientColumns.filter(
                (column) => column.value === item
            )[0];
        else return this.state.columns.filter((column) => column.value === item)?.[0];
    };
    multipleSelect(rows) {
        if (!rows.selectedRows.length) this.setState({ multipleSelect: "" });
        else {
            this.setState({ multipleSelect: rows.selectedRows });
        }
    }
    // LONG-3916 : Disable Edit for Device Provision templates
    disableOption = (row, value) => {
        if ((this.state.deviceData.group_name || row.template_type === 'DeviceProvision') && value === 'Edit & ReApply')
            return "disabled"
        else if (row.template_type === 'DeviceProvision') {
            return "disabled"
        }
        else if (this.state.deviceData.is_ippt && row.template_type !== "NetworkConfiguration" &&
            row.template_type !== "TriggerConfiguration" && row.template_type !== "ModemConfiguration" &&
            row.template_type !== "SystemConfiguration" && value === 'Edit & ReApply') {
            return "disabled"
        }
        else if (process.env.REACT_APP_AD_USER === 'TRUE' &&
            (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true') && value === 'Edit & ReApply')
            return "disabled"
        else
            return undefined
    }
    // Disable view for Device Provision templates
    disableClick = (row, value) => {
        if ((this.state.deviceData.group_name || row.template_type === 'DeviceProvision') && value === 'Edit & ReApply')
            return undefined
        else if (row.template_type === 'DeviceProvision') {
            return undefined
        }
        else if (this.state.deviceData.is_ippt && row.template_type !== "NetworkConfiguration" &&
            row.template_type !== "TriggerConfiguration" && row.template_type !== "ModemConfiguration" &&
            row.template_type !== "SystemConfiguration" && value === 'Edit & ReApply') {
            return "disabled"
        }
        else if (process.env.REACT_APP_AD_USER === 'TRUE' &&
            (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true') && value === 'Edit & ReApply')
            return "disabled"
        else {
            if (value === 'View') {
                return () => {
                    this.setState({
                        nonSync: false
                    }, () => {
                        this.handleApplyTemplate(row, true, false, false);
                    })
                }
            }
            else {
                return () => {
                    this.setState({
                        nonSync: false
                    }, () => { this.handleApplyTemplate(row, false, true, false) })
                }
            }
        }
    }
    checkVersions = (data) => {
        let templateType = data.template_type;
        if (templateType === 'TunnelConfiguration') {
            templateType = 'DataTunnel'
        } else if (templateType === 'DNSFiltering') {
            templateType = 'DNSFilteringConfiguration'
        }
        const Model = this.state.deviceData.device_model;
        let result = true;
        if (data.template_version !== null && templateType !== 'TriggerConfiguration') {
            // if (this.state.deviceData?.supported_templates ) {
            if (this.state.deviceData?.supported_templates !== undefined && this.state.deviceData?.supported_templates !== null
                && Object.keys(this.state.deviceData?.supported_templates).length > 0) {
                // Validation is added to check if template version of the template which we are applying to the deviceand matches with the latest device version for CHIMAY device
                if (Common.getModels(Model) === 'CHIMAY') {
                    if (this.state.deviceData?.supported_templates[templateType]) {
                        if (data?.template_version?.chimay !== this.state.deviceData?.supported_templates[templateType]) {
                            result = false;
                            this.setState(
                                {
                                    title: "Version Mismatch",
                                    message: data?.template_version?.chimay < this.state.deviceData?.supported_templates?.[templateType]
                                        ? 'Template version is older and might not be compatible with device, please create new template to proceed further'
                                        : 'Device Firmware is out of sync with EDGE Manager. Please upgrade the device firmware.',
                                    showPopup: true,
                                    error: true,
                                    delete: true,
                                    template_type: templateType,
                                    applyTemplateData: data,
                                    firmwareUpgrade: data?.template_version?.chimay < this.state.deviceData?.supported_templates?.[templateType] ? false : true,
                                    newTemplate: data?.template_version?.chimay < this.state.deviceData?.supported_templates?.[templateType] ? true : false
                                },
                                () => {
                                    $("#versionErrorMessage").modal("show");
                                }
                            );
                        }
                    } else {
                        result = false;
                        //Show popup if value is missing means the device is older
                        this.setState(
                            {
                                title: "Version Mismatch",
                                message: `Device firmware is older and its not supporting ${templateType}. Please upgrade your device.`,
                                showPopup: true,
                                error: true,
                                delete: true,
                                firmwareUpgrade: true
                            },
                            () => {
                                $("#versionErrorMessage").modal("show");
                            }
                        );
                    }
                }
                // Validation is added to check if template version of the template which we are applying to the deviceand matches with the latest device version for CBR device
                else if (Common.getModels(Model) === 'vCPE') {
                    if (this.state.deviceData?.supported_templates[templateType]) {
                        if (data?.template_version?.cbr !== this.state.deviceData?.supported_templates[templateType]) {
                            result = false;
                            this.setState(
                                {
                                    title: "Version Mismatch",
                                    message: data?.template_version?.cbr < this.state.deviceData?.supported_templates?.[templateType]
                                        ? 'Template version is older and might not be compatible with device, please create new template to proceed further'
                                        : 'Device Firmware is out of sync with EDGE Manager. Please upgrade the device firmware.',
                                    showPopup: true,
                                    error: true,
                                    delete: true,
                                    template_type: templateType,
                                    applyTemplateData: data,
                                    firmwareUpgrade: data?.template_version?.cbr < this.state.deviceData?.supported_templates?.[templateType] ? false : true,
                                    newTemplate: data?.template_version?.cbr < this.state.deviceData?.supported_templates?.[templateType] ? true : false
                                },
                                () => {
                                    $("#versionErrorMessage").modal("show");
                                }
                            );
                        }
                        // }
                    } else {
                        result = false;
                        //Show popup if value is missing means the device is older
                        this.setState(
                            {
                                title: "Version Mismatch",
                                message: `Device firmware is older and its not supporting ${templateType}. Please upgrade your device.`,
                                showPopup: true,
                                error: true,
                                delete: true,
                                firmwareUpgrade: true
                            },
                            () => {
                                $("#versionErrorMessage").modal("show");
                            }
                        );
                    }
                }
                // Validation is added to check if template version of the template which we are applying to the deviceand matches with the latest device version for all types of MIFI devices
                else if (Common.getModels(Model) === 'MIFI' || Common.getModels(Model) === 'Stormbreaker' || Common.getModels(Model) === 'Firestorm' ||
                    Common.getModels(Model) === 'Bradshaw' || Common.getModels(Model) === 'Orval' || Common.getModels(Model) === 'Sander') {
                    if (this.state.deviceData?.supported_templates[templateType]) {
                        if (data?.template_version?.mifi !== this.state.deviceData?.supported_templates[templateType]) {
                            result = false;
                            this.setState(
                                {
                                    title: "Version Mismatch",
                                    message: data?.template_version?.mifi < data?.supported_templates?.[templateType]
                                        ? 'Template version is older and might not be compatible with device, please create new template to proceed further'
                                        : 'Device Firmware is out of sync with EDGE Manager. Please upgrade the device firmware.',
                                    showPopup: true,
                                    error: true,
                                    delete: true,
                                    template_type: templateType,
                                    applyTemplateData: data,
                                    firmwareUpgrade: data?.template_version?.mifi < this.state.deviceData?.supported_templates?.[templateType] ? false : true,
                                    newTemplate: data?.template_version?.mifi < this.state.deviceData?.supported_templates?.[templateType] ? true : false
                                },
                                () => {
                                    $("#versionErrorMessage").modal("show");
                                }
                            );
                        }
                    } else {
                        result = false;
                        //Show popup if value is missing means the device is older
                        this.setState(
                            {
                                title: "Version Mismatch",
                                message: `Device firmware is older and its not supporting ${templateType}. Please upgrade your device.`,
                                showPopup: true,
                                error: true,
                                delete: true,
                                firmwareUpgrade: true
                            },
                            () => {
                                $("#versionErrorMessage").modal("show");
                            }
                        );
                    }
                } else {
                    result = true;
                    //Show popup if value is missing means the device is older
                    this.setState(
                        {
                            title: "Version Mismatch",
                            message: `Device firmware is older and its not supporting ${templateType}. Please upgrade your device.`,
                            showPopup: true,
                            error: true,
                            delete: true,
                            //   keyRotation: true
                        },
                        () => {
                            $("#versionErrorMessage").modal("show");
                        }
                    );
                }
            } else {
                result = true;
                //Show popup if value is missing means the device is older
                this.setState(
                    {
                        title: "Version Mismatch",
                        message: `Device firmware is older and might not be compatible with the latest template, some feature might not work properly`,
                        showPopup: true,
                        error: true,
                        delete: true,
                    },
                    () => {
                        $("#versionErrorMessage").modal("show");
                    }
                );
            }
        } else {
            result = true;
            //Show popup if value is missing means the device is older
            if (templateType !== 'TriggerConfiguration') {
                this.setState(
                    {
                        title: "Version Mismatch",
                        message: `Template version is older and might not be compatible with device`,
                        showPopup: true,
                        error: true,
                        delete: true,
                    },
                    () => {
                        $("#versionErrorMessage").modal("show");
                    }
                );
            }
        }
        return result;
    }
    handleAddTemplate = (templateData) => {
        if (templateData) {
            templateData.template_id = ""
            templateData.template_name = ""
            templateData.id = ""
            this.props.history.push({
                pathname: '/templates',
                state: {
                    fromAppliedList: true,
                    templateDetails: templateData,
                    template_type: templateData.template_type
                }
            })
        }
    }
    handleApplyTemplate = (data, view, edit, reapply) => {
        this.setState({
            applyTemplateData: {}
        }, () => {
            if (
                this.state.deviceData.device_status === 1 &&
                (edit === true || reapply === true)
            ) {
                let model = Common.getModels(this.state.deviceData?.device_model);
                let templateType = data?.template_type;
                let createdTemplateVersionCBR = data?.template_version?.cbr;
                let createdTemplateVersionChimay = data?.template_version?.chimay;
                let createdTemplateVersionMifi = data?.template_version?.mifi;
                if (model === "MIFI") {
                    model = Common.getMiFiModels(this.state.deviceData?.device_model);
                }
                const idData = {
                    device_id: data.device_id,
                };
                ApiCall(urlMapping.getModuleList(), (response1) => {
                    if (response1?.success) {
                        if (templateType === 'TunnelConfiguration') {
                            templateType = 'DataTunnel'
                        } else if (templateType === 'DNSFiltering') {
                            templateType = 'DNSFilteringConfiguration'
                        }
                        let currentTemplateVersionCBR = response1?.data?.cbr_templates_version?.[templateType];
                        let currentTemplateVersionChimay = response1?.data?.chimay_templates_version?.[templateType];
                        let currentTemplateVersionMifi = response1?.data?.mifi_templates_version?.[templateType]
                        const versionCheck = model === 'vCPE' ?
                            createdTemplateVersionCBR === currentTemplateVersionCBR : (model === 'CHIMAY' ?
                                createdTemplateVersionChimay === currentTemplateVersionChimay :
                                createdTemplateVersionMifi === currentTemplateVersionMifi
                            )
                        if (data.template_version === null || templateType === 'TriggerConfiguration' || versionCheck) {
                            if (this.checkVersions(data)) {
                                //LONG-2299 -EM - Not able to re-apply template for failed network template
                                if (
                                    (edit === true || reapply === true) &&
                                    (data.template_operation_type === "NetworkConfiguration" ||
                                        data.template_operation_type === "TriggerConfiguration")
                                ) {
                                    this.setState(
                                        {
                                            networkViewData: [],
                                            applyTemplateData: JSON.parse(JSON.stringify(data)),
                                            openApplyTemplate: true,
                                            view,
                                            edit,
                                            reapply,
                                            selectedModel: model,
                                        },
                                        () => {
                                            this.props.dispatch({
                                                type: Action.OPEN_APPlY_TEMPLATE,
                                            });
                                        }
                                    );
                                } else {
                                    if (String(idData.device_id) !== "") {
                                        ApiCall(urlMapping.getNetworkView(idData), (response) => {
                                            if (response?.Success) {
                                                this.setState(
                                                    {
                                                        showLoader: false,
                                                        networkViewData: response?.data,
                                                        applyTemplateData: JSON.parse(JSON.stringify(data)),
                                                        openApplyTemplate: true,
                                                        selectedModel: model,
                                                        view,
                                                        edit,
                                                        reapply,
                                                    },
                                                    () => {
                                                        this.props.dispatch({
                                                            type: Action.OPEN_APPlY_TEMPLATE,
                                                        });
                                                    }
                                                );
                                            } else {
                                                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                                                    this.setState({
                                                        showLoader: false,
                                                        showRefreshModal: true,
                                                    });
                                                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                                                    this.setState({
                                                        title: 'Tenant Error',
                                                        message: response.response.data.message,
                                                        showLoader: false,
                                                        isAxiosError: true,
                                                        delete: false,
                                                        error: true
                                                    }, () => $("#errorMessage").modal("show"))
                                                } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                                                    this.setState({
                                                        title: 'Network Error',
                                                        message: 'Something went wrong. Please try again later.',
                                                        showLoader: false,
                                                        isAxiosError: true,
                                                        delete: false,
                                                        error: true
                                                    }, () => $("#errorMessage").modal("show"))
                                                } else {
                                                    this.setState(
                                                        {
                                                            title: "Re-apply Template",
                                                            message: "To apply template device must have networks!",
                                                            show: true,
                                                            error: true,
                                                            view,
                                                            edit,
                                                            reapply,
                                                        },
                                                        () => {
                                                            $("#errorMessage").modal("show");
                                                        }
                                                    );
                                                }
                                            }
                                        });
                                    }
                                }
                            }
                        } else {
                            this.setState(
                                {
                                    title: "Version Mismatch",
                                    message: 'Template version is outdated, as EDGE Manager is upgraded, Please create new template to proceed further',
                                    showPopup: true,
                                    error: true,
                                    delete: true,
                                    template_type: templateType,
                                    applyTemplateData: data,
                                    newTemplate: true
                                },
                                () => {
                                    $("#versionErrorMessage").modal("show");
                                }
                            );
                        }
                    } else if (parseInt(response1?.response?.status) === 404 && response1?.response?.data) {
                        this.setState({
                            title: 'Tenant Error',
                            message: response1.response.data.message,
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else if (response1?.isAxiosError) {
                        this.setState({
                            title: 'Network Error',
                            message: 'Something went wrong. Please try again later.',
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                })
            } else if (this.state.deviceData.device_status === 1 || view === true) {
                // model needed to view trigger config for offline device
                let model = Common.getModels(this.state.deviceData.device_model);
                if (model === "MIFI") {
                    model = Common.getMiFiModels(this.state.deviceData.device_model);
                }
                this.setState(
                    {
                        // applyTemplateData: JSON.parse(JSON.stringify(data)),
                        applyTemplateData: data,
                        openApplyTemplate: true,
                        view,
                        edit,
                        reapply,
                        selectedModel: model,
                    },
                    () => {
                        this.props.dispatch({
                            type: Action.OPEN_APPlY_TEMPLATE,
                        });
                    }
                );
            } else {
                this.setState(
                    {
                        title: "Re-apply Template",
                        message: "To apply template device must be online!",
                        show: true,
                        error: true,
                        view,
                        edit,
                        reapply,
                        status: this.state.deviceData.device_status,
                    },
                    () => {
                        $("#errorMessage").modal("show");
                    }
                );
            }
        })
    };
    firmwareUpgrade = () => {
        $("#exampleModal").modal("hide");
        $("#errorMessage").modal("hide");
        $("#versionErrorMessage").modal("hide")
        // this.closeApplyTemplate();
        this.props.history.push({
            pathname: '/deviceFirmware',
            state: { data: this.state.deviceData.device_model }
        })
    }
    resetPopup = () => {
        if (this.state.firmwareUpgrade)
            this.firmwareUpgrade();
        else if (this.state.newTemplate) {
            this.handleAddTemplate(this.state.applyTemplateData);
        }
        else {
            this.setState(
                {
                    show: false,
                    error: false,
                    delete: false,
                    factoryReset: false,
                    reboot: false,
                    createError: false,
                    downloadTemp: false,
                    create: false,
                },
                () => {
                    $("#errorMessage").modal("hide");
                    if (this.state.checkpoint) {
                        this.props.selectedTab("Device-Checkpoint");
                        this.setState({ checkpoint: false });
                    }
                }
            );
        }
    };
    openDefaultTemplateList = () => {
        $("#exampleModal").modal("hide");
        this.props.history.push({
            pathname: "/bootstrapTemplates",
            state: { data: "" },
        });
    };
    // LONG-1505 : Added copy template functionality on device template page
    openTemplateList = () => {
        $("#exampleModal").modal("hide");
        this.props.history.push({
            pathname: "/templates",
            state: { data: "" },
        });
    };
    closeLinkpopup = () => {
        this.setState(
            {
                showLink: false,
                copyShow: false,
                title: "",
                message: "",
            },
            () => {
                this.getAppliedTemplateList();
                $("#exampleModal").modal("hide");
            }
        );
    };
    getModel = () => {
        let model = Common.getModels(this.state.modelName);
        if (model === "MIFI") {
            model = Common.getMiFiModels(this.state.modelName);
        }
        return model;
    };
    factoryTemplate = () => {
        let template_operation_ids = [];
        this.state.appliedTemplateList.map((item) => {
            template_operation_ids.push(item.template_operation_id);
        });
        const data = {
            device_id: this.state.id,
            template_operation_ids: template_operation_ids,
        };
        this.setState({
            showLoader: true,
        });
        ApiCall(urlMapping.restoreFactoryTemplate(data), (response) => {
            this.setState({
                showLoader: false,
                show: false,
            });
            $("#errorMessage").modal("hide");
            if (response?.success === true) {
                this.setState(
                    {
                        title: "Restore to Factory Template",
                        message: response?.message,
                        show: true,
                        error: false,
                        delete: false,
                    },
                    () => $("#errorMessage").modal("show")
                );
            } else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({
                        showRefreshModal: true,
                    });
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else {
                    this.setState(
                        {
                            title: "Restore to Factory Template",
                            message: response?.message,
                            show: true,
                            error: true,
                            delete: false,
                        },
                        () => $("#errorMessage").modal("show")
                    );
                }
            }
        });
    };
    confirmCreate = () => {
        let result = true;
        let createdTime = '';
        let diff = '';
        let templates = [];
        let model = Common.getModels(this.state.deviceData.device_model);
        if (model === 'MIFI') {
            model = Common.getMiFiModels(this.state.deviceData.device_model)
        }
        switch (model) {
            //EMGT-383 EM - Able to create the checkpoint even though the template is getting failed	
            case 'CHIMAY':
                templates = this.state.appliedTemplateList.filter(item => item.template_operation_type === 'NetworkConfiguration' ||
                    item.template_operation_type === 'WiFiConfiguration' || item.template_operation_type === 'WANConfiguration' ||
                    item.template_operation_type === 'FirewallConfiguration' || item.template_operation_type === 'TriggerConfiguration' ||
                    item.template_operation_type === 'QoSConfiguration' || item.template_operation_type === 'BGPConfiguration');
                if (templates.length < 7)
                    result = false;
                break;
            //EMGT-383 EM - Able to create the checkpoint even though the template is getting failed
            case 'vCPE':
                templates = this.state.appliedTemplateList.filter(item => item.template_operation_type === 'NetworkConfiguration' ||
                    item.template_operation_type === 'BGPConfiguration' || item.template_operation_type === 'WANConfiguration' ||
                    item.template_operation_type === 'FirewallConfiguration' || item.template_operation_type === 'TriggerConfiguration' ||
                    item.template_operation_type === 'QoSConfiguration');
                if (templates.length < 6)
                    result = false;
                break;
            case 'Stormbreaker':
                templates = this.state.appliedTemplateList.filter(item =>
                    item.template_operation_type === 'NetworkConfiguration' ||
                    item.template_operation_type === 'TriggerConfiguration' ||
                    item.template_operation_type === "FirewallConfiguration");
                if (templates.length < 3)
                    result = false;
                break;
            case 'Firestorm':
                templates = this.state.appliedTemplateList.filter(item =>
                    item.template_operation_type === 'NetworkConfiguration' ||
                    item.template_operation_type === 'TriggerConfiguration');
                if (templates.length < 2)
                    result = false;
                break;
            case 'Moretti_Model':
            case 'Duvel_Model':
                templates = this.state.appliedTemplateList.filter(item =>
                    item.template_operation_type === 'NetworkConfiguration' ||
                    item.template_operation_type === 'TriggerConfiguration' ||
                    item.template_operation_type === 'WiFiConfiguration' ||
                    item.template_operation_type === "FirewallConfiguration");
                if (templates.length < 4)
                    result = false;
                break;
            case "Orval":
                templates = this.state.appliedTemplateList.filter(
                    (item) =>
                        item.template_operation_type === "NetworkConfiguration" ||
                        item.template_operation_type === "WANConfiguration" ||
                        item.template_operation_type === "TriggerConfiguration"
                );
                if (templates.length < 3) result = false;
                break;
            default:
                result = false;
        }
        if (!result) {
            this.setState({
                title: 'Create Checkpoint',
                message: 'Device doesn\'t have all the required templates',
                show: true,
                error: true,
                delete: false,
                create: false,
                restore: false
            }, () => {
                $("#errorMessage").modal("show")
            });
        } else if (templates.filter(item => item.template_operation_status !== 'Successful').length > 0) {
            this.setState({
                title: 'Create Checkpoint',
                message: 'All templates must be in successful state',
                show: true,
                error: true,
                delete: false,
                create: false,
                restore: false
            }, () => {
                $("#errorMessage").modal("show")
            });
        }
        else if (templates.filter((item) => item.template_operation_status !== "Successful").length === 0) {
            let optionalTemplates
            this.state.optionalTemplates.map((item) => {
                optionalTemplates = templates.filter(val => val.template_type === item &&
                    item.template_operation_status !== "Successful")
            });
            if (optionalTemplates.length > 0) {
                this.setState({
                    title: 'Create Checkpoint',
                    message: 'All templates must be in successful state',
                    show: true,
                    error: true,
                    delete: false,
                    create: false,
                    restore: false
                }, () => {
                    $("#errorMessage").modal("show")
                });
            }
            else {
                templates.map((item) => {
                    if (item.template_operation_type === 'NetworkConfiguration') {
                        createdTime = moment.utc(item.created_date).local();
                    } else {
                        if (createdTime !== '' && item.template_operation_type !== "TriggerConfiguration") {
                            diff = moment.utc(moment(moment.utc(item.created_date).local(), 'DD MMMM YYYY hh:mm:ss:SSS A').diff(moment(createdTime, 'DD MMMM YYYY hh:mm:ss:SSS A'))).format("HH:mm:ss:SSS");
                        }
                    }
                })
                if (diff === '') {
                    this.setState({
                        title: 'Create Checkpoint',
                        message: 'The current templates configurations will be marked as a checkpoint and can be restored in the future as required. Click OK to proceed or Click Cancel to cancel the operation.',
                        show: true,
                        error: true,
                        delete: true,
                        create: true,
                        restore: false
                    }, () => {
                        $("#errorMessage").modal("show")
                    });
                } else {
                    this.setState({
                        title: 'Create Checkpoint',
                        message: 'The Network template and other templates are not in sync with each other. Kindly, check the dependent templates and update them to be in sync with the Network template.',
                        show: true,
                        error: true,
                        delete: false,
                        create: false,
                        restore: false
                    }, () => {
                        $("#errorMessage").modal("show")
                    });
                }
            }
        }
    };
    confirmOptional = () => {
        if (this.state.appliedTemplateList.filter((item => item.template_operation_status !== 'Successful')).length === 0) {
            if (this.state.appliedTemplateList.filter(item =>
                item.template_operation_type === 'DNSFiltering' ||
                item.template_operation_type === 'ModemConfiguration').length > 0) {
                this.setState({
                    showOptional: true
                },
                    () => $("#optionalModal").modal("show")
                )
            } else {
                this.confirmToSet();
            }
        }
        else {
            this.setState({
                title: 'Set As Bootstrap Templates',
                message: 'All templates must be in successful state',
                show: true,
                error: true,
                delete: false,
                create: false,
                restore: false
            }, () => {
                $("#errorMessage").modal("show")
            });
        }
    }
    createCheckpoint = () => {
        let data = {};
        if (this.state.createError) {
            data = {
                device_mac: this.state.deviceData.device_mac,
                is_delete: true,
            };
        } else {
            data = {
                device_mac: this.state.deviceData.device_mac,
                is_delete: false,
            };
        }
        this.setState({
            showLoader: true,
        });
        ApiCall(urlMapping.createCheckpoint(data), (response) => {
            this.setState({
                showLoader: false,
                show: false,
            });
            if (response?.success) {
                $("#errorMessage").modal("hide");
                this.setState(
                    {
                        title: "Create Checkpoint",
                        message: response?.message,
                        show: true,
                        error: false,
                        delete: false,
                        create: false,
                        createError: false,
                        checkpoint: true,
                    },
                    () => {
                        $("#errorMessage").modal("show");
                    }
                );
            } else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({
                        showRefreshModal: true,
                    });
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else {
                    $("#errorMessage").modal("hide");
                    this.setState(
                        {
                            title: "Create Checkpoint",
                            message: response?.message,
                            show: true,
                            error: true,
                            delete: false,
                            create: false,
                            createError: true,
                        },
                        () => $("#errorMessage").modal("show")
                    );
                }
            }
        });
    };
    chooseAction = (deleteAct, createAct) => {
        if (deleteAct && createAct) {
            this.createCheckpoint();
        } else if (this.state.createError) {
            this.createCheckpoint();
        } else if (this.state.downloadTemp) {
            this.downloadTemplates();
        } else if (deleteAct) {
            this.setAsDefaultTemplates();
        } else {
            return "undefined";
        }
    };
    downloadTemplates = () => {
        let template_operation_ids = [];
        this.state.appliedTemplateList.map((item) => {
            if (item.template_operation_type !== 'TunnelConfiguration') {
                template_operation_ids.push(item.template_operation_id);
            }
        });
        let data = { template_operation_ids: template_operation_ids }
        this.setState({
            showLoader: true,
        });
        ApiCall(urlMapping.downloadDeviceTemplate(data), (response) => {
            if (response?.success) {
                var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(response?.data));
                var downloadAnchorNode = document.createElement('a');
                downloadAnchorNode.setAttribute("href", dataStr);
                downloadAnchorNode.setAttribute("download", "device templates.json");
                document.body.appendChild(downloadAnchorNode); // required for firefox
                downloadAnchorNode.click();
                downloadAnchorNode.remove();
                this.setState({
                    showLoader: false,
                }, () => {
                    this.getAppliedTemplateList();
                })
            }
            else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({ showLoader: false, showRefreshModal: true })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else {
                    this.setState({
                        showLoader: false,
                        title: 'Download Templates',
                        message: response?.message,
                        show: true,
                        error: true,
                    }, () => {
                        $("#errorMessage").modal("show");
                    });
                }
            }
        })
    }
    openUploadTemplates = () => {
        this.setState({
            uploadTemp: true
        }, () => {
            $("#uploadTempModal").modal("show");
        })
    }
    closeUploadTemplates = () => {
        this.setState({
            uploadTemp: false,
            jsonFile: undefined
        }, () => {
            $("#uploadTempModal").modal("hide");
        })
    }
    jsonFileHandleSelect = (e) => {
        this.setState({ jsonFile: e.target.files[0] })
    }
    deleteJson = () => {
        this.setState({ jsonFile: undefined })
    }
    uploadTemplates = () => {
        const formData = new FormData();
        formData.append("device_template_file", this.state.jsonFile);
        formData.append("device_id", this.state.id);
        this.setState({
            showLoader: true,
        });
        ApiCall(urlMapping.uploadDeviceTemplate(formData), (response) => {
            if (response?.success) {
                $("#uploadTempModal").modal("hide");
                this.setState({
                    jsonFile: undefined,
                    showLoader: false,
                    title: 'Upload Templates',
                    message: response?.message,
                    show: true,
                    error: false,
                }, () => {
                    this.getAppliedTemplateList();
                    $("#errorMessage").modal("show");
                })
            }
            else {
                $("#uploadTempModal").modal("hide");
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({ showLoader: false, showRefreshModal: true })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else {
                    this.setState({
                        jsonFile: undefined,
                        showLoader: false,
                        title: 'Upload Templates',
                        message: response?.response?.data ? response?.response?.data?.message : response?.message,
                        show: true,
                        error: true,
                    }, () => {
                        $("#errorMessage").modal("show");
                    });
                }
            }
        })
    }
    closeVersionError = () => {
        this.setState({
            firmwareUpgrade: false,
            newTemplate: false,
            show: false,
            error: false,
        })
    }
    nonSyncTemplates = () => {
        if (this.state.appliedTemplateList.length !== 0) {
            let templates = [];
            let resultVersion = true;
            let resultTemplate = true;
            let resultFirmware = true;
            let currentTemplateVersionCBR = this.state.templateVersions?.cbr_templates_version;
            let currentTemplateVersionChimay = this.state.templateVersions?.chimay_templates_version;
            let currentTemplateVersionMifi = this.state.templateVersions?.mifi_templates_version;
            let model = Common.getModels(this.state.deviceData?.device_model);
            if (model === 'MIFI') {
                model = Common.getMiFiModels(this.state.deviceData?.device_model);
            }
            this.state?.appliedTemplateList?.map((item) => {
                let templateType = item?.template_operation_type;
                if (templateType === 'TunnelConfiguration') {
                    templateType = 'DataTunnel'
                } else if (templateType === 'DNSFiltering') {
                    templateType = 'DNSFilteringConfiguration'
                }
                if (model === "vCPE") {
                    if (currentTemplateVersionCBR?.[String(templateType)]) {
                        if (item?.template_version?.cbr !== currentTemplateVersionCBR?.[String(templateType)])
                            resultVersion = false;
                        if (item?.template_version?.cbr > this.state.deviceData?.supported_templates?.[String(templateType)])
                            resultFirmware = false
                    }
                }
                else if (model === "CHIMAY") {
                    if (currentTemplateVersionChimay?.[String(templateType)]) {
                        if (item?.template_version?.chimay !== currentTemplateVersionChimay?.[String(templateType)])
                            resultVersion = false;
                        if (item?.template_version?.chimay > this.state.deviceData?.supported_templates?.[String(templateType)])
                            resultFirmware = false
                    }
                }
                else {
                    if (currentTemplateVersionMifi?.[String(templateType)]) {
                        if (item?.template_version?.mifi !== currentTemplateVersionMifi?.[String(templateType)])
                            resultVersion = false;
                        if (item?.template_version?.mifi > this.state.deviceData?.supported_templates?.[String(templateType)])
                            resultFirmware = false
                    }
                }
            })
            switch (model) {
                case "CHIMAY":
                    templates = this.state?.appliedTemplateList?.filter(
                        (item) =>
                            item.template_operation_type === "WiFiConfiguration" ||
                            item.template_operation_type === "WANConfiguration" ||
                            item.template_operation_type === "FirewallConfiguration" ||
                            item.template_operation_type === "TriggerConfiguration" ||
                            item.template_operation_type === "QoSConfiguration" ||
                            item.template_operation_type === "BGPConfiguration"
                    );
                    if (templates.length < 6)
                        resultTemplate = false;
                    break;
                case "vCPE":
                    templates = this.state.appliedTemplateList?.filter(
                        (item) =>
                            item.template_operation_type === "BGPConfiguration" ||
                            item.template_operation_type === "WANConfiguration" ||
                            item.template_operation_type === "FirewallConfiguration" ||
                            item.template_operation_type === "TriggerConfiguration" ||
                            item.template_operation_type === "QoSConfiguration"
                    );
                    if (templates.length < 5)
                        resultTemplate = false;
                    break;
                case "Stormbreaker":
                    templates = this.state.appliedTemplateList?.filter(
                        (item) =>
                            item.template_operation_type === "TriggerConfiguration" ||
                            item.template_operation_type === "FirewallConfiguration"
                            || item.template_operation_type === 'ModemConfiguration'
                    );
                    if (templates.length < 3)
                        resultTemplate = false;
                    break;
                case "Firestorm":
                    templates = this.state.appliedTemplateList?.filter(
                        (item) => item.template_operation_type === "TriggerConfiguration"
                    );
                    if (templates.length < 1)
                        resultTemplate = false;
                    break;
                case "Moretti_Model":
                case "Duvel_Model":
                    templates = this.state.appliedTemplateList?.filter(
                        (item) =>
                            item.template_operation_type === "TriggerConfiguration" ||
                            item.template_operation_type === "WiFiConfiguration" ||
                            item.template_operation_type === "FirewallConfiguration"
                    );
                    if (templates.length < 3)
                        resultTemplate = false;
                    break;
                case "Orval":
                    templates = this.state.appliedTemplateList.filter(
                        (item) =>
                            item.template_operation_type === "NetworkConfiguration" ||
                            item.template_operation_type === "WANConfiguration" ||
                            item.template_operation_type === "TriggerConfiguration"
                    );
                    if (templates.length < 3) resultTemplate = false;
                    break;
                default:
                    templates = this.state.appliedTemplateList?.filter(
                        (item) =>
                            item.template_operation_type === "WiFiConfiguration" ||
                            item.template_operation_type === "WANConfiguration" ||
                            item.template_operation_type === "FirewallConfiguration" ||
                            item.template_operation_type === "TriggerConfiguration" ||
                            item.template_operation_type === "QoSConfiguration" ||
                            item.template_operation_type === "BGPConfiguration"
                    );
                    if (templates.length < 6)
                        resultTemplate = false;
                    break;
            }
            if (!resultVersion || !resultTemplate || !resultFirmware) {
                this.setState(
                    {
                        title: "Reorder Templates",
                        message: !resultFirmware
                            ? "Device Firmware is out of sync with EDGE Manager. Please upgrade the device firmware."
                            : !resultVersion
                                ? "Some templates have a version mismatch and are unable to proceed further."
                                : "Device doesn't have all the required templates",
                        show: !resultFirmware ? false : true,
                        showPopup: !resultFirmware ? true : false,
                        error: true,
                        delete: !resultFirmware ? true : false,
                        firmwareUpgrade: !resultFirmware ? true : false,
                        newTemplate: false
                    },
                    () => {
                        !resultFirmware
                            ? $("#versionErrorMessage").modal("show")
                            : $("#errorMessage").modal("show")
                    }
                );
            }
            else {
                let arr = ["TriggerConfiguration", "WiFiConfiguration", "WANConfiguration", "BGPConfiguration", "QoSConfiguration", "FirewallConfiguration"];
                let newTemplate = []
                arr.map((item) => {
                    let list = templates?.filter((val) => val.template_operation_type === item)
                    if (list?.length !== 0)
                        newTemplate.push(list[0])
                })
                this.setState({
                    nonSyncTemplates: newTemplate,
                    nonSyncCount: 0,
                    nonSync: true
                }, () => this.nonSync()
                )
            }
        }
    }
    nonSync = () => {
        if (this.state.nonSyncTemplates?.length > this.state.nonSyncCount) {
            this.handleApplyTemplate(this.state.nonSyncTemplates?.[parseInt(this.state.nonSyncCount)], false, true, false);
        }
        else {
            this.setState({
                showLoader: false,
                nonSync: false
            })
        }
    }
    render() {
        return (
            <>
                {" "}
                {this.state.showLoader && <Loader />}
                {/* Upload Templates */}
                {this.state.uploadTemp &&
                    <div className="modal fade licence" id="uploadTempModal" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" data-backdrop="static">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Upload Templates</h5>
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => this.closeUploadTemplates()}>
                                        <span aria-hidden="true"><img src="/images/svg-icons/cancel.svg" alt="" /></span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className='form-group mb-0'>
                                        <label>Add JSON File</label>
                                        <div className="drag-box">
                                            <input
                                                className="f1"
                                                type="file"
                                                accept={".json"}
                                                onClick={(e) => e.target.files[0] && this.jsonFileHandleSelect(e)}
                                                onChange={(e) => this.jsonFileHandleSelect(e)} />
                                            <div className="csv-file">
                                                <img src="../images/svg-icons/outbox.svg" alt="" />
                                                <span>Click here to upload templates</span>
                                            </div>
                                        </div>
                                    </div>

                                    {(this.state.jsonFile !== undefined) && <div className="droped-files">
                                        <span>{this.state.jsonFile.name}</span>
                                        <img className="delete-icon" src="/images/svg-icons/delete-red.svg" alt="" onClick={() => this.deleteJson()} />
                                    </div>}
                                    <div>
                                        {(this.state.jsonFile !== undefined &&
                                            !this.state.jsonFile.name.includes('.json')) &&
                                            <label className='license-btn'>Please upload a JSON file!!</label>
                                        }
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="reset"
                                        onClick={() => this.closeUploadTemplates()}
                                        className="btn btn-light"
                                        data-dismiss="modal"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => this.uploadTemplates()}
                                        disabled={(this.state.jsonFile === undefined || !this.state.jsonFile.name.includes('.json'))}
                                        className="btn btn-primary"
                                        data-dismiss="modal"
                                    >
                                        Submit
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>}
                {/* Upload Templates Ends */}
                <div className="white-bg">
                    <div className="ta-box set-pos mb-0">
                        <div className="action-btn">
                            <button
                                className="short-btn"
                                onClick={() => this.setState({ nonSync: false }, () => this.getAppliedTemplateList())}
                                title="Refresh">
                                <img src="/images/svg-icons/refreshing.svg" alt="" />
                                &nbsp;Refresh
                            </button>
                            <button
                                className="short-btn dropdown-toggle filter-btn mr-3"
                                data-toggle="dropdown" title="Columns"><img src="/images/svg-icons/columns.svg" alt="" />
                                &nbsp;Columns
                            </button>
                            <div className="filter-section dropdown-menu dropdown-menu-right" onChange={(e) => this.handleChange(e)}>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value="selectAll"
                                        checked={
                                            this.state.selectedTab === "ConnectedClient"
                                                ? this.state.selectedOption.length ===
                                                this.state.clientColumns.length
                                                : this.state.selectedOption.length ===
                                                this.state.columns.length
                                        }
                                        style={{ cursor: "pointer" }}
                                    />
                                    <label className="form-check-label">Select All</label>
                                </div>
                                {
                                    this.state.columns.map((item, index) => {
                                        if (item.value !== "Action")
                                            return (
                                                <div className="form-check" key={index}>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox" value={item.value}
                                                        checked={JSON.stringify(this.state.selectedOption)?.includes(item.value)}
                                                        style={{ cursor: "pointer" }}
                                                    />
                                                    <label className="form-check-label">{item.value}</label>
                                                </div>
                                            )
                                    })
                                }
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value="Action"
                                        checked={true}
                                        style={{ cursor: "not-allowed" }}
                                        disabled
                                    />
                                    <label className="form-check-label">Action</label>
                                </div>
                            </div>
                        </div>
                        <div className="da-box mr-2">
                            <img
                                className="a-dots"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                src="/images/svg-icons/ellipsis.svg"
                                alt=""
                            />
                            <div className="drop-box dropdown-menu dropdown-menu-right">
                                <ul>
                                    <li
                                        className={
                                            (this.state.appliedTemplateList.filter(
                                                (item) => item.template_operation_status === "Pending"
                                            ).length > 0 || (process.env.REACT_APP_AD_USER === 'TRUE' &&
                                                (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                                                    localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                                                (this.props?.userRole === "Viewer_Group" ||
                                                    this.props?.userRole === "Operator_Group"))
                                                ? "disabled"
                                                : ""
                                        }
                                        onClick={this.state.appliedTemplateList.filter(
                                            (item) => item.template_operation_status === "Pending"
                                        ).length > 0 || (process.env.REACT_APP_AD_USER === 'TRUE' &&
                                            (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                                                localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                                            (this.props?.userRole === "Viewer_Group" ||
                                                this.props?.userRole === "Operator_Group")
                                            ? undefined
                                            : () => {
                                                //EMGT-274 -EM - Wrong pop up will show after dowload template and then given set as bootstarp
                                                this.setState(
                                                    {
                                                        downloadTemp: false,
                                                    },
                                                    () => { this.confirmOptional() }
                                                );
                                            }}
                                        disabled={
                                            this.state.appliedTemplateList.filter(
                                                (item) => item.template_operation_status === "Pending"
                                            ).length > 0 || (process.env.REACT_APP_AD_USER === 'TRUE' &&
                                                (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                                                    localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                                            (this.props?.userRole === "Viewer_Group" ||
                                                this.props?.userRole === "Operator_Group")
                                        }
                                    >
                                        <img src="/images/svg-icons/settings-lock.svg" alt="" />
                                        Set As Bootstrap
                                    </li>
                                    <li
                                        className={(process.env.REACT_APP_AD_USER === 'TRUE' &&
                                            (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                                                localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                                            (this.props?.userRole === "Viewer_Group" ||
                                                this.props?.userRole === "Operator_Group") ? "disabled" : ""}
                                        onClick={(process.env.REACT_APP_AD_USER === 'TRUE' &&
                                            (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                                                localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                                            (this.props?.userRole === "Viewer_Group" ||
                                                this.props?.userRole === "Operator_Group") ? undefined : () => this.factoryTemplate()}>
                                        <img src="/images/svg-icons/settings-lock.svg" alt="" />{" "}
                                        Restore To Factory Templates
                                    </li>
                                    <li className=
                                        // {(process.env.REACT_APP_AD_USER === 'TRUE' &&
                                        // (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                                        //     localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                                        // (this.props?.userRole === "Viewer_Group" ||
                                        //     this.props?.userRole === "Operator_Group") ? 
                                            "disabled"
                                            //  : ""}
                                        // onClick={(process.env.REACT_APP_AD_USER === 'TRUE' &&
                                        //     (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                                        //         localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                                        //     (this.props?.userRole === "Viewer_Group" ||
                                        //         this.props?.userRole === "Operator_Group") ? undefined : () => this.confirmCreate()}
                                        >
                                        <img src="/images/svg-icons/settings-lock.svg" alt="" />{" "}
                                        Create Checkpoint
                                    </li>
                                    <li className={(process.env.REACT_APP_AD_USER === 'TRUE' &&
                                        (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                                            localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                                        (this.props?.userRole === "Viewer_Group" ||
                                            this.props?.userRole === "Operator_Group") ? "disabled" : ""}
                                        onClick={(process.env.REACT_APP_AD_USER === 'TRUE' &&
                                            (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                                                localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                                            (this.props?.userRole === "Viewer_Group" ||
                                                this.props?.userRole === "Operator_Group") ? undefined : () => this.nonSyncTemplates()}>
                                        <img
                                            src="/images/reorder.png"
                                            title="Rearrange templates to be in sync with Network template"
                                            alt=""
                                        />{" "}
                                        Reorder Template
                                    </li>
                                    {/* Added upload/download template options in Actions and removed the buttons  */}
                                    <li
                                        className={
                                            // this.state?.deviceData?.group_name || (process.env.REACT_APP_AD_USER === 'TRUE' 
                                            // && (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                                            //         localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                                            //     (this.props?.userRole === "Viewer_Group" ||
                                            //         this.props?.userRole === "Operator_Group")
                                            //     ? 
                                                "disabled"
                                                // : undefined
                                        }
                                        // onClick={
                                        //     this.state.deviceData.group_name || (process.env.REACT_APP_AD_USER === 'TRUE' &&
                                        //         (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                                        //             localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                                        //         (this.props?.userRole === "Viewer_Group" ||
                                        //             this.props?.userRole === "Operator_Group")
                                        //         ? undefined
                                        //         : () => this.openUploadTemplates()}
                                        ><img src="/images/svg-icons/upload-icon.svg" alt="" /> Upload Templates</li>
                                    <li
                                        className={
                                            // this.state?.deviceData?.group_name || this.state.appliedTemplateList.filter(
                                            //     (item) => item.template_operation_status === "Pending"
                                            // ).length > 0
                                            //     ? 
                                                "disabled"
                                                // : undefined
                                        }
                                        // onClick={
                                        //     this.state.deviceData.group_name || this.state.appliedTemplateList.filter(
                                        //         (item) => item.template_operation_status === "Pending"
                                        //     ).length > 0
                                        //         ? undefined
                                        //         :
                                        //         () => {
                                        //             this.setState(
                                        //                 {
                                        //                     downloadTemp: true
                                        //                 },
                                        //                 () => { this.confirmToSet() });
                                        //         }}
                                    >
                                        <img src="/images/svg-icons/download-arrow.svg" alt="" /> Download Templates
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <DataTable
                        columns={this.state.selectedOption.length === 0 ? this.state.columns : this.state.selectedOption}
                        data={this.state.appliedTemplateList}
                        highlightOnHover
                        fixedHeader
                    />
                    {this.state.openApplyTemplate && Object.keys(this.state.applyTemplateData).length > 0 && (
                        <ApplyTemplate
                            data={this.state.applyTemplateData}
                            reapply={this.state.reapply}
                            networkViewData={this.state.networkViewData}
                            view={this.state.view}
                            edit={this.state.edit}
                            link={!this.state.edit}
                            info={false}
                            props={this.props}
                            interfaces={this.state.interfaces}
                            selectedModel={this.state.selectedModel}
                            exactModel={this.state.deviceData.device_model}
                            refreshList={() => this.getAppliedTemplateList()}
                            device_id={this.state.id}
                            device_mac={this.state.deviceData.device_mac}
                            nonSync={this.state.nonSync}
                            deviceDetails={this.state.deviceData}
                        />
                    )}
                    {this.state.show && (
                        <ErrorPopup
                            title={this.state.title}
                            message={this.state.message}
                            error={this.state.error}
                            delete={this.state.delete}
                            createError={this.state.createError}
                            reset={() => this.resetPopup()}
                            onSubmit={() =>
                                this.chooseAction(this.state.delete, this.state.create)
                            }
                        />
                    )}
                    {this.state.showLink && (
                        <LinkPopup
                            title={this.state.title}
                            message={this.state.message}
                            open={() => this.openDefaultTemplateList()}
                            close={() => this.closeLinkpopup()}
                        />
                    )}
                    {/* LONG-1505 : Added copy template functionality on device template page */}
                    {this.state.copyShow && (
                        <LinkPopup
                            title={this.state.title}
                            message={this.state.message}
                            open={() => this.openTemplateList()}
                            close={() => this.closeLinkpopup()}
                        />
                    )}
                    {/* LONG-1505 : Added copy template functionality on device template page */}
                    {this.state.copyShow && (
                        <ErrorPopup
                            title={this.state.title}
                            message={this.state.message}
                            error={this.state.error}
                            delete={this.state.delete}
                            reset={() => this.resetPopupCopy()}
                            onSubmit={() => this.resetPopupCopy()}
                        />
                    )}
                    {/* log  */}
                    {/* LONG-1505 : Added copy template functionality on device template page */}
                    {this.state.showCopy && <div className="modal fade exampleModal" id="exampleModalCopy"
                        tabIndex="-1" aria-labelledby="exampleModalLabel" data-backdrop="static" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Copy Template</h5>
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close">
                                        <span aria-hidden="true"><img src="/images/svg-icons/cancel.svg" alt="" /></span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label>Template Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="template_name"
                                            value={this.state.copyTemplate.template_name}
                                            onChange={(e) => this.setCopyTemplateValues(e)} />

                                    </div>
                                    <div className="form-group">
                                        <label>Description</label>
                                        <textarea
                                            className="form-control"
                                            name="template_desc"
                                            value={this.state.copyTemplate.template_desc}
                                            onChange={(e) => this.setCopyTemplateValues(e)}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-light"
                                        data-dismiss="modal"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => this.saveData()}
                                    >
                                        Create
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    <div>
                        <div
                            className={
                                this.state.openLog
                                    ? "slide-overlay zindex10 open"
                                    : "slide-overlay zindex10"
                            }
                        ></div>
                        <div
                            className={
                                this.state.openLog
                                    ? "sidebar-slide zindex10 open "
                                    : "sidebar-slide zindex10"
                            }
                        >
                            <div className="s-header">
                                <h1 className="ss-title">Operation Logs</h1>
                                <button onClick={() => this.setState({ openLog: false })}>
                                    <img src="/images/svg-icons/s-cancel.svg" alt="" />
                                </button>
                            </div>
                            <div>
                                <div className="s-body pb-3">
                                    <div className="txt-blue">
                                        Operation ID: <strong>{this.state.operation_id}</strong>
                                    </div>
                                    {this.state.OperationLog.length > 0 &&
                                        this.state.OperationLog.map((item) => {
                                            return (
                                                <div className="operationlogs-box">
                                                    <div className="logs-list wa-card gray-bg">
                                                        <div className="time">
                                                            {this.getTimeExact(item.created_date)}
                                                        </div>
                                                        <div className="card-title txt-blue">
                                                            Operation Logs
                                                        </div>
                                                        <p>{item.logs}</p>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* error popup for version mismatch */}
                    {this.state.showPopup && (
                        <div className="modal fade errorMessage" id="versionErrorMessage" tabIndex="-1" data-backdrop="static"
                            role="dialog" aria-labelledby="errorMessageTitle" aria-hidden="true"
                        >
                            <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-header bg-white border-0">
                                    </div>
                                    <div className="modal-body pt-5">
                                        <div className="error-msg">
                                            <span className={this.state.error ? "error-icon" : "success-icon"}>
                                                {this.state.error ? (this.state.info ? 'i' : '!') : ''}
                                            </span>
                                            <div className="txt1">{this.state.title}</div>
                                            <div className="txt2">{this.state.message}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer border-top-0 justify-content-center pb-4">
                                        {(this.state.firmwareUpgrade || this.state.newTemplate) &&
                                            <button
                                                type="button"
                                                className="btn btn-light"
                                                data-dismiss="modal"
                                                onClick={this.closeVersionError}
                                            >
                                                Cancel</button>}
                                        {(this.state.delete || this.state.error)
                                            && <button
                                                type="button"
                                                className={this.state.error ? "btn btn-danger" : "btn btn-primary"}
                                                data-dismiss="modal"
                                                onClick={() => this.resetPopup()}>
                                                {'OK'}
                                            </button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* log end  */}
                    {/* Optional Templates */}
                    {this.state.showOptional &&
                        <div className="modal fade" id="optionalModal"
                            tabIndex="-1" aria-labelledby="exampleModalLabel" data-backdrop="static" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Optional Bootstrap Template</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true"><img src="/images/svg-icons/cancel.svg" alt="" /></span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="form-group">
                                            <span>Check/Uncheck the checkbox to add/remove optional templates as part of default Bootstrap templates. The template(s) which are marked will be pushed
                                                as part of the default bootstrap template for the new device(s) onboarding.</span>
                                        </div>
                                        <div className="form-group">
                                            {this.state.appliedTemplateList.filter((item) =>
                                                item.template_operation_type === 'DNSFiltering').length === 1
                                                && <>
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.optionalTemplates.filter(item => item === 'DNSFiltering').length === 0}
                                                        disabled={this.state.appliedTemplateList.filter((item) =>
                                                            item.template_operation_type === 'DNSFiltering')[0].template_operation_status !== "Successful"}
                                                        onClick={(e) => this.setOptionalTemplates(e, 'DNSFiltering')} />
                                                    <span> DNS Filtering</span>
                                                </>
                                            }
                                        </div>
                                        <div className="form-group">
                                            {this.state.appliedTemplateList.filter((item) =>
                                                item.template_operation_type === 'ModemConfiguration').length === 1
                                                && <>
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.optionalTemplates.filter(item => item === 'ModemConfiguration').length === 0}
                                                        disabled={this.state.appliedTemplateList.filter((item) =>
                                                            item.template_operation_type === 'ModemConfiguration')[0].template_operation_status !== "Successful"}
                                                        onClick={(e) => this.setOptionalTemplates(e, 'ModemConfiguration')} />
                                                    <span> Modem Configuration</span>
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-light"
                                            data-dismiss="modal"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => this.confirmToSet()}
                                        >
                                            Set
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {this.state.showRefreshModal && <ErrorHandlePopup />}
                    {(this.state.isAxiosError) &&
                        <ErrorPopup
                            title={this.state.title}
                            message={this.state.message}
                            error={this.state.error}
                            delete={this.state.delete}
                            reset={() => this.setState({ isAxiosError: false })}
                        />
                    }
                </div>
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        authenticated: state.auth.authenticated,
        open: state.displaySidebarMenu.openSide,
        SsoUser: state.auth.SsoUser,
        userRole: JSON.parse(localStorage.getItem('user'))?.userRole
    };
};
export default withRouter(connect(mapStateToProps)(AppliedTemplateList));