import React, { Component } from 'react'
import { withRouter } from "react-router";
import { connect } from "react-redux";
import * as Action from "../../redux/actionTypes";
import SideMenu from '../../layOuts/SideMenu';
import Header from '../../layOuts/Header';
import { Loader } from '../../layOuts/Loader';
import DataTable from "react-data-table-component";
import userApiCall from "../../redux/API/ADUserApiCall";
import userUrlMapping from "../../redux/API/ADUserAPI";
import AddNewUser from "./addNewUser";
import { ErrorPopup } from '../../layOuts/errorPopup';
import ErrorHandlePopup from '../ErrorHandlePopup';
import * as StatusCode from '../../utils/statusCodes';
import $ from "jquery";
class ADUserList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userList: "",
            userId: '',
            userFirstName: "",
            userLastName: "",
            userEmailId: "",
            userRole: "",
            roles: [],
            multipleSelect: '',
            show: false,
            showLoader: false,
            showFilter: false,
            showRefreshModal: false,
            pageNo: 1,
            pageSize: 10,
            totalCount: 0,
            title: "",
            error: false,
            delete: false,
            message: "",
            currentUserID: localStorage.getItem("userId") || "",
            columns: [
                {
                    name: 'First Name',
                    center: true,
                    cell: (row) => (
                        <span title={row.userFirstName}>
                            {row.userFirstName ? row.userFirstName : "--"}</span>
                    ),
                    selector: ''
                },
                {
                    name: 'Last Name',
                    center: true,
                    cell: (row) => (
                        <span title={row.userLastName}>
                            {row.userLastName ? row.userLastName : "--"}</span>
                    ),
                    selector: ''
                },
                {
                    name: 'Email Id',
                    center: true,
                    cell: (row) => (
                        <span title={row.userEmail}>
                            {row.userEmail ? row.userEmail : "--"}</span>
                    ),
                    selector: ''
                },
                {

                    name: 'User Role',
                    center: true,
                    selector: row => row.userRole ? row.userRole : "--"
                },
                {
                    name: 'Status',
                    center: true,
                    cell: (row) => (
                        <span title={row.userStatus}>
                            {row.userStatus ? row.userStatus : "--"}</span>
                    ),
                    selector: ''
                },
                {
                    value: "Action",
                    center: true,
                    cell: (row) => (
                        <div className="da-box">
                            <img className="a-dots" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" src="/images/svg-icons/ellipsis.svg" alt="" />
                            <div className="drop-box dropdown-menu">
                                <ul>
                                    <li className={this.props.userRole !== 'Admin' && localStorage.getItem('ssoUser') !== 'true' &&
                                     sessionStorage.getItem('ssoUser') !== 'true' ? 'disabled' : ''}
                                        onClick={this.props.userRole === 'Admin' || localStorage.getItem('ssoUser') === 'true' ||
                                         sessionStorage.getItem('ssoUser') === 'true' ? () => {
                                            this.setState({
                                                userId: row.userId, userFirstName: row.userFirstName,
                                                userLastName: row.userLastName, userEmailId: row.userEmail ? row.userEmail : "",
                                                userRole: row.userRole
                                            }); this.getUpdateList([row])
                                        } : undefined}><img className="edit " src="/images/svg-icons/edit.svg" alt="" />Edit</li>

                                    <li
                                        className={
                                            (
                                                (this.props.userRole !== 'Admin' &&
                                                localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true') ||
                                                (row?.userId === this.state.currentUserID)
                                            )
                                                ? 'disabled'
                                                : ''
                                        }
                                        onClick={
                                            ((this.props.userRole === 'Admin' || localStorage.getItem('ssoUser') === 'true') && (row?.userId !== this.state.currentUserID))
                                                ? () => {
                                                    this.setState({ multipleSelect: [row.userId] });
                                                    this.confirmDelete();
                                                }
                                                : undefined
                                        }
                                    >
                                        <img
                                            className="delete"
                                            src="/images/svg-icons/delete.svg"
                                            alt=""
                                        /> Delete</li>
                                </ul>
                            </div>
                        </div>
                    ),
                    allowOverflow: true,
                    button: true,
                    width: "56px",
                },
            ],
        }
    }
    componentDidMount() {
        this.UserList();
    }
    getUpdateList = (row) => {
        this.setState({ multipleSelect: [row] });
        this.addNewUser();

    }
    deleteUser = () => {

        this.setState({ showLoader: true })
        let userId = this.state.multipleSelect
        userApiCall(userUrlMapping.deleteUser(userId), (response) => {
            if (response.status) {
                this.setState({
                    title: 'Delete User',
                    message: 'User deleted successfully',
                    show: true,
                    showLoader: false,
                    delete: false,
                    error: false
                }, () => $("#errorMessage").modal("show"))
                this.UserList();
            }
            else {
                if (response.response.data.apierror.status === "NOT_FOUND") {
                    this.setState({
                        title: 'Tenant Error',
                        message: "Tenant does not exist",
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else if (response.error) {
                    this.setState({
                        title: 'Delete Error',
                        message: response.error,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else {
                    this.setState({
                        title: 'Delete User',
                        // Wrong message displayed if 403 error is returned by API
                        message: response?.response?.data?.statusCode ? StatusCode[response?.response?.data?.statusCode] :
                            'Something went wrong',
                        showLoader: false,
                        show: true,
                        delete: false,
                        error: true,
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        })

    }
    componentDidUpdate() {
        if (this.state.pageNo - 1 * this.state.pageSize === this.state.totalCount)
            // eslint-disable-next-line react/no-direct-mutation-state
            this.state.pageNo = this.state.pageNo - 1;
    }
    UserList = () => {
        this.setState({ showLoader: true })
        userApiCall(userUrlMapping.getTenantUser(this.state.pageNo,
            this.state.pageSize), (response) => {
                if (response.status === true) {
                    const userList = response.data.userList;
                    const totalCount = response.data.total;
                    this.setState({
                        showLoader: false,
                        userList,
                        totalCount,
                    })
                }
                else {
                    if (response?.response?.data?.apierror?.status === "NOT_FOUND") {
                        this.setState({
                            title: 'Tenant Error',
                            message: "Tenant does not exist",
                            showLoader: false,
                            isAxiosError: true,
                            delete: false,
                            error: true
                        }, () => $("#errorMessage").modal("show"))
                    }
                    else if (response.isAxiosError) {
                        this.setState({
                            showLoader: false,
                            showRefreshModal: true
                        })
                    }
                    // else {
                    this.setState({ showLoader: false })
                    // }
                }
            })
    }
    confirmDelete = () => {
        this.setState({
            title: this.state.multipleSelect.length > 1 ? 'Delete Users' : 'Delete User',
            message: this.state.multipleSelect.length > 1 ? 'Are you sure you want to delete users ?' : 'Are you sure you want to delete user?',
            show: true,
            error: true,
            delete: true
        }, () => {
            $("#errorMessage").modal("show")
        });
    }
    chooseAction = (deleteAct) => {
        if (deleteAct) {
            this.deleteUser();
        }

    }
    resetPopup = () => {
        this.setState({
            show: false,
            error: false,
            delete: false,
            // Long-1629 Reboot and Factory reset buttons become disable when clicked cancel in delete conformation
            multipleSelect: '',
            toggledClearRows: !this.state.toggledClearRows
        }, () => {
            $("#errorMessage").modal("hide");
            if (this.state.title === 'Tenant Error') {
                this.props.dispatch({
                    type: Action.LOGOUT,
                });
            } else {
                this.UserList();
            }
        })
    }
    getFilterUserList = () => {
        this.setState(prevState => ({ showFilter: !prevState.showFilter }));
    }
    multipleSelect(rows) {
        if (!rows.selectedRows.length)
            this.setState({ multipleSelect: "" });
        else {
            this.setState({ multipleSelect: rows.selectedRows }
            )
        }
    }
    addNewUser = () => {
        userApiCall(userUrlMapping.getRoles(), (response) => {
            if (response.status === true) {
                const roles = response.data;
                // const totalCount = response.count;
                this.setState({
                    showLoader: false,
                    roles,
                    // totalCount: totalCount,
                }, () => {
                    this.props.dispatch({
                        type: Action.OPEN_ADD_AD_USER,
                    });
                })
            }
        });
    };
    closeAddUser = () => {
        this.props.dispatch({
            type: Action.CLOSE_ADD_AD_USER,
        })
        this.setState({
            userId: "",
            multipleSelect: "",
            userFirstName: "",
            userLastName: "",

        })
    }
    paginationChange = (e) => {
        this.setState({
            pageNo: e
        },
            () => {
                this.UserList();
            }
        );

    };
    pageSizeChange = (e) => {
        this.setState({ pageSize: e }, () => {
            this.UserList();
        });
    };
    resetValue = () => {
        this.setState({ user: "", deviceId: "", deviceName: [] }, () => this.getDeviceList())

    }
    hideDropdown = (e) => {
        var popup = $("#parentDiv");
        if (
            !$("#open").is(e.target) &&
            !popup.is(e.target) &&
            popup.has(e.target).length === 0
        ) {
            this.setState({ showFilter: false })
        }
    };

    render() {
        const { userRole } = this.props
        return (
            <div onMouseDown={(e) => this.hideDropdown(e)}>
                <SideMenu />
                {this.state.showLoader && <Loader />}
                <section id="rhsMain" className={this.props.open ? "rhs-main" : "rhs-main expand-w"}>
                    <Header />
                    <div className="white-bg page-height">
                        <div className="ta-box">
                            <h1 className="page-title">User {userRole === 'Admin' || localStorage.getItem('ssoUser') === 'true' ?
                                (<img src="/images/svg-icons/add.svg" className="add-btn" alt="" onClick={() => this.addNewUser()} />) :
                                (<img src="/images/svg-icons/add.svg" className="add-btn disabled" alt="" title="No permission to create User" />)
                            }</h1>
                            <div className="action-btn">
                                <button onClick={() => this.UserList()}><img src="/images/svg-icons/refreshing.svg" alt="" /> Refresh</button>
                            </div>
                        </div>

                        <DataTable
                            columns={this.state.columns}
                            data={this.state.userList}
                            // selectableRows
                            highlightOnHover
                            fixedHeader
                            pagination
                            paginationServer
                            paginationTotalRows={this.state.totalCount}
                            onChangePage={(e) => this.paginationChange(e)}
                            onChangeRowsPerPage={(e) => this.pageSizeChange(e)}
                            onSelectedRowsChange={(data) => this.multipleSelect(data)}
                            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                        />
                        {this.props.openAddADUser &&
                            <AddNewUser
                                userId={this.state.userId}
                                multiselect={this.state.multipleSelect}
                                userFirstName={this.state.userFirstName}
                                userLastName={this.state.userLastName}
                                userEmailId={this.state.userEmailId}
                                userRole={this.state.userRole}
                                UserList={() => this.UserList()}
                                Roles={this.state.roles}
                                closeAddUser={() => this.closeAddUser()}
                            />
                        }
                    </div>

                    {/* Add new user slide close */}
                </section>
                {(this.state.show) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        delete={this.state.delete}
                        reset={() => this.resetPopup()}
                        onSubmit={() => this.chooseAction(this.state.delete, this.state.factoryReset, this.state.reboot)}
                    />
                }
                {(this.state.showRefreshModal) &&
                    <ErrorHandlePopup
                    />
                }
            </div>

        );
    }
}
const mapStateToProps = (state) => {
    return ({
        authenticated: state.auth.authenticated,
        open: state.displaySidebarMenu.openSide,
        openAddADUser: state.displayAddDevice.openAddADUser,
        isSuperAdmin: state.auth.isSuperAdmin,
        userRole: JSON.parse(localStorage.getItem('user'))?.userRole,
        SsoUser: state.auth.SsoUser
    });
}
export default withRouter(connect(mapStateToProps)(ADUserList));

