import React, { Component } from "react";
import urlMapping from "../../../redux/API/api";
import apiCall from "../../../redux/API/apiCall";
import { Loader } from "../../../layOuts/Loader";
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { ErrorPopup } from "../../../layOuts/errorPopup";
export default class DeviceGroupTunnelView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      tunnelsData: [],
      showLoader: false,
      devicedata: this.props.data,
      isAxiosError: false,
    };
  }
  componentDidMount() {
    this.getTunnelsView();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.data).length !== 0) {
      this.setState({
        devicedata: nextProps.data,
      });
    }
    if (nextProps.id !== this.state.id) {
      this.setState(
        {
          id: nextProps.id,
        },
        () => this.getTunnelsView()
      );
    }
  }
  getTunnelsView = () => {
    let data = {
      group_id: this.state.id,
    };
    this.setState({ showLoader: true });
    apiCall(urlMapping.getGroupTunnelsView(data), (response) => {
      this.setState({ showLoader: false });
      if (response?.success) {
        let tunnelsData = response?.data;
        this.setState({ tunnelsData });
      } else {
        if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
          this.setState({ showRefreshModal: true });
        } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
          this.setState({
              title: 'Tenant Error',
              message: response.response.data.message,
              showLoader: false,
              isAxiosError: true,
              delete: false,
              error: true
          }, () => $("#errorMessage").modal("show"))
      } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
          this.setState({
            title: 'Network Error',
            message: 'Something went wrong. Please try again later.',
            showLoader: false,
            isAxiosError: true,
            delete: false,
            error: true
          }, () => $("#errorMessage").modal("show"))
        } else {
          let tunnelsData = [];
          this.setState({ tunnelsData });
        }
      }
    });
  };

  render() {
    return (
      <>
        {this.state.showLoader && <Loader />}
        <div className="ta-box set-pos mb-0">
          <div className="action-btn d-flex align-items-center">
            <button onClick={() => this.getTunnelsView()}>
              <img src="/images/svg-icons/refreshing.svg" alt="" /> Refresh
            </button>
          </div>
        </div>
        {this.state.tunnelsData.length > 0 ? (
          <>
            <div className="white-bg pl-2 pr-2">
              <div className="networks-view">
                <div className="row no-gutters">
                  {this.state.tunnelsData.map((item, index) => {
                    return (
                      <div key={index} className="col-md-3 mb-3">
                        <div className="network-card rounded">
                          <div className="network-header tunnel-head rounded-top d-flex justify-content-between p-2">
                            <div className="">
                              <Link
                                to={{
                                  pathname: "/tunnels",
                                  state: {
                                    selectedHub: item.hub_mac,
                                    group_id: this.state.id
                                  },
                                }}
                                className="txt-blue"
                              >
                                Detail View
                              </Link>
                            </div>

                          </div>
                          <div className="p-2 tunnel-body">
                            {/* UI changes for device group tunnel view */}
                            <ul>
                              <li className="system-connection dotted-line">
                                <span className="">
                                  <img
                                    src={
                                      "/images/svg-icons/hub.svg"
                                    } alt=""
                                  />
                                  <div>{item.hub_name}</div>
                                </span>
                                <span>
                                  <img
                                    src={
                                      "/images/svg-icons/computer.svg"
                                    } alt=""
                                  />
                                  <div>{item.group_name}</div>
                                </span>
                              </li>

                            </ul>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="white-bg pl-2 pr-2 no-tunnels">
            No tunnels found for this device group
          </div>
        )}
        {(this.state.isAxiosError) &&
          <ErrorPopup
            title={this.state.title}
            message={this.state.message}
            error={this.state.error}
            delete={this.state.delete}
            reset={() => this.setState({ isAxiosError: false })}
          />
        }
      </>
    );
  }
}
