import React, { Component } from 'react'
import { connect } from "react-redux";
import userApiCall from "../../redux/API/ADUserApiCall";
import userUrlMapping from "../../redux/API/ADUserAPI";
import { ErrorPopup } from '../../layOuts/errorPopup';
import $ from "jquery";
import { Loader } from '../../layOuts/Loader';
import * as Common from "./../../utils/common";
import ErrorHandlePopup from "../ErrorHandlePopup";
import * as StatusCode from '../../utils/statusCodes';

class AddNewUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mac: "",
            addUser: "",
            response: "",
            show: false,
            userId: this.props.userId,
            showLoader: false,
            showRefreshModal: false,
            title: '',
            group: '',
            message: '',
            error: false,
            validPassword: "",
            roles: this.props.Roles ? this.props.Roles : [],
            userData: {
                firstName: this.props.userFirstName ? this.props.userFirstName : "",
                lastName: this.props.userLastName ? this.props.userLastName : "",
                email: this.props.userEmailId ? this.props.userEmailId : "",
                // username: "",
                // password: "",
                roleId: this.props.userRole ? this.getRoleId(this.props.userRole) : ""
            },
            updateUserData: {}
        }
    }
    getRoleId = (e) => {
        if (this.props.Roles)
            return this.props.Roles?.filter(role => role.roleName === e)[0].roleId;
    }
    handleUpdateUser = () => {
        if (Object.keys(this.state.updateUserData).length > 0) {
            let data = this.state.updateUserData;

            this.setState({
                showLoader: true,
            });
            userApiCall(userUrlMapping.updateUser(data, this.state.userId), (response) => {
                if (response.status === true) {
                    this.setState({
                        showLoader: false,
                        title: 'Update User',
                        message: 'User updated successfully',
                        response: response,
                        show: true,
                        error: false,
                    }, () => {
                        this.props.UserList()
                        $("#errorMessage").modal("show");
                    });
                }
                else {
                        this.setState({
                            showLoader: false,
                            title: 'Update User',
                            message: response?.response?.data?.statusCode ? StatusCode[response?.response?.data?.statusCode] : "Something went wrong!",
                            show: true,
                            delete: false,
                            error: true,
                        }, () => $("#errorMessage").modal("show"))
                }

            })
        }
    }
    handleAddUser = () => {
        if (this.state.userData.firstName !== '' && this.state.userData.email !== '' &&
            this.state.userData.lastName !== '' && this.state.userData.roleId !== '') {
            let data = {
                firstName: this.state.userData.firstName,
                lastName: this.state.userData.lastName,
                email: this.state.userData.email,
                roleId: this.state.userData.roleId,
                isDefaultAdmin: 0
            }
            this.setState({
                showLoader: true,
            });
            userApiCall(userUrlMapping.addUser(data), (response) => {
                if (response.status) {
                    this.setState({
                        showLoader: false,
                        title: 'Add User',
                        message: 'User added successfully',
                        show: true,
                        error: false,
                    }, () => {
                        this.props.UserList()
                        $("#errorMessage").modal("show");
                    });
                }
                else {
                    // if (response.response.status === 401) {
                    //     this.setState({ showLoader: false, showRefreshModal: true })
                    // }
                    // else {
                        this.setState({
                            showLoader: false,
                            title: 'Add User',
                            message: 
                            response?.response?.data?.statusCode ? StatusCode[response?.response?.data?.statusCode]:
                            'Something went wrong',
                            show: true,
                            delete: false,
                            error: true,
                        }, () => $("#errorMessage").modal("show"))
            //         }
                }
            })
        }
    }
    resetPopup = () => {
        this.setState({
            show: false,
            error: false,
        }, () => {
            $("#errorMessage").modal("hide");
            this.props.closeAddUser();
        })
    }
    errorEmpty = () => {
        if ((this.state.userData.firstName === '') || (this.state.userData.lastName === '') ||
            (this.state.userData.roleId === "" || this.state.userData.email === '')) {
            return true;
        }
    }
    removeError = (e) => {
        const element = document.getElementById(e.target.name);
        if (element) element.style.display = "none";
        this.setState({
            error: false,
            validPassword: ''
        });
    };
    //LONG-2294-EM - [User] Request 500 error throws when configuring user with invalid mail
    checkUserDataError = () => {
        let result = false;
        const elements = document.getElementsByClassName("error-txt");
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.cssText === "display: block;")
                result = true;
        }
        if (this.state.userData.firstName === "" || this.state.userData.lastName === ""
            || this.state.userData.roleId === "" || this.state.userData.email === "")
            result = true;
        return result;
    }
    setUserData = (e) => {
        if (Common.removeError(e)) {
            this.setState({
                error: false
            })
        }
        let { userData } = this.state;
        const name = e.target.name;
        const value = e.target.value
        userData[name] = value;
        this.setState({
            userData
        })
    }
    updateUserData = (e) => {
        if (Common.removeError(e)) {
            this.setState({
                error: false
            })
        }
        let { updateUserData } = this.state;
        let { userData } = this.state;
        const name = e.target.name;
        const value = e.target.value
        updateUserData[name] = value;
        userData[name] = value;
        this.setState({
            updateUserData,
            userData
        })
    }
    validateUser = (e) => {

        if (e.target.name === "firstName") {
            if (e.target.value === "") {
                const element = document.getElementById(e.target.name);
                element.style.display = "block";
                element.innerHTML = 'Firstname cannot be empty!';
                this.setState({
                    error: true,
                });
            }
            else {
                if (e.target.value.length > 32) {
                    const element = document.getElementById(e.target.name);
                    element.style.display = "block";
                    element.innerHTML = 'Firstname should be between 1-32';
                    this.setState({
                        error: true,
                    });
                }
                else
                    this.removeError(e)
            }
        }
        else if (e.target.name === "lastName") {

            if (e.target.value === "") {
                const element = document.getElementById(e.target.name);
                element.style.display = "block";
                element.innerHTML = 'Lastname cannot be empty!';
                this.setState({
                    error: true,
                });
            }
            else {
                if (e.target.value.length > 32) {
                    const element = document.getElementById(e.target.name);
                    element.style.display = "block";
                    element.innerHTML = 'Lastname should be between 1-32';
                    this.setState({
                        error: true,
                    });
                }
                else
                    this.removeError(e)
            }

        }
        else if (e.target.name === "email") {
            if (e.target.value !== "") {
                if ((! /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value))) {
                    const element = document.getElementById(e.target.name);
                    element.style.display = "block";
                    this.setState({
                        error: true,
                    });
                }
                else {
                    this.removeError(e)
                }
            } else {
                this.removeError(e);
            }
        }
        else if (e.target.name === "roleId") {
            if (e.target.value === "") {
                const element = document.getElementById(e.target.name);
                element.style.display = "block";
                this.setState({
                    error: true,
                });
            } else {
                this.removeError(e);
            }

        }
        else {
            this.removeError(e)
        }
    }


    render() {
        return (
            <div>
                 {this.state.showLoader && <Loader />}
                <div className={this.props.openAddADUser ? "slide-overlay open" : "slide-overlay"}></div>
                <div className={this.props.openAddADUser ? "sidebar-slide open" : "sidebar-slide"}>
                    <div className="s-header">
                        <h1 className="ss-title">{this.props.userId ? 'Update User' : 'Add New User'}</h1>
                        <button onClick={() => this.props.closeAddUser()}>
                            <img src="/images/svg-icons/s-cancel.svg" alt="" />
                        </button>
                    </div>
                    <div className='s-body'>
                        <div className='row'>
                            <div className='col-md-6 col-sm-12'>
                                <div className='form-group'>
                                    <label>First Name
                                        <span className="red-txt">*</span>
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="First Name of User">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </label>
                                    <input type='text' name="firstName" defaultValue={this.state.userData.firstName}
                                        onChange={this.state.userId ? this.updateUserData : this.setUserData}
                                        onBlur={(e) => this.validateUser(e)} className='form-control' />
                                    <div className="error-txt" id="firstName">Invalid value</div>
                                </div>
                            </div>
                            <div className='col-md-6 col-sm-12'>
                                <div className='form-group'>
                                    <label>Last Name
                                        <span className="red-txt">*</span>
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Last Name of User">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </label>
                                    <input type='text' name="lastName" defaultValue={this.state.userData.lastName}
                                        onChange={this.state.userId ? this.updateUserData : this.setUserData}
                                        onBlur={(e) => this.validateUser(e)} className='form-control' />
                                    <div className="error-txt" id="lastName">Invalid value</div>
                                </div>
                            </div>
                            {/* {this.state.userId ?
                                "" :  */}
                            <div className='col-md-6 col-sm-12'>
                                <div className='form-group'>
                                    <label>Email
                                    <span className="red-txt">*</span>
                                    </label>
                                    <input type='text' name="email" defaultValue={this.state.userData.email}
                                        onChange={this.state.userId ? this.updateUserData : this.setUserData}
                                        onBlur={(e) => this.validateUser(e)} className='form-control' />
                                    <div className="error-txt" id="email">Invalid Email</div>
                                </div>
                            </div>
                            {/* {this.state.userId ? "" : */}
                            <div className='col-md-6 col-sm-12'>
                                <div className='form-group'>
                                    <label>Role
                                        <span className="red-txt">*</span>
                                        <div className="tooltip-c" data-toggle="tooltip" data-placement="right" title="Role of User">
                                            <img src="/images/svg-icons/info.svg" alt="" />
                                        </div>
                                    </label>
                                    <select name="roleId"
                                        onChange={this.state.userId ? (e) => this.updateUserData(e) :
                                            (e) => this.setUserData(e)}
                                        value={this.state.userData.roleId}
                                        onBlur={(e) => this.validateUser(e)} className='form-control'>
                                        <option value="">Select Role</option>
                                        {this.state.roles.map((role) => {
                                            return <option value={role.roleId}>{role.roleName}</option>
                                        })}
                                    </select>
                                    <div className="error-txt" id="roleId">Invalid value</div>
                                </div>
                            </div>
                            {/* } */}
                        </div>
                    </div>
                    <div className="s-footer">
                        <button className="btn btn-light" onClick={() => this.props.closeAddUser()}>Cancel</button>
                        <button className="btn btn-primary" 
                         disabled={this.state.userId ? this.state.error : this.errorEmpty() || this.checkUserDataError()} 
                         onClick={this.state.userId ? () => this.handleUpdateUser() :
                            () => this.handleAddUser()}>{this.state.userId ? "Update" : "Save"}</button>

                    </div>
                </div>
                {(this.state.show) &&
                    <ErrorPopup
                        title={this.state.title}
                        message={this.state.message}
                        error={this.state.error}
                        reset={() => this.resetPopup()}
                    />
                }
                {(this.state.showRefreshModal) &&
                    <ErrorHandlePopup
                    />
                }

            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return ({
        openAddADUser: state.displayAddDevice.openAddADUser,
    });
}
export default connect(mapStateToProps)(AddNewUser);