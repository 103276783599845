import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ApiCall from "../../../redux/API/apiCall";
import urlMapping from "../../../redux/API/api";
import { Loader } from '../../../layOuts/Loader';
import moment from 'moment';
import $ from 'jquery';
import DataTable from "react-data-table-component";
import * as Action from '../../../redux/actionTypes';
import ApplyTemplate from "../../templates/AddTemplates/ApplyTemplate";
import { ErrorPopup } from '../../../layOuts/errorPopup';
import * as Common from '../../../utils/common';
import ErrorHandlePopup from "../../ErrorHandlePopup";
class AppliedTemplateList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRefreshModal: false,
            showLoader: false,
            multipleSelect: '',
            title: '',
            message: '',
            edit: false,
            show: false,
            error: false,
            delete: false,
            option: {},
            id: this.props?.id || "",
            appliedTemplateList: [],
            applyTemplateData: {},
            openApplyTemplate: true,
            deviceData: this.props?.deviceData || "",
            view: false,
            interfaces: [],
            showPopup: false,
            isAxiosError: false,
            columns: [
                {
                    name: "Template Name",
                    selector: "template_name",
                    value: "Template Name",
                    cell: (row) => (<span title={row?.template_name} style={{ width: 'auto' }}>
                        {row?.template_name}</span>
                    ),
                },
                {
                    name: "Template Type",
                    selector: "template_type",
                    value: "Template Type",
                    center: true,
                    cell: (row) => (<span title={Common.getTemplateType(row?.template_type)} style={{ width: 'auto' }}>
                        {Common.getTemplateType(row?.template_type)}</span>
                    ),
                },
                {
                    name: "Supported Version",
                    selector: "supported_verion",
                    value: "Supported Version",
                    center: true,
                    cell: (row) => (
                        <span title={"Version of templates supported by the group"} style={{ width: "auto" }}>
                            {row?.versions?.supported_version}
                        </span>
                    ),
                }, {
                    name: "Applied Version",
                    selector: "applied_verion",
                    value: "Applied Version",
                    center: true,
                    cell: (row) => (
                        <span title={"Version of the applied templates for the group"} style={{ width: "auto" }}>
                            {row?.versions?.applied_version}
                        </span>
                    ),
                }, {
                    name: "Latest Version",
                    selector: "latest_verion",
                    value: "Latest Version",
                    center: true,
                    cell: (row) => (
                        <span title={"Version of the latest templates available in the EM"} style={{ width: "auto" }}>
                            {row?.versions?.latest_version}
                        </span>
                    ),
                },
                {
                    name: "Last Updated On",
                    selector: "created_date",
                    value: "Last Updated On",
                    center: true,
                    cell: (row) => (<span title={row?.created_date} style={{ width: 'auto' }}>
                        {this.getTimeExact(row?.created_date)}</span>
                    ),
                },
                {
                    value: "Action",
                    center: true,
                    cell: (row) => (
                        <div className="da-box">
                            <img className="a-dots" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" src="/images/svg-icons/ellipsis.svg" alt="" />
                            <div className="drop-box dropdown-menu">
                                <ul>
                                    {/* Disable view for Device Provision templates */}
                                    <li
                                        // className={this.disableOption(row)}
                                        onClick={this.disableClick(row, 'View')}>
                                        <img src="/images/svg-icons/view.svg" alt="" />View</li>
                                    {row.template_operation_status !== "Pending" &&
                                        // LONG-3916 : Disable Edit for Device Provision templates
                                        <li className={
                                            (process.env.REACT_APP_AD_USER === 'TRUE' &&
                                            (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                                            localStorage.getItem('ssoUser') !== 'true' && sessionStorage.getItem('ssoUser') !== 'true')) ||
                                            (this.props?.userRole === "Viewer_Group" ||
                                              this.props?.userRole === "Operator_Group")
                                                ? "disabled"
                                                : this.disableOption(row)
                                        }
                                            // Disable view for Device Provision templates
                                            onClick={
                                                (process.env.REACT_APP_AD_USER === 'TRUE' &&
                                    (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' &&
                                      sessionStorage.getItem('ssoUser') !== 'true')) ||
                                    (this.props?.userRole === "Viewer_Group" ||
                                      this.props?.userRole === "Operator_Group")
                                                    ? undefined
                                                    : this.disableClick(row, 'Edit & ReApply')
                                            }>
                                            <img className="edit" src="/images/svg-icons/reply.svg" alt="" />Edit & ReApply Template</li>}
                                </ul>
                            </div>
                        </div>
                    ),
                    allowOverflow: true,
                    button: true,
                    width: "56px",
                },
            ],
        }
    }
    componentDidMount() {
        this.getAppliedTemplateList();
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.id || nextProps.deviceData) {
            this.setState({
                id: nextProps.id || "",
                deviceData: nextProps.deviceData || ""
            }, () => this.getAppliedTemplateList()
            )
        }
    }
    getTimeExact = (time) => {
        let date = moment.utc(time);
        date = date.local();
        return date.format('DD MMMM YYYY hh:mm:ss A');
    }
    //LONG-3595-EM - [Device group]Not able to map interface in edit and re-apply network template
    getModulesData = async () => {
        this.setState({
            showLoader: true,
        });
        await ApiCall(urlMapping.getModuleList(), (response) => {
            this.setState({
                showLoader: false,
            });
            if (response?.success) {
                Object.keys(response?.data?.models_interface)?.map(item => {
                    if (item === this.state.deviceData.supported_models) {
                        this.setState({
                            templateVersions: response?.data,
                            interfaces: response?.data?.models_interface?.[item],
                        });
                    }
                })
            } else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({ showRefreshModal: true });
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            }
        });
    };
    getAppliedTemplateList = () => {
        this.setState({ showLoader: true })
        let data = { group_id: this.state.id }
        ApiCall(urlMapping.deviceGroupTemplateList(data), async (response) => {
            if (response?.Success) {
                await this.getModulesData();
                let model = Common.getModels(this.state?.deviceData?.supported_models);
                if (model === 'MIFI') {
                    model = Common.getMiFiModels(this.state?.deviceData?.supported_models);
                }
                response?.data?.map((item) => {
                    let templateType = item?.template_type;
                    if (templateType === 'TunnelConfiguration') {
                        templateType = 'DataTunnel'
                    } else if (templateType === 'DNSFiltering') {
                        templateType = 'DNSFilteringConfiguration'
                    }
                    let supported_version = this.state.deviceData?.supported_templates?.[String(templateType)];
                    let applied_version = model === "vCPE"
                        ? item.template_version?.cbr
                        : model === "CHIMAY"
                            ? item.template_version?.chimay
                            : item.template_version?.mifi;
                    let latest_version = model === "vCPE"
                        ? this.state.templateVersions?.cbr_templates_version?.[String(templateType)]
                        : model === "CHIMAY"
                            ? this.state.templateVersions?.chimay_templates_version?.[String(templateType)]
                            : this.state.templateVersions?.mifi_templates_version?.[String(templateType)];
                    item.versions = {
                        supported_version: supported_version || "-",
                        applied_version: applied_version || "-",
                        latest_version: latest_version || "-"
                    }
                    return item
                })
                this.setState({
                    appliedTemplateList: response?.data,
                    showLoader: false,
                })
            } else {
                if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                    this.setState({ showLoader: false, showRefreshModal: true })
                } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                } else {
                    this.setState({
                        appliedTemplateList: [],
                        showLoader: false
                    })
                }
            }
        })
    }
    multipleSelect(rows) {
        if (!rows.selectedRows.length)
            this.setState({ multipleSelect: "" });
        else {
            this.setState({ multipleSelect: rows.selectedRows }
            )
        }
    }
    // LONG-3916 : Disable Edit for Device Provision templates
    // Disable view for Device Provision templates
    disableOption = (row) => {
        //LONG-3700-EM - Edit and re-apply of BGP template is applying incorrectly
        if (row.template_type === 'DeviceProvision' || row.template_type === 'BGPConfiguration')
            return "disabled"
        else if (this.state.deviceData.is_ippt && row.template_type !== "NetworkConfiguration" &&
            row.template_type !== "TriggerConfiguration" && row.template_type !== "ModemConfiguration" &&
            row.template_type !== "SystemConfiguration") {
            return "disabled"
        }
        // else if (process.env.REACT_APP_AD_USER === 'TRUE' &&
        //     (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' && 
        //     sessionStorage.getItem('ssoUser') !== 'true'))
        //     return "disabled"
        else
            return undefined
    }
    disableClick = (row, value) => {
        //LONG-3700-EM - Edit and re-apply of BGP template is applying incorrectly
        if (row.template_type === 'DeviceProvision' || row.template_type === 'BGPConfiguration')
            return undefined
        else if (this.state.deviceData.is_ippt && row.template_type !== "NetworkConfiguration" &&
            row.template_type !== "TriggerConfiguration" && row.template_type !== "ModemConfiguration" &&
            row.template_type !== "SystemConfiguration" && value === 'Edit & ReApply') {
            return "disabled"
        }
        // else if (process.env.REACT_APP_AD_USER === 'TRUE' &&
        //     (this.props.userRole !== 'Admin' && this.props.userRole !== 'Manager' && 
        //     sessionStorage.getItem('ssoUser') !== 'true') && value !== 'View')
        //     return "disabled"
        // Disable view for Device Provision templates
        else {
            if (value === 'View') {
                return () => { this.handleApplyTemplate(row, true, false); }
            }
            else {
                return () => { this.handleApplyTemplate(row, false, true); }
            }
        }
    }
    handleApplyTemplate = (data, view, edit) => {
        // Trigger config view issue fix for device group
        if (this.state.deviceData.is_all_device_online === 1 && (edit === true || view === true)) {
            let model = Common.getModels(this.state.deviceData.supported_models);
            if (model === 'MIFI') {
                model = Common.getMiFiModels(this.state.deviceData.supported_models);
            }
            const idData = {
                group_id: this.state.id
            }
            let templateType = data.template_type;
            let createdTemplateVersionCBR = data.template_version?.cbr;
            let createdTemplateVersionChimay = data.template_version?.chimay;
            let createdTemplateVersionMifi = data.template_version?.mifi;
            ApiCall(urlMapping.getModuleList(), (response1) => {
                if (response1?.success) {
                    if (templateType === 'TunnelConfiguration') {
                        templateType = 'DataTunnel'
                    } else if (templateType === 'DNSFiltering') {
                        templateType = 'DNSFilteringConfiguration'
                    }
                    let currentTemplateVersionCBR = response1?.data?.cbr_templates_version?.[templateType];
                    let currentTemplateVersionChimay = response1?.data?.chimay_templates_version?.[templateType];
                    let currentTemplateVersionMifi = response1?.data?.mifi_templates_version?.[templateType];
                    const versionCheck = model === 'vCPE' ?
                        createdTemplateVersionCBR === currentTemplateVersionCBR : (model === 'CHIMAY' ?
                            createdTemplateVersionChimay === currentTemplateVersionChimay :
                            createdTemplateVersionMifi === currentTemplateVersionMifi
                        )
                    if (data.template_version === null || templateType === 'TriggerConfiguration' || versionCheck) {
                        if (edit === true && data.template_type === 'NetworkConfiguration') {
                            this.setState({
                                networkViewData: [],
                                applyTemplateData: data,
                                openApplyTemplate: true,
                                view,
                                edit,
                                selectedModel: model,
                            }, () => {
                                this.props.dispatch({
                                    type: Action.OPEN_APPlY_TEMPLATE,
                                });
                            });
                        }
                        else {
                            if (String(idData.group_id) !== "")
                                ApiCall(urlMapping.groupNetworks(idData), (response) => {
                                    if (response?.success) {
                                        this.setState({
                                            showLoader: false,
                                            networkViewData: response?.data,
                                            applyTemplateData: data,
                                            openApplyTemplate: true,
                                            selectedModel: model,
                                            view,
                                            edit
                                        }, () => {
                                            this.props.dispatch({
                                                type: Action.OPEN_APPlY_TEMPLATE,
                                            });
                                        });
                                    } else {
                                        if (parseInt(response?.response?.status) === 401 || parseInt(response?.response?.status) === 403) {
                                            this.setState({ showRefreshModal: true })
                                        } else if (parseInt(response?.response?.status) === 404 && response?.response?.data) {
                                            this.setState({
                                                title: 'Tenant Error',
                                                message: response.response.data.message,
                                                showLoader: false,
                                                isAxiosError: true,
                                                delete: false,
                                                error: true
                                            }, () => $("#errorMessage").modal("show"))
                                        }
                                        else if (response?.isAxiosError && response?.success === undefined && response?.response?.data?.success === undefined) {
                                            this.setState({
                                                title: 'Network Error',
                                                message: 'Something went wrong. Please try again later.',
                                                showLoader: false,
                                                isAxiosError: true,
                                                delete: false,
                                                error: true
                                            }, () => $("#errorMessage").modal("show"))
                                        }
                                    }
                                });
                        }
                    } else {
                        this.setState(
                            {
                                title: "Version Mismatch",
                                message: 'Template version is outdated, as EDGE Manager is upgraded, Please create new template to proceed further',
                                showPopup: true,
                                error: true,
                                delete: true,
                            },
                            () => {
                                $("#versionErrorMessage").modal("show");
                            }
                        );
                    }
                } else if (parseInt(response1?.response?.status) === 404 && response1?.response?.data) {
                    this.setState({
                        title: 'Tenant Error',
                        message: response1.response.data.message,
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
                else if (response1?.isAxiosError) {
                    this.setState({
                        title: 'Network Error',
                        message: 'Something went wrong. Please try again later.',
                        showLoader: false,
                        isAxiosError: true,
                        delete: false,
                        error: true
                    }, () => $("#errorMessage").modal("show"))
                }
            })
        } else if (this.state.deviceData.is_all_device_online === 1 || view === true) {
            let model = '';
            if (data.template_type === 'TriggerConfiguration') {
                model = Common.getModels(this.state.deviceData.supported_models);
                if (model === 'MIFI') {
                    model = Common.getMiFiModels(this.state.deviceData.supported_models);
                }
            }
            this.setState({
                applyTemplateData: data,
                openApplyTemplate: true,
                selectedModel: model,
                view,
                edit
            }, () => {
                this.props.dispatch({
                    type: Action.OPEN_APPlY_TEMPLATE,
                });
            });
        }
        else {
            this.setState({
                title: 'Re-apply Template',
                message: 'To apply template device must be online!',
                show: true,
                error: true,
                view,
                edit,
                status: this.state.deviceData.device_status,
            }, () => {
                $("#errorMessage").modal("show");
            });
        }
    }
    resetPopup = () => {
        this.setState({
            show: false,
            error: false,
            delete: false,
            factoryReset: false,
            reboot: false,
        }, () => {
            $("#errorMessage").modal("hide");
        })
    }
    render() {
        return (
            <> {this.state.showLoader && <Loader />}
                < div className="white-bg" >
                    <div className="ta-box set-pos mb-0">
                        <div className="action-btn">
                            <button
                                onClick={() => this.getAppliedTemplateList()}>
                                <img src="/images/svg-icons/refreshing.svg" alt="" />&nbsp;Refresh</button>
                        </div>
                    </div>
                    <DataTable
                        columns={this.state.columns}
                        data={this.state.appliedTemplateList}
                        highlightOnHover
                        fixedHeader
                    />
                    {
                        this.state.openApplyTemplate
                        && Object.keys(this.state.applyTemplateData).length > 0
                        && <ApplyTemplate
                            data={this.state.applyTemplateData}
                            selectedModel={this.state.selectedModel}
                            deviceGroup={true}
                            //LONG-3699-EM - Network selection dropdown is not displaying while applying WiFi configuration
                            networkViewData={this.state.networkViewData}
                            group_id={this.state.id}
                            group_name={this.props.group_name ? this.props.group_name : ""}
                            device_group_uuid={this.state.deviceData.group_uuid}
                            view={this.state.view}
                            edit={this.state.edit}
                            link={!this.state.edit}
                            interfaces={this.state.interfaces}
                            info={false}
                            props={this.props}
                            refreshList={() => this.getAppliedTemplateList()}
                        />
                    }
                    {(this.state.show) &&
                        <ErrorPopup
                            title={this.state.title}
                            message={this.state.message}
                            error={this.state.error}
                            delete={this.state.delete}
                            reset={() => this.resetPopup()}
                            onSubmit={() => this.chooseAction(this.state.delete, this.state.factoryReset, this.state.reboot, this.props.location.state.data.device_id)}
                        />
                    }
                    {/* error popup for version mismatch */}
                    {this.state.showPopup && (
                        <div
                            className="modal fade errorMessage"
                            id="versionErrorMessage"
                            tabIndex="-1"
                            data-backdrop="static"
                            role="dialog"
                            aria-labelledby="errorMessageTitle"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-header bg-white border-0">
                                    </div>
                                    <div className="modal-body pt-5">
                                        <div className="error-msg">
                                            <span className={this.state.error ? "error-icon" : "success-icon"}>
                                                {this.state.error ? (this.state.info ? 'i' : '!') : ''}
                                            </span>
                                            <div className="txt1">{this.state.title}</div>
                                            <div className="txt2">{this.state.message}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer border-top-0 justify-content-center pb-4">
                                        {(this.state.delete || this.state.error) &&
                                            <button
                                                type="button"
                                                className={this.state.error ? "btn btn-danger" : "btn btn-primary"}
                                                data-dismiss="modal"
                                                onClick={() => this.resetPopup()}>
                                                {'OK'}
                                            </button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {this.state.showRefreshModal && <ErrorHandlePopup />}
                    {(this.state.isAxiosError) &&
                        <ErrorPopup
                            title={this.state.title}
                            message={this.state.message}
                            error={this.state.error}
                            delete={this.state.delete}
                            reset={() => this.setState({ isAxiosError: false })}
                        />
                    }
                </div >
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return ({
        authenticated: state.auth.authenticated,
        open: state.displaySidebarMenu.openSide,
        userRole: JSON.parse(localStorage.getItem('user'))?.userRole,
        SsoUser: state.auth.SsoUser
    });
}
export default withRouter(connect(mapStateToProps)(AppliedTemplateList));
